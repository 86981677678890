import {
    SPLIT_ORDER_REQUEST,
    SPLIT_ORDER_SUCCESS,
    SPLIT_ORDER_FAIL,
    GET_SHIPMENTS_BY_ORDER_ID_REQUEST,
    GET_SHIPMENTS_BY_ORDER_ID_SUCCESS,
    GET_SHIPMENTS_BY_ORDER_ID_FAIL,
    ASSIGN_DELIVERY_PERSON_REQUEST,
    ASSIGN_DELIVERY_PERSON_SUCCESS,
    ASSIGN_DELIVERY_PERSON_FAIL,
    CLEAR_ERRORS
} from '../constants/shipmentConstants';
import axiosInstance from '../utils/api';  // You can modify the import path if necessary

// Split an order into shipments
export const splitOrderIntoShipments = (orderData) => async (dispatch) => {
    try {
        dispatch({ type: SPLIT_ORDER_REQUEST });

        const { data } = await axiosInstance.post('/shipment-orders/split-order', orderData);

        dispatch({
            type: SPLIT_ORDER_SUCCESS,
            payload: data.shipments
        });
    } catch (error) {
        dispatch({
            type: SPLIT_ORDER_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

// Assign a delivery person to a shipment
export const assignDeliveryPerson = (shipmentId, deliveryPersonId) => async (dispatch) => {
    try {
        dispatch({ type: ASSIGN_DELIVERY_PERSON_REQUEST });

        const { data } = await axiosInstance.patch('/shipment-orders/assign-delivery-person', {
            shipment_id: shipmentId,
            delivery_person_id: deliveryPersonId
        });

        dispatch({
            type: ASSIGN_DELIVERY_PERSON_SUCCESS,
            payload: data.message // You might want to adjust this based on your API response
        });
    } catch (error) {
        dispatch({
            type: ASSIGN_DELIVERY_PERSON_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

// Get shipments by Order ID
export const getShipmentsByOrderId = (orderId) => async (dispatch) => {
    try {
        dispatch({ type: GET_SHIPMENTS_BY_ORDER_ID_REQUEST });

        const { data } = await axiosInstance.get(`/shipment-orders/${orderId}`);

        dispatch({
            type: GET_SHIPMENTS_BY_ORDER_ID_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_SHIPMENTS_BY_ORDER_ID_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

// Clear Errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
