import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SingleAreaChart = ({ orders }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (orders && orders.length > 0) {
      // Get the earliest and latest order dates
      const dates = orders.map((order) => new Date(order.order_date));
      const initialStartDate = new Date(Math.min(...dates));
      const initialEndDate = new Date(Math.max(...dates));

      // Set the initial dates
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);

      // Set the initial chart data using count of orders
      const orderCounts = countOrdersByDate(orders);
      setChartData(orderCounts);
    }
  }, [orders]);

  useEffect(() => {
    if (orders && orders.length > 0 && startDate && endDate) {
      const filteredOrders = orders.filter((order) => {
        const orderDate = new Date(order.order_date); // Use order_date for filtering
        return orderDate >= startDate && orderDate <= endDate;
      });

      // Update chart data based on the selected date range using count of orders
      const orderCounts = countOrdersByDate(filteredOrders);
      setChartData(orderCounts);
    }
  }, [orders, startDate, endDate]);

  console.log("chart data:", chartData);

  // Function to count orders by date
  const countOrdersByDate = (orders) => {
    const counts = {};
    orders.forEach((order) => {
      const date = new Date(order.order_date).toLocaleDateString(); // Format date
      counts[date] = (counts[date] || 0) + 1; // Increment count for that date
    });
    return Object.entries(counts).map(([date, count]) => ({ date, count })); // Convert to array
  };

  const options = {
    chart: {
      height: 300,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Order Count",
        data: chartData.map((item) => item.count), // Use counts for data
      },
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      strokeDashArray: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.1,
        opacityTo: 0.8,
      },
    },
    xaxis: {
      type: "category",
      categories: chartData.map((item) => item.date), // Use dates for categories
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#9ca3af",
          fontSize: "13px",
          fontFamily: "Inter, ui-sans-serif",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      labels: {
        align: "left",
        style: {
          colors: "#9ca3af",
          fontSize: "13px",
          fontFamily: "Inter, ui-sans-serif",
          fontWeight: 400,
        },
        formatter: (value) => (value >= 1000 ? `${value / 1000}k` : value),
      },
    },
    tooltip: {
      x: {
        format: "MMMM yyyy",
      },
      y: {
        formatter: (value) => `${value >= 1000 ? `${value / 1000}k` : value}`,
      },
    },
    responsive: [
      {
        breakpoint: 568,
        options: {
          chart: {
            height: 300,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#9ca3af",
                fontSize: "11px",
                fontFamily: "Inter, ui-sans-serif",
                fontWeight: 400,
              },
              formatter: (value) =>
                value >= 1000 ? `${value / 1000}k` : value,
            },
          },
        },
      },
    ],
  };

  return (
    <div>
      <div className="mb-4 flex">
        <DatePicker
          className="border border-gray-300 rounded-md p-2 w-48 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
        />
        <DatePicker
          className="border border-gray-300 rounded-md p-2 w-48 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 ml-10"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
        />
      </div>
      <div id="hs-single-area-chart">
        <Chart
          options={options}
          series={options.series}
          type="area"
          height={300}
        />
      </div>
    </div>
  );
};

export default SingleAreaChart;
