import { useEffect, useState, useRef } from "react";
import Sidebar from "./Sidebar/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const Dashboard = ({ activeTab, children }) => {
  const [onMobile, setOnMobile] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isOpenNotfi, setIsOpenNotfi] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dashboardRef = useRef(null);

  const { user, isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    console.log("Dashboard children:", children);
  }, [children])

  useEffect(() => {
    const handleResize = () => {
      setOnMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("Dashboard page");
  }, []);

  const setTogglebar = () => {
    setToggle(!toggle);
  };
  useEffect(() => {
    if (onMobile) {
      setToggleSidebar(false);
    }
  }, [onMobile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dashboardRef.current &&
        !dashboardRef.current.contains(event.target)
      ) {
        setToggleSidebar(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    console.log("Logging out...");
  };

  const handleProfile = () => {
    navigate("/admin/profile");
    setIsDropdownOpen(!isDropdownOpen);
  };

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div ref={dashboardRef} className="flex bg-[#f9f9fb] min-h-screen sm:min-w-full">
      {!onMobile && !toggle &&  <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar} />}
      {toggleSidebar && (
        <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar} />
      )}
      <div
        className={`w-full min-h-screen ${
          toggle ? "" : "lg:ml-[18rem] sm:ml-48 md:ml-[10rem] sm:w-4/5"
        } `}
      >
        {!onMobile && (
          <div className="w-full bg-white shadow-md lg:w-full">
            <div className="flex justify-between items-center p-2 border-b border-gray-300">
              <div className="flex items-center gap-4 text-gray-500">
                <button className="pl-4" onClick={setTogglebar}>
                  <MenuIcon />
                </button>
                <span className="mt-1 text-lg">Overview</span>
              </div>
              <div className="pr-4 cursor-pointer">
                {user && (
                  <div className="relative">
                    <button
                      className="flex items-center space-x-2 bg-white rounded-full p-2 focus:outline-none hover:bg-gray-200"
                      onClick={handleDropdownToggle}
                    >
                      <div className="relative">
                        <img
                          src={user.owner_photo}
                          className="w-10 h-10 rounded-full object-cover shadow-md"
                          alt="User Avatar"
                        />
                        <span className="absolute right-0 bottom-0 w-3 h-3 rounded-full bg-green-500 border-2 border-white"></span>
                      </div>
                      <span className="text-gray-700">{user.name}</span>
                      <span className="ml-2">&#9662;</span>
                    </button>
                    {isDropdownOpen && (
                      <div className="absolute right-0 mt-3 bg-white border-b border-gray-300 rounded-md shadow-lg z-10 w-60">
                        <div className="flex items-center px-4 py-2 border-b border-gray-300">
                          <img
                            src={user.owner_photo}
                            className="w-8 h-8 rounded-full object-cover shadow-md"
                            alt="User Avatar"
                          />
                          <span className="ml-2 text-gray-700">{user.name}</span>
                        </div>
                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={handleProfile}
                        >
                          Profile
                        </button>
                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={handleLogout}
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col gap-6 p-4 pb-6 overflow-hidden">
          <div className="flex justify-between">
            <div>
              <button
                onClick={() => setToggleSidebar(true)}
                className="sm:hidden bg-gray-700 w-10 h-10 rounded-full shadow text-white flex items-center justify-center"
              >
                <MenuIcon />
              </button>
            </div>
            <div className="flex gap-2 sm:hidden">
              <div onClick={() => setIsOpenNotfi(true)}>
                <NotificationsIcon
                  className="mt-1"
                  style={{ color: "#f33636" }}
                />
              </div>
              <div className="relative">
                <img
                  src={user.owner_photo}
                  className="w-10 h-10 rounded-full object-cover shadow-md cursor-pointer"
                  alt="User Avatar"
                  onClick={handleDropdownToggle}
                />
                <span className="absolute right-0 bottom-0 w-3 h-3 rounded-full bg-green-500 border-2 border-white"></span>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-3 bg-white border-b border-gray-300 rounded-md shadow-lg z-10 w-60">
                    <div className="flex items-center px-4 py-2 border-b border-gray-300">
                      <img
                        src={user.owner_photo}
                        className="w-8 h-8 rounded-full object-cover shadow-md"
                        alt="User Avatar"
                      />
                      <span className="ml-2 text-gray-700">{user.name}</span>
                    </div>
                    <button
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={handleProfile}
                    >
                      Profile
                    </button>
                    <button
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          
        </div>
        {children}
      </div>
    </div>
  );
};

export default Dashboard;
