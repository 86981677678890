import axios from "axios";
import {
  ALL_ORDERS_FAIL,
  ALL_ORDERS_REQUEST,
  ALL_ORDERS_SUCCESS,
  CLEAR_ERRORS,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  FORWARD_ORDER_FAIL,
  FORWARD_ORDER_REQUEST,
  FORWARD_ORDER_SUCCESS,
  MY_ORDERS_FAIL,
  MY_ORDERS_REQUEST,
  MY_ORDERS_SUCCESS,
  NEW_ORDER_FAIL,
  NEW_ORDER_REQUEST,
  NEW_ORDER_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  PAYMENT_STATUS_FAIL,
  PAYMENT_STATUS_REQUEST,
  PAYMENT_STATUS_SUCCESS,
  UPDATE_ORDER_ITEM_STATUS_REQUEST,
  UPDATE_ORDER_ITEM_STATUS_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_ITEM_STATUS_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_STATUS_FAIL,
  UPDATE_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  GET_ORDER_SUCCESS,
  GET_ORDER_REQUEST,
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  ORDERS_FAIL,
} from "../constants/orderConstants";
import axiosInstance from "../utils/api";
import { type } from "@testing-library/user-event/dist/type";

// New Order
export const newOrder = (order) => async (dispatch) => {
  try {
    dispatch({ type: NEW_ORDER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Create order in backend
    const { data } = await axiosInstance.post("/orders/add", order, config);

    console.log("Backend order response:", data); // Log the response for debugging

    // Dispatch success action
    dispatch({
      type: NEW_ORDER_SUCCESS,
      payload: data,
    });

    // Extract order_id and payment_reference_id from the response
    const { order_id, payment_reference_id } = data;

    // Return the order_id and payment_reference_id to the calling function
    return {
      order_id, // Keep the same naming convention
      payment_reference_id, // Keep the same naming convention
    };
  } catch (error) {
    console.error("Error creating order:", error); // Log any error that occurs

    dispatch({
      type: NEW_ORDER_FAIL,
      payload: error.response?.data?.message || "Unknown error occurred",
    });

    throw error; // Rethrow to handle in the component
  }
};

// Get User Orders
export const myOrders = () => async (dispatch) => {
  try {
    dispatch({ type: MY_ORDERS_REQUEST });

    // Retrieve phone number from localStorage
    const userPhoneNumber = localStorage.getItem("userPhoneNumber");

    // Check if phone number exists
    if (!userPhoneNumber) {
      throw new Error("Phone number is not available in localStorage");
    }

    // Make the request with the phone number to get orders
    const { data: ordersData } = await axiosInstance.get(`/orders/order-by-phone`, {
      params: { phone_number: userPhoneNumber }  // Ensure parameter name matches
    });

    // Fetch shipments for each order
    const ordersWithShipments = await Promise.all(
      ordersData.map(async (order) => {
        try {
          const { data: shipmentsData } = await axiosInstance.get(`/shipment-orders/${order.order_id}`);
          return {
            ...order,
            shipments: shipmentsData || [], // Add shipments to the order, default to empty array if none
          };
        } catch (error) {
          console.error(`Error fetching shipments for order ${order.order_id}:`, error);
          return {
            ...order,
            shipments: [], // Default to empty array if there's an error fetching shipments
          };
        }
      })
    );

    dispatch({
      type: MY_ORDERS_SUCCESS,
      payload: ordersWithShipments, // Ensure the payload matches the expected format
    });
  } catch (error) {
    console.error("Error fetching orders:", error);
    dispatch({
      type: MY_ORDERS_FAIL,
      payload: error.response ? error.response.data.message : error.message,
    });
  }
};

// Get Order Details
export const getOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDER_REQUEST });

    // Fetch order details
    const { data: orderData } = await axiosInstance.get(`/orders/order/${id}`);
    console.log('API Response:', orderData);

    const ordersWithShipments = await Promise.all(
      orderData.map(async (order) => {
        try {
          const { data: shipmentsData } = await axiosInstance.get(`/shipment-orders/${id}`);
          return {
            ...order,
            shipments: shipmentsData || [], // Add shipments to the order, default to empty array if none
          };
        } catch (error) {
          console.error(`Error fetching shipments for order ${id}:`, error);
          return {
            ...order,
            shipments: [], // Default to empty array if there's an error fetching shipments
          };
        }
      })
    );

    dispatch({
      type: GET_ORDER_SUCCESS,
      payload: ordersWithShipments[0],  // Send the order with shipments in the payload
    });
  } catch (error) {
    console.error('API Error:', error);
    dispatch({
      type: GET_ORDER_FAIL,
      payload: error.response?.data?.message || error.message,
    });
  }
};


// Get Payment Status
export const getPaymentStatus = (id) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_STATUS_REQUEST });

    const { data } = await axios.get(
      `https://backend.vivimart.in/api/v1/payment/status/${id}`
    );

    dispatch({
      type: PAYMENT_STATUS_SUCCESS,
      payload: data.txn,
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_STATUS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get All Orders ---ADMIN
export const getAllOrders = (store_id) => async (dispatch) => {
  try {
    dispatch({ type: ALL_ORDERS_REQUEST });

    // Fetch orders by store ID
    const { data: ordersData } = await axiosInstance.get(`/orders/orders-by-store`, {
      params: { store_id },
    });

    console.log("getAllOrders response data:", ordersData); // Log the response data

    // Fetch shipments for each order
    const ordersWithShipments = await Promise.all(
      ordersData.map(async (order) => {
        try {
          const { data: shipmentsData } = await axiosInstance.get(`/shipment-orders/${order.order_id}`);
          return {
            ...order,
            shipments: shipmentsData || [], // Add shipments to the order, default to empty array if none
          };
        } catch (error) {
          console.error(`Error fetching shipments for order ${order.order_id}:`, error);
          return {
            ...order,
            shipments: [], // Default to empty array if there's an error fetching shipments
          };
        }
      })
    );

    dispatch({
      type: ALL_ORDERS_SUCCESS,
      payload: ordersWithShipments, // Ensure the payload matches the expected format
    });
  } catch (error) {
    console.error("getAllOrders error:", error); // Log any errors

    dispatch({
      type: ALL_ORDERS_FAIL,
      payload: error.response ? error.response.data.message : error.message,
    });
  }
};

// Get All Orders ---ADMIN
export const getOrders = () => async (dispatch) => {
  try {
    dispatch({ type: ORDERS_REQUEST });

    const { data } = await axiosInstance.get("/orders/all"); // Adjust the endpoint if necessary

    console.log("get Orders response data:", data); // Log the response data

    dispatch({
      type: ORDERS_SUCCESS,
      payload: data, // Ensure the payload matches the expected format
    });
  } catch (error) {
    console.error("getAllOrders error:", error); // Log any errors

    dispatch({
      type: ORDERS_FAIL,
      payload: error.response ? error.response.data.message : error.message,
    });
  }
};

// Update Order ---ADMIN
export const updateOrder = (id, order) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ORDER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axiosInstance.put(
      `https://backend.vivimart.in/api/v1/admin/order/${id}`,
      order,
      config
    );

    dispatch({
      type: UPDATE_ORDER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Order Status
export const updateOrderStatus = (order_id, status) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ORDER_REQUEST });

    const config = {
      header: {
        "content-Type": "application/json",
      },
    };

    const { data } = await axiosInstance.post(
      "/orders/update-status",
      { order_id, status },
      config
    );

    dispatch({
      type: UPDATE_ORDER_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ORDER_STATUS_FAIL,
      payload: error.response.data.error,
    });
  }
};

export const updateOrderItemStatus = (order_id, items) => async (dispatch) => {
  dispatch({ type: UPDATE_ORDER_ITEM_STATUS_REQUEST });

  try {
    console.log("Dispatching updateOrderItemStatus with:", { order_id, items });

    const response = await axiosInstance.post("/orders/update-item-status", {
      order_id,
      items,
    });

    console.log("Response from updateOrderItemStatus:", response.data);

    dispatch({
      type: UPDATE_ORDER_ITEM_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error in updateOrderItemStatus:", error.response ? error.response.data : error.message);

    dispatch({
      type: UPDATE_ORDER_ITEM_STATUS_FAIL,
      payload: error.response ? error.response.data : { message: 'Unknown error' },
    });
  }
};

// Delete Order ---ADMIN
export const deleteOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ORDER_REQUEST });

    const { data } = await axios.delete(
      `https://backend.vivimart.in/api/v1/admin/order/${id}`
    );

    dispatch({
      type: DELETE_ORDER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const forwardOrder = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FORWARD_ORDER_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axiosInstance.post(
      "/orders/forward",
      payload,
      config
    );

    dispatch({ type: FORWARD_ORDER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FORWARD_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clear All Errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}