import { useNavigate } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { useSelector } from 'react-redux';

const Actions = ({ id }) => {
    const navigate = useNavigate();
    const { orders } = useSelector((state) => state.orders); // Access orders from Redux

    const handleViewDetails = () => {
        navigate(`/admin/all-orders-details/${id}`);
    }

    const handlePrintInvoice = () => {
        const order = orders.find(order => order.order_id === id); // Find the order by ID
        if (order) {
            navigate("/admin/orders/printStoreInvoice", {
                state: { order }, // Pass the order details to the print invoice route
            });
        } else {
            console.error("Order not found for ID:", id);
        }
    }

    return (
        <>
            <div className="flex justify-between items-center gap-3">
                <button onClick={handleViewDetails} className="text-yellow-600 hover:bg-yellow-300 border border-yellow-600 p-1 rounded-lg bg-yellow-200">
                    <VisibilityOutlinedIcon />
                </button>
                <button onClick={handlePrintInvoice} className="text-green-600 hover:bg-green-200 p-1 border border-green-600 rounded-lg bg-green-100">
                    <LocalPrintshopOutlinedIcon />
                </button>
            </div>
        </>
    );
};

export default Actions;