import WebFont from "webfontloader";
import Footer from "./components/Layouts/Footer/Footer";
import Header from "./components/Layouts/Header/Header";
import Register from "./components/User/Register";
import AdminLogin from "./components/Admin/Login";
import StoreLogin from "./components/Store/Login";
import StoreRegister from "./components/Store/Register";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { loginUser, loadUser, logoutUser } from "./actions/userAction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthState } from "./utils/auth";
import { trackPageView } from "./utils/analytics";
import UpdateProfile from "./components/User/UpdateProfile";
import UpdatePassword from "./components/User/UpdatePassword";
import ForgotPassword from "./components/User/ForgotPassword";
import ResetPassword from "./components/User/ResetPassword";
import ProtectedRoute from "./Routes/ProtectedRoute";
import Home from "./components/Home/Home";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import Products from "./components/Products/Products";
import Cart from "./components/Cart/Cart";
import Shipping from "./components/Cart/Shipping";
import OrderConfirm from "./components/Cart/OrderConfirm";
import Payment from "./components/Cart/Payment";
import OrderStatus from "./components/Cart/OrderStatus";
import OrderSuccess from "./components/Cart/OrderSuccess";
import MyOrders from "./components/Order/MyOrders";
import OrderDetails from "./components/Order/OrderDetails";
import Dashboard from "./components/Admin/Dashboard";
import DashboardStore from "./components/Store/Dashboard";
import MainData from "./components/Admin/MainData";
import UpdateOrder from "./components/Admin/UpdateOrder";
import ProductTable from "./components/Admin/ProductTable";
import NewProduct from "./components/Admin/NewProduct";
import UpdateProduct from "./components/Admin/UpdateProduct";
import UserTable from "./components/Admin/UserTable";
import UpdateUser from "./components/Admin/UpdateUser";
import ReviewsTable from "./components/Admin/ReviewsTable";
import Wishlist from "./components/Wishlist/Wishlist";
import NotFound from "./components/NotFound";
import Privacy from "./components/Layouts/Footer/Privacy";
import ShippingPolicy from "./components/Layouts/Footer/Shipping";
import ReturnAndReplacement from "./components/Layouts/Footer/ReturnAndReplacement";
import About from "./components/Layouts/Footer/About";
import Contact from "./components/Layouts/Footer/Contact";
import Terms from "./components/Layouts/Footer/Terms";
import Category from "./components/Admin/Category";
import SubCategory from "./components/Admin/SubCategory";
import SubSubCategory from "./components/Admin/SubSubCategory";
import Locations from "./components/Admin/Locations";
import MainDataStore from "./components/Store/MainData";
import Orders from "./components/Store/Orders";
import StoreProductTable from "./components/Store/ProductTable";
import Profile from "./components/Store/Profile";
import StoreHeader from "./components/Layouts/Header/StoreHeader";
import StoreInvoice from "./components/Store/StoreInvoice";
import Checkout from "./components/Cart/Checkout";
import SearchProducts from "./components/Layouts/SearchProducts";
import LoadingPage from "./components/LoadingPage";
import AllCategories from "./components/Home/AllCategories";
import MobileAccount from "./components/Layouts/MobileAccount";
import MyAddress from "./components/User/MyAddress";
import MyWallet from "./components/User/MyWallet";
import MyProfile from "./components/User/MyProfile";
import MobileMyOrders from "./components/Order/MobileMyOrders";
import MobileMyAddress from "./components/User/MobileMyAddress";
import MobileMyWallet from "./components/User/MobileMyWallet";
import MobileOrderDetailsPage from "./components/Order/MobileOrderDetails";
import BrandProducts from "./components/Products/BrandProducts";
import POS from "./components/Store/POS";
import BankInfo from "./components/Store/BankInfo";
import PaymentSuccess from "./components/Store/PaymentSuccess";
import Store from "./components/Admin/Store";
import SubStore from "./components/Admin/SubStore/SubStore";
import AllOrders from "./components/Admin/AllOrders/AllOrders";
import AllOrderDetails from "./components/Admin/AllOrders/AllOrderDetails";
import AdminInVoice from "./components/Admin/AdminInvoice";
import AdminOrders from "./components/Admin/Orders";
import AdminProfile from "./components/Admin/Profile";
import AcceptInvoice from "./components/SharableLink/AcceptInvoice";
import TransactionList from "./components/Store/TransactionList";
import StoreDetails from "./components/Admin/StoreDetails";
import { onMessage } from "./firebase";
import PaymentGateway from "./components/Admin/PaymentGateway";
import VirtualAccount from "./components/Admin/virtualAccount";

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAdminRoute = pathname.startsWith("/admin");
  const isStoreRoute = pathname.startsWith("/store");
  const isCheckout = pathname.startsWith("/checkout");
  const isMobileAccount = pathname.startsWith("/account");
  const isContact = pathname.startsWith("/contact-us");
  const isOrders = pathname.startsWith("/profile/orders");
  const isDetails = pathname.startsWith("/order_details");
  const isAddresses = pathname.startsWith("/profile/addresses");
  const isSharable = pathname.startsWith("/sub-store");

  const isProfile = pathname.startsWith("/profile");
  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    trackPageView(pathname);
  }, [pathname]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Poppins:300,400,500,600,700"],
      },
    });
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      await dispatch(loadUser());
      setLoading(false);
    };
    checkAuth();
  }, [dispatch]);

  // always scroll to top on route/path change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    dispatch(loadUser());
    checkAuthState(dispatch); // Check and restore auth state
  }, [dispatch]);

  if (loading) return <LoadingPage />;

  return (
    <>
      {!isContact &&
        !isMobileAccount &&
        !isAdminRoute &&
        !isStoreRoute &&
        !isSharable &&
        !isCheckout && <Header />}
      {!isProfile &&
        !isMobileAccount &&
        !isAdminRoute &&
        isStoreRoute &&
        !isSharable &&
        isLogin && <StoreHeader />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/my-wallet" element={<MyWallet />} />
        <Route path="/account/my-wallet" element={<MobileMyWallet />} />
        <Route path="/profile/:tab" element={<MyProfile />} />

        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:keyword" element={<Products />} />
        <Route path="/brand-products/:keyword" element={<BrandProducts />} />
        <Route path="/category/:categoryId" element={<AllCategories />} />
        <Route path="/search" element={<SearchProducts />} />
        <Route path="/search/:keyword" element={<SearchProducts />} />

        <Route path="/cart" element={<Cart />} />
        <Route path="/account" element={<MobileAccount />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/shipping-policy" element={<ShippingPolicy />} />
        <Route path="/return-replacement" element={<ReturnAndReplacement />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route
          path="/sub-store/accept-invoice/:uniqueId"
          element={<AcceptInvoice />}
        />

        {/* order process */}
        <Route
          path="/shipping"
          element={
            <ProtectedRoute>
              <Shipping />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/order/confirm"
          element={
            <ProtectedRoute>
              <OrderConfirm />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/process/payment"
          element={
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/orders/success"
          element={<OrderSuccess success={true} />}
        />
        <Route
          path="/orders/failed"
          element={<OrderSuccess success={false} />}
        />
        {/* order process */}

        <Route
          path="/order/:id"
          element={
            <ProtectedRoute>
              <OrderStatus />
            </ProtectedRoute>
          }
        ></Route>

        <Route path="/orders" element={<MyOrders />}></Route>
        <Route path="/account/orders" element={<MobileMyOrders />}></Route>

        <Route path="/addresses" element={<MyAddress />}></Route>
        <Route path="/account/addresses" element={<MobileMyAddress />}></Route>

        <Route path="/order_details/:id" element={<OrderDetails />}></Route>
        <Route
          path="/account/order_details/:id"
          element={<MobileOrderDetailsPage />}
        ></Route>
        <Route
          path="/account/update"
          element={
            <ProtectedRoute>
              <UpdateProfile />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/password/update"
          element={
            <ProtectedRoute>
              <UpdatePassword />
            </ProtectedRoute>
          }
        ></Route>

        <Route path="/password/forgot" element={<ForgotPassword />} />

        <Route path="/password/reset/:token" element={<ResetPassword />} />

        <Route
          path="/wishlist"
          element={
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin"
          element={
            <ProtectedRoute isAdmin>
              <Dashboard activeTab={0}>
                <MainData />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/login"
          element={<AdminLogin setIsLogin={setIsLogin} />}
        />
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <MainData />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/virtual-account"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <VirtualAccount />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/order/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <UpdateOrder />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/products"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <ProductTable />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/stores"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <Store />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/payment-gateway"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <PaymentGateway />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/storeDetails/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <StoreDetails />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/sub-stores"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <SubStore />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/profile"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <AdminProfile />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/all-orders"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <AllOrders />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/orders"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <AdminOrders />
              </Dashboard>
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/all-orders-details/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <AllOrderDetails />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/orders/printStoreInvoice"
          element={<AdminInVoice />}
        />

        <Route
          path="/admin/new_product"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <NewProduct />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/product/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <UpdateProduct />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/users"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <UserTable />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/user/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <UpdateUser />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/reviews"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <ReviewsTable />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/Category"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <Category />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/SubCategory"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <SubCategory />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/SubSubCategory"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <SubSubCategory />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/Admin/location"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard>
                <Locations />
              </Dashboard>
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/store"
          element={
            <ProtectedRoute isStore>
              <DashboardStore activeTab={0}>
                <MainDataStore />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/login"
          element={<StoreLogin setIsLogin={setIsLogin} />}
        />
        <Route path="/store/register" element={<StoreRegister />} />
        <Route
          path="/store/products"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <StoreProductTable />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/pos"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <POS />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/bank-info"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <BankInfo />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/transactions"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <TransactionList />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/orders"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <Orders />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/profile"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <Profile />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store"
          element={
            <ProtectedRoute isStore={true}>
              <DashboardStore>
                <MainDataStore />
              </DashboardStore>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/orders/printStoreInvoice"
          element={<StoreInvoice />}
        />

        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      {!isProfile &&
        !isContact &&
        !isMobileAccount &&
        !isSharable &&
        !isAdminRoute &&
        !isStoreRoute &&
        !isCheckout &&
        !isOrders &&
        !isAddresses && <Footer />}
    </>
  );
}

export default App;
