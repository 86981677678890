import React, { useState } from "react";
import { Modal, Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { createStore, getStores } from "../../actions/storeAction";

const AddStoreModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [storeData, setStoreData] = useState({
    id: "",
    name: "",
    owner_name: "",
    owner_photo: "",
    password: "",
    state: "",
    city_name: "",
    primary_location: "",
    sec_location_1: "",
    sec_location_2: "",
    sec_location_3: "",
    sec_location_4: "",
    sec_location_5: "",
    package_fee: null,
    sec_location_6: "",
    sec_location_7: "",
    sec_location_8: "",
    sec_location_9: "",
    sec_location_10: "",
    is_active: 1,
    email: "",
    phone_number: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreData({ ...storeData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createStore(storeData))
    .then(() => {
      handleClose(); // Close the modal after submitting
      dispatch(getStores());
  });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal-content" style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px', maxWidth: '500px', maxHeight: '80vh', overflowY: 'auto', margin: 'auto' }}>
        <h2>Add New Store</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            name="id"
            label="Store ID"
            value={storeData.id}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="name"
            label="Name"
            value={storeData.name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="owner_name"
            label="Owner Name"
            value={storeData.owner_name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="owner_photo"
            label="Owner Photo URL"
            value={storeData.owner_photo}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            value={storeData.password}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="state"
            label="State"
            value={storeData.state}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="city_name"
            label="City Name"
            value={storeData.city_name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="primary_location"
            label="Primary Location"
            value={storeData.primary_location}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sec_location_1"
            label="Secondary Location 1"
            value={storeData.sec_location_1}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sec_location_2"
            label="Secondary Location 2"
            value={storeData.sec_location_2}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sec_location_3"
            label="Secondary Location 3"
            value={storeData.sec_location_3}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sec_location_4"
            label="Secondary Location 4"
            value={storeData.sec_location_4}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sec_location_5"
            label="Secondary Location 5"
            value={storeData.sec_location_5}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="package_fee"
            label="Package Fee"
            type="number"
            value={storeData.package_fee}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sec_location_6"
            label="Secondary Location 6"
            value={storeData.sec_location_6}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sec_location_7"
            label="Secondary Location 7"
            value={storeData.sec_location_7}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sec_location_8"
            label="Secondary Location 8"
            value={storeData.sec_location_8}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sec_location_9"
            label="Secondary Location 9"
            value={storeData.sec_location_9}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sec_location_10"
            label="Secondary Location 10"
            value={storeData.sec_location_10}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="email"
            label="Email"
            value={storeData.email}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="phone_number"
            label="Phone Number"
            value={storeData.phone_number}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            Add Store
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default AddStoreModal;
