import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, loginUser } from "../../actions/userAction";
import { useSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MetaData from "../Layouts/MetaData";
import BackdropLoader from "../Layouts/BackdropLoader";
import Logomb from "../../assets/images/Logomb.png";
import { auth } from "../../firebase"; // Import the auth object from firebase.js
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const MobileLogin = ({ setIsMobLoginOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isContinue, setIsContinue] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const { loading, isAuthenticated, error } = useSelector(
    (state) => state.user
  );
  const redirect = location.search ? location.search.split("=")[1] : "account";
  const [seconds, setSeconds] = useState(30);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(timer);
    }
  }, [seconds]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      navigate(`/${redirect}`);
    }
  }, [dispatch, error, isAuthenticated, redirect, navigate, enqueueSnackbar]);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (isNaN(value)) return; // Only allow numeric input

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input box automatically if available
    if (index < newOtp.length - 1 && value !== "") {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleBackspace = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleDelete = (index, event) => {
    if (event.key === "Delete" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            handleSendOtp();
          },
          "expired-callback": () => {},
        }
      );
    }
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    setUpRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    const fullPhoneNumber = `+91${phoneNumber}`;

    signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier)
      .then((result) => {
        setConfirmationResult(result);
        setIsContinue(true);
        enqueueSnackbar("OTP sent successfully!", { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        console.log(error);
      });
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    const otpString = otp.join("");
    console.log(otpString);
    if (confirmationResult) {
      confirmationResult
        .confirm(otpString)
        .then((result) => {
          const user = result.user;
          console.log("user:", user.phoneNumber);
          // Store the phone number in localStorage
          localStorage.setItem("userPhoneNumber", user.phoneNumber);
          setIsSuccess(true);

          setTimeout(() => {
            setIsSuccess(false);
            setIsContinue(false);
            setIsMobLoginOpen(false);
          }, 2000);
          // Proceed with login or registration logic
          // dispatch(loginUser WithPhone(user.phoneNumber));
        })
        .catch((error) => {
          enqueueSnackbar("Invalid OTP", { variant: "error" });
          console.log(error);
        });
    }
  };

  const handleResendCode = () => {
    setUpRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    const fullPhoneNumber = `+91${phoneNumber}`;
  
    signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier)
      .then((result) => {
        setConfirmationResult(result);
        setIsContinue(true);
        enqueueSnackbar("OTP sent successfully!", { variant: "success" });
        setSeconds(10);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      {loading && <BackdropLoader />}
      <div
        className={`bg-white rounded-xl ${
          isContinue ? "px-0  pt-0" : "px-8 py-0"
        }`}
      >
        {!isContinue && (
          <>
            <div className="flex items-center justify-center">
              <div
                onClick={() => setIsMobLoginOpen(false)}
                className="cursor-pointer text-gray-500 self-start mt-3 ml-[-1.5rem]"
              >
                <KeyboardBackspaceIcon />
              </div>
              <div className="text-center flex-grow">
                <img
                  src={Logomb}
                  alt="Vivimart Logo"
                  className="mx-auto w-24 h-24"
                />
                <h2 className="text-2xl font-bold text-gray-700">
                  <span>Welcome to </span>
                  <span className="text-primary-blue">ViviMart.in</span>
                </h2>
                <p className="text-md text-gray-700 font-sans font-semibold">
                  Log in
                </p>
              </div>
            </div>

            <form
              onSubmit={handleSendOtp}
              className="space-y-4 text-center mx-auto max-w-md"
            >
              <TextField
                placeholder="Enter mobile number"
                variant="outlined"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="font-bold">+91</span>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  className: "font-bold", // Optional: Customize input label style
                }}
                sx={{
                  "& .MuiOutlinedInput-input::placeholder": {
                    color: "gray", // Change placeholder color here
                    fontStyle: "sans", // Change other styles as needed
                    fontWeight: "800",
                    fontSize: "15px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "2.7rem",
                    borderRadius: "0.6rem", // Equivalent to rounded-md in Tailwind
                  },
                }}
              />

              <button
                id="sign-in-button"
                type="submit"
                disabled={phoneNumber.length !== 10}
                className={`rounded-lg font-sans py-3 px-6 text-md font-medium w-[16rem] text-white ${
                  phoneNumber.length === 10
                    ? "bg-primary-blue"
                    : "bg-gray-400 text-gray-700 cursor-not-allowed"
                }`}
              >
                Continue
              </button>
            </form>

            <div className="text-xs text-gray-700 mt-2 text-center">
              By continuing, you agree to Vivimart's{" "}
              <Link
                to="/terms"
                className="text-primary-blue"
                onClick={() => setIsMobLoginOpen(false)}
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy"
                className="text-primary-blue"
                onClick={() => setIsMobLoginOpen(false)}
              >
                Privacy Policy.
              </Link>
            </div>
          </>
        )}
        {isContinue && !isSuccess && (
          <div className="flex flex-col justify-center items-center">
            <div
              className="flex items-center w-[15rem]"
            >
              <div
                onClick={() => setIsContinue(false)}
                className="cursor-pointer text-gray-500 self-start relative"
              >
                <KeyboardBackspaceIcon />
              </div>
              <div className="text-center flex-grow">
                <span>OTP Verification</span>
              </div>
            </div>
            <div className="text-center font-sans mt-8">
              <h2 className="pb-1 text-xs text-[#333333]">
                We have sent a verification code to
              </h2>
              <span className="text-gray-900 text-md">+91-{phoneNumber}</span>
            </div>
            <form className="text-center mt-6">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={digit}
                  onChange={(e) => handleInputChange(index, e)}
                  onKeyDown={(e) => handleBackspace(index, e)}
                  onKeyUp={(e) => handleDelete(index, e)}
                  maxLength={1}
                  className="w-12 h-12 m-1 text-center text-lg border-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-blue"
                />
              ))}
              {seconds !== 0 ? (
                <button className="text-[#0c831f] cursor-pointer block text-[14px] py-6 text-center w-full m-0">
                  Resend Code in {seconds}
                </button>
              ) : (
                <div className="w-full flex items-center justify-center py-6">
                  <button
                    className="text-white bg-primary-blue border border-primary-blue cursor-pointer block text-md py-2 px-6 rounded-md text-center m-0"
                    type="button"
                    onClick={handleResendCode}
                  >
                    Resend Code
                  </button>
                </div>
              )}
              <div className="w-full flex items-center justify-center mb-4">
                  <button
                    className="text-white bg-green-600 border border-green-600 cursor-pointer block text-md py-2 px-6 rounded-md text-center m-0"
                    type="submit"
                    onClick={handleVerifyOtp}
                  >
                    Verify
                  </button>
                </div>
            </form>
          </div>
        )}
        {isSuccess && (
          <div
            className="flex flex-col justify-center items-center gap-4"
          >
            <div className="flex flex-col items-center gap-4">
              <CheckCircleIcon style={{ fontSize: "40px", color: "green" }} />
              <span className="text-lg">Successfully Logged In!</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileLogin;