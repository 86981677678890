import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { newOrder } from "../../actions/orderAction";
import logo from "../../assets/images/logo1.png";
import Logomb from "../../assets/images/Logomb.png";
import AccordionPanel from "./AccordionPanel";
import { emptyCart } from "../../actions/cartAction";
import { getLocationByPostalCode } from "../../actions/locationAction";
import axiosInstance from "../../utils/api";
import { getPaymentGateways } from "../../actions/paymentGatewayActions"; // Adjust the path as necessary

const Checkout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const stdDelivery = location.state?.stdDelivery || [];
  const fastDelivery = location.state?.fastDelivery || [];
  const selectedAddress = location.state?.selectedAddress || {};
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [filteredGateways, setFilteredGateways] = useState([]);
  const { storeLocation } = useSelector((state) => state.storeLocation);

  const { gateways } = useSelector((state) => state.paymentGateways);

  useEffect(() => {
    dispatch(getLocationByPostalCode());
    dispatch(getPaymentGateways());
  }, [dispatch]);

  useEffect(() => {
    if (gateways?.length > 0) {
      // Filter the active gateways (where is_active === 1)
      const activeGateways = gateways.filter(gateway => gateway.is_active === 1);
      setFilteredGateways(activeGateways); // Update the filteredGateways state
      setPaymentMethod(activeGateways[0]?.name); // Set the default payment method
      console.log("Filtered gateways:", activeGateways[0]?.name);
    }
  }, [gateways]);

  const locationId = storeLocation ? storeLocation.id : 1;

  // Define cartItems based on selected delivery method
  const cartItems = fastDelivery.length > 0 ? fastDelivery : stdDelivery;

  const calculateAmount = () => {
    return cartItems.reduce(
      (total, item) => total + item.sell_price * item.quantity,
      0
    );
  };

  const totalAmount = calculateAmount();

  const handleCodSubmit = () => {
    setPaymentMethod("cod");
    handlePayment();
  };

  const handleOnlinePaymentSubmit = async (gateway) => {
    if (!paymentMethod) {
      enqueueSnackbar("Please select a payment method.", { variant: "error" });
      return;
    }

    // Fetch the payment gateway details
    const selectedGateway = filteredGateways.find(gw => gw.name === paymentMethod);
  
    if (!selectedGateway || !selectedGateway.api_key) {
      enqueueSnackbar("Invalid payment gateway.", { variant: "error" });
      return;
    }
  
    // Proceed to handle payment with the fetched gateway
    handlePayment();
  };
  

  const handlePayment = async () => {
    console.log("Current payment method:", paymentMethod);
    if (!paymentMethod) {
      enqueueSnackbar("Please Select a payment method.", { variant: "error" });
      return;
    }
  
    if (isProcessing) return;
    setIsProcessing(true);
  
    let cartItems = [];
    if (fastDelivery.length > 0) {
      cartItems = fastDelivery.map((product) => ({
        product_id: product.product_id,
        quantity: product.quantity,
        sell_price: product.sell_price,
        weight: product.weight,
        delivery_option: "FastDelivery",
      }));
    } else if (stdDelivery.length > 0) {
      cartItems = stdDelivery.map((product) => ({
        product_id: product.product_id,
        quantity: product.quantity,
        sell_price: product.sell_price,
        weight: product.weight,
        delivery_option: "StandardDelivery",
      }));
    }

    const payload = {
      location: locationId,
      cartItems,
      address: `${selectedAddress.name}, ${selectedAddress.house}, ${selectedAddress.floor}, ${selectedAddress.area}, ${selectedAddress.landmark}, ${selectedAddress.pincode}, ${selectedAddress.phoneNumber}, ${selectedAddress.selectResident}`,
      phone_number: localStorage.getItem("userPhoneNumber"),
      delivery_notes: "Leave at the front door.",
      payment_method: paymentMethod,
    };
  
    try {
      console.log("Sending order payload:", JSON.stringify(payload, null, 2));
      const response = await dispatch(newOrder(payload));
      console.log("Order response:", response);
  
      if (!response || response.error) {
        throw new Error(response?.error || "Unknown error while creating order.");
      }
  
      const { order_id: orderId, payment_reference_id: paymentReferenceId } = response;
      console.log("Order created successfully with ID:", paymentReferenceId);
  
      // Fetch payment gateway details
      const selectedGateway = filteredGateways.find(gw => gw.name === paymentMethod);
      console.log("Fetched gateway details:", selectedGateway);
  
      if (!selectedGateway || !selectedGateway.api_key) {
        enqueueSnackbar("Invalid payment gateway.", { variant: "error" });
        return;
      }

      if (response) {
        if (paymentMethod === "Razorpay") {
          console.log("Initiating Razorpay payment...");
          await handleRazorpayPayment(selectedGateway, paymentReferenceId, orderId);  // Pass paymentReferenceId here
        } else if (paymentMethod === "payu") {
          console.log("Initiating PayU payment...");
          await handlePayUPayment(selectedGateway, orderId);
        } else if (paymentMethod === "cashfree") {
          console.log("Initiating Cashfree payment...");
          await handleCashfreePayment(selectedGateway, orderId);
        } else if (paymentMethod === "cod") {
          console.log("Cash on Delivery selected.");
          enqueueSnackbar("Order placed successfully with Cash on Delivery.", { variant: "success" });
        } else {
          enqueueSnackbar("Unsupported payment method.", { variant: "error" });
          return;
        }
      } else {
        enqueueSnackbar("Failed to create order.", { variant: 'error' });
      }
  
      enqueueSnackbar(`Order placed successfully with ${paymentMethod === "cod" ? "Cash on Delivery" : "Online Payment"}.`, { variant: "success" });
    } catch (error) {
      console.error("Error in handlePayment:", error);
      enqueueSnackbar("Payment failed. Please try again.", { variant: "error" });
    } finally {
      setIsProcessing(false);
    }
  };
  
  const handleRazorpayPayment = async (selectedGateway, paymentReferenceId, orderId) => {

    console.log("paymentReferenceId:", paymentReferenceId);
    console.log("Setting up Razorpay payment options...");
    const options = {
      key: selectedGateway.api_key,
      amount: totalAmount * 100, // Amount in paise
      currency: "INR",
      name: "ViviMart",
      description: "Order Payment",
      handler: async function (response) {
        console.log("Payment response received from Razorpay:", response);
        try {
          // Verify payment on backend with the payment_reference_id received
          const verificationResponse = await verifyPayment(response, paymentReferenceId, orderId);
          console.log("Payment verification response:", verificationResponse);
          // Handle verification response (e.g., show success message or update order status)
        } catch (error) {
          enqueueSnackbar("Payment verification error.", { variant: "error" });
        }
      },
      prefill: {
        name: selectedAddress.name,
        contact: selectedAddress.phoneNumber,
      },
      theme: {
        color: "#F37254",
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  
  const verifyPayment = async (response, paymentReferenceId, orderId) => {
    console.log("Payload for verifying payment", { paymentReferenceId, orderId });
    try {
      const res = await axiosInstance.post("/orders/verify-payment", {
        payment_reference_id: paymentReferenceId,
      });
      return res.data; // Return the response from the verification API
    } catch (error) {
      console.error("Error verifying payment:", error);
      throw error; // Rethrow to handle in the component
    }
  };

  const handlePayUPayment = async (selectedGateway) => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://test.payu.in/_payment"; // Use the appropriate URL for production

    // Add necessary fields to the form
    const fields = {
      key: selectedGateway.api_key,
      // Add other required fields like amount, product info, etc.
    };

    Object.keys(fields).forEach((key) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = fields[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  const handleCashfreePayment = async (selectedGateway) => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://test.cashfree.com/billpay/checkout/post/submit"; // Use the appropriate URL for production

    // Add necessary fields to the form
    const fields = {
      appId: selectedGateway.api_key,
      // Add other required fields like order ID, amount, etc.
    };

    Object.keys(fields).forEach((key) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = fields[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  useEffect(() => {
    if (orderPlaced) {
      const timeoutId = setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [orderPlaced, navigate]);

  const updateCartItems = (orderedItems) => {
    const orderedProductIds = orderedItems.map((item) => item.product_id);
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const updatedCartItems = cartItems.filter(
      (item) => !orderedProductIds.includes(item.product_id)
    );

    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

    const remainingQuantity = updatedCartItems.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    localStorage.setItem("totalQuantity", remainingQuantity);
  };

  const calculateTotalQuantity = () => {
    return cartItems.reduce((total, product) => total + product.quantity, 0);
  };

  return (
    <div className="w-screen h-screen bg-white">
      <div className="w-full m-auto flex justify-between items-center relative">
        <div className="bg-primary-blue flex items-center flex-1 w-full">
          <div className="flex col-span-1 pb-1 pt-2">
            <Link className="h-16 mr-1 sm:mr-4 hidden lg:block pr-14" to="/">
              <img
                draggable="false"
                className="h-full w-full object-contain"
                src={logo}
                alt="Vivimart Logo"
              />
            </Link>
          </div>
          <div className="lg:hidden flex col-span-1 pb-1 pt-2">
            <div className="flex col-span-1 relative bottom-[0.2rem]">
              <Link className="h-16 mr-1 sm:mr-4" to="/">
                <img
                  draggable="false"
                  className="h-full w-full object-contain"
                  src={Logomb}
                  alt="Vivimart Logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-0 flex bg-white">
        <div className="text-[14px] ">
          <div className=" font-snas text-[#1c1c1c] text-[24px] font-semibold leading-[32px] pt-[4rem] lg:pl-[10rem] pl-[1rem] lg:w-[50rem] custom-text">
            Select Payment Method
          </div>
          <div>
            <AccordionPanel
              onCodSubmit={handleCodSubmit}
              onPaymentSelect={handleOnlinePaymentSubmit}
            />
          </div>
        </div>
        <div className="mt-16 rounded-lg border-gray-300 hidden lg:block">
          <div className="">
            <div className="p-4 bg-primary-blue text-[18px] rounded-t-lg">
              <span className="font-bold text-white">My Cart</span>
              <span
                className={`ml-4 font-semibold ${
                  fastDelivery.length > 0 ? "text-[#FFCE44]" : "text-[#FFCE44]"
                }`} // Conditional text color
              >
                {fastDelivery.length > 0
                  ? "( Fast Delivery )"
                  : "( Standard Delivery )"}
              </span>
            </div>
            <div className="p-4 mb-2">
              <span className="font-semibold font-sans text-black">
                Delivery Address
              </span>
              <div className="mt-1.5 text-sm text-[#999999] overflow-wrap-break">
                {Object.keys(selectedAddress).length !== 0 && (
                  <span>
                    {selectedAddress.name}, {selectedAddress.house},
                    {selectedAddress.floor}, {selectedAddress.area},{" "}
                    {selectedAddress.landmark}
                  </span>
                )}
              </div>
            </div>
            <div className="bg-[rgb(251, 251, 251)] h-[25rem] overflow-auto hidden-scrollbar">
              <div>
                <table className="w-full border-collapse border border-[#eee]">
                  <thead className="sticky top-0 bg-white z-10">
                    <tr>
                      <th className="p-4 text-left">Product</th>
                      <th className="p-4 w-72 text-left">Details</th>
                      <th className="p-4 text-center">
                        ({calculateTotalQuantity()})
                        <span className="ml-2">Items</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((product, index) => (
                      <tr
                        key={index}
                        className="border-t border-[#eee] hidden-scrollbar overflow-y-auto"
                      >
                        <td className="p-4">
                          <img
                            className="w-[84px] h-full object-cover"
                            src={product.product_img}
                            alt={product.product_name}
                          />
                        </td>
                        <td className="p-4">
                          <div className="overflow-hidden line-clamb-2">
                            {product.product_name}
                          </div>
                          <div className="text-[#999999]">{product.weight}</div>
                          <span className="block items-center">
                            ₹{" "}
                            {product.quantity > 0
                              ? product.sell_price * product.quantity
                              : product.sell_price}
                          </span>
                        </td>
                        <td className="p-4 text-center">
                          {product.quantity} item
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="p-4">
                <span className="font-semibold text-black">
                  Total Amount: ₹{totalAmount.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 lg:hidden pb-[60px]"></div>
    </div>
  );
};

export default Checkout;
