import React, { useState, useEffect } from "react";
import { Modal, Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { createSubStore, getAllSubStores } from "../../../actions/subStoreActions";

const AddStoreModal = ({ open, handleClose, existingStores }) => {
  const dispatch = useDispatch();
  const [storeData, setStoreData] = useState({
    id: '',
    store_id: '',
    sub_store_id: '',
    phone: '',
    sub_store_owner: '',
    is_active: 1,
  });

  // Effect to set the new ID based on existing stores
  useEffect(() => {
    if (open && existingStores.length > 0) {
      const highestId = Math.max(...existingStores.map(store => store.id));
      setStoreData(prev => ({ ...prev, id: highestId + 1 }));
    } else {
      setStoreData(prev => ({ ...prev, id: 1 })); // Start from 1 if no stores exist
    }
  }, [open, existingStores]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreData({ ...storeData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createSubStore(storeData))
      .then(() => {
        handleClose(); // Close the modal after submitting
        // Optionally, dispatch getAllSubStores to refresh the data
        dispatch(getAllSubStores());
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal-content" style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px', maxWidth: '500px', maxHeight: '80vh', overflowY: 'auto', margin: 'auto' }}>
        <h2>Add New Store</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            name="store_id"
            label="Store ID"
            value={storeData.store_id}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="sub_store_id"
            label="Sub Store ID"
            value={storeData.sub_store_id}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="phone"
            label="Phone"
            value={storeData.phone}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="sub_store_owner"
            label="Sub Store Owner"
            value={storeData.sub_store_owner}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />          
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            Add Store
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default AddStoreModal;