import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import MyOrders from '../Order/MyOrders';
import MyAddress from '../User/MyAddress';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import MyWallet from './MyWallet';

const MyProfile = () => {
  const { tab } = useParams(); // Get the tab from URL parameters
  const [activeComponent, setActiveComponent] = useState(tab || 'orders'); // Default to 'orders' if no tab is provided
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    const phoneNumber = localStorage.getItem("userPhoneNumber");
    if (phoneNumber) {
      setUserPhoneNumber(phoneNumber);
    }
  }, []);

  const handleLogout = () => {
    // dispatch(logoutUser());
    // navigate("/login");
    // enqueueSnackbar("Logout Successfully", { variant: "success" });
    localStorage.removeItem("userPhoneNumber");
    window.location.href = "/";
  };

  const handleTabChange = (component) => {
    setActiveComponent(component);
    navigate(`/profile/${component}`); // Update URL
  };


  const renderComponent = () => {
    switch (activeComponent) {
      case 'orders':
        return <MyOrders />;
      case 'addresses':
        return <MyAddress />;
      case 'my-wallet':
        return <MyWallet />  
      default:
        return <MyOrders />;
    }
  };

  return (
    <div className="flex w-auto h-auto lg:h-[50rem] mt-4 lg:mt-32 sm:mt-0 lg:mx-32">
      {/* Sidebar */}
      <div className="hidden sm:flex flex-col w-72 px-1">
        <div className="max-w-sm mx-auto bg-white p-4 w-full">
          <div className="flex items-center mb-4">
            <AccountCircleOutlinedIcon sx={{ fontSize: 60 }} />
            <div className="ml-4">
              <p className="text-gray-500">{userPhoneNumber}</p>
            </div>
          </div>
          <div className="space-y-4 mt-6">
            <div className="flex items-center border-b p-2 justify-between cursor-pointer" onClick={() => handleTabChange('orders')}>
              <div className="flex items-center">
                <MedicalServicesOutlinedIcon sx={{ fontSize: 20 }} />
                <span className="ml-4 text-lg">My Orders</span>
              </div>
            </div>
            <div className="flex items-center border-b p-2 justify-between cursor-pointer" onClick={() => handleTabChange('addresses')}>
              <div className="flex items-center">
                <PinDropOutlinedIcon sx={{ fontSize: 20 }} />
                <span className="ml-4 text-lg">My Addresses</span>
              </div>
            </div>
            <div className="flex items-center border-b p-2 justify-between cursor-pointer" onClick={() => handleTabChange('my-wallet')}>
              <div className="flex items-center">
                <PinDropOutlinedIcon sx={{ fontSize: 20 }} />
                <span className="ml-4 text-lg">Wallet</span>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center'>
            <button className='bg-[#F70D1A] text-white px-6 py-1 mt-4 font-semibold rounded-lg' onClick={handleLogout}>
            Log Out
            </button>            
        </div>
      </div>

      {/* Content Area */}
      <div className="flex-1 w-full mx-1 lg:mx-6">
        {renderComponent()}
      </div>
    </div>
  );
};

export default MyProfile;