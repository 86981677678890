import {
  SUB_STORE_FAIL,
  SUB_STORE_REQUEST,
  SUB_STORE_SUCCESS,
  CLEAR_ERRORS,
  SUB_STORE_CREATE_REQUEST,
  SUB_STORE_CREATE_SUCCESS,
  SUB_STORE_CREATE_FAIL,
  SUB_STORE_UPDATE_REQUEST,
  SUB_STORE_UPDATE_SUCCESS,
  SUB_STORE_UPDATE_FAIL,
  SUB_STORE_DELETE_REQUEST,
  SUB_STORE_DELETE_SUCCESS,
  SUB_STORE_DELETE_FAIL,
  SUB_STORE_TOGGLE_REQUEST,
  SUB_STORE_TOGGLE_SUCCESS,
  SUB_STORE_TOGGLE_FAIL,
  SUB_STORE_ALL_REQUEST,
  SUB_STORE_ALL_SUCCESS,
  SUB_STORE_ALL_FAIL,
  SUB_STORE_ENABLE_REQUEST,
  SUB_STORE_ENABLE_SUCCESS,
  SUB_STORE_ENABLE_FAIL,
  SUB_STORE_DISABLE_REQUEST,
  SUB_STORE_DISABLE_SUCCESS,
  SUB_STORE_DISABLE_FAIL,
  SUB_STORE_BY_ID_REQUEST,
  SUB_STORE_BY_ID_SUCCESS,
  SUB_STORE_BY_ID_FAIL,
} from "../constants/subStoreConstants";

const initialState = {
  subStores: [],
  loading: false,
  error: null,
};

const idInitialState = {
    subStore: [],
    loading: false,
    error: null,
  };

export const subStoreReducer = (state = initialState, action) => {
  switch (action.type) {
      case SUB_STORE_ALL_REQUEST:
      case SUB_STORE_REQUEST:
      case SUB_STORE_CREATE_REQUEST:
      case SUB_STORE_UPDATE_REQUEST:
      case SUB_STORE_DELETE_REQUEST:
      case SUB_STORE_TOGGLE_REQUEST:
      case SUB_STORE_ENABLE_REQUEST:
      case SUB_STORE_DISABLE_REQUEST:
          return {
              ...state,
              loading: true,
          };
      case SUB_STORE_ALL_SUCCESS:
          return {
              ...state,
              loading: false,
              subStores: action.payload,
          };
      case SUB_STORE_SUCCESS:
          return {
              ...state,
              loading: false,
              subStores: action.payload,
          };
      case SUB_STORE_CREATE_SUCCESS:
          return {
              ...state,
              loading: false,
              subStores: [...state.subStores, action.payload],
          };
      case SUB_STORE_UPDATE_SUCCESS:
          return {
              ...state,
              loading: false,
              subStores: state.subStores.map((subStore) =>
                  subStore.id === action.payload.id ? action.payload : subStore
              ),
          };
      case SUB_STORE_DELETE_SUCCESS:
          return {
              ...state,
              loading: false,
              subStores: state.subStores.filter((subStore) => subStore.id !== action.payload.id),
          };
      case SUB_STORE_TOGGLE_SUCCESS:
          return {
              ...state,
              loading: false,
              subStores: state.subStores.map((subStore) =>
                  subStore.id === action.payload.id ? { ...subStore, is_active: action.payload.is_active } : subStore
              ),
          };
      case SUB_STORE_ENABLE_SUCCESS:
          return {
              ...state,
              loading: false,
              subStores: state.subStores.map((subStore) =>
                  subStore.id === action.payload.id ? { ...subStore, is_active: true } : subStore
              ),
          };
      case SUB_STORE_DISABLE_SUCCESS:
          return {
              ...state,
              loading: false,
              subStores: state.subStores.map((subStore) =>
                  subStore.id === action.payload.id ? { ...subStore, is_active: false } : subStore
              ),
          };
      case SUB_STORE_FAIL:
      case SUB_STORE_CREATE_FAIL:
      case SUB_STORE_UPDATE_FAIL:
      case SUB_STORE_DELETE_FAIL:
      case SUB_STORE_TOGGLE_FAIL:
      case SUB_STORE_ENABLE_FAIL:
      case SUB_STORE_DISABLE_FAIL:
      case SUB_STORE_ALL_FAIL:
          return {
              ...state,
              loading: false,
              error: action.payload,
          };
      case CLEAR_ERRORS:
          return {
              ...state,
              error: null,
          };
      default:
          return state;
  }
};

export const subStoreByIdReducer = (state = idInitialState, action) => {
    switch (action.type) {
      case SUB_STORE_BY_ID_REQUEST:
        return { ...state, loading: true };
      case SUB_STORE_BY_ID_SUCCESS:
        return { loading: false, subStore: action.payload, error: null };
      case SUB_STORE_BY_ID_FAIL:
        return { loading: false, error: action.payload };
      case CLEAR_ERRORS:
        return { ...state, error: null };
      default:
        return state;
    }
  };