import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addItemsToCart,
  removeItemsFromCart,
  emptyCart,
} from "../../actions/cartAction";
import CartItem from "./CartItem";       
import EmptyCart from "./EmptyCart";
import PriceSidebar from "./PriceSidebar";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { ADMIN_PRODUCT_API } from "../../utils/constants";
import StandardDelivery from "./StandardDelivery";
import FastDelivery from "./FastDelivery";
import DeliveryEdit from "./DeliveryEdit";
import homeImage from "../../assets/images/house.png";
import officeImage from "../../assets/images/office.png";
import otherImage from "../../assets/images/other.png";

const Cart = ({ closeCartSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addedProduct, setAddedProduct] = useState([]);
  const [delivery, setDelivery] = useState("");
  const [proceed, setProceed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [addressDetails, setAddressDetails] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [currentDelivery, setCurrentDelivery] = useState("StandardDelivery");
  const [mismatchAddress, setMismatchAddress] = useState(null);

  const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

  useEffect(() => {
    const addresses = JSON.parse(localStorage.getItem("addressDetails")) || [];
    console.log("Address details:", addresses);
    setAddressDetails(addresses);
  }, []);

  const addAddress = () => {
    setIsEdit(false);
    setIsOpen(true);
  };

  const handleEdit = (index) => {
    handleSelectAddress(index);
    setIsEdit(true);
    setEditingIndex(index);
    setIsOpen(true);
  };

  const refreshAddressDetails = () => {
    const addresses = JSON.parse(localStorage.getItem("addressDetails")) || [];
    setAddressDetails(addresses);
  };

  const placeOrderHandler = () => {
    closeCartSidebar();
  };

  const handleSelectAddress = (index) => {
    const address = addressDetails[index];
    setSelectedAddress(address);

    const selectedPostalCode = localStorage.getItem("SelectedPostalCode");
    if (address.pincode !== selectedPostalCode) {
      setMismatchAddress(address);
    } else {
      setMismatchAddress(null);
    }

    setProceed(false);
  };

  const getImageUrl = (selectResident) => {
    switch (selectResident) {
      case "Home":
        return homeImage;
      case "Office":
        return officeImage;
      case "Other":
        return otherImage;
      default:
        return "https://via.placeholder.com/150";
    }
  };

  // Check for available delivery options
  const hasFastDeliveryOption = cartItems.some(
    (item) => item.delivery_option === "Fast Delivery"
  );
  const hasStandardDeliveryOption = cartItems.some(
    (item) => item.delivery_option === "Standard Delivery"
  );

  // Set default delivery option based on available options
  useEffect(() => {
    if (!delivery) { // Only set default if no delivery option is currently selected
      if (hasFastDeliveryOption && hasStandardDeliveryOption) {
        setDelivery("StandardDelivery"); // Default to Standard if both are available
      } else if (hasFastDeliveryOption) {
        setDelivery("FastDelivery");
      } else if (hasStandardDeliveryOption) {
        setDelivery("StandardDelivery");
      }
    }
  }, [
    cartItems,
    hasFastDeliveryOption,
    hasStandardDeliveryOption,
    delivery, // Add delivery to the dependency array
  ]);

  const handleStdDelivery = () => {
    setDelivery("StandardDelivery");
  };

  const handleFastDelivery = () => {
    setDelivery("FastDelivery");
  };

  return (
    <>
      <div className="w-[25rem] bg-white shadow-lg h-full hidden-scrollbar overflow-y-auto transition-transform lg:transform lg:translate-x-0">
        <div className="rounded-none border-0 bottom-0 shadow-md right-0 top-0 transition-all duration-400 ease-in-out w-full absolute bg-blue-100 outline-none hidden-scrollbar overflow-auto p-0">
          <div
            className="min-h-screen overflow-y-auto"
            style={{
              backgroundColor:
                delivery === "FastDelivery" ? "rgb(209, 240, 213)" : "#f1c992",
            }}
          >
            {!proceed && (
              <>
                <div className="top-0 w-full z-20 sticky bg-white">
                  <div className="px-4 py-4 flex justify-between items-center">
                    <span className="leading-5 text-lg font-okra text-gray-800 font-bold">
                      My Cart
                    </span>
                    <div
                      className="text-xl text-gray-800 font-customfont cursor-pointer font-bold"
                      onClick={closeCartSidebar}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  {cartItems.length === 0 ? (
                    <EmptyCart closeCartSidebar={closeCartSidebar} />
                  ) : (
                    <>
                      <div className="px-2 py-2 w-full flex items-center bg-white rounded-xl border border-gray-300">
                        {hasFastDeliveryOption && (
                          <div
                            className="flex-1 py-2 px-3 text-center cursor-pointer rounded-md"
                            onClick={handleFastDelivery}
                            style={{
                              backgroundColor:
                                delivery === "FastDelivery"
                                  ? "rgb(209, 240, 213)"
                                  : "white",
                            }}
                          >
                            <span className="text-[13px]">Fast Delivery</span>
                            <Tooltip title="Delivery in 30 minutes" arrow>
                              <span className="px-1">
                                <InfoIcon style={{ fontSize: "18px" }} />
                              </span>
                            </Tooltip>
                          </div>
                        )}
                        {hasStandardDeliveryOption && (
                          <div
                            className="flex-1 py-2 px-3 text-center cursor-pointer rounded-md"
                            onClick={handleStdDelivery}
                            style={{
                              backgroundColor:
                                delivery === "StandardDelivery"
                                  ? "#f1c992"
                                  : "white",
                            }}
                          >
                            <span className="text-[13px]">
                              Standard Delivery
                            </span>
                            <Tooltip
                              title="Delivery in couple of working days"
                              arrow
                            >
                              <span className="px-1">
                                <InfoIcon style={{ fontSize: "18px" }} />
                              </span>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      {delivery === "StandardDelivery" && (
                        <StandardDelivery
                          setProceed={setProceed}
                          closeCartSidebar={closeCartSidebar}
                          delivery={delivery}
                          selectedAddress={selectedAddress}
                          setSelectedAddress={setSelectedAddress}
                        />
                      )}
                      {delivery === "FastDelivery" && (
                        <FastDelivery
                          setProceed={setProceed}
                          closeCartSidebar={closeCartSidebar}
                          delivery={delivery}
                          selectedAddress={selectedAddress}
                          setSelectedAddress={setSelectedAddress}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            )}
            {proceed && (
              <div>
                <div className="top-0 w-full z-20 sticky bg-white">
                  <div className="px-4 py-3 flex items-center font-sans">
                    <div
                      className="text-xl text-gray-800 font-customfont cursor-pointer font-bold"
                      onClick={() => setProceed(false)}
                    >
                      <KeyboardBackspaceIcon />
                    </div>
                    <span className="leading-5 text-lg font-okra text-gray-800 font-bold pl-4 pt-1">
                      Select delivery address
                    </span>
                  </div>
                </div>
                <div className="w-full p-3">
                  <div className="p-3 box-border bg-white rounded-xl cursor-pointer">
                    <div
                      className="flex items-center gap-4"
                      style={{ color: "rgb(12, 131, 31)" }}
                      onClick={() => addAddress()}
                    >
                      <div>
                        <AddIcon />
                      </div>
                      <div>
                        <span>Add a new address</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full p-3">
                  <div className="pl-4 font-sans text-sm">
                    <span>Your saved address</span>
                  </div>
                  <div className="p-3 box-border items-center rounded-xl mt-3 flex">
                    <div className="flex flex-col font-sans w-full max-w-2xl rounded-lg">
                      {addressDetails.length > 0 ? (
                        addressDetails.map((address, index) => {
                          const isMismatched =
                            address.pincode !==
                            localStorage.getItem("SelectedPostalCode");
                          return (
                            <div
                              key={index}
                              className={`box-border w-full max-w-md border border-gray-300 bg-white rounded-xl p-4 mt-2 flex items -start justify-between cursor-pointer ${
                                isMismatched ? "blur-[1px]" : ""
                              }`}
                              onClick={
                                isMismatched
                                  ? undefined
                                  : () => handleSelectAddress(index)
                              }
                            >
                              <div className="flex items-center">
                                <div className="w-12 h-12 bg-gray-100 flex items-center justify-center rounded-md">
                                  <img
                                    src={getImageUrl(address.selectResident)}
                                    alt={address.selectResident}
                                    className="w-full h-full object-cover rounded-md"
                                  />
                                </div>
                                <div className="ml-4">
                                  <h2 className="text-lg font-semibold">
                                    {address.selectResident}
                                  </h2>
                                  <p className="text-gray-600">
                                    {address.house}, {address.floor},{" "}
                                    {address.area}, {address.landmark},{" "}
                                    {address.pincode}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center gap-2">
                                <EditIcon
                                  onClick={() => handleEdit(index)}
                                  className="text-green-600"
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div>No addresses available</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-70 py-4">
          <DeliveryEdit
            isEdit={isEdit}
            setIsOpen={setIsOpen}
            editingIndex={editingIndex}
            selectedAddress={selectedAddress}
            onAddressSaved={refreshAddressDetails}
          />
        </div>
      )}
    </>
  );
};

export default Cart;