export const STORE_CREATE_REQUEST = 'STORE_CREATE_REQUEST';
export const STORE_CREATE_SUCCESS = 'STORE_CREATE_SUCCESS';
export const STORE_CREATE_FAIL = 'STORE_CREATE_FAIL';

export const STORE_UPDATE_REQUEST = 'STORE_UPDATE_REQUEST';
export const STORE_UPDATE_SUCCESS = 'STORE_UPDATE_SUCCESS';
export const STORE_UPDATE_FAIL = 'STORE_UPDATE_FAIL';

export const STORE_DELETE_REQUEST = 'STORE_DELETE_REQUEST';
export const STORE_DELETE_SUCCESS = 'STORE_DELETE_SUCCESS';
export const STORE_DELETE_FAIL = 'STORE_DELETE_FAIL';

export const STORE_GET_REQUEST = 'STORE_GET_REQUEST';
export const STORE_GET_SUCCESS = 'STORE_GET_SUCCESS';
export const STORE_GET_FAIL = 'STORE_GET_FAIL';

export const GET_STORE_BY_ID_REQUEST = 'GET_STORE_BY_ID_REQUEST';
export const GET_STORE_BY_ID_SUCCESS = 'GET_STORE_BY_ID_SUCCESS';
export const GET_STORE_BY_ID_FAIL = 'GET_STORE_BY_ID_FAIL';

export const STORE_ENABLE_REQUEST = 'STORE_ENABLE_REQUEST';
export const STORE_ENABLE_SUCCESS = 'STORE_ENABLE_SUCCESS';
export const STORE_ENABLE_FAIL = 'STORE_ENABLE_FAIL';

export const STORE_DISABLE_REQUEST = 'STORE_DISABLE_REQUEST';
export const STORE_DISABLE_SUCCESS = 'STORE_DISABLE_SUCCESS';
export const STORE_DISABLE_FAIL = 'STORE_DISABLE_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';