import {
  STORE_CREATE_REQUEST,
  STORE_CREATE_SUCCESS,
  STORE_CREATE_FAIL,
  STORE_UPDATE_REQUEST,
  STORE_UPDATE_SUCCESS,
  STORE_UPDATE_FAIL,
  STORE_DELETE_REQUEST,
  STORE_DELETE_SUCCESS,
  STORE_DELETE_FAIL,
  STORE_GET_REQUEST,
  STORE_GET_SUCCESS,
  STORE_GET_FAIL,
  STORE_ENABLE_REQUEST,
  STORE_ENABLE_SUCCESS,
  STORE_ENABLE_FAIL,
  STORE_DISABLE_REQUEST,
  STORE_DISABLE_SUCCESS,
  STORE_DISABLE_FAIL,
  CLEAR_ERRORS,
  GET_STORE_BY_ID_REQUEST,
  GET_STORE_BY_ID_SUCCESS,
  GET_STORE_BY_ID_FAIL,
  VIRTUAL_ACCOUNT_CREATE_REQUEST,
  VIRTUAL_ACCOUNT_CREATE_SUCCESS,
  VIRTUAL_ACCOUNT_CREATE_FAIL,
  ADD_ALLOWED_PAYER_REQUEST,
  ADD_ALLOWED_PAYER_SUCCESS,
  ADD_ALLOWED_PAYER_FAIL,
  GET_ALL_VIRTUAL_ACCOUNTS_REQUEST,
  GET_ALL_VIRTUAL_ACCOUNTS_SUCCESS,
  GET_ALL_VIRTUAL_ACCOUNTS_FAIL,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
} from "../constants/storeConstants";

const initialState = {
  stores: [],
  store: null, // For storing a single store
  loading: false,
  error: null,
  customer: null,
};

const vaInitialState = {
  virtualAccount: null,
  virtualAccounts: [],
  loading: false,
  error: null,
};

export const virtualAccountDetailReducer = (state = vaInitialState, action) => {
  switch (action.type) {
    case VIRTUAL_ACCOUNT_CREATE_REQUEST:
    case GET_ALL_VIRTUAL_ACCOUNTS_REQUEST:
    case ADD_ALLOWED_PAYER_REQUEST:
      return { ...state, loading: true };
    case VIRTUAL_ACCOUNT_CREATE_SUCCESS:
      return { ...state, loading: false, virtualAccount: action.payload };
    case GET_ALL_VIRTUAL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        virtualAccounts: action.payload,
      };
    case ADD_ALLOWED_PAYER_SUCCESS:
      return { ...state, loading: false }; // You can handle the success response as needed
    case VIRTUAL_ACCOUNT_CREATE_FAIL:
    case ADD_ALLOWED_PAYER_FAIL:
    case GET_ALL_VIRTUAL_ACCOUNTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_GET_REQUEST:
    case STORE_CREATE_REQUEST:
    case STORE_UPDATE_REQUEST:
    case STORE_DELETE_REQUEST:
    case STORE_ENABLE_REQUEST:
    case STORE_DISABLE_REQUEST:
    case GET_STORE_BY_ID_REQUEST:
    case CUSTOMER_UPDATE_REQUEST:
      return { ...state, loading: true };
    case STORE_GET_SUCCESS:
      return { ...state, loading: false, stores: action.payload };
    case GET_STORE_BY_ID_SUCCESS:
      return { ...state, loading: false, store: action.payload }; // Store single store data
    case STORE_CREATE_SUCCESS:
    case STORE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        stores: [...state.stores, action.payload],
      }; // Update stores list
    case STORE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        stores: state.stores.filter((store) => store.id !== action.payload),
      };
    case CUSTOMER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: action.payload, // Update customer details on success
      };
    case STORE_ENABLE_SUCCESS:
    case STORE_DISABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        stores: state.stores.map((store) =>
          store.id === action.payload.id
            ? { ...store, is_active: action.payload.is_active }
            : store
        ),
      };
    case STORE_GET_FAIL:
    case STORE_CREATE_FAIL:
    case STORE_UPDATE_FAIL:
    case STORE_DELETE_FAIL:
    case STORE_ENABLE_FAIL:
    case STORE_DISABLE_FAIL:
    case GET_STORE_BY_ID_FAIL:
    case CUSTOMER_UPDATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};
