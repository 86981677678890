import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axiosInstance from '../../utils/api';
import { useDispatch } from 'react-redux';
import { updateOrderItemStatus } from '../../actions/orderAction';
import { splitOrderIntoShipments } from '../../actions/shipmentActions';
import { useSnackbar } from 'notistack';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const AcceptInvoice = () => {
    const dispatch = useDispatch()
    const { uniqueId } = useParams(); // Get unique ID from URL
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [items, setItems] = useState([]); // Get items from state or initialize as empty
    const [orderDetails, setOrderDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pickupAddress, setPickupAddress] = useState("");

    useEffect(() => {
        console.log("passing unique id:", uniqueId)
        const fetchOrderDetails = async () => {
            try {
                const response = await axiosInstance.get(`/orders/forwarded/${uniqueId}`); // Adjust the endpoint as needed
                setOrderDetails(response.data);
                setPickupAddress(response.data.pickup_address);
                console.log("order details by response:", response.data)
                setLoading(false);
            } catch (error) {
                console.error("Error fetching order details:", error);
                setError("Failed to fetch order details.");
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [uniqueId]);

    console.log("pickup address", pickupAddress)

    const generatePDF = () => {
        const imgUrl = "https://ik.imagekit.io/efsdltq0e/Logo/Logo%202.png?updatedAt=1726132810913";
        const img = new Image();
    
        img.onload = () => {
            const pdfContent = `
                <div style="font-family: Arial, sans-serif; padding: 20px;">
                    <div style="text-align: center;">
                        <img src="${imgUrl}" alt="Logo" style="height: 50px;" />
                        <h1>Your Company Name</h1>
                        <h2 style="font-size: 24px;">INVOICE</h2>
                        <p>Date: ${new Date().toLocaleDateString()}</p>
                        <p>Invoice #: ${orderDetails.id}</p>
                    </div>
                    <div style="border-bottom: 2px solid #ccc; padding-bottom: 10px; margin-bottom: 20px;">
                        <h2 style="font-size: 20px;">Bill To:</h2>
                        <p>${orderDetails.original_order_address}</p>
                        <p>${orderDetails.original_order_phone_number}</p>
                    </div>
                    <table style="width: 100%; border-collapse: collapse;">
                        <thead>
                            <tr>
                                <th style="border: 1px solid #ccc; padding: 8px;">Description</th>
                                <th style="border: 1px solid #ccc; padding: 8px;">Quantity</th>
                                <th style="border: 1px solid #ccc; padding: 8px;">Price</th>
                                <th style="border: 1px solid #ccc; padding: 8px;">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${orderDetails.order_items.map(item => `
                                <tr>
                                    <td style="border: 1px solid #ccc; padding: 8px;">${item.Product_name}</td>
                                    <td style="border: 1px solid #ccc; padding: 8px;">${item.quantity}</td>
                                    <td style="border: 1px solid #ccc; padding: 8px;">₹${item.sell_price}</td>
                                    <td style="border: 1px solid #ccc; padding: 8px;">₹${(item.sell_price * item.quantity)}</td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                    <div style="text-align: right; margin-top: 20px;">
                        <strong>Total:</strong> ₹${orderDetails.order_items.reduce((total, item) => total + (item.sell_price * item.quantity), 0).toFixed(2)}
                    </div>
                    
                </div>
            `;
    
            // Create a temporary DOM element
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = pdfContent;
            document.body.appendChild(tempDiv);
    
            // Use html2canvas to create a canvas from the temporary element
            html2canvas(tempDiv).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 190; // Set the width of the image
                const pageHeight = 295; // A4 page height in mm
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
    
                let position = 0;
    
                // Add the image to the PDF
                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
    
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
    
                // Save the PDF
                pdf.save(`invoice_${orderDetails.id}.pdf`);
    
                // Remove the temporary element from the DOM
                document.body.removeChild(tempDiv);
            }).catch(error => {
                console.error("Error generating PDF:", error);
                // Optionally, you can show a notification to the user about the error
            });
        };
    
        img.onerror = () => {
            console.error("Image failed to load. Please check the URL.");
        };
    
        img.src = imgUrl; // Start loading the image
    };

    const handleAccept = async () => {

        // Generate PDF
        generatePDF();
        const validItems = orderDetails.order_items.map((item) => ({
            product_id: item.product_id,
            status: "Forwarded",
            item_weight: item.weight,
            item_quantity: item.quantity,
            item_sell_price: item.sell_price,
        }));

        try {
            await dispatch(updateOrderItemStatus(orderDetails.original_order_id, validItems));

            // Now pass the updated order details to split into shipments
            const shipmentData = {
                order_id: orderDetails.original_order_id,
                order_items: validItems,
                pickup_address: pickupAddress,
            };

            // Dispatch splitOrderIntoShipments action
            await dispatch(splitOrderIntoShipments(shipmentData));

            enqueueSnackbar("Order accepted successfully", {
                variant: "success",
            });
        } catch (error) {
            console.error("Error dispatching order:", error);
            enqueueSnackbar("Failed to dispatch order", { variant: "error" });
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Show a loading state
    }

    if (error) {
        return <div>{error}</div>; // Show error message if there's an error
    }
    
    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-md rounded-lg p-6 w-11/12 max-w-lg">
                <h1 className="text-2xl font-bold mb-4 text-center">Purchase Order</h1>
                <h2 className="text-lg font-semibold mb-2">Items to Accept:</h2>
                <ul className="list-disc list-inside mb-4">
                    {orderDetails.order_items.map((item, index) => (
                        <div
                        key={item.product_id} // Use product_id as the key
                        className="flex items-center border rounded-lg gap-2 mt-3 p-2 bg-white"
                      >
                        <img
                          src={item.product_image_0}
                          alt={item.Product_name}
                          className="w-12 h-12 object-cover rounded"
                        />
                        <div className="flex flex-col ml-3 flex-grow">
                          <div className="text-left text-sm">{item.Product_name}</div>
                          <div className="text-left text-sm text-gray-600">{item.weight}</div>
                          <div className="text-left text-sm font-semibold">{item.sell_price}</div>
                        </div>
                        <div className="ml-auto mr-12 text-right">{item.quantity} .Qty</div>
                      </div>
                    ))}
                </ul>
                <button 
                    onClick={handleAccept} 
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                    Accept Order
                </button>
            </div>
        </div>
    );
};

export default AcceptInvoice;