import axios from "axios";
import {
  ADD_TO_CART,
  EMPTY_CART,
  REMOVE_FROM_CART,
  SAVE_SHIPPING_INFO,
  GET_CART_ITEMS_FAIL,
  GET_CART_ITEMS_SUCCESS,
} from "../constants/cartConstants";

const BASE_URL = "https://backend.vivimart.in/api";

const updateLocalStorage = (cartItems) => {
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);
  localStorage.setItem("totalQuantity", totalQuantity.toString());
};

export const addItemsToCart = (productId, quantity, weight, sell_price, MRP, offer, delivery_option) => (dispatch, getState) => {
  try {
    let cartItems = [...getState().cart.cartItems];
    const existingItemIndex = cartItems.findIndex((item) => item.product_id === productId && item.weight === weight);

    if (existingItemIndex !== -1) {
      // Update the quantity
      cartItems[existingItemIndex].quantity += quantity;

      // Remove item if quantity is 0 or less
      if (cartItems[existingItemIndex].quantity <= 0) {
        cartItems.splice(existingItemIndex, 1);
      }
    } else if (quantity > 0) {
      // Add new item
      cartItems.push({ product_id: productId, quantity, weight, sell_price, MRP, offer, delivery_option });
    }

    updateLocalStorage(cartItems);

    dispatch({
      type: ADD_TO_CART,
      payload: cartItems,
    });
  } catch (error) {
    console.error("Error adding to cart:", error);
  }
};

export const removeItem = (productId, weight) => (dispatch, getState) => {
  try {
    const updatedCartItems = getState().cart.cartItems.filter(
      (item) => item.product_id !== productId || item.weight !== weight
    );

    console.log("Updated cart items after removal:", updatedCartItems);

    updateLocalStorage(updatedCartItems);

    dispatch({
      type: REMOVE_FROM_CART,
      payload: { productId, weight },
    });
  } catch (error) {
    console.error("Error removing item from cart:", error);
  }
};


export const removeItemsFromCart = (id) => async (dispatch, getState) => {
  try {
    await axios.delete(`${BASE_URL}/cart/remove`, {
      data: { product_id: id },
      withCredentials: true,
    });

    const updatedCartItems = getState().cart.cartItems.filter(
      (item) => item.product_id !== id
    );

    console.log("Updated cart items after backend removal:", updatedCartItems);

    updateLocalStorage(updatedCartItems);

    dispatch({
      type: REMOVE_FROM_CART,
      payload: id,
    });
  } catch (error) {
    console.error("Error removing from cart:", error);
  }
};

export const emptyCart = () => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/cart/removeAll`, { withCredentials: true });
    localStorage.removeItem("cartItems");
    localStorage.removeItem("totalQuantity");
    dispatch({ type: EMPTY_CART });
  } catch (error) {
    console.error("Error emptying cart:", error);
  }
};

export const saveShippingInfo = (data) => async (dispatch) => {
  try {
    await axios.post(`${BASE_URL}/orders/shipping`, data, { withCredentials: true });
    dispatch({
      type: SAVE_SHIPPING_INFO,
      payload: data,
    });
    localStorage.setItem("shippingInfo", JSON.stringify(data));
  } catch (error) {
    console.error("Error saving shipping info:", error);
  }
};

export const saveOrderedInfo = (data) => async (dispatch) => {
  try {
    await axios.post(`${BASE_URL}/orders/add`, data);
    dispatch({
      type: SAVE_SHIPPING_INFO,
      payload: data,
    });
    localStorage.setItem("shippingInfo", JSON.stringify(data));
  } catch (error) {
    console.error("Error saving ordered info:", error);
  }
};

export const getCartItems = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/cart`);
    dispatch({
      type: GET_CART_ITEMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error("Error fetching cart items:", error);
    dispatch({
      type: GET_CART_ITEMS_FAIL,
      payload: error.message || "Failed to fetch cart items",
    });
  }
};
