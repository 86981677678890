import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Actions from "./Action";
import MetaData from "../../Layouts/MetaData";
import BackdropLoader from "../../Layouts/BackdropLoader";
import {
  deleteSubStore,
  toggleSubStoreStatus,
  getSubStoreByStoreId,
  clearError,
  getAllSubStores,
  enableSubStore,
  disableSubStore,
} from "../../../actions/subStoreActions";
import EditModal from "./EditModal";
import AddModal from "./AddModal";

const SubStore = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [updateCount, setUpdateCount] = useState(0);
  const [selectedStore, setSelectedStore] = useState(null); // State to hold selected store data
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const { subStores, error, loading } = useSelector((state) => state.subStores); // Access sub-store data
  const { isDeleted, deleteError } = useSelector((state) => state.subStores);

  console.log("sub store data:", subStores);

  const toggleActiveHandler = (id, isActive) => {
    if (isActive) {
      dispatch(enableSubStore(id));
    } else {
      dispatch(disableSubStore(id));
    }
    setUpdateCount((prev) => prev + 1); // Force a re-render
  };

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(deleteError, { variant: "error" });
      dispatch(clearError());
    }
    dispatch(getAllSubStores()); // Fetch sub-stores data
  }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar, updateCount]);

const deleteStoreHandler = (id) => {
    dispatch(deleteSubStore(id)).then(() => {
        setUpdateCount((prev) => prev + 1); // Force a re-render
        enqueueSnackbar('Store deleted successfully', { variant: 'success' });
    });
};

  const handleEditClick = (store) => {
    setSelectedStore(store); // Set the selected sub-store data
    setEditModalOpen(true); // Open the edit modal
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "store_id",
      headerName: "Store ID",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "sub_store_id",
      headerName: "Sub Store ID",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "sub_store_owner",
      headerName: "Sub Store Owner",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      flex: 1,
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Actions
                editRoute={`/admin/sub-store/${params.row.id}`}
                deleteHandler={deleteStoreHandler}
                id={params.row.id}
                isActive={params.row.is_active}
                toggleActiveHandler={toggleActiveHandler}
                onEditClick={() => handleEditClick(params.row)}
                onDeleteSuccess={() => {
                    // Optionally, you can also refresh the data here
                    dispatch(getAllSubStores()); // Refresh the data after deletion
                }}
            />
      ),
    },
  ];

  const rows = subStores.map(item => {
    if (!item.id) {
        console.error("Item without id found:", item); // Log items without an id
        return null; // Skip this item
    }
    return {
        id: item.id, // Ensure id is present
        store_id: item.store_id,
        sub_store_id: item.sub_store_id,
        phone: item.phone,
        sub_store_owner: item.sub_store_owner,
        is_active: item.is_active,
    };
}).filter(row => row !== null); // Filter out any null entries

console.log("Rows for DataGrid:", rows);

  console.log("Rows for DataGrid:", rows);

  return (
    <>
      <MetaData title="Admin Stores | Vivimart" />

      {loading && <BackdropLoader />}

      <div className="flex justify-between mx-10 mb-6">
        <div className="text-[20px]">Sub Store Table</div>
        <button
          className="text-[20px] bg-primary-blue px-4 py-1 rounded-lg text-white font-semibold"
          onClick={() => setAddModalOpen(true)} // Open the add modal
        >
          Add
        </button>
      </div>

      <div
        className="bg-white rounded-xl shadow-lg w-full"
        style={{ height: 470 }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectIconOnClick
          sx={{
            boxShadow: 0,
            border: 0,
          }}
        />
      </div>

      <EditModal
        open={isEditModalOpen}
        handleClose={() => setEditModalOpen(false)}
        storeData={selectedStore}
      />
      <AddModal
        open={isAddModalOpen}
        handleClose={() => setAddModalOpen(false)} // Close the add modal
        existingStores={subStores} // Pass existing stores for ID calculation
      />
    </>
  );
};

export default SubStore;
