import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MyOrders from "../Order/MyOrders";
import MyAddress from "../User/MyAddress";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails, updateOrderStatus } from "../../actions/orderAction"; // Import updateOrderStatus action
import { useSnackbar } from "notistack";

const MobileOrderDetailsPage = () => {
  const [activeComponent, setActiveComponent] = useState("orders"); // Default to 'orders'
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { order, loading, error } = useSelector((state) => state.orderDetails);
  const { isUpdated } = useSelector((state) => state.order);

  useEffect(() => {
    const phoneNumber = localStorage.getItem("userPhoneNumber");
    if (phoneNumber) {
      setUserPhoneNumber(phoneNumber);
    }
  }, []);

  const handleLogout = () => {
    // dispatch(logoutUser());
    // navigate("/login");
    // enqueueSnackbar("Logout Successfully", { variant: "success" });
    localStorage.removeItem("userPhoneNumber");
    window.location.href = "/";
  };

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetails(id));
    }
  }, [dispatch, id, isUpdated]);

  const handleCancelOrder = () => {
    dispatch(updateOrderStatus(id, "Cancelled")).then(() => {
      enqueueSnackbar("Order canceled successfully", { variant: "success" });
      navigate("/orders");
    });
  };

   // Date formatting function
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedDate = date.toLocaleString('en-US', options);

    // Append the appropriate suffix for the day
    const day = date.getDate();
    const suffix = day % 10 === 1 && day !== 11 ? 'st' :
                   day % 10 === 2 && day !== 12 ? 'nd' :
                   day % 10 === 3 && day !== 13 ? 'rd' : 'th';
    
    return formattedDate.replace(/(\d+)(?=,)/, `${day}${suffix}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!order) return <p>No order details available.</p>;

  return (
    <div className="flex w-auto h-[50rem] lg:mt-36 sm:mt-0 lg:mx-32">
      <div className="flex fixed z-40 w-full top-0 left-0 border-b p-4 bg-white lg:hidden mr-4">
              <div className="">
                <Link to="/account/orders">
                  <ArrowBackIosNewIcon sx={{ fontSize: 25 }} />
                </Link>
              </div>
              <div>
                <div className="text-[20px] ml-4 lg:mb-4">#ORDER {order.order_id}</div>
              </div>
            </div>

      {/* Content Area */}
      <div className="flex-1 mt-16 w-full">
        <div className="order-details-page p-4">
          <h2 className="text-lg font-bold mb-2">Order Details</h2>
          <p className="mb-2">{order.order_items.length} items</p>
          <div className="order-details bg-white lg:p-4 rounded-lg">
            <ul className="w-full">
              {order.order_items.map((item, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between mb-4 lg:w-[60rem]"
                >
                  <div className="w-full border p-2 flex items-center justify-between rounded-lg">
                    <div className="flex items-center">
                      <img
                        src={item.product_image_0}
                        alt={item.Product_name}
                        className="w-12 h-12 object-cover rounded-md mr-4"
                      />
                      <div>
                        <p className="font-semibold">{item.Product_name}</p>
                        <div className="flex">
                        <p className="text-sm text-gray-600">
                          {item.weight}
                        </p>
                        <p className="text-sm ml-4 text-gray-600">
                          {item.quantity} unit{item.quantity > 1 ? "s" : ""}
                        </p>

                        </div>
                        
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="font-semibold">
                        ₹{parseFloat(item.sell_price).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="bill-summary border rounded-lg p-4 pt-4 mt-4 lg:w-[60rem]">
              <h3 className="text-md font-semibold mb-4">Bill Summary</h3>
              <div className="flex justify-between mb-2">
                <p>Item Total</p>
                <p>₹{parseFloat(order.total_price).toFixed(2)}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p>Handling Charge</p>
                <p>FREE</p>
              </div>
              <div className="flex justify-between mb-2">
                <p>Delivery Fee: Free with Pass</p>
                <p className="text-green-500">FREE</p>
              </div>
              <div className="flex justify-between font-bold text-lg mt-2 border-t pt-2">
                <p>Total Bill</p>
                <p>₹{parseFloat(order.total_price).toFixed(2)}</p>
              </div>
            </div>
            <div className="bill-summary border rounded-lg p-4 pt-4 mt-4 lg:w-[60rem]">
              <h3 className="text-md font-semibold mb-4">Order Details</h3>
              <div className="justify-between mb-2">
                <div className="text-[16px] text-gray-400">Order ID</div>
                <div className="text-[12px]">#ORDER {order.order_id}</div>
              </div>
              <div className="justify-between mb-2">
                <div className="text-[16px] text-gray-400">Delivery Address</div>
                <div className="text-[12px]">{order.address}</div>
              </div>
              <div className="justify-between mb-2">
                <div className="text-[16px] text-gray-400">Order Placed</div>
                <div className="text-[12px]">{formatDate(order.order_date)}</div>
              </div>
            </div>
            <button
              onClick={handleCancelOrder}
              className="bg-red-500 text-white mt-4 py-2 px-4 rounded-lg shadow-md hover:bg-red-600"
            >
              Cancel Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileOrderDetailsPage;
