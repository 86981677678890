import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MyOrders from "../Order/MyOrders";
import MyAddress from "../User/MyAddress";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails, updateOrderStatus } from "../../actions/orderAction"; // Import updateOrderStatus action
import { useSnackbar } from "notistack";

const OrderDetailsPage = () => {
  const [activeComponent, setActiveComponent] = useState("orders"); // Default to 'orders'
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [canCancelOrder, setCanCancelOrder] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { order, loading, error } = useSelector((state) => state.orderDetails);
  const { isUpdated } = useSelector((state) => state.order);

  useEffect(() => {
    const phoneNumber = localStorage.getItem("userPhoneNumber");
    if (phoneNumber) {
      setUserPhoneNumber(phoneNumber);
    }
  }, []);

  useEffect(() => {
    if (order) {
      const orderDate = new Date(order.order_date);
      const currentTime = new Date();
      const timeDifference = currentTime - orderDate; // Time difference in milliseconds
      const fiveMinutesInMillis = 5 * 60 * 1000; // 5 minutes in milliseconds

      // Check if the order was placed within the last 5 minutes
      setCanCancelOrder(timeDifference < fiveMinutesInMillis);
    }
  }, [order]);

  const handleLogout = () => {
    // dispatch(logoutUser());
    // navigate("/login");
    // enqueueSnackbar("Logout Successfully", { variant: "success" });
    localStorage.removeItem("userPhoneNumber");
    window.location.href = "/";
  };

  // Date formatting function
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = date.toLocaleString("en-US", options);

    // Append the appropriate suffix for the day
    const day = date.getDate();
    const suffix =
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th";

    return formattedDate.replace(/(\d+)(?=,)/, `${day}${suffix}`);
  };

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetails(id));
    }
  }, [dispatch, id, isUpdated]);

  const handleCancelOrder = () => {
    dispatch(updateOrderStatus(id, "Cancelled")).then(() => {
      enqueueSnackbar("Order canceled successfully", { variant: "success" });
      navigate("/orders");
    });
  };

  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!order) return <p>No order details available.</p>;

  return (
    <div className="flex w-auto h-auto mt-44 lg:mt-36 sm:mt-0 lg:mx-32">
      {/* Sidebar */}
      <div className="hidden sm:flex flex-col w-72 px-1">
        <div className="max-w-sm mx-auto bg-white p-4 w-full">
          <div className="flex items-center mb-4">
            <AccountCircleOutlinedIcon sx={{ fontSize: 60 }} />
            <div className="ml-4">
              <p className="text-gray-500">{userPhoneNumber}</p>
            </div>
          </div>
          <div className="space-y-4 mt-6">
            <div className="flex items-center border-b p-2 justify-between cursor-pointer">
              <Link to={`/profile/orders`}>
                <div className="flex items-center">
                  <MedicalServicesOutlinedIcon sx={{ fontSize: 20 }} />
                  <span className="ml-4 text-lg">My Orders</span>
                </div>
              </Link>
            </div>
            <div className="flex items-center border-b p-2 justify-between cursor-pointer">
              <Link to={`/profile/addresses`}>
                <div className="flex items-center">
                  <PinDropOutlinedIcon sx={{ fontSize: 20 }} />
                  <span className="ml-4 text-lg">My Addresses</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            className="bg-[#F70D1A] text-white px-6 py-1 mt-4 font-semibold rounded-lg"
            onClick={handleLogout}
          >
            Log Out
          </button>
        </div>
      </div>

      {/* Content Area */}
      <div className="flex-1 w-full">
        <div className="order-details-page p-4">
          <h2 className="text-lg font-bold mb-2">Order Details</h2>
          <p className="mb-2">{order.order_items.length} items</p>
          <div className="order-details bg-white lg:p-4 rounded-lg">
            <ul className="w-full">
              {order.shipments && order.shipments.length > 0
                ? order.shipments.map((shipment, shipmentIndex) => (
                    <div key={shipmentIndex}>
                      <h3 className="font-bold mb-2">
                        Shipment {shipmentIndex + 1}
                      </h3>
                      {shipment.items.map((shipmentItem, itemIndex) => {
                        // Find the corresponding order item based on product_id
                        const matchingOrderItem = order.order_items.find(
                          (orderItem) =>
                            orderItem.product_id === shipmentItem.product_id
                        );

                        // If a matching order item is found, render the details
                        return matchingOrderItem ? (
                          <li
                            key={itemIndex}
                            className="flex items-center justify-between mb-4 lg:w-[60rem]"
                          >
                            <div className="w-full border p-2 flex items-center justify-between rounded-lg">
                              <div className="flex items-center">
                                <img
                                  src={matchingOrderItem.product_image_0} // Use image from matching order item
                                  alt={matchingOrderItem.Product_name} // Use name from matching order item
                                  className="w-12 h-12 object-cover rounded-md mr-4"
                                />
                                <div>
                                  <p className="font-semibold">
                                    {matchingOrderItem.Product_name}
                                  </p>
                                  <div className="flex">
                                    <p className="text-sm text-gray-600">
                                      {matchingOrderItem.weight}
                                    </p>
                                    <p className="text-sm ml-4 text-gray-600">
                                      {matchingOrderItem.quantity} unit
                                      {matchingOrderItem.quantity > 1
                                        ? "s"
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="text-right">
                                <p className="font-semibold">
                                  ₹
                                  {parseFloat(
                                    matchingOrderItem.sell_price
                                  ).toFixed(2)}
                                </p>
                              </div>
                            </div>
                          </li>
                        ) : null; // If no matching order item is found, render nothing
                      })}
                    </div>
                  ))
                : order.order_items.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between mb-4 lg:w-[60rem]"
                    >
                      <div className="w-full border p-2 flex items-center justify-between rounded-lg">
                        <div className="flex items-center">
                          <img
                            src={item.product_image_0}
                            alt={item.Product_name}
                            className="w-12 h-12 object-cover rounded-md mr-4"
                          />
                          <div>
                            <p className="font-semibold">{item.Product_name}</p>
                            <div className="flex">
                              <p className="text-sm text-gray-600">
                                {item.weight}
                              </p>
                              <p className="text-sm ml-4 text-gray-600">
                                {item.quantity} unit
                                {item.quantity > 1 ? "s" : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <p className="font-semibold">
                            ₹{parseFloat(item.sell_price).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
            </ul>
            <div className="bill-summary border rounded-lg p-4 pt-4 mt-4 lg:w-[60rem]">
              <h3 className="text-md font-semibold mb-4">Bill Summary</h3>
              <div className="flex justify-between mb-2">
                <p>Item Total</p>
                <p>₹{parseFloat(order.total_price).toFixed(2)}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p>Handling Charge</p>
                <p>FREE</p>
              </div>
              <div className="flex justify-between mb-2">
                <p>Delivery Fee: Free with Pass</p>
                <p className="text-green-500">FREE</p>
              </div>
              <div className="flex justify-between font-bold text-lg mt-2 border-t pt-2">
                <p>Total Bill</p>
                <p>₹{parseFloat(order.total_price).toFixed(2)}</p>
              </div>
            </div>
            <div className="bill-summary border rounded-lg p-4 pt-4 mt-4 lg:w-[60rem]">
              <h3 className="text-md font-semibold mb-4">Order Details</h3>
              <div className="justify-between mb-2">
                <div className="text-[16px] text-gray-400">Order ID</div>
                <div className="text-[12px]">#ORDER {order.order_id}</div>
              </div>
              <div className="justify-between mb-2">
                <div className="text-[16px] text-gray-400">
                  Delivery Address
                </div>
                <div className="text-[12px]">{order.address}</div>
              </div>
              <div className="justify-between mb-2">
                <div className="text-[16px] text-gray-400">Order Placed</div>
                <div className="text-[12px]">
                  {formatDate(order.order_date)}
                </div>
              </div>
            </div>
            {canCancelOrder && (
              <button
                onClick={handleCancelOrder}
                className="bg-red-500 text-white mt-4 py-2 px-4 rounded-lg shadow-md hover:bg-red-600"
              >
                Cancel Order
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsPage;
