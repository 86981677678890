import { useEffect, useState } from 'react';
import { myOrders, clearErrors } from '../../actions/orderAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Layouts/Loader';
import { useSnackbar } from 'notistack';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SearchIcon from '@mui/icons-material/Search';
import OrderItem from "./OrderItem"
import MinCategory from '../Layouts/MinCategory';
import MetaData from '../Layouts/MetaData';
import { Link } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import MobileOrderItem from './MobileOrderItem';

const orderStatus = ["Processing", "Shipped", "Delivered"];
const dt = new Date();
const ordertime = [dt.getMonth(), dt.getFullYear() - 1, dt.getFullYear() - 2];



const MobileMyOrders = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState("");
  const [orderTime, setOrderTime] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");

  useEffect(() => {
    const phoneNumber = localStorage.getItem("userPhoneNumber");
    if (phoneNumber) {
      setUserPhoneNumber(phoneNumber);
    }
  }, []);


  const { orders, loading, error } = useSelector((state) => state.myOrders);


  console.log("My Orders:", orders);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(myOrders());
  }, [dispatch, error, enqueueSnackbar]);

  useEffect(() => {
    if (!loading && orders) {
      setFilteredOrders(orders.slice().reverse());
    }
  }, [loading, orders]);

  useEffect(() => {
    setSearch("");
    // console.log(status);
    // console.log(typeof orderTime);
    // console.log(orderTime);

    if (!status && +orderTime === 0) {
      setFilteredOrders(orders);
      return;
    }

    if (status && orderTime) {
      if (+orderTime === dt.getMonth()) {
        const filteredArr = orders.filter(
          (order) =>
            order.orderStatus === status &&
            new Date(order.createdAt).getMonth() === +orderTime
        );
        setFilteredOrders(filteredArr);
      } else {
        const filteredArr = orders.filter(
          (order) =>
            order.orderStatus === status &&
            new Date(order.createdAt).getFullYear() === +orderTime
        );
        setFilteredOrders(filteredArr);
      }
    } else if (!status) {
      if (+orderTime === dt.getMonth()) {
        const filteredArr = orders.filter(
          (order) => new Date(order.createdAt).getMonth() === +orderTime
        );
        setFilteredOrders(filteredArr);
      } else {
        const filteredArr = orders.filter(
          (order) => new Date(order.createdAt).getFullYear() === +orderTime
        );
        setFilteredOrders(filteredArr);
      }
    } else {
      const filteredArr = orders.filter(
        (order) => order.orderStatus === status
      );
      setFilteredOrders(filteredArr);
    }
    // eslint-disable-next-line
  }, [status, orderTime]);

  const searchOrders = (e) => {
    e.preventDefault();
    if (!search.trim()) {
      enqueueSnackbar("Empty Input", { variant: "warning" });
      return;
    }
    const arr = orders.map((el) => ({
      ...el,
      orderItems: el.orderItems.filter((order) =>
        order.name.toLowerCase().includes(search.toLowerCase())
      ),
    }));
    setFilteredOrders(arr);
  };

  const clearFilters = () => {
    setStatus("");
    setOrderTime(0);
  };

  return (
    <>
      <MetaData title="My Orders | Vivimart" />

      <main className="w-full bg-gray-100">
        {/* <!-- row --> */}
        <div className="flex gap-1 mt-2">
          {/* <!-- sidebar column  --> */}

          {/* <!-- orders column --> */}
          <div className="flex-1 w-full mx-2 lg:mx-8">
            <div className="flex fixed z-40 w-full top-0 left-0 border-b p-4 bg-white lg:hidden mr-4">
              <div className="">
                <Link to="/account">
                  <ArrowBackIosNewIcon sx={{ fontSize: 25 }} />
                </Link>
              </div>
              <div>
                <div className="text-[20px] ml-4 lg:mb-4">My Orders</div>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="flex flex-col h-full lg:h-[30rem] sm:mr-4 mt-12 overflow-auto">
                {/* ... existing search bar and filters code */}

                {/* Orders List */}
                <div className="">
                  {filteredOrders.length ? (
                    filteredOrders.map((order) => (
                      <MobileOrderItem key={order.order_id} order={order} />
                    ))
                  ) : (
                    <p className="text-center text-gray-500">No orders found</p>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* <!-- orders column --> */}
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  );
};

export default MobileMyOrders;
