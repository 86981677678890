// src/actions/subStoreActions.js

import {
  SUB_STORE_FAIL,
  SUB_STORE_REQUEST,
  SUB_STORE_SUCCESS,
  CLEAR_ERRORS,
  SUB_STORE_CREATE_REQUEST,
  SUB_STORE_CREATE_SUCCESS,
  SUB_STORE_CREATE_FAIL,
  SUB_STORE_UPDATE_REQUEST,
  SUB_STORE_UPDATE_SUCCESS,
  SUB_STORE_UPDATE_FAIL,
  SUB_STORE_DELETE_REQUEST,
  SUB_STORE_DELETE_SUCCESS,
  SUB_STORE_DELETE_FAIL,
  SUB_STORE_TOGGLE_REQUEST,
  SUB_STORE_TOGGLE_SUCCESS,
  SUB_STORE_TOGGLE_FAIL,
  SUB_STORE_ALL_REQUEST,
  SUB_STORE_ALL_SUCCESS,
  SUB_STORE_ALL_FAIL,
  SUB_STORE_ENABLE_REQUEST,
  SUB_STORE_ENABLE_SUCCESS,
  SUB_STORE_ENABLE_FAIL,
  SUB_STORE_DISABLE_REQUEST,
  SUB_STORE_DISABLE_SUCCESS,
  SUB_STORE_DISABLE_FAIL,
  SUB_STORE_BY_ID_REQUEST,
  SUB_STORE_BY_ID_SUCCESS,
  SUB_STORE_BY_ID_FAIL,
} from "../constants/subStoreConstants";
import axiosInstance from "../utils/api";


// Fetch all sub-stores
export const getAllSubStores = () => async (dispatch) => {
    try {
      dispatch({ type: SUB_STORE_ALL_REQUEST });
  
      const { data } = await axiosInstance.get('/sub-stores'); // Adjust the endpoint as needed
  
      dispatch({
        type: SUB_STORE_ALL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUB_STORE_ALL_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  // Fetch a sub-store by ID
export const getSubStoreById = (id) => async (dispatch) => {
  try {
    dispatch({ type: SUB_STORE_BY_ID_REQUEST });

    const { data } = await axiosInstance.get(`/sub-stores/id/${id}`); // Adjust the endpoint as needed

    dispatch({
      type: SUB_STORE_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUB_STORE_BY_ID_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// Fetch sub-stores by store ID
export const getSubStoreByStoreId = (storeId) => async (dispatch) => {
  try {
      dispatch({ type: SUB_STORE_REQUEST });

      const { data } = await axiosInstance.get(`/sub-stores/${storeId}`);

      dispatch({
          type: SUB_STORE_SUCCESS,
          payload: data,
      });
  } catch (error) {
      dispatch({
          type: SUB_STORE_FAIL,
          payload: error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
      });
  }
};

export const enableSubStore = (id) => async (dispatch) => {
    try {
      dispatch({ type: SUB_STORE_ENABLE_REQUEST });
  
      const { data } = await axiosInstance.patch(`/sub-stores/enable/${id}`);
  
      dispatch({
        type: SUB_STORE_ENABLE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUB_STORE_ENABLE_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };
  
  // Disable a sub-store
  export const disableSubStore = (id) => async (dispatch) => {
    try {
      dispatch({ type: SUB_STORE_DISABLE_REQUEST });
  
      const { data } = await axiosInstance.patch(`/sub-stores/disable/${id}`);
  
      dispatch({
        type: SUB_STORE_DISABLE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUB_STORE_DISABLE_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

// Create a new sub-store
export const createSubStore = (subStoreData) => async (dispatch) => {
  try {
      dispatch({ type: SUB_STORE_CREATE_REQUEST });

      const { data } = await axiosInstance.post('/sub-stores/add', subStoreData);

      dispatch({
          type: SUB_STORE_CREATE_SUCCESS,
          payload: data,
      });
  } catch (error) {
      dispatch({
          type: SUB_STORE_CREATE_FAIL,
          payload: error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
      });
  }
};

// Update an existing sub-store
export const updateSubStore = ({ id, subStoreData }) => async (dispatch) => {
  try {
      dispatch({ type: SUB_STORE_UPDATE_REQUEST });

      const { data } = await axiosInstance.put(`/sub-stores/${id}`, subStoreData);

      dispatch({
          type: SUB_STORE_UPDATE_SUCCESS,
          payload: data,
      });
  } catch (error) {
      dispatch({
          type: SUB_STORE_UPDATE_FAIL,
          payload: error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
      });
  }
};

// Delete (deactivate) a sub-store
export const deleteSubStore = (id) => async (dispatch) => {
  try {
      dispatch({ type: SUB_STORE_DELETE_REQUEST });

      const { data } = await axiosInstance.delete(`/sub-stores/remove/${id}`);

      dispatch({
          type: SUB_STORE_DELETE_SUCCESS,
          payload: data,
      });
  } catch (error) {
      dispatch({
          type: SUB_STORE_DELETE_FAIL,
          payload: error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
      });
  }
};

export const toggleSubStoreStatus = (id) => async (dispatch) => {
  try {
    dispatch({ type: SUB_STORE_TOGGLE_REQUEST });

    const { data } = await axiosInstance.patch(`/sub-stores/toggle/${id}`);

      dispatch({
          ttype: SUB_STORE_TOGGLE_SUCCESS,
          payload: data,
      });
  } catch (error) {
      dispatch({
        type: SUB_STORE_TOGGLE_FAIL,
        payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
  }
};

// Clear errors
export const clearError = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};