import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import MetaData from "../Layouts/MetaData";
import BackdropLoader from "../Layouts/BackdropLoader";
import {
  getAllVirtualAccounts,
  clearErrors,
  updateCustomer,
} from "../../actions/storeAction.js";
import {
  createVirtualAccount,
  addAllowedPayer,
} from "../../actions/storeAction.js"; // Import the new action

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axiosInstance from "../../utils/api.js";

const VirtualAccount = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [updateCount, setUpdateCount] = useState(0);

  // State for managing the main dialog (for creating virtual account)
  const [open, setOpen] = useState(false);
  const [storeData, setStoreData] = useState({
    id: "",
    name: "",
    email: "",
    phone_number: "",
  });

  // State for managing the allowed payer dialog (for ifsc and account_number)
  const [payerOpen, setPayerOpen] = useState(false);
  const [payerData, setPayerData] = useState({
    ifsc: "",
    account_number: "",
  });
  const [selectedStoreId, setSelectedStoreId] = useState(null); // Store the selected store ID

  const [selectedCustomerData, setSelectedCustomerData] = useState(null);
  const [editOpen, setEditOpen] = useState(false);

  const { virtualAccounts, error, loading } = useSelector(
    (state) => state.virtualAccount
  );

  console.log("Selected Customer Data:", selectedCustomerData);

  useEffect(() => {
    dispatch(getAllVirtualAccounts()); // Fetch virtual accounts
  }, [dispatch, updateCount]);

  const handleCustomerEdit = (customerData) => {
    setSelectedCustomerData(customerData);
    setEditOpen(true); // Open the dialog for editing customer details
  };

  const columns = [
    { field: "id", headerName: "ID", minWidth: 100, flex: 0.5 },
    {
      field: "customer_id",
      headerName: "Customer ID",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <span
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => handleCustomerEdit(params.row)} // Open customer edit modal
        >
          {params.row.customer_id}
        </span>
      ),
    },
    { field: "store_id", headerName: "Store ID", minWidth: 200, flex: 0.5 },
    { field: "name", headerName: "Store Owner Name", minWidth: 200, flex: 0.5 },
    { field: "contact", headerName: "Contact", minWidth: 200, flex: 1 },
    { field: "email", headerName: "Mail ID", minWidth: 200, flex: 1 },
    {
      field: "virtual_id",
      headerName: "Virtual ID",
      minWidth: 200,
      flex: 0.5,
      renderCell: (params) => (
        <span
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => handleStoreIdClick(params.row.virtual_id)} // Open allowed payer dialog
        >
          {params.row.virtual_id}
        </span>
      ),
    },
    {
      field: "beneficiary_name",
      headerName: "Beneficiary Name",
      minWidth: 200,
      flex: 1,
    },
    { field: "ifsc_code", headerName: "IFSC Code", minWidth: 150, flex: 1 },
    
    {
      field: "account_number",
      headerName: "Account Number",
      minWidth: 200,
      flex: 1,
    },   
    
  ];

  const rows = virtualAccounts.map((item) => ({
    id: item.id || null, // Default to null if id is missing
    store_id: item.store_id || null, // Default to null if store_id is missing
    name: item.name || null, // Default to null if name is missing
    virtual_id: item.account_id || null, // Default to null if account_id is missing
    email: item.email || null, // Default to null if email is missing
    contact: item.contact || null, // Default to null if contact is missing
    account_number: item.account_number || null, // Default to null if account_number is missing
    customer_id: item.customer_id || null, // Default to null if customer_id is missing
    ifsc_code: item.ifsc || null, // Default to null if ifsc is missing
    beneficiary_name: item.beneficiary_name
  }));
  

  // Handle form input change for the main dialog
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form input change for the payer form
  const handlePayerChange = (e) => {
    const { name, value } = e.target;
    setPayerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form input change for the customer edit form
  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setSelectedCustomerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submit for creating virtual account
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/stores/virtual-account-create", storeData);

      console.log("Response:", response);
  
      if (response?.data?.error) {
        if (
          response.data.error.code === "BAD_REQUEST_ERROR" &&
          response.data.error.description === "Customer already exists for the merchant"
        ) {
          enqueueSnackbar("Customer already exists for this merchant.", { 
            variant: "error" 
          });
        } else {
          enqueueSnackbar("Failed to create Virtual Account", { 
            variant: "error" 
          });
        }
      } else {
        setUpdateCount((prev) => prev + 1);
        setOpen(false); // Close only if successful
        setStoreData({});
        enqueueSnackbar("Virtual Account created successfully", { 
          variant: "success" 
        });
      }
    } catch (error) {
      enqueueSnackbar("Customer already exists.", { 
        variant: "error" 
      });
    }
  };
  

  // Handle form submit for adding allowed payer
  const handlePayerSubmit = async (e) => {
    e.preventDefault();
    if (selectedStoreId) {
      try {
        const response = await axiosInstance.post(
          `/stores/${selectedStoreId}/add-allowed-payer`,
          payerData
        );
        console.log("Payer added successfully:", response.data);
        setPayerOpen(false); // Close the payer popup
        setPayerData({ ifsc: "", account_number: "" }); // Reset form
        enqueueSnackbar("Payer added successfully", { variant: "success" });
      } catch (error) {
        console.error("Error adding allowed payer:", error.response?.data || error);
        const errorData = error.response?.data?.error;
        if (errorData) {
          if (
            errorData.code === "BAD_REQUEST_ERROR" &&
            errorData.reason === "duplicate_allowed_payer"
          ) {
            enqueueSnackbar("Payer details already exist for this virtual account.", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("Payer details already exist for this virtual account.", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Failed to add payer due to an unexpected error.", {
            variant: "error",
          });
        }
      }
    }
  };

  // Open the payer dialog and set the selected store ID
  const handleStoreIdClick = (virtualId) => {
    console.log("Virtual ID:", virtualId);
    setSelectedStoreId(virtualId); // Set the store ID
    setPayerOpen(true); // Open the payer dialog
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (selectedCustomerData?.id) {
      const updatedData = {
        ...selectedCustomerData, // Retain the selected customer data
      };
      // Call API to update the customer details
      dispatch(updateCustomer(selectedCustomerData.customer_id, updatedData)); // Implement this action for updating customer
      setUpdateCount(updateCount + 1);
      setEditOpen(false); // Close the popup
      dispatch(getAllVirtualAccounts());
      enqueueSnackbar("Customer details updated successfully", {
        variant: "success",
      });
    }
  };

  return (
    <>
      <MetaData title="Admin Virtual Accounts | Vivimart" />

      {loading && <BackdropLoader />}

      <div className="flex justify-between mx-10 mb-6">
        <div className="text-[20px]">Virtual Accounts</div>
        <button
          onClick={() => setOpen(true)} // Open the popup
          className="text-[20px] bg-primary-blue px-4 py-1 rounded-lg text-white font-semibold"
        >
          Add
        </button>
      </div>

      <div
        className="bg-white rounded-xl shadow-lg w-full"
        style={{ height: 470 }}
      >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
      </div>

      {/* Popup Form for creating virtual account */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create Virtual Account</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="ID"
              name="id"
              value={storeData.id}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Name"
              name="name"
              value={storeData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Email"
              name="email"
              value={storeData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Phone Number"
              name="phone_number"
              value={storeData.phone_number}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Description"
              name="custom_description"
              value={storeData.custom_description}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup Form for adding allowed payer */}
      <Dialog open={payerOpen} onClose={() => setPayerOpen(false)}>
        <DialogTitle>Add Allowed Payer</DialogTitle>
        <DialogContent>
          <form onSubmit={handlePayerSubmit}>
            <TextField
              label="IFSC"
              name="ifsc"
              value={payerData.ifsc}
              onChange={handlePayerChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Account Number"
              name="account_number"
              value={payerData.account_number}
              onChange={handlePayerChange}
              fullWidth
              margin="normal"
              required
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPayerOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePayerSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup Form for editing customer */}
      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <DialogTitle>Edit Customer Details</DialogTitle>
        <DialogContent>
          <form onSubmit={handleEditSubmit}>
            <TextField
              label="Customer ID"
              name="customer_id"
              value={selectedCustomerData?.customer_id || ""}
              onChange={handleCustomerChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Name"
              name="name"
              value={selectedCustomerData?.name || ""}
              onChange={handleCustomerChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Email"
              name="email"
              value={selectedCustomerData?.email || ""}
              onChange={handleCustomerChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Phone Number"
              name="contact"
              value={selectedCustomerData?.contact || ""}
              onChange={handleCustomerChange}
              fullWidth
              margin="normal"
              required
            />
            {/* Add other fields here */}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VirtualAccount;
