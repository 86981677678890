import {
    GET_DELIVERY_PERSONS_REQUEST,
    GET_DELIVERY_PERSONS_SUCCESS,
    GET_DELIVERY_PERSONS_FAIL,
    ADD_DELIVERY_PERSON_REQUEST,
    ADD_DELIVERY_PERSON_SUCCESS,
    ADD_DELIVERY_PERSON_FAIL,
    UPDATE_DELIVERY_PERSON_REQUEST,
    UPDATE_DELIVERY_PERSON_SUCCESS,
    UPDATE_DELIVERY_PERSON_FAIL,
    DELETE_DELIVERY_PERSON_REQUEST,
    DELETE_DELIVERY_PERSON_SUCCESS,
    DELETE_DELIVERY_PERSON_FAIL,
    ENABLE_DELIVERY_PERSON_REQUEST,
    ENABLE_DELIVERY_PERSON_SUCCESS,
    ENABLE_DELIVERY_PERSON_FAIL,
    DISABLE_DELIVERY_PERSON_REQUEST,
    DISABLE_DELIVERY_PERSON_SUCCESS,
    DISABLE_DELIVERY_PERSON_FAIL,
    CREATE_DELIVERY_ORDER_REQUEST,
    CREATE_DELIVERY_ORDER_SUCCESS,
    CREATE_DELIVERY_ORDER_FAIL,
    GET_DELIVERY_PERSONS_BY_STORE_ID_REQUEST,
    GET_DELIVERY_PERSONS_BY_STORE_ID_SUCCESS,
    GET_DELIVERY_PERSONS_BY_STORE_ID_FAIL,
    CLEAR_ERRORS,
} from "../constants/deliveryPersonConstants";

const initialState = {
    deliveryPersons: [],
    loading: false,
    error: null,
};

export const deliveryPersonReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DELIVERY_PERSONS_REQUEST:
        case ADD_DELIVERY_PERSON_REQUEST:
        case UPDATE_DELIVERY_PERSON_REQUEST:
        case DELETE_DELIVERY_PERSON_REQUEST:
        case ENABLE_DELIVERY_PERSON_REQUEST:
        case DISABLE_DELIVERY_PERSON_REQUEST:
        case CREATE_DELIVERY_ORDER_REQUEST:
        case GET_DELIVERY_PERSONS_BY_STORE_ID_REQUEST:
            return { ...state, loading: true };
        case GET_DELIVERY_PERSONS_SUCCESS:
            return { loading: false, deliveryPersons: action.payload };
        case GET_DELIVERY_PERSONS_BY_STORE_ID_SUCCESS:
            return { loading: false, deliveryPersons: action.payload };
        case ADD_DELIVERY_PERSON_SUCCESS:
        case UPDATE_DELIVERY_PERSON_SUCCESS:
        case ENABLE_DELIVERY_PERSON_SUCCESS:
        case DISABLE_DELIVERY_PERSON_SUCCESS:
        case CREATE_DELIVERY_ORDER_SUCCESS:
            return { ...state, loading: false };
        case DELETE_DELIVERY_PERSON_SUCCESS:
            return {
                ...state,
                loading: false,
                deliveryPersons: state.deliveryPersons.filter(person => person.id !== action.payload.id),
            };
        case GET_DELIVERY_PERSONS_FAIL:
        case ADD_DELIVERY_PERSON_FAIL:
        case UPDATE_DELIVERY_PERSON_FAIL:
        case DELETE_DELIVERY_PERSON_FAIL:
        case ENABLE_DELIVERY_PERSON_FAIL:
        case DISABLE_DELIVERY_PERSON_FAIL:
        case CREATE_DELIVERY_ORDER_FAIL:
        case GET_DELIVERY_PERSONS_BY_STORE_ID_FAIL:
            return { ...state, loading: false, error: action.payload };
        case CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};