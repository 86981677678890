export const GET_PAYMENT_GATEWAYS_REQUEST = "GET_PAYMENT_GATEWAYS_REQUEST";
export const GET_PAYMENT_GATEWAYS_SUCCESS = "GET_PAYMENT_GATEWAYS_SUCCESS";
export const GET_PAYMENT_GATEWAYS_FAIL = "GET_PAYMENT_GATEWAYS_FAIL";

export const UPDATE_PAYMENT_GATEWAY_REQUEST = "UPDATE_PAYMENT_GATEWAY_REQUEST";
export const UPDATE_PAYMENT_GATEWAY_SUCCESS = "UPDATE_PAYMENT_GATEWAY_SUCCESS";
export const UPDATE_PAYMENT_GATEWAY_FAIL = "UPDATE_PAYMENT_GATEWAY_FAIL";

export const TOGGLE_PAYMENT_GATEWAY_STATUS_REQUEST = "TOGGLE_PAYMENT_GATEWAY_STATUS_REQUEST";
export const TOGGLE_PAYMENT_GATEWAY_STATUS_SUCCESS = "TOGGLE_PAYMENT_GATEWAY_STATUS_SUCCESS";
export const TOGGLE_PAYMENT_GATEWAY_STATUS_FAIL = "TOGGLE_PAYMENT_GATEWAY_STATUS_FAIL";

export const GET_PAYMENT_GATEWAY_BY_NAME_REQUEST = "GET_PAYMENT_GATEWAY_BY_NAME_REQUEST";
export const GET_PAYMENT_GATEWAY_BY_NAME_SUCCESS = "GET_PAYMENT_GATEWAY_BY_NAME_SUCCESS";
export const GET_PAYMENT_GATEWAY_BY_NAME_FAIL = "GET_PAYMENT_GATEWAY_BY_NAME_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";