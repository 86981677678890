import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

const Searchbar = ({ items, handleSearch }) => {
  const [keyword, setKeyword] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [isChangingPlaceholder, setIsChangingPlaceholder] = useState(false);
  const [searchResults, setSearchResults] = useState([]); // State for search results
  const navigate = useNavigate();
  const location = useLocation();
  const searchInputRef = useRef(null);

  const placeholders = ["Fruits", "Vegetables", "Groceries", "Fish", "Chicken", "Mutton", "Shoes", "Slippers", "Electronics"];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsChangingPlaceholder(true);
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
      setTimeout(() => {
        setIsChangingPlaceholder(false);
      }, 1000); // Wait for the transition to complete
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval);
  }, [placeholders.length]);

  useEffect(() => {
    if (location.pathname === "/") {
      setKeyword("");
    }
  }, [location.pathname]);

  const handleOnSearch = (string) => {
    console.log("Searching for:", string);
    
    // Filter items based on the search string
    const filteredResults = items.filter(item => 
      item.name.toLowerCase().includes(string.toLowerCase())
    );

    // Create a custom item for "Show all results for [keyword]"
    const showAllResultsItem = {
      id: 'show_all_results', // Unique ID for this item
      name: `Show all results for "${string}"`, // Display text
      isShowAll: true // Custom property to identify this item
    };

    // Append the custom item to the filtered results
    const updatedResults = [...filteredResults, showAllResultsItem];
    setSearchResults(updatedResults); // Update the state with new results
    handleSearch(string);
  };

  const handleOnSelect = (item) => {
    if (item.isShowAll) {
      navigate(`/search/${item.name.replace('Show all results for "', '').replace('"', '')}`);
    } else {
      console.log("Selected item:", item);
      navigate(`/search/${item.name}`);
    }
    
    // Clear the input field after a short delay
    setTimeout(() => {
      setKeyword(""); // Reset the input field
      console.log("keyword reseted")
      searchInputRef.current?.focus();
    }, 100); // Adjust the delay as needed
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleOnChange = (event) => {
    setKeyword(event.target.value); // Update the keyword state on input change
  };

  const fuseOptions = {
    keys: [
      "name",
      "categories",
      "subCategories",
      "subSubCategories",
      "brandName",
    ],
    threshold: 0.6,
    useExtendedSearch: true,
  };

  const formatResult = (item) => {
    return (
      <div className="flex flex-wrap w-full items-center md:flex-nowrap lg:flex-nowrap">
        <div className="flex items-center md:mb-0">
          {item.isShowAll ? ( // Check if it's the "Show all results" item
            <span className="font-bold">{item.name}</span> // Make it bold or style it differently
          ) : (
            <>
              <img src={item.img} alt={item.name} className="w-8 h-8 mr-3" />
              <span>{item.name}</span>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <style>
        {`
    ::placeholder {
  color: gray;
  font-size: 16px;
  font-family: Arial;
}

.changing::placeholder {
  opacity: 0;
  transition: opacity 0.5s;
}
  `}
      </style>

      <form>
        <ReactSearchAutocomplete
          items={searchResults} // Use the updated search results
          fuse={fuseOptions}
          onSelect={handleOnSelect}
          onSearch={handleOnSearch}
          onChange={handleOnChange} // Add the onChange handler
          onFocus={handleOnFocus}
          formatResult={formatResult}
          value={keyword} // Set the input value to the keyword state
          placeholder={`Search "${placeholders[placeholderIndex]}"`}
        />
      </form>
    </>
  );
};

export default Searchbar;