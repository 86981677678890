import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  ADMIN_SUB_CATEGORY_API,
  ADMIN_PRODUCT_API,
} from "../../utils/constants";
import { NextBtn, PreviousBtn } from "./Banner/Banner";
import Slider from "react-slick";
import Product from "../Products/Product";
import CategoryProduct from "./CategoryProduct";

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  initialSlide: 1,
  swipe: true,
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
  ],
};

const CategorySection = ({ id, category }) => {
  const dispatch = useDispatch();
  const [subCategory, setSubCategory] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(20);
  const [showAll, setShowAll] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchSubCategoryData = async () => {
      try {
        const response = await fetch(
          `${ADMIN_SUB_CATEGORY_API}/category/${category}`
        );
        const data = await response.json();
        console.log("Fetched product data:", data); // Debugging line

        setSubCategory(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchSubCategoryData();
  }, [category]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await fetch(
          `${ADMIN_PRODUCT_API}/category/${category}?limit=8`
        );
        const productData = await response.json();

        console.log("API Response:", productData);

        const totalCount = productData[0]; // Total number of products
        const newProducts = productData[1]; // Array of product objects

        console.log("Fetched product data:", newProducts);

        setProducts(newProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProductData();
  }, [category]);

  console.log("sub category data:", subCategory);
  console.log("products data:", products);

  return (
    <div className="w-full h-full">
      {subCategory && subCategory.length > 0 && (
        <div className="w-full mt-6 lg:mt-0">
          <div className="flex justify-between items-center border-l-4 w-auto border-primary-blue">
            <h1 className="text-[18px] lg:text-2xl font-semibold lg:text-gray-900 ml-3 lg:ml-4">
              Explore By {category}
            </h1>
            {subCategory.length > 12 && (
              <button
                onClick={() => setShowAll(!showAll)}
                className="text-blue-500 text-[18px] rounded-lg"
              >
                {showAll ? "Show Less" : "See All"}
              </button>
            )}
          </div>
          <div className="grid grid-cols-4 gap-4 md:grid-cols-6 md:gap-4 lg:grid-cols-8 lg:gap-6 w-full lg:h-full mt-8">
            {showAll
              ? subCategory.map((item, index) => (
                  <div key={item.sub_category_id}>
                    <Link
                      to={`/products?sub_category_id=${item.sub_category_id}`}
                      key={item.sub_category_id}
                    >
                      <div className="items-center">
                        <div className="flex justify-center items-center">
                          <img
                            src={item.sub_category_img}
                            alt={item.sub_category_name}
                            className="w-full h-full object-cover rounded-lg mb-2 items-center"
                            onError={(e) =>
                              (e.target.src =
                                "https://ik.imagekit.io/efsdltq0e/icons/No_img.png?updatedAt=1727376099723")
                            }
                          />
                        </div>
                        <p className="text-[12px] md:text-[14px] lg:text-[18px] font-semibold text-center lg:text-center leading-4 lg:leading-6">
                          {item.sub_category_name}
                        </p>
                      </div>
                    </Link>
                    {(index + 1) % 5 === 0 && <br />}{" "}
                    {/* add a line break every 5 items */}
                  </div>
                ))
              : subCategory.slice(0, 12).map((item, index) => (
                  <div key={item.sub_category_id}>
                    <Link
                      to={`/products?sub_category_id=${item.sub_category_id}`}
                      key={item.sub_category_id}
                    >
                      <div className="items-center">
                        <div className="flex justify-center items-center">
                          <img
                            src={item.sub_category_img}
                            alt={item.sub_category_name}
                            className="w-full h-full object-cover rounded-lg mb-2 items-center"
                            onError={(e) =>
                              (e.target.src =
                                "https://ik.imagekit.io/efsdltq0e/icons/No_img.png?updatedAt=1727376099723")
                            }
                          />
                        </div>
                        <p className="text-[12px] md:text-[14px] lg:text-[18px] font-semibold text-center lg:text-center leading-4 lg:leading-6">
                          {item.sub_category_name}
                        </p>
                      </div>
                    </Link>
                    {(index + 1) % 5 === 0 && <br />}{" "}
                    {/* add a line break every 5 items */}
                  </div>
                ))}
          </div>
          <div className="w-full h-full bg-white lg:mt-6 lg:mb-6">
            <div className="text-[18px] mb-2 lg:text-2xl font-semibold lg:text-gray-900">
              {category}
            </div>
            <Slider {...settings}>
              {products.map((item, index) => (
                <div className="flex flex-cols w-full items-center justify-center bg-white h-full overflow-x-auto hidden-scrollbar">
                  <div className="m-1 md:my-2 lg:my-2">
                    <CategoryProduct
                      key={item.Product_id}
                      _id={item.Product_id}
                      product={item}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategorySection;
