import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError, getSubStoreByStoreId } from "../../actions/subStoreActions";
import { useSnackbar } from "notistack";
import "./OrderDetails.css";
import { updateOrderStatus, forwardOrder, updateOrderItemStatus, getAllOrders } from "../../actions/orderAction";
import { BsInfoCircle } from "react-icons/bs";
import { CgNotes } from "react-icons/cg";
import { MdDeliveryDining } from "react-icons/md";
import { GiShoppingBag } from "react-icons/gi";
import { useNavigate } from "react-router";
import { DataGrid } from '@mui/x-data-grid';

const OrderDetails = ({ order }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [checkedItems, setCheckedItems] = useState({});
  const [tab, setTab] = useState("New");
  const [subStore, setSubStore] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedSubStoreOwner, setSelectedSubStoreOwner] = useState("");
  const [forwardedItems, setForwardedItems] = useState([]);
  const [currentTab, setCurrentTab] = useState("New Order");
  const [refreshOrderDetails, setRefreshOrderDetails] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState([]);
  

  const { subStores, loading, error } = useSelector((state) => state.subStores);
  const { success, error: statusError } = useSelector(
    (state) => state.orderItemStatus
  );
  const [orderDetails, setOrderDetails] = useState(order);

  useEffect(() => {
    const storeId = order.store_id;
    if (storeId) {
      dispatch(getSubStoreByStoreId(storeId));
    }

    return () => {
      dispatch(clearError());
    };
  }, [dispatch, order.store_id]);

  useEffect(() => {
    dispatch(getAllOrders(order.order_id));
  }, [order.order_id]);

  useEffect(() => {
    if (refreshOrderDetails) {
      dispatch(getAllOrders(order.order_id))
        .then((response) => {
          if (response && response.data) {
            setOrderDetails(response.data);
            console.log("Updated order details:", response.data);
          } else {
            console.error("No order details found in response:", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching updated order details:", error);
        })
        .finally(() => {
          setRefreshOrderDetails(false);
        });
    }
  }, [dispatch, order.order_id, refreshOrderDetails]);
  

  useEffect(() => {
    const allItemsSelected = order.order_items.every(item => checkedItems[item.product_id]);
    setAllSelected(allItemsSelected);
  }, [checkedItems, order.order_items]);

  const handleCheckboxChange = (productId) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [productId]: !prevState[productId],
    }));
  };

  const handleForward = () => {
    const selectedItems = Object.keys(checkedItems)
      .filter((productId) => checkedItems[productId])
      .map((productId) => ({
        product_id: parseInt(productId, 10),
        quantity:
          order.order_items.find(
            (item) => item.product_id === parseInt(productId, 10)
          )?.quantity || 0,
      }));

    // Add logic to handle the forward action with selectedItems and selectedSubStoreOwner
    console.log("Selected Items: ", selectedItems);
    console.log("Selected Sub Store Owner: ", selectedSubStoreOwner);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleAccept = () => {
    const itemsToDispatch = order.order_items.filter(
      (item) => checkedItems[item.product_id]
    );

    console.log("Items to Dispatch:", itemsToDispatch);
    if (itemsToDispatch.length === 0) {enqueueSnackbar("Please select items to Dispatch", {
        variant: "warning",
      });
      return;
    }

    const validItems = itemsToDispatch.map((item) => ({
      product_id: item.product_id,
      status: "Accepted",
    }));

    console.log("Valid items for dispatch:", validItems);
    dispatch(updateOrderItemStatus(order.order_id, validItems))
      .then(() => {
        setRefreshOrderDetails(true); // Trigger reload
        setCheckedItems({});
        setAllSelected(false);
        enqueueSnackbar("Order accepted successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error("Error dispatching order:", error);
        enqueueSnackbar("Failed to dispatch order", { variant: "error" });
      });
  };

  const handleSelectAll = () => {
    const newCheckedItems = {};
    order.order_items
      .filter((item) => !forwardedItems.includes(item.product_id))
      .forEach((item) => {
        newCheckedItems[item.product_id] = !allSelected;
      });
    setCheckedItems(newCheckedItems);
    setAllSelected(!allSelected);
  };

  const selectedItems = order.order_items.filter(
    (item) => checkedItems[item.product_id]
  );
  const selectedTotalItems = selectedItems.length;
  const selectedTotalQuantity = selectedItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const selectedTotalAmount = selectedItems
    .reduce((total, item) => total + item.sell_price * item.quantity, 0)
    .toFixed(2);

  const handleConformForward = () => {
    const itemsToDispatch = order.order_items.filter(
      (item) => checkedItems[item.product_id]
    );

    console.log("Items to Dispatch:", itemsToDispatch);
    if (itemsToDispatch.length === 0) {
      enqueueSnackbar("Please select items to Dispatch", {
        variant: "warning",
      });
      return;
    }

    const validItems = itemsToDispatch.map((item) => ({
      product_id: item.product_id,
      status: "Forwarded",
    }));

    console.log("Valid items for forward:", validItems);
    dispatch(updateOrderItemStatus(order.order_id, validItems))
      .then(() => {
        setRefreshOrderDetails(true); // Trigger reload
        setCheckedItems({});
        setAllSelected(false);
        setIsPopupOpen(false);
        enqueueSnackbar("Order Forwarded successfully", { variant: "success" });
      })
      .catch((error) => {
        console.error("Error dispatching order:", error);
        enqueueSnackbar("Failed to dispatch order", { variant: "error" });
      });
  };

  const handleSubStoreChange = (e) => {
    const selectedSubStore = subStores.find(
      (store) => store.sub_store_id === e.target.value
    );
    setSubStore(e.target.value);
    setSelectedSubStoreOwner(selectedSubStore?.sub_store_owner || "");
    console.log("Sub Store changed:", e.target.value);
  };

  // Filter for displayed items based on tab selection
  const filteredItems =
    currentTab === "Accepted"
      ? order.order_items.filter((item) => item.status === "Accepted")
      : currentTab === "Forwarded"
      ? order.order_items.filter((item) => item.status === "Forwarded")
      : currentTab === "Dispatched"
      ? order.order_items.filter((item) => item.status === "Dispatched")
      : currentTab === "Delivered"
      ? order.order_items.filter((item) => item.status === "Delivered")
      : currentTab === "Canceled"
      ? order.order_items.filter((item) => item.status === "Canceled")
      : order.order_items;

  // Filter for forwarded items
  const forwardedOrderItems = order.order_items.filter(
    (item) => item.status === "Forwarded"
  );

  //function to implement status
  const getStatusStyle = (status) => {
    switch (status) {
      case "New":
        return "text-blue-500 font-bold";
      case "Accepted":
        return "text-green-500 font-bold";
      case "Forwarded":
        return "text-yellow-500 font-bold";
      case "Dispatched":
        return "text-orange-500 font-bold";
      case "Delivered":
        return "text-green-500 font-bold";
      case "Canceled":
        return "text-red-500 font-bold";
      default:
        return "text-gray-500 font-bold";
    }
  };

  const renderActionButton = () => {
    switch (currentTab) {
      case "New Order":
        return (
          <>
            <button
              className="bg-green-500 text-white py-2 px-4 rounded"
              onClick={handleAccept}
            >
              Accept
            </button>
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded"
              onClick={handleForward}
            >
              Forward
            </button>
          </>
        );
      case "Accepted":
        return (
          <>
            <button
              className="bg-yellow-500 text-white py-2 px-4 rounded"
              onClick={handleDispatch}
            >
              Dispatch
            </button>
          </>
        );
      case "Forwarded":
        return (
          <>
            <button
              className="bg-yellow-500 text-white py-2 px-4 rounded"
              onClick={handleDispatch}
            >
              Dispatch
            </button>
          </>
        );
      case "Dispatched":
      case "Delivered":
      case "Canceled":
        return null;
      default:
        return null;
    }
  };

  const handleDispatch = () => {
    const itemsToDispatch = order.order_items.filter(
      (item) => checkedItems[item.product_id]
    );

    console.log("Items to Dispatch:", itemsToDispatch);
    if (itemsToDispatch.length === 0) {
      enqueueSnackbar("Please select items to Dispatch", {
        variant: "warning",
      });
      return;
    }

    const validItems = itemsToDispatch.map((item) => ({
      product_id: item.product_id,
      status: "Dispatched",
    }));

    console.log("Valid items for dispatch:", validItems);
    dispatch(updateOrderItemStatus(order.order_id, validItems))
      .then(() => {
        setRefreshOrderDetails(true); // Trigger reload
        setCheckedItems({});
        setAllSelected(false);
        enqueueSnackbar("Order dispatched successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error("Error dispatching order:", error);
        enqueueSnackbar("Failed to dispatch order", { variant: "error" });
      });
  };

  const handlePrintInvoice = () => {
    const selectedItems = order.order_items.filter(
      (item) => checkedItems[item.product_id]
    );
    setInvoiceItems(selectedItems);

    navigate("/admin/orders/printStoreInvoice", {
      state: { order: { ...order, order_items: selectedItems } },
    });
  };

  const columns = [
    {
      field: "selectAll",
      headerAlign: "center",
      align: "center",
      headerName: (
        <input
          type="checkbox"
          checked={allSelected}
          onChange={handleSelectAll}
          className="mr-2 custom-checkbox"
        />
      ),
      minWidth: 50,
      type: "number",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="checkbox"
          className="mr-2 custom-checkbox"
          checked={checkedItems[params.row.product_id] || false}
          onChange={() => handleCheckboxChange(params.row.product_id)}
        />
      ),
    },
    {
      field: "category",
      headerName: "Category",
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "product",
      headerName: "Product",
      minWidth: 200,
      flex: 0.2,
      renderCell: (params) => (
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 rounded-full">
            <img
              draggable="false"
              src={params.row.image}
              alt={params.row.name}
              className="w-full h-full rounded-full object-cover"
            />
          </div>
          {params.row.name}
        </div>
      ),
    },
    {
      field: "weight",
      headerName: "Unit",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "sell_price",
      headerName: "Sell Price",
      type: "number",
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      renderCell: (params) => <span>₹{params.row.sell_price.toLocaleString()}</span>,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
      renderCell: (params) => (
        <span className={`${getStatusStyle(params.value)}`}>
          {params.value}
        </span>
      ),
    },
    {
      field: "total_price",
      headerName: "Total Price",
      type: "number",
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      renderCell: (params) => (
        <span>₹{(params.row.sell_price * params.row.quantity).toFixed(2)}</span>
      ),
    },
    
  ];

  const rows = filteredItems.map((item) => ({
    id: item.product_id,
    product_id: item.product_id,
    name: item.Product_name,
    category: item.category,
    image: item.product_image_0, // Ensure this key matches your actual data
    weight: item.weight,
    quantity: item.quantity,
    sell_price: item.sell_price,
    status: item.status,
    total_price: item.sell_price * item.quantity,
  }));

  return (
    <div className="px-8 py-4">
      <div>
        <div className="p-6 flex border shadow-md rounded-lg relative gap-8 cursor-pointer">
          <div className="flex gap-2 flex-col w-full">
            <h1>
              <span className="font-bold">Order Date: </span>
              {order.order_date}
            </h1>
            <h2>
              <span className="font-bold">Delivery Address: </span>
              {order.address}
            </h2>
          </div>
        </div>
        {/* Tabs for selecting order view */}
        <div className="flex flex-wrap justify-center gap-4 md:gap-8 mt-10 mb-10 border shadow-md rounded-lg p-6">
          <button
            className={`w-full md:w-auto px-4 py-2 ${
              currentTab === "New Order"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setCurrentTab("New Order")}
          >
            Orders
          </button>
          <button
            className={`w-full md:w-auto px-4 py-2 ${
              currentTab === "Accepted"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setCurrentTab("Accepted")}
          >
            Accepted
          </button>
          <button
            className={`w-full md:w-auto px-4 py-2 ${
              currentTab === "Forwarded"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setCurrentTab("Forwarded")}
          >
            Forwarded
          </button>
          <button
            className={`w-full md:w-auto px-4 py-2 ${
              currentTab === "Dispatched"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setCurrentTab("Dispatched")}
          >
            Dispatched
          </button>
          <button
            className={`w-full md:w-auto px-4 py-2 ${
              currentTab === "Delivered"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setCurrentTab("Delivered")}
          >
            Delivered
          </button>
          <button
            className={`w-full md:w-auto px-4 py-2 ${
              currentTab === "Canceled"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setCurrentTab("Canceled")}
          >
            Canceled
          </button>
        </div>
        <div className="mt-4 mb-4 border shadow-md rounded-lg p-6">
          <div className="flex justify-between items-center mb-2 pb-2 border-b">
            <div className="text-md font-bold mb-2">Order Items</div>
            <button
              onClick={handlePrintInvoice}
              className="text-white bg-blue-500 p-2 mb-2"
            >
              Print Invoice
            </button>
          </div>
          {/* Order Items Table */}
          
          <div className="bg-white rounded-xl w-full" style={{ height: 300 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          sx={{
            boxShadow: 0,
            border: 0,
          }}
        />
      </div>

        </div>
        <div className="mt-6 border shadow-md rounded-md p-6">
          <div className="text-md font-bold mb-4 pb-2">
            Selected Items Summary
          </div>
          <div className="flex flex-wrap gap-4">
            <div className="border p-2 rounded-lg bg-purple-50 flex-1 text-center">
              Selected Items: {selectedTotalItems}
            </div>
            <div className="border p-2 rounded-lg bg-green-50 flex-1 text-center">
              Selected Items Quantity: {selectedTotalQuantity}
            </div>
            <div className="border p-2 rounded-lg bg-amber-100 flex-1 text-center">
              Selected Items Total Price: {selectedTotalAmount}
            </div>
          </div>
        </div>
        {/* Actions */}
        <div className="mt-4 flex gap-4">{renderActionButton()}</div>
        {/* Popup for Forward Order */}
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-gray-50 shadow-lg rounded-lg p-6 w-11/12 max-w-md">
              <h2 className="text-xl font-bold mb-4">Forward Details</h2>
              <p className="text-lg text-gray-400">Items to Forward:</p>
              <div className="list-disc pl-5 mb-4 ">
                {selectedItems.map((item) => (
                  <div
                    key={item.item_id}
                    className="flex items-center border rounded-lg gap-2 mt-3 p-2 bg-white hover:shadow-lg"
                  >
                    <img
                      src={item.product_image_0}
                      alt={item.Product_name}
                      className="w-12 h-12 object-cover rounded"
                    />
                    <div className="flex flex-col ml-3 flex-grow">
                      <div className="text-left text-sm">
                        {item.Product_name}
                      </div>
                      <div className="text-left text-sm text-gray-600">
                        {item.weight}
                      </div>
                      <div className="text-left text-sm font-semibold">
                        {item.sell_price}
                      </div>
                    </div>
                    <div className="ml-auto mr-12 text-right">
                      {item.quantity}
                    </div>
                  </div>
                ))}
              </div>
              <div className="form-group">
                <label htmlFor="subStore" className="font-bold text-gray-500">
                  <label htmlFor="subStore" className="font-bold text-gray-500">
                    Select Sub Store:
                  </label>
                  <select
                    id="subStore"
                    value={subStore}
                    onChange={handleSubStoreChange}
                    className="w-full p-2 border border-gray-300 rounded mt-2"
                  >
                    <option value="">Select Sub Store</option>
                    {subStores.map((subStore) => (
                      <option
                        key={subStore.sub_store_id}
                        value={subStore.sub_store_id}
                      >
                        {subStore.sub_store_owner}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="flex justify-end mt-4">
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                  onClick={handleClosePopup}
                >
                  Close
                </button>
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 ml-4"
                  onClick={handleConformForward}
                >
                  Forward
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
