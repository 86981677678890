import React, { useState, useEffect } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const AccordionPanel = ({ onUpiIdSubmit, onCardSubmit, onCodSubmit }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [upiId, setUpiId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cardDetails, setCardDetails] = useState({
    name: "",
    cardNumber: "",
    month: "",
    year: "",
    cvc: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Validate card details whenever they change
    validate(cardDetails);
  }, [cardDetails]);

  const handleCardChange = (e) => {
    const { name, value } = e.target;

    // Allow letters in the name field, but restrict others to digits
    if (name === "name") {
      setCardDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    } else {
      setCardDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value.replace(/\D/g, ""), // Allow only digits for other fields
      }));
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name can't be blank";
    }
    if (!values.cardNumber) {
      errors.cardNumber = "Card number can't be blank";
    } else if (values.cardNumber.length !== 16) {
      errors.cardNumber = "Card number must be 16 digits";
    }
    if (!values.month) {
      errors.month = "Can't be blank";
    } else if (values.month > 12) {
      errors.month = "Must be less than 12";
    }
    if (!values.year) {
      errors.year = "Can't be blank";
    }
    if (!values.cvc) {
      errors.cvc = "Can't be blank";
    }
    return errors;
  };

  const handleCardSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(cardDetails);
    if (Object.keys(errors).length === 0) {
      setLoading(true); // Start loading
      await onCardSubmit(cardDetails); // Assume onCardSubmit is a promise
      setLoading(false); // Stop loading
    } else {
      setFormErrors(errors);
    }
  };

  const handleUpiIdSubmit = async () => {
    setLoading(true); // Start loading
    await onUpiIdSubmit(upiId); // Assume onUpiIdSubmit is a promise
    setLoading(false); // Stop loading
  };

  const handleCodSubmit = async () => {
    setLoading(true); // Start loading
    await onCodSubmit(); // Assume onCodSubmit is a promise
    setLoading(false); // Stop loading
  };

  const togglePanel = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const panels = [
    {
      title: "Add credit or debit cards",
      content: (
        <div className={`p-4 ${activeIndex === 0 ? "block" : "hidden"}`}>
          <div className="px-4 py-4 md:px-6 md:py-4 lg:px-8 lg:py-4 border-2 border-blue-600 box-border rounded-lg bg-blue-50 cursor-pointer">
            <div className="flex flex-shrink-0 flex-no-wrap items-start">
              <div className="ml-1">
                <p className="leading-6 font-medium text-[16px] text-gray-700">
                  Add Debit / Credit / ATM Card
                </p>
                <div className="flex">
                  <div className="py-2 ">
                    <div className="relative w-full max-w-full h-6 overflow-hidden mr-2 rounded-md">
                      <img
                        src="https://b.zmtcdn.com/payments/card-logos/visa_v2.png"
                        alt="visa_v2"
                        className="w-full h-full object-contain opacity-100 transition-opacity duration-250 ease-in-out"
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="relative w-full max-w-full h-6 overflow-hidden mx-2 rounded-md">
                      <img
                        src="https://b.zmtcdn.com/payments/card-logos/mastercard_v2.png"
                        alt="mastercard_v2"
                        className="w-full h-full object-contain opacity-100 transition-opacity duration-250 ease-in-out"
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="relative w-full max-w-full h-6 overflow-hidden mx-2 rounded-md">
                      <img
                        src="https://b.zmtcdn.com/payments/card-logos/amex_v2.png"
                        alt="amex_v2"
                        className="w-full h-full object-contain opacity-100 transition-opacity duration-250 ease-in-out"
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="relative w-full max-w-full h-6 overflow-hidden mx-2 rounded-md">
                      <img
                        src="https://b.zmtcdn.com/payments/card-logos/rupay_v2.png"
                        alt="rupay_v2"
                        className="w-full h-full object-contain opacity-100 transition-opacity duration-250 ease-in-out"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <form onSubmit={handleCardSubmit}>
                    <div>
                      <TextField
                        fullWidth
                        id="Name"
                        label="Name on card"
                        type="Name"
                        name="name"
                        value={cardDetails.name}
                        onChange={handleCardChange}
                        error={!!formErrors.name}
                        helperText={formErrors.name}
                        required
                        InputProps={{
                          style: {
                            borderRadius: 5,
                            height: "3rem",
                            width: "100%",
                            maxWidth: "26rem",
                            padding: "10px 15px",
                          },
                        }}
                      />
                    </div>
                    <div className="mt-4">
                      <TextField
                        fullWidth
                        id="CardNumber"
                        name="cardNumber"
                        label="Card Number"
                        value={cardDetails.cardNumber}
                        onChange={handleCardChange}
                        error={!!formErrors.cardNumber}
                        helperText={formErrors.cardNumber}
                        required
                        InputProps={{
                          style: {
                            borderRadius: 5,
                            height: "3rem",
                            width: "100%",
                            maxWidth: "26rem",
                            padding: "10px 15px",
                          },
                        }}
                      />
                    </div>
                    <div className="mt-4 flex flex-col sm:flex-row gap-4">
                      <div className="flex flex-row gap-3">
                        <TextField
                          label="Exp Month (MM)"
                          name="month"
                          value={cardDetails.month}
                          onChange={handleCardChange}
                          error={!!formErrors.month}
                          helperText={formErrors.month}
                          required
                          inputProps={{ maxLength: 2 }}
                        />
                        <TextField
                          label="Exp Year (YY)"
                          name="year"
                          value={cardDetails.year}
                          onChange={handleCardChange}
                          error={!!formErrors.year}
                          helperText={formErrors.year}
                          required
                          input
                          Props={{ maxLength: 2 }}
                        />
                      </div>

                      <TextField
                        label="CVC"
                        name="cvc"
                        value={cardDetails.cvc}
                        onChange={handleCardChange}
                        error={!!formErrors.cvc}
                        helperText={formErrors.cvc}
                        required
                        inputProps={{ maxLength: 3 }}
                      />
                    </div>

                    <button
                      className={`w-full max-w-[26rem] mt-4 min-h-[44px] inline-flex items-stretch rounded-md ${
                        Object.keys(formErrors).length === 0
                          ? "bg-primary-blue cursor-pointer" // Enable button
                          : "bg-transparent cursor-not-allowed pointer-events-none opacity-50" // Disable button
                      }`}
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center">
                            <svg
                              className="animate-spin h-5 w-5 mr-3 text-white"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              />
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S16.627 6 12 6z"
                              />
                            </svg>
                            <span className="text-lg">Processing...</span>
                          </span>
                        </>
                      ) : (
                        <span className="inline-flex items-center justify-center h-full w-full leading-[44px] whitespace-nowrap text-lg font-light text-white">
                          Checkout
                        </span>
                      )}
                    </button>
                  </form>
                  <p className="leading-6 mt-6 text-gray-500 text-base text-xs break-words">
                    We accept Credit and Debit Cards from Visa, Mastercard,
                    American Express, Rupay, Pluxee & Diners.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Add new UPI ID",
      content: (
        <div className={`p-4 ${activeIndex === 1 ? "block" : "hidden"}`}>
          <div class="p-4 md:p-5 lg:p-4.8 bg-blue-50 border border-blue-600 rounded-lg cursor-pointer">
            <div className="flex flex-start">
              <div className="">
                <p className="leading-6 m-0 text-gray-700 text-[16px] font-medium">
                  Add new UPI
                </p>
                <div className="flex">
                  <div className="py-2">
                    <div class="relative w-full max-w-full h-6 overflow-hidden mr-2 rounded-md">
                      <img
                        src="https://b.zmtcdn.com/zpaykit/81b0d98cd17e3900a6f29aeeb78649281567742742.png"
                        alt="gpay"
                        className="w-full h-full object-contain opacity-100 transition-opacity duration-250 ease-in-out"
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <div class="relative w-full max-w-full h-6 overflow-hidden mx-2 rounded-md">
                      <img
                        src="https://b.zmtcdn.com/zpaykit/f52c90e85df90272a009580e23e410361567743087.png"
                        alt="ppay"
                        className="w-full h-full object-contain opacity-100 transition-opacity duration-250 ease-in-out"
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <div class="relative w-full max-w-full h-6 overflow-hidden mx-2 rounded-md">
                      <img
                        src="https://b.zmtcdn.com/zpaykit/cddd7915933e173e862363b300cb2e441567742836.png"
                        alt="bhmi"
                        className="w-full h-full object-contain opacity-100 transition-opacity duration-250 ease-in-out"
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <div class="relative w-full max-w-full h-6 overflow-hidden mx-2 rounded-md">
                      <img
                        src="https://b.zmtcdn.com/zpaykit/af07d421bc6da0f623672f3044a882901567742922.png"
                        alt="paytm"
                        className="w-full h-full object-contain opacity-100 transition-opacity duration-250 ease-in-out"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center">
                  <div className="relative w-full sm:w-[16rem] h-12 mb-2 sm:mb-0">
                    {" "}
                    {/* Added mb-2 for margin-bottom on mobile */}
                    <input
                      type="text"
                      value={upiId}
                      onChange={(e) => setUpiId(e.target.value)}
                      placeholder="Enter UPI ID"
                      className="px-3 py-2 border border-gray-300 rounded-lg w-full" // Added w-full for full width
                    />
                  </div>
                  <button
                    className="w-full lg:min-w-[10rem] lg:min-h-[44px] inline-flex items-stretch rounded-lg bg-transparent lg:ml-2 p-0 border-none cursor-pointer"
                    onClick={() => handleUpiIdSubmit()}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <span className="bg-primary-blue text-white px-4 py-2 rounded-md flex items-center">
                          <svg
                            className="animate-spin h-5 w-5 mr-3 text-white"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S16.627 6 12 6z"
                            />
                          </svg>
                          <span className="text-lg">Processing...</span>
                        </span>
                      </>
                    ) : (
                      <span className="inline-flex items-center justify-center h-full w-full min-w-[9rem] min-h-[44px] leading-[44px] whitespace-nowrap text-lg font-light text-white opacity-100 bg-primary-blue border-0 rounded-lg px-6 transition-transform duration-250 ease-in-out focus:outline-none">
                        Checkout
                      </span>
                    )}
                  </button>
                </div>
                <p className="leading-6 m-0 text-gray-500 text-xs font-medium mt-4">
                  The UPI ID is in the format of name/phone number@bankname
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Cash",
      content: (
        <div className={`p-4 ${activeIndex === 2 ? "block" : "hidden"}`}>
          <div>Please keep exact change handy to help us serve you better</div>
          <button
            className="w-full lg:min-w-[10rem] mt-4 min-h-[44px] inline-flex items-stretch rounded-lg bg-transparent p-0 border-none cursor-pointer"
            onClick={handleCodSubmit}
            disabled={loading}
          >
            {loading ? (
              <>
                <span className="bg-primary-blue text-white px-4 py-2 rounded-md flex items-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S16.627 6 12 6z"
                    />
                  </svg>
                  <span className="text-lg">Processing...</span>
                </span>
              </>
            ) : (
              <span className="inline-flex items-center justify-center h-full w-full min-w-[9rem] min-h-[44px] leading-[44px] whitespace-nowrap text-lg font-light text-white opacity-100 bg-primary-blue border-0 rounded-lg px-6 transition-transform duration-250 ease-in-out focus:outline-none">
                Place Order
              </span>
            )}
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="lg:w-[36rem] md:w-[46rem] w-[23rem] mx-auto lg:ml-[10rem] ml-[1rem] mt-2 lg:h-[30rem] hidden-scrollbar lg:overflow-y-auto">
      {panels.map((panel, index) => (
        <div key={index} className="border border-gray-300 rounded">
          <div
            className="flex justify-between items-center p-5 cursor-pointer "
            onClick={() => togglePanel(index)}
          >
            <span className="text-xl font-sans">{panel.title}</span>
            <svg
              className={`w-4 h-4 transition-transform transform ${
                activeIndex === index ? "rotate-0" : "rotate-180"
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          {panel.content}
        </div>
      ))}
    </div>
  );
};

export default AccordionPanel;