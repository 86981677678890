import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/api";

const AccordionPanel = ({ onCodSubmit, onPaymentSelect }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");

  const togglePanel = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchPaymentGateways = async () => {
      try {
        const response = await axiosInstance.get("/payment-gateways"); // Replace with your API endpoint
        const activeGateways = response.data.filter(
          (gateway) => gateway.is_active === 1
        );
        setPaymentGateways(activeGateways);
      } catch (error) {
        console.error("Error fetching payment gateways:", error);
      }
    };

    fetchPaymentGateways();
  }, []);


  const handlePayment = (gateway) => {
    setPaymentMethod(gateway.name); // Set selected payment method name
    onPaymentSelect(gateway); // Pass the selected gateway to the parent
  };

  const panels = [
    {
      title: "Online Payment",
      content: (
        <div className={`p-4 ${activeIndex === 0 ? "block" : "hidden"}`}>
          <div>Pay using online payment gateway</div>
          {paymentGateways.map((gateway) => (
            <div key={gateway.id}>
              <button
                className="w-full lg:min-w-[10rem] mt-4 min-h-[44px] inline-flex items-stretch rounded-lg bg-transparent p-0 border-none cursor-pointer"
                onClick={() => handlePayment(gateway)}
              >
                <span className="inline-flex items-center justify-center h-full w-full min-w-[9rem] min-h-[44px] leading-[44px] whitespace-nowrap text-lg font-light text-white opacity-100 bg-primary-blue border-0 rounded-lg px-6 transition-transform duration-250 ease-in-out focus:outline-none">
                  Pay with {gateway.name}
                </span>
              </button>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Cash on Delivery",
      content: (
        <div className={`p-4 ${activeIndex === 1 ? "block" : "hidden"}`}>
          <div>Please keep exact change handy to help us serve you better</div>
          <button
            className="w-full lg:min-w-[10rem] mt-4 min-h-[44px] inline-flex items-stretch rounded-lg bg-transparent p-0 border-none cursor-pointer"
            onClick={onCodSubmit}
          >
            <span className="inline-flex items-center justify-center h-full w-full min-w-[9rem] min-h-[44px] leading-[44px] whitespace-nowrap text-lg font-light text-white opacity-100 bg-primary-blue border-0 rounded-lg px-6 transition-transform duration-250 ease-in-out focus:outline-none">
              Place Order
            </span>
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="lg:w-[36rem] md:w-[46rem] w-[23rem] mx-auto lg:ml-[10rem] ml-[1rem] mt-2 lg:h-[30rem] hidden-scrollbar lg:overflow-y-auto">
      {panels.map((panel, index) => (
        <div key={index} className="border border-gray-300 rounded">
          <div
            className="flex justify-between items-center p-5 cursor-pointer "
            onClick={() => togglePanel(index)}
          >
            <span className="text-xl font-sans">{panel.title}</span>
            <svg
              className={`w-4 h-4 transition-transform transform ${
                activeIndex === index ? "rotate-0" : "rotate-180"
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          {panel.content}
        </div>
      ))}
    </div>
  );
};

export default AccordionPanel;