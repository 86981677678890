// ExampleComponent.js
import React, { useState } from 'react';
import ComingSoon from '../Layouts/ComingSoon';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";

const MobileMyWallet = () => {
  const [isFeatureAvailable, setIsFeatureAvailable] = useState(false); // Change this to true when the feature is ready

  return (
    <div className=''>
        <div className="flex mb-4 p-2 border-b">
            <div className="">
            <Link to="/account">
            <ArrowBackIosNewIcon sx={{ fontSize: 20 }} />
            </Link>
            </div>
            <div className="ml-4 text-[20px]">
                My Wallet
            </div>

        </div>
      {isFeatureAvailable ? (
        <div className='w-full h-full bg-black'>
          {/* Render your feature here */}
          <h1 className="text-2xl">This is the feature!</h1>
        </div>
      ) : (
        <div className='w-full h-full'>
          <ComingSoon featureName="Wallet feature is coming soon..." />
        </div>
        
      )}
    </div>
  );
};

export default MobileMyWallet;