import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import {
  clearErrors,
  deleteProduct,
  getAdminProducts,
} from "../../actions/productAction";
import Rating from "@mui/material/Rating";
import { DELETE_PRODUCT_RESET } from "../../constants/productConstants";
import Actions from "./Actions";
import MetaData from "../Layouts/MetaData";
import BackdropLoader from "../Layouts/BackdropLoader";
import {
  deleteStore,
  disableStore,
  enableStore,
  getStores,
} from "../../actions/storeAction";
import { DELETE_STORE_RESET } from "../../constants/storeConstants";
import EditStoreModal from "./EditStoreModal";
import AddStoreModal from "./AddStoreModal";

const Store = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [updateCount, setUpdateCount] = useState(0);
  const [selectedStore, setSelectedStore] = useState(null); // State to hold selected store data
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const { stores, error, loading } = useSelector((state) => state.store); // Access store data
  const { isDeleted, deleteError } = useSelector((state) => state.store); // Access delete state

  useEffect(() => {
    console.log("Fetched Stores:", stores);
}, [stores]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (deleteError) {
      enqueueSnackbar(deleteError, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getStores()); // Fetch stores data
  }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

  const deleteStoreHandler = (id) => {
    dispatch(deleteStore(id)); // Call delete action
  };

  const toggleActiveHandler = (id, isActive) => {
    if (isActive) {
      dispatch(enableStore(id));
    } else {
      dispatch(disableStore(id));
    }
    setUpdateCount((prev) => prev + 1); // Force a re-render
  };

  useEffect(() => {
    dispatch(getStores()); // Fetch stores data again
  }, [dispatch, updateCount]);

  useEffect(() => {
    console.log("Fetched Stores:", stores);
  }, [stores]);

  const handleEditClick = (store) => {
    setSelectedStore(store); // Set the selected store data
    setEditModalOpen(true); // Open the edit modal
  };

  const columns = [
    {
      field: "id",
      headerName: "Store ID",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "owner_photo",
      headerName: "Photo",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => (
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 rounded-full">
            <img
              draggable="false"
              src={params.row.owner_photo}
              alt={params.row.name}
              className="w-full h-full rounded-full object-cover"
            />
          </div>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "phone_number",
      headerName: "Phone",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email ID",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "primary_location",
      headerName: "Primary Location",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      flex: 1,
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Actions
          editRoute={`/admin/store/${params.row.id}`}
          deleteHandler={deleteStoreHandler}
          id={params.row.id}
          isActive={params.row.is_active}
          toggleActiveHandler={toggleActiveHandler} // Pass the toggle handler
          onEditClick={() => handleEditClick(params.row)}
        />
      ),
    },
  ];

  const rows = stores.map((item) => ({
    id: item.id,
    name: item.name,
    owner_name: item.owner_name, // Include owner_name
    owner_photo: item.owner_photo,
    password: item.password, // Include password
    state: item.state, // Include state
    city_name: item.city_name, // Include city_name
    primary_location: item.primary_location,
    sec_location_1: item.sec_location_1, // Include secondary locations
    sec_location_2: item.sec_location_2,
    sec_location_3: item.sec_location_3,
    sec_location_4: item.sec_location_4,
    sec_location_5: item.sec_location_5,
    package_fee: item.package_fee, // Include package_fee
    sec_location_6: item.sec_location_6,
    sec_location_7: item.sec_location_7,
    sec_location_8: item.sec_location_8,
    sec_location_9: item.sec_location_9,
    sec_location_10: item.sec_location_10,
    is_active: item.is_active, // Assuming this is already a boolean
    email: item.email,
    phone_number: item.phone_number,
  }));

  console.log("Rows for DataGrid:", rows);

  return (
    <>
      <MetaData title="Admin Stores | Vivimart" />

      {loading && <BackdropLoader />}

      <div className="flex justify-between mx-10 mb-6">
        <div className="text-[20px]">Store Table</div>
        <button
          className="text-[20px] bg-primary-blue px-4 py-1 rounded-lg text-white font-semibold"
          onClick={() => setAddModalOpen(true)} // Open the add modal
        >
          Add
        </button>
      </div>

      <div
        className="bg-white rounded-xl shadow-lg w-full"
        style={{ height: 470 }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectIconOnClick
          sx={{
            boxShadow: 0,
            border: 0,
          }}
        />
      </div>

      <EditStoreModal
        open={isEditModalOpen}
        handleClose={() => setEditModalOpen(false)}
        storeData={selectedStore}
      />
      <AddStoreModal
        open={isAddModalOpen}
        handleClose={() => setAddModalOpen(false)} // Close the add modal
      />
    </>
  );
};

export default Store;
