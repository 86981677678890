import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import veg from "../../assets/images/Veg.png";
import nonveg from "../../assets/images/NonVeg.png";
import { removeItem, addItemsToCart } from "../../actions/cartAction";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import deliveryBike from "../../assets/images/delivery-bike.png";
import deliveryTruck from "../../assets/images/delivery-truck.png";
import { ADMIN_PRODUCT_API } from "../../utils/constants";

const Product = ({ id, product }) => {
  const [showCounter, setShowCounter] = useState(false);
  const [productDetails, setProductDetails] = useState(product);
  const [selectedWeight, setSelectedWeight] = useState(productDetails.Weight);
  const [selectedSellPrice, setSelectedSellPrice] = useState(
    productDetails.sell_price
  );
  const [selectedMRP, setSelectedMRP] = useState(productDetails.MRP);
  const [selectedOffer, setSelectedOffer] = useState(productDetails.offer);
  const [weightQuantities, setWeightQuantities] = useState({});
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  console.log("cart items:  ", cartItems);

  const {
    MRP,
    offer,
    kind,
    Weight,
    you_save,
    sell_price,
    Brand_name,
    Product_name,
    Prodouct_img_0,
    Product_id,
    delivery_option,
    variablePrices,
    is_active,
  } = product;

  console.log("Product component product data:", product);

  const showOffer = sell_price > MRP;

  useEffect(() => {
    const quantities = {};
    cartItems.forEach((item) => {
      if (item.product_id === Product_id) {
        quantities[item.weight] = item.quantity;
      }
    });
    setWeightQuantities(quantities);
  }, [cartItems, Product_id]);

  const fetchProductData = async () => {
    try {
      // Get cart items from local storage and check if the product exists in the cart
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const item = cartItems.find((item) => item.product_id === Product_id);

      if (item) {
        setShowCounter(true);
        setCount(item.quantity);
      } else {
        setShowCounter(false);
      }

      // Fetch the price based on storeId if available
      const storedStoreId = localStorage.getItem("StoreId");
      const storeId = storedStoreId ? JSON.parse(storedStoreId).store_id : null;

      console.log("Fetched Store ID:", storeId); // Debugging line

      const priceUrl = storeId
        ? `${ADMIN_PRODUCT_API}/${Product_id}/price?storeId=${storeId}`
        : `${ADMIN_PRODUCT_API}/${Product_id}`;

      console.log("Price URL:", priceUrl); // Debugging line

      const priceResponse = await fetch(priceUrl);

      if (!priceResponse.ok) {
        throw new Error(`HTTP error! status: ${priceResponse.status}`);
      }

      const priceData = await priceResponse.json();

      // Update selected weight and sell price states
      if (priceData.length > 0) {
        setSelectedWeight(priceData[0].Weight);
        setSelectedSellPrice(priceData[0].sell_price);
        setSelectedMRP(priceData[0].MRP);
        setSelectedOffer(priceData[0].offer);
      } else {
        setSelectedWeight(product.Weight);
        setSelectedSellPrice(product.sell_price);
        setSelectedMRP(product.MRP);
        setSelectedOffer(product.offer);
      }

      console.log("Price Data:", priceData); // Debugging line

      // Update product details with fetched price data
      if (priceData && Array.isArray(priceData)) {
        setProductDetails((prevState) => ({
          ...prevState,
          variablePrices: priceData, // Set the variablePrices to the fetched price data
          MRP: priceData[0]?.MRP || prevState.MRP,
          offer: priceData[0]?.offer || prevState.offer,
          you_save: priceData[0]?.you_save || prevState.you_save,
          sell_price: priceData[0]?.sell_price || prevState.sell_price,
          delivery_option:
            priceData[0]?.delivery_option || prevState.delivery_option,
        }));
      }
    } catch (error) {
      console.error("Error fetching product or price data:", error);
    }
  };

  useEffect(() => {
    fetchProductData();
    if (product.variablePrices && product.variablePrices.length > 0) {
      setSelectedWeight(product.variablePrices[0].Weight);
      setSelectedSellPrice(product.variablePrices[0].sell_price);
      setSelectedMRP(product.variablePrices[0].MRP);
      setSelectedOffer(product.variablePrices[0].offer);
    } else {
      setSelectedWeight(product.Weight);
      setSelectedSellPrice(product.sell_price);
      setSelectedMRP(product.MRP);
      setSelectedOffer(product.offer);
    }
  }, [Product_id]); // Dependency array to re-fetch if Product_id changes

  useEffect(() => {
    const existingItem = cartItems.find(
      (item) => item.product_id === Product_id
    );
    if (existingItem) {
      setShowCounter(true);
      setCount(existingItem.quantity);
    } else {
      setShowCounter(false);
      setCount(0);
    }
  }, [cartItems, Product_id]);

  const handleAddToCart = (
    productId,
    quantity,
    weight,
    sell_price,
    MRP,
    offer,
    delivery_option
  ) => {
    dispatch(
      addItemsToCart(productId, quantity, weight, sell_price, MRP, offer, delivery_option)
    );
    setSelectedWeight(weight);
    setSelectedSellPrice(sell_price);
    setSelectedMRP(MRP);
    setSelectedOffer(offer);
    setShowCounter(true);
  };

  const handleIncrease = (productId, weight) => {
    dispatch(addItemsToCart(productId, 1, weight));
  };

  const handleDecrease = (productId, weight) => {
    if (count > 1) {
      dispatch(addItemsToCart(productId, -1, weight));
    } else {
      dispatch(removeItem(productId, weight));
      setShowCounter(false);
    }
  };

  const [isBoxOpen, setIsBoxOpen] = useState(false);

  const toggleBox = () => {
    setIsBoxOpen(!isBoxOpen);
  };

  return (
    <>
      <style>
        {`
    .zoom-container {
      position: relative;
      overflow: hidden;
      transform: scale(1);
    }

    .zoom-image {
      transition: transform 0.3s ease; /* Adjust the duration as needed */
      transform-origin: center center; /* Ensures zoom effect is centered */
    }

    .zoom-container:hover .zoom-image {
      transform: scale(1.1); /* Adjust scale factor as needed */
      transition: transform 0.6s ease;
    }
  `}
      </style>
      <div className="bg-white rounded-lg h-[360px] md:h-[335px] lg:h-[360px] flex-grow relative border border-[0.5px solid rgb(232, 232, 232)]">
        <div className="box-border  font-normal rounded-lg flex flex-col justify-between">
          <Link to={`/product/:${product.Product_id}`} state={{ count: count }}>
            <div className="zoom-container h-44 md:h-36 lg:h-44 w-full items-center">
              <img
                className="zoom-image bg-white w-full h-full object-contain"
                src={Prodouct_img_0}
                alt={Product_name}
                onError={(e) => (e.target.src = "/images/Noimage.jpg")}
              />
              {selectedOffer > 0 || productDetails.offer > 0 ? (
                <span
                  className="absolute top-1 left-1 flex items-center justify-center h-6 w-20 p-2 rounded-full"
                  style={{ backgroundColor: "#ffff33" }}
                >
                  <span className="text-sm lg:text-[14px] font-semibold block uppercase text-black">
                    {selectedOffer || productDetails.offer}% OFF
                  </span>
                </span>
              ) : null}
            </div>
          </Link>

          <div className="flex items-center pl-1 lg:mt-[-20px] z-20">
            <img
              className="h-4 w-auto mr-2"
              src={kind === "veg" ? veg : nonveg}
              alt={kind === "veg" ? "Veg" : "Non Veg"}
            />
          </div>
        </div>

        <div className="p-2 pt-1">
          <div className="">
            <h2 className="block text-sm md:text-xs truncate-3-lines text-gray-800">
              {product.Brand_name}
            </h2>
            <h2
              className="text-customGray text-[16px] lg:text-[15px] text-opacity-100 mb-1 truncate leading-5 font-normal pt-1 cursor-pointer"
              style={{ fontWeight: "500" }}
              title={product.Product_name}
            >
              {product.Product_name}
            </h2>
          </div>
          <div className="pb-1 lg:block">
            {product.variablePrices && product.variablePrices.length > 0 ? (
              <div className="bg-center bg-no-repeat bg-cover">
                <button
                  className="flex justify-between items-center border border-gray-100 rounded-md w-full overflow-hidden text-ellipsis whitespace-nowrap px-2 mt-2 h-7 leading-4 bg-white text-gray-600"
                  onClick={toggleBox}
                >
                  <span className="text-xs px-2">{selectedWeight}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 ml-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d={
                        isBoxOpen
                          ? "M10 7.414L3.707 13.707a1 1 0 001.414 1.414L10 10.242l4.879 4.879a1 1 0 001.414-1.414L10 7.414z"
                          : "M10 12.586L3.707 6.293a1 1 0 011.414-1.414L10 10.758l4.879-4.879a1 1 0 111.414 1.414L10 12.586z"
                      }
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              <div className="bg-center mt-3 bg-no-repeat bg-cover">
                <span className="text-xs px-2">{product.Weight}</span>
              </div>
            )}
          </div>
          <div className="flex justify-between items-center mt-3 md:mt-3 gap-2">
            <div className="flex flex-col text-xs">
              {!showOffer ? (
                <>
                  <div>
                    <span className="font-semibold text-[16px] lg:text-base text-[13px] md:text-[11px]">
                      ₹{selectedSellPrice || productDetails.sell_price}
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-400 line-through lg:text-sm text-[12px] md:text-[11px]">
                      ₹{selectedMRP || productDetails.MRP}
                    </span>
                  </div>
                </>
              ) : (
                <span className="text-gray-600 lg:text-xs text-[13px] md:text-[11px]">
                  {selectedWeight || productDetails.Weight}
                </span>
              )}
            </div>
            <div className="text-xs">
              {is_active === 1 ? (
                showCounter ? (
                  <div className="flex items-center lg:w-[5rem] w-[4rem] md:w-[3.5rem]">
                    <button
                      className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-sm leading-5 tracking-wide uppercase rounded-l"
                      onClick={() =>
                        handleDecrease(product.Product_id, selectedWeight)
                      }
                      style={{ backgroundColor: "rgb(49, 134, 22)" }}
                    >
                      -
                    </button>
                    <div
                      style={{ backgroundColor: "rgb(49, 134, 22)" }}
                      className="h-8 text-white font-semibold text-sm leading-5 tracking-wide bg-green-50 flex justify-center items-center px-2"
                    >
                      {weightQuantities[selectedWeight]}
                    </div>
                    <button
                      className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-md leading-5 tracking-wide uppercase rounded-r "
                      onClick={() =>
                        handleIncrease(product.Product_id, selectedWeight)
                      }
                      style={{ backgroundColor: "rgb(49, 134, 22)" }}
                    >
                      +
                    </button>
                  </div>
                ) : (
                  <button
                    style={{
                      border: "1px solid rgb(49, 134, 22)",
                      color: "rgb(49, 134, 22)",
                      fontSize: "13px",
                    }}
                    className="cursor-pointer w-[3rem] lg:w-16 md:w-[3.5rem] h-7 md:h-8 font-semibold text-xs leading-5 tracking-wide uppercase bg-green-50 rounded-lg flex justify-center items-center"
                    onClick={() =>
                      handleAddToCart(
                        product.Product_id,
                        1,
                        selectedWeight,
                        selectedSellPrice,
                        selectedMRP,
                        selectedOffer,
                        productDetails.delivery_option
                      )
                    }
                  >
                    ADD
                  </button>
                )
              ) : (
                <div className="text-red-500 text-base">Out of Stock</div>
              )}
            </div>
          </div>
          <div
            className={`mt-1 md:mt-2 rounded-md w-full p-1 flex items-center justify-center ${
              productDetails.delivery_option?.toLowerCase() === "fast delivery"
                ? "bg-yellow-200"
                : "bg-red-200"
            }`}
          >
            <div className="ml-2 hidden lg:block">
              {delivery_option === "Standard Delivery" ? (
                <img
                  src={deliveryTruck}
                  alt="deliveryTruck"
                  className="w-4 h-4 lg:w-6 lg:h-6"
                />
              ) : delivery_option === "fast delivery" ? (
                <img
                  src={deliveryTruck} // Replace with the appropriate fast delivery icon
                  alt="deliveryTruck"
                  className="w-4 h-4 lg:w-6 lg:h-6"
                />
              ) : (
                <img
                  src={deliveryBike}
                  alt="deliveryBike"
                  className="w-4 h-4 lg:w-6 lg:h-6"
                />
              )}
            </div>
            <div className="text-[12px] lg:text-[11px] md:text-sm lg:ml-1">
              {productDetails.delivery_option}
            </div>
          </div>
          {isBoxOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
              <div className="relative bg-white w-96 lg:w-4/12 lg:max-w-4/12 rounded-lg shadow-lg">
                <div className="flex justify-between items-center p-2 border-b border-gray-200">
                  <h2 className="text-lg font-semibold text-[#1F1F1F] truncate">
                    {product.Product_name}
                  </h2>
                  <div
                    onClick={toggleBox}
                    className="cursor-pointer text-black rounded-full p-1"
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div className="flex flex-col p-4">
                  {product.variablePrices &&
                    productDetails.variablePrices.map((price, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between bg-gray-50 p-2 lg:p-4 mb-2 rounded-lg shadow-sm"
                      >
                        <div className="flex items-center lg:gap-4">
                          <div className="relative">
                            {price.offer > 0 && (
                              <span className="absolute top-0 left-0 bg-green-500 text-white text-[10px] px-2 py-0.5 rounded-full">
                                {price.offer}% OFF
                              </span>
                            )}
                            <img
                              src={product.Prodouct_img_0}
                              alt={product.Product_name}
                              className="h-16 w-16 rounded-md object-cover"
                            />
                          </div>
                          <div className="p-2 w-10">
                            <h3 className="text-base lg:font-medium text-black lg:text-[#1F1F1F]">
                              {price.Weight}
                            </h3>
                          </div>
                          <div className="ml-10">
                            <div className="text-gray-500 line-through text-sm">
                              ₹{price.MRP}
                            </div>
                            <div className="text-green-600 text-lg font-bold">
                              ₹{price.sell_price}
                            </div>
                          </div>
                        </div>
                        {product.is_active === 1 ? (
                          showCounter && weightQuantities[price.Weight] ? (
                            <div className="flex items-center lg:w-[5rem] w-[4rem] md:w-[3.5rem]">
                              <button
                                className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-sm leading-5 tracking-wide uppercase rounded-l"
                                onClick={() =>
                                  handleDecrease(
                                    product.Product_id,
                                    price.Weight
                                  )
                                }
                                style={{ backgroundColor: "rgb(49, 134, 22)" }}
                              >
                                -
                              </button>
                              <div
                                style={{ backgroundColor: "rgb(49, 134, 22)" }}
                                className="h-8 text-white font-semibold text-sm leading-5 tracking-wide bg-green-50 flex justify-center items-center px-2"
                              >
                                {weightQuantities[price.Weight]}
                              </div>
                              <button
                                className="cursor-pointer w-8 h-8 bg-transparent text-white font-semibold text-md leading-5 tracking-wide uppercase rounded-r "
                                onClick={() =>
                                  handleIncrease(
                                    product.Product_id,
                                    price.Weight
                                  )
                                }
                                style={{ backgroundColor: "rgb(49, 134, 22)" }}
                              >
                                +
                              </button>
                            </div>
                          ) : (
                            <button
                              style={{
                                border: "1px solid rgb(49, 134, 22)",
                                color: "rgb(49, 134, 22)",
                                fontSize: "13px",
                              }}
                              className="cursor-pointer w-[3rem] lg:w-16 md:w-[3.5rem] h-7 md:h-8 font-semibold text-xs leading-5 tracking-wide uppercase bg-green-50 rounded-lg flex justify-center items-center"
                              onClick={() =>
                                handleAddToCart(
                                  product.Product_id,
                                  1,
                                  price.Weight,
                                  price.sell_price,
                                  price.MRP,
                                  price.offer,
                                  productDetails.delivery_option
                                )
                              }
                            >
                              ADD
                            </button>
                          )
                        ) : (
                          <div className="text-red-500 text-base">
                            Out of Stock
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Product;