import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { newOrder } from "../../actions/orderAction";
import logo from "../../assets/images/logo1.png";
import Logomb from "../../assets/images/Logomb.png";
import AccordionPanel from "./AccordionPanel";
import axiosInstance from "../../utils/api";
import { emptyCart } from "../../actions/cartAction";
import { getLocationByPostalCode } from "../../actions/locationAction";

const Checkout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const stdDelivery = location.state?.stdDelivery || [];
  const fastDelivery = location.state?.fastDelivery || [];
  const selectedAddress = location.state?.selectedAddress || {}
  const [paymentMethod, setPaymentMethod] = useState("");
  const [upiId, setUpiId] = useState("");
  const [cardDetails, setCardDetails] = useState({
    name: "",
    cardNumber: "",
    expiry: "",
    cvv: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);

  const { storeLocation } = useSelector((state) => state.storeLocation);

  useEffect(() => {
    dispatch(getLocationByPostalCode());
  }, [dispatch]);

  const locationId = storeLocation ? storeLocation.id : 1;

  console.log("Selected address:", selectedAddress);

  useEffect(() => {
    if (paymentMethod) {
      handlePayment(); // Call handlePayment when paymentMethod changes
    }
  }, [paymentMethod]);

  const handlePayment = async () => {
    if (!paymentMethod) {
      enqueueSnackbar("Please Select a payment method.", { variant: 'error' });
      return;
    }

    if (isProcessing) return; 
    setIsProcessing(true);

    let cartItems = [];
    if (fastDelivery.length > 0) {
      cartItems = fastDelivery.map((product) => ({
        product_id: product.product_id,
        quantity: product.quantity,
        sell_price: product.sell_price,
        weight: product.weight,
        delivery_option: "FastDelivery",
      }));
    } else if (stdDelivery.length > 0) {
      cartItems = stdDelivery.map((product) => ({
        product_id: product.product_id,
        quantity: product.quantity,
        sell_price: product.sell_price,
        weight: product.weight,
        delivery_option: "StandardDelivery",
      }));
    }

    const payload = {
      location: locationId,
      cartItems,
      address: `${selectedAddress.name}, ${selectedAddress.house}, ${selectedAddress.floor}, ${selectedAddress.area}, ${selectedAddress.landmark}, ${selectedAddress.pincode}, ${selectedAddress.phoneNumber}, ${selectedAddress.selectResident}`,
      phone_number: localStorage.getItem("userPhoneNumber"),
      delivery_notes: "Leave at the front door.",
      payment_method: paymentMethod,
    };
    

    try {
      if (paymentMethod === "cod") {
        const responseMessage = await dispatch(newOrder(payload));
        setOrderPlaced(true);
        updateCartItems(payload.cartItems);
        dispatch(emptyCart());
        enqueueSnackbar("Order placed successfully on cash on delivery.", { variant: 'success' });
      } else {
        const razorpayOrderId = await dispatch(newOrder(payload));
        if (razorpayOrderId) {
          openRazorpay(razorpayOrderId, payload);
        } else {
          enqueueSnackbar("Failed to create order.", { variant: 'error' });
        }
      }
    } catch (error) {
      console.error("Error creating order:", error);
      enqueueSnackbar("Payment failed. Please try again.", { variant: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  const openRazorpay = (orderId, payload) => {
    const options = {
      key: "rzp_test_Cd1cVSHpocrBwT",
      amount: payload.cartItems.reduce(
        (acc, item) => acc + item.sell_price * item.quantity,
        0
      ) * 100,
      currency: "INR",
      name: "Vivimart",
      description: "Payment for order",
      order_id: orderId,
      handler: async (response) => {
        try {
          await axiosInstance.post("/orders/verify-payment", {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          });
          updateCartItems(payload.cartItems);
          dispatch(emptyCart());
          enqueueSnackbar("Payment successfull. Order placed successfully.", { variant: 'success' });
          navigate("/");
          window.location.reload(); // Refresh the page after Razorpay payment
        } catch (error) {
          console.error("Payment verification failed:", error.response ? error.response.data : error.message);
          enqueueSnackbar("Payment verification failed. Please try again", { variant: 'error' });
        }
      },
      prefill: {
        name: selectedAddress.name,
        email: "customer@example.com",
        contact: localStorage.getItem("userPhoneNumber"),
      },
      notes: {
        address: payload.address,
      },
      theme: {
        color: "#F37254",
      },
      config: {
        display: {
          blocks: {},
          hide: [],
          sequence: [],
          preferences: {
            show_default_blocks: false,
          },
        },
      },
    };

    // Configure payment method based on the selected payment method
    if (paymentMethod === "upi") {
      console.log("Configuring UPI payment method with UPI ID:", upiId);
      options.config.display.blocks.upi = {
        name: "Pay with UPI",
        instruments: [
          {
            method: "upi",
            vpa: upiId, // User's UPI ID
          },
        ],
      };
      options.config.display.hide.push({ method: "card" }); // Hide card method
      options.config.display.sequence.push("block.upi"); // Ensure UPI is prioritized
    } else if (paymentMethod === "card") {
      console.log("Configuring Card payment method with card details:", cardDetails);
      options.config.display.blocks.card = {
        name: "Pay with Card",
        instruments: [
          {
            method: "card",
            card: {
              number: cardDetails.cardNumber,
              name: cardDetails.name,
              expiry: cardDetails.expiry,
              cvv: cardDetails.cvv,
            },
          },
        ],
      };
      options.config.display.hide.push({ method: "upi" }); // Hide UPI method
      options.config.display.sequence.push("block.card"); // Ensure Card is prioritized
    }

    console.log("Razorpay Options:", options);

    if (window.Razorpay) {
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } else {
      console.error("Razorpay script not loaded");
    }
  };

  useEffect(() => {
    // Step 3: Redirect to home after 2 seconds if order is placed
    if (orderPlaced) {
      const timeoutId = setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 2000);

      return () => clearTimeout(timeoutId); // Clear timeout on component unmount
    }
  }, [orderPlaced, navigate]);

  const updateCartItems = (orderedItems) => {
    const orderedProductIds = orderedItems.map((item) => item.product_id);
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const updatedCartItems = cartItems.filter((item) => !orderedProductIds.includes(item.product_id));

    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

    const remainingQuantity = updatedCartItems.reduce((acc, item) => acc + item.quantity, 0);
    localStorage.setItem("totalQuantity", remainingQuantity);
  };


  const handleCodSubmit = async () => {
    console.log("Placing order with Cash on Delivery...");
    if (isProcessing) return; 
    setIsProcessing(true);

    let cartItems = fastDelivery.length > 0 ? fastDelivery : stdDelivery;

    const payload = {
      location: locationId,
      cartItems: cartItems.map((product) => ({
        product_id: product.product_id,
        quantity: product.quantity,
        sell_price: product.sell_price,
        weight: product.weight,
      })),
      address: `${selectedAddress.name}, ${selectedAddress.house}, ${selectedAddress.floor}, ${selectedAddress.area}`,
      phone_number: localStorage.getItem("userPhoneNumber"),
      delivery_notes: "Leave at the front door.",
      payment_method: "COD",
    };

    try {
      const responseMessage = await dispatch(newOrder(payload));
      setOrderPlaced(true);
      updateCartItems(payload.cartItems);
      dispatch(emptyCart());
      enqueueSnackbar("Order placed successfully on cash on delivery.", { variant: 'success' });
    } catch (error) {
      console.error("Error placing order:", error);
      enqueueSnackbar("Failed to place order. Please try again.", { variant: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpiIdSubmit = (upiId) => {
    setPaymentMethod ("upi");
    setUpiId(upiId);
  };

  const handleCardSubmit = (cardDetails) => {
    setPaymentMethod("card");
    setCardDetails(cardDetails);
  };

  const calculateTotalQuantity = () => {
    const selectedDelivery =
      fastDelivery.length > 0 ? fastDelivery : stdDelivery;
    return selectedDelivery.reduce(
      (total, product) => total + product.quantity,
      0
    );
  };

  return (
    <div className="w-screen h-screen bg-white">
      <div className="w-full m-auto flex justify-between items-center relative">
        <div className="bg-primary-blue flex items-center flex-1 w-full">
          <div className="flex col-span-1 pb-1 pt-2">
            <Link className="h-16 mr-1 sm:mr-4 hidden lg:block pr-14" to="/">
              <img
                draggable="false"
                className="h-full w-full object-contain"
                src={logo}
                alt="Vivimart Logo"
              />
            </Link>
          </div>
          <div className="lg:hidden flex col-span-1 pb-1 pt-2">
            <div className="flex col-span-1 relative bottom-[0.2rem]">
              <Link className="h-16 mr-1 sm:mr-4" to="/">
                <img
                  draggable="false"
                  className="h-full w-full object-contain"
                  src={Logomb}
                  alt="Vivimart Logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-0 flex bg-white">
        <div className="text-[14px] ">
          <div className=" font-snas text-[#1c1c1c] text-[24px] font-semibold leading-[32px] pt-[4rem] lg:pl-[10rem] pl-[1rem] lg:w-[50rem] custom-text">
            Select Payment Method
          </div>
          <div>
            <AccordionPanel
              onUpiIdSubmit={handleUpiIdSubmit}
              onCardSubmit={handleCardSubmit}
              onCodSubmit={handleCodSubmit} // Pass the handleCodSubmit function
            />
          </div>
        </div>
        <div className="mt-16 rounded-lg border-gray-300 hidden lg:block">
          <div className="">
            <div className="p-4 bg-primary-blue text-[18px] rounded-t-lg">
              <span className="font-bold text-white">My Cart</span>
              <span
                className={`ml-4 font-semibold ${
                  fastDelivery.length > 0 ? "text-[#FFCE44]" : "text-[#FFCE44]"
                }`} // Conditional text color
              >
                {fastDelivery.length > 0
                  ? "( Fast Delivery )"
                  : "( Standard Delivery )"}
              </span>
            </div>
            <div className="p-4 mb-2">
              <span className="font-semibold font-sans text-black">
                Delivery Address
              </span>
              <div className="mt-1.5 text-sm text-[#999999] overflow-wrap-break">
                {Object.keys(selectedAddress).length !== 0 && (
                  <span>
                    {selectedAddress.name}, {selectedAddress.house},
                    {selectedAddress.floor}, {selectedAddress.area},{" "}
                    {selectedAddress.landmark}
                  </span>
                )}
              </div>
            </div>
            <div className="bg-[rgb(251, 251, 251)] h-[25rem] overflow-auto hidden-scrollbar">
              <div>
                <table className="w-full border-collapse border border-[#eee]">
                  <thead className="sticky top-0 bg-white z-10">
                    <tr>
                      <th className="p-4 text-left">Product</th>
                      <th className="p-4 w-72 text-left">Details</th>
                      <th className="p-4 text-center">
                        ({calculateTotalQuantity()})
                        <span className="ml-2">Items</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(fastDelivery.length > 0 ? fastDelivery : stdDelivery).map(
                      (product, index) => (
                        <tr
                          key={index}
                          className="border-t border-[#eee] hidden-scrollbar overflow-y-auto"
                        >
                          <td className="p-4">
                            <img
                              className="w-[84px] h-full object-cover"
                              src={product.product_img}
                              alt={product.product_name}
                            />
                          </td>
                          <td className="p-4">
                            <div className="overflow-hidden line-clamb-2">
                              {product.product_name}
                            </div>
                            <div className="text-[#999999]">
                              {product.weight}
                            </div>
                            <span className="block items-center">
                              ₹{" "}
                              {product.quantity > 0
                                ? product.sell_price * product.quantity
                                : product.sell_price}
                            </span>
                          </td>
                          <td className="p-4 text-center">
                            {product.quantity} item
                          </td>{" "}
                          {/* Centering the item count */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 lg:hidden pb-[60px]"></div>
    </div>
  );
};

export default Checkout;
