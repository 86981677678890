import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import notFound from "../../assets/images/NotFound.png";
import CloseIcon from "@mui/icons-material/Close";
import { getStoreIdByPostalCode } from "../../actions/locationAction";
import { BASE_URL } from "../../config";

const DetectLocation = ({ setLocation, searchLoc, setIsOpen }) => {
  const dispatch = useDispatch();
  const [detectLocation, setDetectLocation] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [searchLocation, setSearchLocation] = useState("");
  const [addresses, setAddresses] = useState([]);

  // Access the store location and store ID from Redux state
  const { storeLocation, loading, error } = useSelector((state) => state.storeLocation);

  console.log("selected location from DetectLocation:", searchLoc)

  useEffect(() => {
    const selectedAddress = localStorage.getItem("selectedAddress");
  }, [])  

  // Define fetchCurrentLocation as a separate function
  const fetchCurrentLocation = async (latitude, longitude) => {
    console.log("Fetching current location with lat:", latitude, "lng:", longitude);
    try {
      // Change 'latlng' to 'lat' and 'lng'
      const response = await fetch(`${BASE_URL}api/geocode?lat=${latitude}&lng=${longitude}`);
      const data = await response.json();
      if (response.ok) {
        console.log("Current location data:", data);
        setCurrentLocation(data);
      } else {
        console.error("Location not found");
        setCurrentLocation("Location not found");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      setDetectLocation(true);
    }
  };
  
  const handleDetectLocation = () => {
    console.log("Detect my location button clicked");
    setDetectLocation(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        console.log("Current position on button click:", position);
        const { latitude, longitude } = position.coords; // Extract latitude and longitude
        await fetchCurrentLocation(latitude, longitude); // Pass coordinates to the function
      },
      (error) => {
        console.error("Geolocation error:", error);
        setDetectLocation(false);
      }
    );
  };

  useEffect(() => {
    if (searchLocation) {
      handleSearch();
    }
  }, [searchLocation]);

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}api/autocomplete?input=${searchLocation}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const predictions = data.predictions;
      const promises = predictions.map(async (prediction) => {
        const placeId = prediction.place_id;
        const placeResponse = await fetch(
          `${BASE_URL}api/place-details?place_id=${placeId}`
        );
        if (!placeResponse.ok) {
          throw new Error(`HTTP error! Status: ${placeResponse.status}`);
        }
        const placeData = await placeResponse.json();
        const addressComponents = placeData.result.address_components;
        const postalCodeComponent = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );
        const postalCode = postalCodeComponent
          ? postalCodeComponent.long_name
          : null;
        return { ...placeData.result, postal_code: postalCode };
      });
      const addressResults = await Promise.all(promises);
      setAddresses(
        addressResults.filter((address) => address.postal_code !== null)
      );
    } catch (error) {
      console.error("Error searching location:", error);
      // Handle error state here, e.g., show error message to the user
    }
  };

  const handleSelectLocation = (location) => {
    setLocation(location); // Update the selected location in Header
    setSearchLocation(location.formatted_address); // Update searchLocation state
    localStorage.setItem("selectedAddress", location.formatted_address);
    localStorage.setItem("SelectedPostalCode", location.postal_code);

    // Dispatch action to get store ID by postal code
    dispatch(getStoreIdByPostalCode());

    localStorage.removeItem("cartItems");

    localStorage.removeItem("totalQuantity");

    setIsOpen(false); // Close the location selector
    window.location.reload(); // Optional: Reload the page
};

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
      <div
        className="w-full max-w-lg absolute top-24 rounded-lg left-28 hidden lg:block"
        style={{
          height:
            (detectLocation && typeof currentLocation !== "object") ||
            addresses.length !== 0
              ? "33rem"
              : "0rem",
          backgroundColor:
            detectLocation || addresses.length !== 0 ? "white" : "transparent",
        }}
      >
        <div
          className={`w-full bg-white ${
            searchLoc !== "" ? "h-25" : "h-48"
          } rounded-t-lg`}
        >
          <div className="p-3">
            {searchLoc === "" ? (
              <div>
                <div className="flex justify-between">
                  <h2 className="font-sans tracking-wider text-m font-normal leading-normal tracking-normal text-gray-500">
                    Welcome to vivimart
                  </h2>
                </div>
                <div className="flex items-center justify-center space-x-3 mt-2 mb-2">
                  <PlaceOutlinedIcon
                    style={{ fontSize: "2.5rem" }}
                    className="text-blue-700"
                  />
                  <p className="font-okra tracking-wide text-base font-normal leading-normal tracking-normal text-gray-700 ml-4 flex items-center">
                    Please provide your delivery location to see products at
                    nearby store
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex justify-between z-20 mb-20">
                <h2 className="font-sans text-base font-normal leading-normal tracking-normal text-gray-600 font-medium">
                  Change Location
                </h2>
                <button
                  className="text-xs text-gray-600"
                  onClick={() => setIsOpen(false)}
                >
                  <CloseIcon />
                </button>
              </div>
            )}

            <div
              className={`flex items-start flex absolute ${
                searchLoc !== "" ? "top-12" : "top-42"
              } left-16 mt-4 z-10`}
            >
              <button
                onClick={handleDetectLocation}
                className="flex w-64 justify-center font-inherit items-center p-2 h-11 bg-primary-blue text-m text-white rounded-lg"
              >
                Detect my location
              </button>
              <div className="flex items-center w-full">
                <input
                  style={{ fontFamily: "'Okra', Helvetica" }}
                  className="w-52 p-2 ml-6 h-11 rounded-lg bg-gray-100 border border-blue-500 text-gray-700 
              text-base cursor-default outline-none overflow-hidden relative box-border text-base leading-5 font-normal"
                  placeholder="Search delivery location"
                  value={searchLocation}
                  onChange={(e) => setSearchLocation(e.target.value)}
                />
              </div>
            </div>
          </div>
          {detectLocation &&
            currentLocation === null &&
            addresses.length === 0 && (
              <div className="text-xs text-red-600 text-center mt-12">
                We do not have permission to determine your location. Please
                enter manually.
              </div>
            )}
        </div>

        {detectLocation && currentLocation && addresses.length === 0 && (
          <div
            key="current-location"
            className="p-2 relative text-left bg-white border rounded-b-lg cursor-pointer flex"
            onClick={() => handleSelectLocation(currentLocation)}
          >
            <div className="flex ml-2 items-center justify-center text-center">
              <PlaceOutlinedIcon />
            </div>
            <div className="mt-1 ml-4">
              {/* Display the formatted address of the current location */}
              <div className="font-semibold text-gray-800">Current Location</div>
              <div className="text-[12px] mt-1">
                {currentLocation.formatted_address || "No Address"}
              </div>
            </div>
          </div>
        )}
        {/* Scrollable Container for Addresses */}
        <div
          className="overflow-y-auto scroll-smooth scrollbar-hide z-40 mx-6"
          style={{
            height: "20rem", // Adjust the height as needed
          }}
        >
          {addresses.map((address, index) => {
            // Extracting the city name from address components
            const cityComponent = address.address_components.find((component) =>
              component.types.includes("locality")
            );
            const cityName = cityComponent
              ? cityComponent.long_name
              : "City not specified";

            return (
              <div
                key={index}
                className="p-2 relative text-left bg-white border rounded-lg cursor-pointer flex"
                onClick={() => handleSelectLocation(address)}
              >
                <div className="flex ml-2 items-center justify-center text-center">
                  <PlaceOutlinedIcon />
                </div>
                <div className="mt-1 ml-4">
                  {/* Display the city name above the address */}
                  <div className="font-semibold text-gray-800">{cityName}</div>
                  <div className="text-[12px] mt-1">
                    {typeof address.formatted_address === "string"
                      ? address.formatted_address
                      : "No Address"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="lg:hidden max-w-lg absolute top-44 font-sans pr-4"
        style={{ left: "2rem" }}
      >
        <div
          style={{
            height: "10rem",
            backgroundColor: "white",
          }}
          className="p-2 rounded-lg"
        >
          <div className="flex items-center justify-center">
            <h2 className="font-sans text-base font-bold ">
              Select your Location
            </h2>
            <button
              className="text-xs text-gray-600 absolute"
              style={{ left: "21rem" }}
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex items-center space-x-3 ">
            <p className="mt-4 font-okra text-base font-normal leading-normal tracking-normal text-gray-700 text-center">
              We need your location to show you assortment from your nearest
              store
            </p>
          </div>
          <div
            className={`flex items-center flex space-x-3 absolute ${
              searchLoc !== "" ? "top-16" : "top-28 "
            } left-20`}
          >
            <button
              onClick={handleDetectLocation}
              className="flex w-48 justify-center font-inherit items-center p-2 h-9 bg-primary-blue text-xs text-white"
            >
              Detect my location
            </button>
            <div className="h-9 flex justify-center items-center">
              <div className="w-7 h-7 border border-gray-300 bg-gray-100 rounded-full flex justify-center items-center mx-4">
                <span className="relative inline-block">
                  <div className="w-4 h-3 font-okra text-xs font-medium text-gray-400 text-center">
                    OR
                  </div>
                </span>
              </div>
            </div>
            <div className="flex items-center w-full">
              <input
                style={{ fontFamily: "'Okra', Helvetica" }}
                className="w-52 p-2 h-10 rounded-lg bg-white border border-gray-300 text-gray-700 
          text-base cursor-default outline-none overflow-hidden relative box-border text-base leading-5 font-normal"
                placeholder="Search delivery location"
                value={searchLocation}
                onChange={(e) => setSearchLocation(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetectLocation;
