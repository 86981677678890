import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getAdminDetails } from "../../actions/adminAction";
import invoiceLogo from '../../assets/invoice-logo.jpg';

const AdminInVoice = () => {
  const dispatch = useDispatch();
  const printContentRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { order } = location.state || {};
  const { user } = useSelector((state) => state.user);
  const { admin } = useSelector((state) => state.admin)
  console.log("admin details:", admin);

  useEffect(() => {
    dispatch(getAdminDetails());
  }, [dispatch]);

  // Function to calculate the total price of selected items
  const calculateTotalPrice = () => {
    return order.order_items.reduce((total, item) => {
      return total + (item.sell_price * item.quantity);
    }, 0);
  };

  const handlePrint = () => {
    const printContent = printContentRef.current;
    if (printContent) {
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.width = "0px";
      iframe.style.height = "0px";
      iframe.style.border = "none";

      document.body.appendChild(iframe);

      const contentClone = printContent.cloneNode(true);
      iframe.contentWindow.document.body.appendChild(contentClone);

      const stylesheets = document.querySelectorAll(
        'link[rel="stylesheet"], style'
      );
      stylesheets.forEach((stylesheet) => {
        iframe.contentWindow.document.head.appendChild(
          stylesheet.cloneNode(true)
        );
      });

      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000); // Remove the iframe after printing (adjust delay as needed)
    } else {
      console.error("Element with ref 'printContentRef' not found.");
    }
  };

  const goToHome = () => {
    navigate("/admin"); // Adjust the route as per your application's home page route
  };

  if (!order) {
    return <div>No order selected for printing.</div>;
  }

  const totalPrice = calculateTotalPrice(); // Calculate total price

  return (
    <div className="bg-[#f2fafc] m-0 p-0 text-size-adjust-none">
      <div className="flex flex-row justify-center items-center my-5">
        <button
          onClick={handlePrint}
          className="px-4 py-2 rounded-md cursor-pointer border-2 border-black text-sm"
        >
          Print Invoice
        </button>
        <button
          onClick={goToHome}
          className="px-4 py-2 rounded-md cursor-pointer border-2 border-black text-sm ml-2"
        >
          Go to Home
        </button>
      </div>
      <div className="border-[#787771] border-4"></div>
      <div className="my-4" ref={printContentRef}>
        <div className="flex flex-col items-center gap-6 max-w-full mx-auto">
          <div className="font-serif text-sm leading-5 text-gray-700">
            <p className="text-center text-lg md:text-xl lg:text-2xl">
              <img
                src={admin.invoice_logo}
                alt={admin.company_name}
                style={{ width: '150px', height: 'auto' }} // Adjust width and height as needed
              />
            </p>
          </div>
          <div className="text-sm text-black-700 leading-5 font-sans">
            <p className="text-center font-normal max-w-[40rem]">
              GVS FOOD AND RETAIL PRIVATE LIMITED
            </p>
          </div>
          <div className="text-sm text-black-700 leading-5 font-sans">
            <p className="text-center font-light max-w-[40rem]">
              GSTIN : {admin.gst_number}
            </p>
          </div>
          <div className="bg-white text-sm font-sans flex flex-col gap-2 w-full md:max-w-[40rem] p-2 rounded-md">
            <div className="flex justify-between">
              <div className="w-1/2 pr-2">
                <div className="flex">
                  <span>Store ID: {order.store_id}</span>
                </div>
                <div className="flex">
                  <span>Invoice Date: {new Date(order.order_date).toLocaleString()}</span>
                </div>
              </div>
              <div className="relative mx-2">
                <div className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 w-0.5 border-l-2 border-dotted border-gray-300" style={{ borderImage: 'linear-gradient(transparent, transparent), linear-gradient(transparent, transparent), linear-gradient(gray, gray)', borderImageSlice: '1', borderImageWidth: '1 0 0 0', borderImageOutset: '0 0 0 0', borderImageRepeat: 'stretch' }}></div>
              </div>
              <div className="w-1/2 pl-2 text-left">
                <div className="flex justify-start">
                  <span>Order ID: {order.order_id}</span>
                </div>
                <div className="flex justify-start">
                  <span>Order Date: {new Date(order.order_date).toLocaleString()}</span>
                </div>
                <div className="flex justify-start">
                  <span>Delivery Address: {order.address}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="md:max-w-[40rem] w-full overflow-x-auto">
            <table className="w-full md:max-w-[70rem] border-collapse">
              <thead className="bg-white">
                <tr className="border-b border-gray-300">
                  <th className="p-4 text-sm text-left">Item</th>
                  <th className="p-4 text-sm text-left">Quantity</th>
                  <th className="p-4 text-sm text-left">Total</th>
                </tr>
              </thead>
              <tbody>
                {order.order_items && order.order_items.map((item) => (
                  <tr key={item.product_id} className="border-b border-gray-300">
                    <td className="p-4 text-sm text-left">{item.Product_name}</td>
                    <td className="p-4 text-sm text-left">X {item.quantity}</td>
                    <td className="p-4 text-sm text-left">Rs. {(item.sell_price * item.quantity).toFixed(2)}</td>
                  </tr>
                ))}
                <tr className="font-sans border-b border-gray-300">
                  <td className="text-md font-semibold p-4">Subtotal</td>
                  <td></td>
                  <td className="text-left">Rs. {totalPrice.toFixed(2)}</td> {/* Display calculated total price */}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-center text-sm text-gray-500 my-4">
            <p>Thank you for your business!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminInVoice;
