export const SPLIT_ORDER_REQUEST = 'SPLIT_ORDER_REQUEST';
export const SPLIT_ORDER_SUCCESS = 'SPLIT_ORDER_SUCCESS';
export const SPLIT_ORDER_FAIL = 'SPLIT_ORDER_FAIL';

export const GET_SHIPMENTS_BY_ORDER_ID_REQUEST = 'GET_SHIPMENTS_BY_ORDER_ID_REQUEST';
export const GET_SHIPMENTS_BY_ORDER_ID_SUCCESS = 'GET_SHIPMENTS_BY_ORDER_ID_SUCCESS';
export const GET_SHIPMENTS_BY_ORDER_ID_FAIL = 'GET_SHIPMENTS_BY_ORDER_ID_FAIL';

export const ASSIGN_DELIVERY_PERSON_REQUEST = 'ASSIGN_DELIVERY_PERSON_REQUEST';
export const ASSIGN_DELIVERY_PERSON_SUCCESS = 'ASSIGN_DELIVERY_PERSON_SUCCESS';
export const ASSIGN_DELIVERY_PERSON_FAIL = 'ASSIGN_DELIVERY_PERSON_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
