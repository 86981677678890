export const SUB_STORE_REQUEST = "SUB_STORE_REQUEST";
export const SUB_STORE_SUCCESS = "SUB_STORE_SUCCESS";
export const SUB_STORE_FAIL = "SUB_STORE_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SUB_STORE_CREATE_REQUEST = "SUB_STORE_CREATE_REQUEST";
export const SUB_STORE_CREATE_SUCCESS = "SUB_STORE_CREATE_SUCCESS";
export const SUB_STORE_CREATE_FAIL = "SUB_STORE_CREATE_FAIL";

export const SUB_STORE_UPDATE_REQUEST = "SUB_STORE_UPDATE_REQUEST";
export const SUB_STORE_UPDATE_SUCCESS = "SUB_STORE_UPDATE_SUCCESS";
export const SUB_STORE_UPDATE_FAIL = "SUB_STORE_UPDATE_FAIL";

export const SUB_STORE_DELETE_REQUEST = "SUB_STORE_DELETE_REQUEST";
export const SUB_STORE_DELETE_SUCCESS = "SUB_STORE_DELETE_SUCCESS";
export const SUB_STORE_DELETE_FAIL = "SUB_STORE_DELETE_FAIL";

export const SUB_STORE_TOGGLE_REQUEST = "SUB_STORE_TOGGLE_REQUEST";
export const SUB_STORE_TOGGLE_SUCCESS = "SUB_STORE_TOGGLE_SUCCESS";
export const SUB_STORE_TOGGLE_FAIL = "SUB_STORE_TOGGLE_FAIL";

export const SUB_STORE_ALL_REQUEST = "SUB_STORE_ALL_REQUEST";
export const SUB_STORE_ALL_SUCCESS = "SUB_STORE_ALL_SUCCESS";
export const SUB_STORE_ALL_FAIL = "SUB_STORE_ALL_FAIL";

export const SUB_STORE_ENABLE_REQUEST = "SUB_STORE_ENABLE_REQUEST";
export const SUB_STORE_ENABLE_SUCCESS = "SUB_STORE_ENABLE_SUCCESS";
export const SUB_STORE_ENABLE_FAIL = "SUB_STORE_ENABLE_FAIL";

export const SUB_STORE_DISABLE_REQUEST = "SUB_STORE_DISABLE_REQUEST";
export const SUB_STORE_DISABLE_SUCCESS = "SUB_STORE_DISABLE_SUCCESS";
export const SUB_STORE_DISABLE_FAIL = "SUB_STORE_DISABLE_FAIL";

export const SUB_STORE_BY_ID_REQUEST = 'SUB_STORE_BY_ID_REQUEST';
export const SUB_STORE_BY_ID_SUCCESS = 'SUB_STORE_BY_ID_SUCCESS';
export const SUB_STORE_BY_ID_FAIL = 'SUB_STORE_BY_ID_FAIL';