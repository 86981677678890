import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { storeLogin } from "../../actions/userAction";
import { useSnackbar } from "notistack";
import MetaData from "../Layouts/MetaData";
import Logo_business from "../../assets/images/Logo_business.png";

const Login = ({ setIsLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loginError, setLoginError] = useState(null)

  const [id, setId] = useState("");
  const [password, setPassword] = useState("");

  const { isAuthenticated, error } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/admin");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      setLoginError(error);
    }
  }, [error, enqueueSnackbar]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError(null);

    // Check if the entered ID is "ADM001"
    if (id !== "ADM001") {
      enqueueSnackbar("Invalid Admin ID.", { variant: "error" });
      return; // Prevent further execution
    }

    try {
      await dispatch(storeLogin(id, password));
    } catch (err) {
      enqueueSnackbar("An unexpected error occurred. Please try again.", { variant: "error" });
    }
  };
    

  return (
    <>
      <MetaData title="Login | Vivimart" />

      <main className="w-full mt-12 sm:pt-20 sm:mt-0  hidden lg:block">
        <div className="flex w-[46rem] sm:mt-4 m-auto mb-7 bg-white shadow-lg rounded-[1rem]">
          <div className="rounded-tl-[1rem] rounded-bl-[1rem] loginSidebar bg-primary-blue py-10 px-4 hidden sm:flex flex-col gap-4 w-[17rem]">
            <h1 className="font-medium text-white text-3xl">Sign In !</h1>
            <p className="text-gray-200 text-lg">
            Welcome Back Admin...!
            </p>
          </div>
          <div className="flex-1 overflow-hidden ">
            <div className="text-center py-10 px-4 sm:px-6 w-[28rem]">
              <form onSubmit={handleLogin}>
                <div className="flex flex-col w-full gap-4 h-[17rem]">
                  <TextField
                    fullWidth
                    id="id"
                    label="Admin Id"
                    type="id"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    id="password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div className="flex flex-col gap-2.5 mt-2 mb-32">
                    <button
                      type="submit"
                      className="text-white py-3 w-full bg-primary-blue shadow hover:shadow-lg rounded-sm font-medium"
                    >
                      Login
                    </button>
                    <Link
                      to="/password/forgot"
                      className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </form>
              <p className="text-xs text-primary-grey text-center">
                By continuing, you agree to Vivimart's{" "}
                <a href="/terms" className="text-primary-blue">
                  Terms of Use
                </a>{" "}
                and{" "}
                <a href="/privacy" className="text-primary-blue">
                  Privacy Policy.
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
      <div className="lg:hidden w-full h-full bg-white h-[52.7rem]">
        <div className="bg-primary-blue h-[33rem] rounded-b-[1rem] flex flex-col items-center justify-center">
          <div className="mt-40 h-52 w-28">
            <img
              draggable="false"
              className="h-[6rem] w-[6rem] object-contain"
              src={Logo_business}
              alt="Vivimart Logo"
            />
          </div>
          <div className="flex flex-col font-bold items-center justify-center w-[22rem] h-[15rem] mt-[4rem] bg-white p-4 rounded-2xl">
            <form onSubmit={handleLogin} className="w-[19rem] self-start mt-0 ">
              <div className="font-sans mb-2">
                <span className="text-xl ">Sign In !</span>
                <p className="text-sm mt-2">
                Welcome Back Admin!
                </p>
              </div>
              <div className="flex flex-col gap-4 h-[7rem]">
                <TextField
                  fullWidth
                  id="id"
                  label="Admin Id"
                  type="id"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  required
                />
                <TextField
                  fullWidth
                  id="password"
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="flex flex-col gap-4 mt-2 mb-32">
                  <button
                    type="submit"
                    className="text-white py-3 w-full bg-primary-blue shadow hover:shadow-lg rounded-2xl font-medium"
                  >
                    Login
                  </button>
                  <Link
                    to="/password/forgot"
                    className="text-center py-3 w-full text-md font-sans"
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </form>
            <div className="relative top-[11rem]">
              <p className="text-xs text-primary-grey text-center">
                By continuing, you agree to Vivimart's{" "}
                <a href="/terms" className="text-primary-blue">
                  Terms of Use
                </a>{" "}
                and{" "}
                <a href="/privacy" className="text-primary-blue">
                  Privacy Policy.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
