import { useState } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const Actions = ({ id, deleteHandler, editRoute, isActive, toggleActiveHandler, onEditClick, onDeleteSuccess }) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteHandler(id); // Call the delete handler
        handleClose(); // Close the dialog immediately
        onDeleteSuccess(); // Call the success callback
    };

    return (
        <>
            <div className="flex justify-between items-center gap-3">
                {/* Toggle Button */}
                <label className="inline-flex items-center cursor-pointer">
                    <input 
                        type="checkbox" 
                        className="sr-only peer" 
                        checked={isActive} 
                        onChange={() => toggleActiveHandler(id, !isActive)} // Call the toggle handler
                    />
                    <div className={`relative w-11 h-6 rounded-full transition-colors duration-200 ${isActive ? 'bg-green-500' : 'bg-gray-200'}`}>
                        <div className={`absolute top-0.5 left-0.5 w-5 h-5 rounded-full transition-transform duration-200 ${isActive ? 'translate-x-full bg-white' : 'bg-gray-300'}`}></div>
                    </div>
                </label>
                {editRoute !== "review" && (
                    <button onClick={onEditClick} className="text-blue-600 hover:bg-blue-200 p-1 rounded-full bg-blue-100">
                        <EditIcon />
                    </button>
                )}
                <button onClick={() => setOpen(true)} className="text-red-600 hover:bg-red-200 p-1 rounded-full bg-red-100">
                    <DeleteIcon />
                </button>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <p className="text-gray-500">Do you really want to delete this sub store? This process cannot be undone.</p>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} className="py-2 px-6 rounded shadow bg-gray-400 hover:bg-gray-500 text-white">Cancel</button>
                    <button onClick={handleDelete} className="py-2 px-6 ml-4 rounded bg-red-600 hover:bg-red-700 text-white shadow">Delete</button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Actions;