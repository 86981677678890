// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar::-webkit-scrollbar {
    width: 5px;
}
   
.sidebar::-webkit-scrollbar-track {
    background-color: #1f2937;
}
   
.sidebar::-webkit-scrollbar-thumb {
    background-color: #4b5563;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".sidebar::-webkit-scrollbar {\n    width: 5px;\n}\n   \n.sidebar::-webkit-scrollbar-track {\n    background-color: #1f2937;\n}\n   \n.sidebar::-webkit-scrollbar-thumb {\n    background-color: #4b5563;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
