export const GET_DELIVERY_PERSONS_REQUEST = "GET_DELIVERY_PERSONS_REQUEST";
export const GET_DELIVERY_PERSONS_SUCCESS = "GET_DELIVERY_PERSONS_SUCCESS";
export const GET_DELIVERY_PERSONS_FAIL = "GET_DELIVERY_PERSONS_FAIL";

export const ADD_DELIVERY_PERSON_REQUEST = "ADD_DELIVERY_PERSON_REQUEST";
export const ADD_DELIVERY_PERSON_SUCCESS = "ADD_DELIVERY_PERSON_SUCCESS";
export const ADD_DELIVERY_PERSON_FAIL = "ADD_DELIVERY_PERSON_FAIL";

export const UPDATE_DELIVERY_PERSON_REQUEST = "UPDATE_DELIVERY_PERSON_REQUEST";
export const UPDATE_DELIVERY_PERSON_SUCCESS = "UPDATE_DELIVERY_PERSON_SUCCESS";
export const UPDATE_DELIVERY_PERSON_FAIL = "UPDATE_DELIVERY_PERSON_FAIL";

export const DELETE_DELIVERY_PERSON_REQUEST = "DELETE_DELIVERY_PERSON_REQUEST";
export const DELETE_DELIVERY_PERSON_SUCCESS = "DELETE_DELIVERY_PERSON_SUCCESS";
export const DELETE_DELIVERY_PERSON_FAIL = "DELETE_DELIVERY_PERSON_FAIL";

export const ENABLE_DELIVERY_PERSON_REQUEST = "ENABLE_DELIVERY_PERSON_REQUEST";
export const ENABLE_DELIVERY_PERSON_SUCCESS = "ENABLE_DELIVERY_PERSON_SUCCESS";
export const ENABLE_DELIVERY_PERSON_FAIL = "ENABLE_DELIVERY_PERSON_FAIL";

export const DISABLE_DELIVERY_PERSON_REQUEST = "DISABLE_DELIVERY_PERSON_REQUEST";
export const DISABLE_DELIVERY_PERSON_SUCCESS = "DISABLE_DELIVERY_PERSON_SUCCESS";
export const DISABLE_DELIVERY_PERSON_FAIL = "DISABLE_DELIVERY_PERSON_FAIL";

export const CREATE_DELIVERY_ORDER_REQUEST = "CREATE_DELIVERY_ORDER_REQUEST";
export const CREATE_DELIVERY_ORDER_SUCCESS = "CREATE_DELIVERY_ORDER_SUCCESS";
export const CREATE_DELIVERY_ORDER_FAIL = "CREATE_DELIVERY_ORDER_FAIL";

export const GET_DELIVERY_PERSONS_BY_STORE_ID_REQUEST = "GET_DELIVERY_PERSONS_BY_STORE_ID_REQUEST";
export const GET_DELIVERY_PERSONS_BY_STORE_ID_SUCCESS = "GET_DELIVERY_PERSONS_BY_STORE_ID_SUCCESS";
export const GET_DELIVERY_PERSONS_BY_STORE_ID_FAIL = "GET_DELIVERY_PERSONS_BY_STORE_ID_FAIL";


export const CLEAR_ERRORS = "CLEAR_ERRORS";