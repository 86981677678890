import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import HomeIcon from "@mui/icons-material/Home";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import BusinessIcon from "@mui/icons-material/Business";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import SearchIcon from "@mui/icons-material/Search";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import pin from "../../assets/images/location.png";

const DeliveryEdit = ({
  setIsOpen,
  isEdit,
  editingIndex,
  selectedAddress,
  onAddressSaved,
}) => {
  const [floor, setFloor] = useState("");
  const [area, setArea] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectResident, setSelectResident] = useState("Home");
  const [house, setHouse] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [center, setCenter] = useState({ lat: 13.0827, lng: 80.2707 });
  const [map, setMap] = useState(null);
  const [isInputFieldsVisible, setIsInputFieldsVisible] = useState(false);
  const [isApiLoaded, setIsApiLoaded] = useState(false);

  const handleApiLoaded = () => {
    setIsApiLoaded(true);
  };

  const handleMobileMapClick = (event) => {

    if (!isApiLoaded) {
      console.error("Google Maps API is not loaded.");
      return;
    }

    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // Reverse geocode the location to get the address
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK") {
        const address = results[0];
        const addressComponents = address.address_components;

        // Parse the address components
        const house = addressComponents.find((component) =>
          component.types.includes("street_number")
        );
        const floor = addressComponents.find((component) =>
          component.types.includes("floor")
        );
        const area = addressComponents.find(
          (component) =>
            component.types.includes("locality") ||
            component.types.includes("sublocality")
        );
        const landmark = addressComponents.find((component) =>
          component.types.includes("point_of_interest")
        );
        const pincode = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );
        const name = "";
        const phoneNumber = "";

        // Update the state variables
        setHouse(house ? house.long_name : "");
        setFloor(floor ? floor.long_name : "");
        setArea(area ? area.long_name : "");
        setLandmark(landmark ? landmark.long_name : "");
        setPincode(pincode ? pincode.long_name : "");
        setName(name);
        setPhoneNumber(phoneNumber);
        setSelectedLocation({ address: address.formatted_address, lat, lng });
      } else {
        console.error("Error geocoding location:", status);
      }
    });
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    updateLocation(lat, lng);
  };

  const updateLocation = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK") {
        const address = results[0];
        const addressComponents = address.address_components;

        const house = addressComponents.find((component) =>
          component.types.includes("street_number")
        );
        const area = addressComponents.find(
          (component) =>
            component.types.includes("locality") ||
            component.types.includes("sublocality")
        );
        const landmark = addressComponents.find((component) =>
          component.types.includes("point_of_interest")
        );
        const pincode = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );

        setHouse(house ? house.long_name : "");
        setArea(area ? area.long_name : "");
        setLandmark(landmark ? landmark.long_name : "");
        setPincode(pincode ? pincode.long_name : "");
        setSelectedLocation({ address: address.formatted_address, lat, lng });
        setCenter({ lat, lng }); // Update the center state
      } else {
        console.error("Error geocoding location:", status);
      }
    });
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        updateLocation(lat, lng); // Update location with current position
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleMapDragEnd = () => {
    if (map) {
      const newCenter = map.getCenter();
      const lat = newCenter.lat();
      const lng = newCenter.lng();
      updateLocation(lat, lng); // Update location when the map is dragged
    }
  };

  const handleMapsClick = (event) => {
    // Get the latitude and longitude of the clicked location
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // Reverse geocode the location to get the address
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK") {
        const address = results[0];
        const addressComponents = address.address_components;

        // Parse the address components
        const house = addressComponents.find((component) =>
          component.types.includes("street_number")
        );
        const floor = addressComponents.find((component) =>
          component.types.includes("floor")
        );
        const area = addressComponents.find(
          (component) =>
            component.types.includes("locality") ||
            component.types.includes("sublocality")
        );
        const landmark = addressComponents.find((component) =>
          component.types.includes("point_of_interest")
        );
        const name = "";
        const phoneNumber = "";

        // Update the state variables
        setHouse(house ? house.long_name : "");
        setFloor(floor ? floor.long_name : "");
        setArea(area ? area.long_name : "");
        setLandmark(landmark ? landmark.long_name : "");
        setName(name);
        setPhoneNumber(phoneNumber);
        setSelectedLocation({ address: address.formatted_address, lat, lng });
      } else {
        console.error("Error geocoding location:", status);
      }
    });
  };

  
  const handleConform = () => {
    setIsInputFieldsVisible(true);
  };

  // Add a new function to handle the search query
  const handleSearch = () => {
    if (searchQuery) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: searchQuery }, (results, status) => {
        if (status === "OK") {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          setCenter({ lat, lng });
          setSelectedLocation({ address: searchQuery, lat, lng });
        } else {
          console.error("Error geocoding location:", status);
        }
      });
    }
  };

  // Call the handleSearch function when the search query changes
  useEffect(() => {
    handleSearch();
  }, [searchQuery]);

  useEffect(() => {
    const storedPostalCode = localStorage.getItem("SelectedPostalCode");
    console.log("selected postal code:", storedPostalCode);
    setPincode(storedPostalCode); // Set the pincode to the selected postal code
  }, []);

  useEffect(() => {
    const storedAddresses = localStorage.getItem("addressDetails");
    if (storedAddresses) {
      try {
        const addressesArray = JSON.parse(storedAddresses);
        if (Array.isArray(addressesArray)) {
          setAddressList(addressesArray);
        }
      } catch (error) {
        console.error("Error parsing stored addresses:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (isEdit && editingIndex !== null) {
      // Use the selectedAddress prop instead of retrieving from local storage
      setHouse(selectedAddress.house || "");
      setFloor(selectedAddress.floor || "");
      setArea(selectedAddress.area || "");
      setLandmark(selectedAddress.landmark || "");
      setPincode(selectedAddress.pincode || "");
      setName(selectedAddress.name || "");
      setPhoneNumber(selectedAddress.phoneNumber || "");
      setSelectResident(selectedAddress.selectResident || "Home");
    } else {
      // Clear fields if adding a new address
      setHouse("");
      setFloor("");
      setArea("");
      setLandmark("");
      setPincode("");
      setName("");
      setPhoneNumber("");
      setSelectResident("Home");
    }
  }, [isEdit, editingIndex, selectedAddress]);

  const addressDetails = {
    house,
    floor,
    area,
    landmark,
    pincode,
    name,
    phoneNumber,
    selectResident,
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSaveAddress = () => {
    // Basic validation
    if (!house || !area || !name || !pincode) {
      alert("Please fill in the required fields.");
      return;
    }

    // Create the address details object
    const addressDetails = {
      house,
      floor,
      area,
      landmark,
      pincode,
      name,
      phoneNumber,
      selectResident,
    };

    // Retrieve and parse the stored addresses or initialize as an empty array
    let storedAddresses;
    try {
      storedAddresses =
        JSON.parse(localStorage.getItem("addressDetails")) || [];

      // Ensure storedAddresses is an array
      if (!Array.isArray(storedAddresses)) {
        console.error(
          "Stored addresses data is not an array. Resetting to empty array."
        );
        storedAddresses = []; // Reset to empty array
      }
    } catch (error) {
      console.error("Error parsing stored addresses:", error);
      storedAddresses = []; // Reset to empty array
    }

    // Update or add address
    if (isEdit && editingIndex >= 0) {
      storedAddresses[editingIndex] = addressDetails; // Update existing address
    } else {
      storedAddresses.push(addressDetails); // Add new address
    }

    // Save updated addresses back to local storage
    localStorage.setItem("addressDetails", JSON.stringify(storedAddresses));

    // Call the function to refresh address details in Cart
    onAddressSaved(); // Call the passed function here

    setIsOpen(false);
  };

  // google map pointer style
  const [iconLoaded, setIconLoaded] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);

  useEffect(() => {
    // Retrieve the selected address from localStorage
    const selectedAddress = localStorage.getItem("selectedAddress");
    if (selectedAddress) {
      // Geocode the address to get latitude and longitude
      const geocodeAddress = async (address) => {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=AIzaSyCYTF8ZL0Rp-JvuBmRsiu7SNdd6i43Fb1g`
        );
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const location = data.results[0].geometry.location;
          setCenter({ lat: location.lat, lng: location.lng });
          setSelectedLocation({
            address: data.results[0].formatted_address,
            lat: location.lat,
            lng: location.lng,
          });
        }
      };
      geocodeAddress(selectedAddress);
    }
  }, []);

  

  const defaultLocation = { lat: 13.0827, lng: 80.2707 };

  return (
    <>
      <div className="lg:w-[55rem] h-screen w-screen rounded-lg lg:h-full bg-white flex flex-row hidden lg:flex">
        <div className="flex-1 relative m-2">
          <div className="absolute top-[7px] left-[49%] transform -translate-x-1/2 w-[calc(100%-24px)] h-[54px] z-[9999]">
            <div className="h-full rounded-[12px] bg-white border border-[rgb(207,207,207)] shadow-[2px_2px_12px_0_rgba(0,0,0,0.06)]">
              <div className="flex items-center h-full ">
                <div className="p-2">
                  <SearchIcon className="text-green-800" />
                </div>
                <div className="text-sm font-sans w-[21rem]">
                  <input
                    className="flex-grow flex-shrink w-full focus:outline-none"
                    type="text"
                    placeholder="Search Location"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                </div>
                <div className="cursor-pointer">
                  <CloseIcon
                    style={{ fontSize: "12px" }}
                    onClick={() => setSearchQuery("")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="h-full rounded-lg relative">
            <div className="h-full w-full absolute top-0 left-0 bg-[#e5e3df]">
              <div style={{ width: "100%", height: "100%" }}>
                <LoadScript googleMapsApiKey="AIzaSyCYTF8ZL0Rp-JvuBmRsiu7SNdd6i43Fb1g" onLoad={handleApiLoaded}>
                  <GoogleMap
                    id="map"
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    center={center}
                    zoom={18}
                    onClick={handleMapClick}
                    onLoad={map => setMap(map)}
                    onDragEnd={handleMapDragEnd}
                    options={{
                      disableDefaultUI: true,
                      zoomControl: false,
                      scrollwheel: false,
                      disableDoubleClickZoom: true,
                      styles: [
                        {
                          featureType: "water",
                          elementType: "geometry",
                          stylers: [{ color: "#e9e9e9" }],
                        },
                        {
                          featureType: "landscape",
                          elementType: "geometry",
                          stylers: [{ color: "#DADBDD" }],
                        },
                        {
                          featureType: "road",
                          elementType: "geometry",
                          stylers: [{ color: "#ffffff" }],
                        },
                        {
                          featureType: "poi",
                          elementType: "geometry",
                          stylers: [{ color: "#c0c0c0" }],
                        },
                        {
                          featureType: "administrative",
                          elementType: "geometry",
                          stylers: [{ color: "#a0a0a0" }],
                        },
                      ],
                    }}
                  >
                    {selectedLocation && (
                      <Marker
                        position={{
                          lat: selectedLocation.lat,
                          lng: selectedLocation.lng,
                        }}
                        icon={{
                          url: require("../../assets/images/Location Pin.gif"), // Adjust the path as necessary
                          scaledSize: new window.google.maps.Size(50, 70), // Adjust the size as necessary
                        }}
                      />
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </div>
          <div className="absolute flex flex-col gap-3 p-3 pb-6 rounded-t-lg bg-white shadow-md z-[9999] bottom-0 left-0 right-0 border border-gray-100">
            <div className="absolute flex items-center p-[8px_10px] gap-2 rounded-lg border-[0.654px] border-[#318616] bg-white shadow-md text-[12px] font-medium text-[#0c831f] cursor-pointer z-[9999] bottom-[246px] h-[30px] box-border top-[-38px]">
              <span>
                <MyLocationIcon style={{ fontSize: "1rem" }} />
              </span>
              <span onClick={handleGetCurrentLocation}>
                Go to current location
              </span>
            </div>
            <div className="font-sans font-bold">Delivering your order to</div>
            <div className="flex items-center p-[10px_12px_10px_8px] gap-2 rounded-lg border-[0.5px] border-[#e8e8e8] bg-[#f6fcfc]">
              <span>
                <PinDropIcon />
              </span>
              <span className="text-sm font-sans">Location</span>
            </div>
          </div>
        </div>
        <div className="flex-1 relative flex items-center hidden m-2 lg:block">
          <div className="flex flex-col w-full h-full">
            <div className="p-[14px_12px] text-[rgb(28,28,28)] text-[17px] font-extrabold flex gap-[10px] items-center justify-between border-b border-[rgb(232,232,232)] sticky bg-white top-0 z-10">
              <span>Enter complete address</span>
              <span onClick={() => handleClose()} className="cursor-pointer">
                <CloseIcon />
              </span>
            </div>
            <div className="px-4 py-6 flex flex-col gap-3">
              <TextField
                value={house}
                onChange={(e) => setHouse(e.target.value)}
                label="House No. / Building"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={floor}
                onChange={(e) => setFloor(e.target.value)}
                label="Floor / Wing"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={area}
                onChange={(e) => setArea(e.target.value)}
                label="Area / Locality"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
                label="Landmark"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                label="Pincode"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Recipient Name"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={phoneNumber}
                onChange={(e) => {
                  const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
                  if (onlyNumbers.length <= 10) {
                    setPhoneNumber(onlyNumbers);
                  }
                }}
                label="Phone Number"
                variant="outlined"
                fullWidth
                size="small"
                inputProps={{ maxLength: 10 }}
              />
              <div className="flex flex-col mt-3">
                <label className="text-sm font-semibold">Address Type</label>
                <div className="flex gap-2">
                  <label>
                    <input
                      type="radio"
                      checked={selectResident === "Home"}
                      onChange={() => setSelectResident("Home")}
                    />
                    <span className="ml-2">Home</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      checked={selectResident === "Office"}
                      onChange={() => setSelectResident("Office")}
                    />
                    <span className="ml-2">Office</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      checked={selectResident === "Other"}
                      onChange={() => setSelectResident("Other")}
                    />
                    <span className="ml-2">Other</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-end mt-4 gap-3">
                <button
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg"
                  onClick={handleSaveAddress}
                >
                  Save Address
                </button>
                <button
                  className="px-4 py-2 bg-gray-400 text-white rounded-lg"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile view */}

      <div className="h-screen w-screen flex flex-col lg:hidden">
        {/* Header Section */}
        <div
          className={`flex flex-col p-4 bg-white shadow-md ${
            isInputFieldsVisible ? "hidden" : ""
          }`}
        >
          <div className="flex flex-row items-center">
            <ArrowBackIcon className="cursor-pointer" />
            <h2 className="ml-4 text-lg font-semibold">Location Information</h2>
          </div>
          <div className="flex mt-4">
            <div className="flex bg-white shadow-md p-2 rounded-lg w-full">
              <SearchIcon className="text-gray-700" />
              <input
                className="ml-2 flex-grow bg-transparent focus:outline-none"
                type="text"
                placeholder="Search Location"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <CloseIcon
                className="cursor-pointer text-gray-600"
                onClick={() => setSearchQuery("")} // Add this line
              />
            </div>
          </div>
        </div>

        {/* Map Section */}
        <div
          className={`h-full w-full relative ${
            isInputFieldsVisible ? "hidden" : ""
          }`}
        >
          <LoadScript googleMapsApiKey="AIzaSyCYTF8ZL0Rp-JvuBmRsiu7SNdd6i43Fb1g" onLoad={handleApiLoaded}>
          <GoogleMap
                    id="map"
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    center={center}
                    zoom={18}
                    onClick={handleMapClick}
                    onLoad={map => setMap(map)}
                    onDragEnd={handleMapDragEnd}
                    options={{
                      disableDefaultUI: true,
                      zoomControl: false,
                      scrollwheel: false,
                      disableDoubleClickZoom: true,
                      styles: [
                        {
                          featureType: "water",
                          elementType: "geometry",
                          stylers: [{ color: "#e9e9e9" }],
                        },
                        {
                          featureType: "landscape",
                          elementType: "geometry",
                          stylers: [{ color: "#DADBDD" }],
                        },
                        {
                          featureType: "road",
                          elementType: "geometry",
                          stylers: [{ color: "#ffffff" }],
                        },
                        {
                          featureType: "poi",
                          elementType: "geometry",
                          stylers: [{ color: "#c0c0c0" }],
                        },
                        {
                          featureType: "administrative",
                          elementType: "geometry",
                          stylers: [{ color: "#a0a0a0" }],
                        },
                      ],
                    }}
                  >
                    {selectedLocation && (
                      <Marker
                        position={{
                          lat: selectedLocation.lat,
                          lng: selectedLocation.lng,
                        }}
                        icon={{
                          url: require("../../assets/images/Location Pin.gif"), // Adjust the path as necessary
                          scaledSize: new window.google.maps.Size(50, 70), // Adjust the size as necessary
                        }}
                      />
                    )}
                  </GoogleMap>
                </LoadScript>

          {/* Center Marker Icon */}
          <div className="absolute bottom-20 right-5 z-10">
            <GpsFixedIcon className="text-purple-600 cursor-pointer" />
          </div>
        </div>

        {/* Bottom Address Info */}
        <div
          className={`absolute bottom-0 w-full bg-white rounded-t-xl shadow-lg p-4 z-10 ${
            isInputFieldsVisible ? "hidden" : ""
          }`}
        >
          {selectedLocation && (
            <div className="mb-3">
              <p className="text-base font-bold">{selectedLocation.address}</p>
            </div>
          )}

          {/* Confirm Button */}
          <button
            className="w-full bg-primary-blue text-white py-2 rounded-lg font-semibold shadow-md"
            onClick={handleConform}
          >
            Confirm & Continue
          </button>
        </div>

        {/* Input Fields */}
        <div
          className={`h-full w-full bg-white ${
            isInputFieldsVisible ? "block" : "hidden"
          }`}
        >
          <div className="flex flex-col w-full h-full">
            <div className="p-[14px_12px] text-[rgb(28,28,28)] text-[17px] font-extrabold flex gap-[10px] items-center justify-between border-b border-[rgb(232,232,232)] sticky bg-white top-0 z-10">
              <span>Enter complete address</span>
              <span onClick={() => handleClose()} className="cursor-pointer">
                <CloseIcon />
              </span>
            </div>
            <div className="px-4 py-6 flex flex-col gap-3">
              <TextField
                value={house}
                onChange={(e) => setHouse(e.target.value)}
                label="House No. / Building"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={floor}
                onChange={(e) => setFloor(e.target.value)}
                label="Floor / Wing"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={area}
                onChange={(e) => setArea(e.target.value)}
                label="Area / Locality"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
                label="Landmark"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                label="Pincode"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Recipient Name"
                variant="outlined"
                fullWidth
                size="small"
              />
              <TextField
                value={phoneNumber}
                onChange={(e) => {
                  const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
                  if (onlyNumbers.length <= 10) {
                    setPhoneNumber(onlyNumbers);
                  }
                }}
                label="Phone Number"
                variant="outlined"
                fullWidth
                size="small"
                inputProps={{ maxLength: 10 }}
              />
              <div className="flex flex-col mt-3">
                <label className="text-sm font-semibold">Address Type</label>
                <div className="flex gap-2">
                  <label>
                    <input
                      type="radio"
                      checked={selectResident === "Home"}
                      onChange={() => setSelectResident("Home")}
                    />
                    <span className="ml-2">Home</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      checked={selectResident === "Office"}
                      onChange={() => setSelectResident("Office")}
                    />
                    <span className="ml-2">Office</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      checked={selectResident === "Other"}
                      onChange={() => setSelectResident("Other")}
                    />
                    <span className="ml-2">Other</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-end mt-4 gap-3">
                <button
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg"
                  onClick={handleSaveAddress}
                >
                  Save Address
                </button>
                <button
                  className="px-4 py-2 bg-gray-400 text-white rounded-lg"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryEdit;
