import {
    GET_DELIVERY_PERSONS_REQUEST,
    GET_DELIVERY_PERSONS_SUCCESS,
    GET_DELIVERY_PERSONS_FAIL,
    ADD_DELIVERY_PERSON_REQUEST,
    ADD_DELIVERY_PERSON_SUCCESS,
    ADD_DELIVERY_PERSON_FAIL,
    UPDATE_DELIVERY_PERSON_REQUEST,
    UPDATE_DELIVERY_PERSON_SUCCESS,
    UPDATE_DELIVERY_PERSON_FAIL,
    DELETE_DELIVERY_PERSON_REQUEST,
    DELETE_DELIVERY_PERSON_SUCCESS,
    DELETE_DELIVERY_PERSON_FAIL,
    ENABLE_DELIVERY_PERSON_REQUEST,
    ENABLE_DELIVERY_PERSON_SUCCESS,
    ENABLE_DELIVERY_PERSON_FAIL,
    DISABLE_DELIVERY_PERSON_REQUEST,
    DISABLE_DELIVERY_PERSON_SUCCESS,
    DISABLE_DELIVERY_PERSON_FAIL,
    CREATE_DELIVERY_ORDER_REQUEST,
    CREATE_DELIVERY_ORDER_SUCCESS,
    CREATE_DELIVERY_ORDER_FAIL,
    GET_DELIVERY_PERSONS_BY_STORE_ID_REQUEST,
    GET_DELIVERY_PERSONS_BY_STORE_ID_SUCCESS,
    GET_DELIVERY_PERSONS_BY_STORE_ID_FAIL,
    CLEAR_ERRORS,
} from "../constants/deliveryPersonConstants";
import axiosInstance from "../utils/api"; // Ensure you have axios instance configured

// Fetch all delivery persons
export const getDeliveryPersons = () => async (dispatch) => {
    try {
        dispatch({ type: GET_DELIVERY_PERSONS_REQUEST });
        const { data } = await axiosInstance.get("/delivery-persons");
        dispatch({ type: GET_DELIVERY_PERSONS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: GET_DELIVERY_PERSONS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Add a new delivery person
export const addDeliveryPerson = (deliveryPersonData) => async (dispatch) => {
    try {
        dispatch({ type: ADD_DELIVERY_PERSON_REQUEST });
        const { data } = await axiosInstance.post("/delivery-persons/add", deliveryPersonData);
        dispatch({ type: ADD_DELIVERY_PERSON_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: ADD_DELIVERY_PERSON_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

export const getDeliveryPersonsByStoreId = (storeId) => async (dispatch) => {
    try {
        dispatch({ type: GET_DELIVERY_PERSONS_BY_STORE_ID_REQUEST });
        const { data } = await axiosInstance.get(`/delivery-persons/store/${storeId}`);
        dispatch({ type: GET_DELIVERY_PERSONS_BY_STORE_ID_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: GET_DELIVERY_PERSONS_BY_STORE_ID_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};


// Update a delivery person
export const updateDeliveryPerson = (id, deliveryPersonData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_DELIVERY_PERSON_REQUEST });
        const { data } = await axiosInstance.put(`/delivery-persons/${id}`, deliveryPersonData);
        dispatch({ type: UPDATE_DELIVERY_PERSON_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: UPDATE_DELIVERY_PERSON_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Delete a delivery person
export const deleteDeliveryPerson = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_DELIVERY_PERSON_REQUEST });
        const { data } = await axiosInstance.delete(`/delivery-persons/remove/${id}`);
        dispatch({ type: DELETE_DELIVERY_PERSON_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: DELETE_DELIVERY_PERSON_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Enable a delivery person
export const enableDeliveryPerson = (id) => async (dispatch) => {
    try {
        dispatch({ type: ENABLE_DELIVERY_PERSON_REQUEST });
        const { data } = await axiosInstance.patch(`/delivery-persons/enable/${id}`);
        dispatch({ type: ENABLE_DELIVERY_PERSON_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: ENABLE_DELIVERY_PERSON_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Disable a delivery person
export const disableDeliveryPerson = (id) => async (dispatch) => {
    try {
        dispatch({ type: DISABLE_DELIVERY_PERSON_REQUEST });
        const { data } = await axiosInstance.patch(`/delivery-persons/disable/${id}`);
        dispatch({ type: DISABLE_DELIVERY_PERSON_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: DISABLE_DELIVERY_PERSON_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Create a delivery order
export const createDeliveryOrder = (orderData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_DELIVERY_ORDER_REQUEST });
        const { data } = await axiosInstance.post("/delivery-persons/delivery-order", orderData);
        dispatch({ type: CREATE_DELIVERY_ORDER_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CREATE_DELIVERY_ORDER_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Clear errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};