import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Using react-router to manage the URL
import axiosInstance from '../../utils/api';

const PaymentSuccess = () => {
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    // Get the payment_id from the query params
    const urlParams = new URLSearchParams(location.search);
    const paymentId = urlParams.get('razorpay_payment_id'); // Razorpay will send this in the query params

    if (paymentId) {
      // Call the handleVerifyPayment function once the payment ID is available
      handleVerifyPayment(paymentId);
    } else {
      alert('Payment verification failed: Missing payment ID.');
    }
  }, [location]);

  const handleVerifyPayment = async (paymentId) => {
    try {
      const response = await axiosInstance.post('/verify-payment', {
        paymentId: paymentId,
        virtualAccountId: "va_PKZk3e4Wh7LwOY", // Your virtual account ID
      });

      if (response.data.success) {
        setPaymentStatus('Funds successfully transferred to the virtual account!');
      } else {
        setPaymentStatus('Payment verification failed: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
    }
  };

  return (
    <div className='mt-96'>
      <h2>Payment Success</h2>
      <p>{paymentStatus ? paymentStatus : 'Verifying payment...'}</p>
    </div>
  );
};

export default PaymentSuccess;
