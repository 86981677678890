// PrimaryDropDownMenu.js
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ChatIcon from "@mui/icons-material/Chat";
import QuizIcon from "@mui/icons-material/Quiz";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import HomeIcon from '@mui/icons-material/Home';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { logoutUser  } from "../../../actions/userAction";

const PrimaryDropDownMenu = ({ setTogglePrimaryDropDown, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { wishlistItems } = useSelector((state) => state.wishlist);

  const handleLogout = () => {
    localStorage.removeItem("userPhoneNumber");
    setTogglePrimaryDropDown(false);
    window.location.href = "/";
  };

  const navs = [
    {
      title: "My Orders",
      icon: <ShoppingBagIcon sx={{ fontSize: "18px" }} />,
      redirect: "/profile/orders",
    },
    {
      title: "Saved Address",
      icon: <HomeIcon sx={{ fontSize: "18px" }} />,
      redirect: "/profile/addresses",
    },
    {
      title: "My Wallet",
      icon: <ChatIcon sx={{ fontSize: "18px" }} />,
      redirect: "/profile/my-wallet",
    },
    {
      title: "FAQ's",
      icon: <QuizIcon sx={{ fontSize: "18px" }} />,
      redirect: "/",
    },
  ];

  const handleOptionClick = () => {
    setTogglePrimaryDropDown(false); // Close the dropdown
  };

  return (
    <div className="absolute w-60 -left-24 ml-2 top-9 bg-white shadow-2xl rounded flex-col text-sm z-10">
      <div className="pl-3 py-3.5 border-b items-center hover:bg-gray-50 rounded-t">
        <div className="flex gap-3">
          <span className="text-primary-blue">
            <AccountCircleIcon sx={{ fontSize: "18px" }} />
          </span>
          My Profile
        </div>
        <div className="ml-6">{user}</div>
      </div>

      {navs.map((item, i) => {
        const { title, icon, redirect } = item;

        return (
          <Link
            className="pl-3 py-3.5 border-b flex gap-3 items-center hover:bg-gray-50"
            to={redirect}
            key={i}
            onClick={handleOptionClick} // Call handleOptionClick on click
          >
            <span className="text-primary-blue">{icon}</span>
            {title}
            {title === "Wishlist" && (
              <span className="ml-auto mr-3 bg-gray-100 p-0.5 px-2 text-gray-600 rounded">
                {wishlistItems.length}
              </span>
            )}
          </Link>
        );
      })}

      <div
        className="pl-3 py-3.5 flex gap-3 items-center hover:bg-gray-50 rounded-b cursor-pointer"
        onClick={handleLogout}
      >
        <span className="text-primary-blue">
          <PowerSettingsNewIcon sx={{ fontSize: "18px" }} />
        </span>
        Logout
      </div>

      <div className="absolute right-1/2 -top-2.5">
        <div className="arrow_down"></div>
      </div>
    </div>
  );
};

export default PrimaryDropDownMenu;