import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

const MobileAccount = () => {
  const [userPhoneNumber, setUserPhoneNumber] = useState("");


  useEffect(() => {
    const phoneNumber = localStorage.getItem("userPhoneNumber");
    if (phoneNumber) {
      setUserPhoneNumber(phoneNumber);
    }
  }, []);

    const handleLogout = () => {
        // dispatch(logoutUser());
        // navigate("/login");
        // enqueueSnackbar("Logout Successfully", { variant: "success" });
        localStorage.removeItem("userPhoneNumber");
        window.location.href = "/";
      };

  return (
    <div className="mx-auto sm:w-screen sm:mx-0 bg-white p-4 w-full">
        <div className="flex mb-4 p-2 border-b">
            <div className="">
            <Link to="/">
            <ArrowBackIosNewIcon sx={{ fontSize: 20 }} />
            </Link>
            </div>
            <div className="ml-4 text-[20px]">
                Account
            </div>

        </div>
      <div className="flex items-center mb-4">
      <AccountCircleOutlinedIcon sx={{ fontSize: 60 }} />
        <div className="ml-4">
          {/* <h2 className="text-lg font-bold">surya</h2> */}
          <p className="text-gray-500">{userPhoneNumber}</p>
        </div>
      </div>      
      <div className="space-y-4 mt-6">
      <Link to="/account/orders">
        <div className="flex items-center border-b p-2 justify-between">        
          <div className="flex items-center">
          <MedicalServicesOutlinedIcon sx={{ fontSize: 20 }} />
            <span className="ml-4 text-lg">            
                My Orders            
            </span>
          </div>
          <ArrowForwardIosOutlinedIcon sx={{ fontSize: 15 }} />
        </div>
        </Link>
        <Link to="/account/addresses">
        <div className="flex items-center border-b p-2 justify-between">        
          <div className="flex items-center">
          <PinDropOutlinedIcon sx={{ fontSize: 20 }} />
            <span className="ml-4 text-lg">            
            My Addresses            
            </span>
          </div>
          <ArrowForwardIosOutlinedIcon sx={{ fontSize: 15 }} />
        </div>
        </Link>
        <Link to="/account/my-wallet">
        <div className="flex items-center border-b p-2 justify-between">        
          <div className="flex items-center">
          <AccountBalanceWalletOutlinedIcon sx={{ fontSize: 20 }} />
            <span className="ml-4 text-lg">            
            Wallet      
            </span>
          </div>
          <ArrowForwardIosOutlinedIcon sx={{ fontSize: 15 }} />
        </div>
        </Link>
      </div>
      <button className="bottom-10 w-full text-red-500 font-bold py-2 rounded-full" onClick={handleLogout}>
        Log Out
      </button>
    </div>
  );
};

export default MobileAccount;
