import React from 'react'
import MetaData from '../MetaData'

const About = () => {
  return (
    <div className="w-full mt-52 lg:mt-28 px-4 sm:px-8 md:px-16 lg:px-32 xl:px-64">
    <MetaData title="About us | Vivimart" />
    <main className="bg-white py-6 lg:p-6 lg:shadow-lg lg:rounded-lg">
      <h1 className="text-[16px] font-bold mb-6 lg:mb-12">
        About us
      </h1>
      <section className="mb-6">
      <h2 className="text-[14px] lg:text-[18px] font-semibold mb-4 lg:mb-6"><a href='www.vivimart.in' className='text-blue-700 underline' target='_blank'>vivimart.in</a> Introduction</h2>
        <p className="mb-4 text-[10px] lg:text-[12px]">
        <a href='www.vivimart.in' className='text-blue-700 underline' target='_blank'>vivimart.in</a>. This means coordinating computerized and retail locations to provide Indian buyers with unlimited choices, unparalleled quality items and an unparalleled shopping experience by mobilizing and enabling nearby franchised stores.
        </p>
        <p className="mb-4 text-[10px] lg:text-[12px] text-justify">
        <a href='www.vivimart.in' className='text-blue-700 underline' target='_blank'>vivimart.in</a>, the online shopping platform founded by GVS Food & Retail Pvt Ltd, was started in more cities and towns across India. This platform connects manufacturers, suppliers, retail franchise stores, and purchasers to make a mutually beneficial model for the entire ecosystem by permitting neighbourhood organizations to contend on cost and supply, and permitting clients to look for their day-to-day necessities at the snap of a button.
        </p>
        <p className="mb-4 text-[10px] lg:text-[12px] text-justify">
        <a href='www.vivimart.in' className='text-blue-700 underline' target='_blank'>vivimart.in</a> is a multicategory online shopping platform, with overall essential products and more top brands in our catalogue. You will find everything you are looking for right here. From fresh fruits and vegetables, rice and dals, spices and seasonings, to packaged products, beverages, personal care products, meats, dairy items, frozen, pet food, household cleaning items, and personal care products, we have it all in a single virtual store. Choose from a wide range of options in every category, exclusively selected to help you find the best quality available at the lowest prices. Select a time slot for delivery and your order will be delivered right to your doorstep, wherever fast delivery and standard delivery are available options in your location. You can pay online using your e-wallet, debit or credit card, or by cash on delivery.</p>
        <p className="mb-4 text-[10px] lg:text-[12px] text-justify">
        <a href='www.vivimart.in' className='text-blue-700 underline' target='_blank'>vivimart.in</a> welcomes an easy, relaxed way of browsing and shopping for groceries and household items. Discover new products and shop for all your food and grocery needs from the comfort of your home or office. No more sitting in traffic, paying for parking, standing in long lines, or carrying heavy bags-get everything you need, when you need it, right at your doorstep. Grocery shopping online is now easy as every product on your monthly shopping list is now available online at <a href='www.vivimart.in' className='text-blue-700 underline' target='_blank'>vivimart.in</a>.
        </p>
        
      </section>

      
    </main>
  </div>
  )
}

export default About
