import { useEffect, useState } from 'react';
import { myOrders, clearErrors } from '../../actions/orderAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Layouts/Loader';
import { useSnackbar } from 'notistack';
import OrderItem from "./OrderItem"
import MetaData from '../Layouts/MetaData';
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const orderStatus = ["Processing", "Shipped", "Delivered"];
const dt = new Date();
const ordertime = [dt.getMonth(), dt.getFullYear() - 1, dt.getFullYear() - 2];



const MyOrders = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState("");
  const [orderTime, setOrderTime] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");

  useEffect(() => {
    const phoneNumber = localStorage.getItem("userPhoneNumber");
    if (phoneNumber) {
      setUserPhoneNumber(phoneNumber);
    }
  }, []);


  const { orders, loading, error } = useSelector((state) => state.myOrders);


  console.log("order data with shipment details:", orders)


  const handleLogout = () => {
    // dispatch(logoutUser());
    // navigate("/login");
    // enqueueSnackbar("Logout Successfully", { variant: "success" });
    localStorage.removeItem("userPhoneNumber");
    window.location.href = "/";
  };

  console.log("My Orders:", orders);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(myOrders());
  }, [dispatch, error, enqueueSnackbar]);

  useEffect(() => {
    if (!loading && orders) {
      setFilteredOrders(orders.slice().reverse());
    }
  }, [loading, orders]);

  useEffect(() => {
    setSearch("");
    // console.log(status);
    // console.log(typeof orderTime);
    // console.log(orderTime);

    if (!status && +orderTime === 0) {
      setFilteredOrders(orders);
      return;
    }

    if (status && orderTime) {
      if (+orderTime === dt.getMonth()) {
        const filteredArr = orders.filter(
          (order) =>
            order.orderStatus === status &&
            new Date(order.createdAt).getMonth() === +orderTime
        );
        setFilteredOrders(filteredArr);
      } else {
        const filteredArr = orders.filter(
          (order) =>
            order.orderStatus === status &&
            new Date(order.createdAt).getFullYear() === +orderTime
        );
        setFilteredOrders(filteredArr);
      }
    } else if (!status) {
      if (+orderTime === dt.getMonth()) {
        const filteredArr = orders.filter(
          (order) => new Date(order.createdAt).getMonth() === +orderTime
        );
        setFilteredOrders(filteredArr);
      } else {
        const filteredArr = orders.filter(
          (order) => new Date(order.createdAt).getFullYear() === +orderTime
        );
        setFilteredOrders(filteredArr);
      }
    } else {
      const filteredArr = orders.filter(
        (order) => order.orderStatus === status
      );
      setFilteredOrders(filteredArr);
    }
    // eslint-disable-next-line
  }, [status, orderTime]);

  const searchOrders = (e) => {
    e.preventDefault();
    if (!search.trim()) {
      enqueueSnackbar("Empty Input", { variant: "warning" });
      return;
    }
    const arr = orders.map((el) => ({
      ...el,
      orderItems: el.orderItems.filter((order) =>
        order.name.toLowerCase().includes(search.toLowerCase())
      ),
    }));
    setFilteredOrders(arr);
  };

  const clearFilters = () => {
    setStatus("");
    setOrderTime(0);
  };

  return (
    <>
      <MetaData title="My Orders | Vivimart" />

      <main className="w-full">
        {/* <!-- row --> */}
        <div className="flex gap-1 mt-2">
          {/* <!-- sidebar column  --> */}

          {/* <!-- orders column --> */}
          <div className="flex-1 w-full mx-4 lg:mx-6">
            <div className="flex fixed z-40 w-full top-0 left-0 border-b p-4 bg-white lg:hidden mr-4">
              <div className="">
                <Link to="/account">
                  <ArrowBackIosNewIcon sx={{ fontSize: 25 }} />
                </Link>
              </div>
              <div>
                <div className="text-[20px] font-semibold lg:mb-4">My Orders</div>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="flex flex-col h-full lg:h-[30rem] gap-2 sm:mr-4 mt-8 overflow-auto">
                {/* ... existing search bar and filters code */}

                {/* Orders List */}
                <div className="">
                  {filteredOrders.length ? (
                    filteredOrders.map((order) => (
                      <OrderItem key={order.order_id} order={order} />
                    ))
                  ) : (
                    <p className="text-center text-gray-500">No orders found</p>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* <!-- orders column --> */}
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  );
};

export default MyOrders;
