import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderDetails } from "../../../actions/orderAction"; // Import the action
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HomeIcon from '@mui/icons-material/Home';
import { getStoreById } from "../../../actions/storeAction";

const AllOrderDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the order ID from the URL parameters
  const { order, loading, error } = useSelector((state) => state.orderDetails); // Access order details from Redux
  const { store, loading: storeLoading, error: storeError } = useSelector((state) => state.store);

  useEffect(() => {
    dispatch(getOrderDetails(id)); // Fetch order details when component mounts
  }, [dispatch, id]);

  useEffect(() => {
    if (order && order.store_id) {
      dispatch(getStoreById(order.store_id)); // Fetch store details when order is available
    }
  }, [dispatch, order]);


  console.log("store data in order details page:", store)

  // Handle loading state
  if (loading || storeLoading) return <div>Loading...</div>;

  // Handle error state
  if (error || storeError) return <div>Error: {error || storeError}</div>;

  // Handle case where order is not found
  if (!order) return <div>No order found.</div>;

  const handlePrintInvoice = () => {
    // Navigate to the print invoice route and pass the order details
    navigate("/admin/orders/printStoreInvoice", {
      state: { order }, // Pass the entire order object
    });
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="max-w-6xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <div className="flex items-center mb-6">
          <h1 className="text-2xl font-bold">Order Details</h1>
          <span className="bg-gray-200 text-gray-700 text-sm rounded-lg p-2 ml-4">
            {order.order_id}
          </span>
        </div>
        <div className="flex justify-between items-start">
          <div className="w-2/3">
            <div className="flex justify-between">
              <div className="mb-6 flex-1">
                {" "}
                {/* Added flex-1 to take equal space */}
                <h2 className="text-xl font-semibold">
                  Order #{order.order_id}
                </h2>
                <p className="flex items-center text-[14px] mt-2 text-gray-500">
                  <CalendarMonthIcon style={{ fontSize: "14px" }} />
                  <span className="ml-2">
                    {new Date(order.order_date).toLocaleString()}
                  </span>
                </p>
                <div className="mt-4">
                  <span className="flex items-center inline-block text-m">
                  <StoreMallDirectoryIcon style={{ fontSize: "14px" }} />
                    <div className="ml-2">
                    Store:{" "}
                    </div>                    
                    <span className="text-blue-500 border border-blue-500 bg-blue-100 text-black text-m ml-2 rounded-lg py-1 px-2">
                      {order.store_id}
                    </span>
                  </span>
                </div>
              </div>
              <div className="flex-1">
                {" "}
                {/* Added flex-1 to take equal space */}
                <button onClick={handlePrintInvoice} className="bg-primary-blue text-white px-4 py-2 rounded-lg font-semibold">
                  Print Invoice
                </button>
                <div className="p-4 rounded-lg mb-6">
                  <h3 className="text-lg font-semibold mb-4">
                    Status:{" "}
                    <span className="text-teal-500">{order.status}</span>
                  </h3>
                  <p className="text-gray-500">
                    Payment Method: {order.payment_method}
                  </p>
                  <p className="text-gray-500">
                    Order Type: <span className="text-teal-500">Delivery</span>
                  </p>
                  <p className="text-gray-500">
                    Payment Status:{" "}
                    <span className="text-teal-500">
                      {order.payment_status}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <table className="w-full">
                <thead>
                  <tr className="text-left text-gray-500">
                    <th className="py-2">#</th>
                    <th className="py-2">Item Details</th>
                    <th className="py-2">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {order.order_items.map((item, index) => (
                    <tr className="border-t" key={item.product_id}>
                      {" "}
                      {/* Use product_id as the key */}
                      <td className="py-4">{index + 1}</td>
                      <td className="py-4 flex items-center">
                        <img
                          src={item.product_image_0} // Correct property for image
                          alt={item.Product_name} // Correct property for name
                          className="w-12 h-12 mr-4"
                        />
                        <div>
                          <p>{item.Product_name}</p>{" "}
                          {/* Correct property for name */}
                          <p className="text-gray-500">
                            {item.quantity} x ₹
                            {parseFloat(item.sell_price).toFixed(2)}{" "}
                            {/* Correct properties for quantity and price */}
                          </p>
                        </div>
                      </td>
                      <td className="py-4">
                        ₹
                        {(item.quantity * parseFloat(item.sell_price)).toFixed(
                          2
                        )}
                      </td>{" "}
                      {/* Calculate total price */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-6">
              <div className="mb-4">
                <p className="text-right text-lg flex justify-between">
                  <div className="font-semibold">Items price:</div>
                  <div className="ml-4">₹{order.total_price}</div>
                </p>
                <p className="text-right text-lg flex justify-between">
                  <div className="font-semibold">Subtotal (TAX Included):</div>
                  <div className="ml-4">₹{order.total_price}</div>
                </p>
                <p className="text-right text-lg flex justify-between">
                  <div className="font-semibold">Discount:</div>
                  <div className="ml-4">₹{order.total_price}</div>
                </p>
                <p className="text-right text-lg flex justify-between">
                  <div className="font-semibold">Coupon discount:</div>
                  <div className="ml-4">₹{order.total_price}</div>
                </p>
                <p className="text-right text-lg flex justify-between">
                  <div className="font-semibold">Delivery fee:</div>
                  <div className="ml-4">₹{order.total_price}</div>
                </p>
              </div>

              <div className="border-t">
                <p className="text-right text-lg font-semibold mt-4">
                  Grant Total: ₹{order.total_price}
                </p>
              </div>
            </div>
          </div>
          <div className="w-1/3 pl-6">
            <div className="bg-gray-50 p-4 rounded-lg mb-6">
              <h3 className="text-[20px] flex items-center font-semibold mb-4">
              <PersonOutlineIcon style={{ fontSize: "20px" }} />
                <div className="ml-2">
                Deliveryman
                </div>
              </h3>
              <div className="flex items-center mb-4">
                <img
                  src="https://placehold.co/50x50"
                  alt="Deliveryman"
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div>
                  <p className="font-semibold">Jhon Doe</p>
                  <p className="text-gray-500">15 Orders delivered</p>
                </div>
              </div>
              <p className="text-gray-500 flex items-center">
              <LocalPhoneIcon style={{ fontSize: "20px" }} />
              <div className="ml-2">
              +8**********
                </div>
              
              </p>
              <p className="text-gray-500 flex items-center">
              <EmailIcon style={{ fontSize: "20px" }} />
              <div className="ml-2">
              +8**********
                </div>
              
              </p>
              <p className="text-gray-500 mt-4">
                <i className="fas fa-map-marker-alt mr-2"></i>R9PG+XCH, Dhaka
                District, BD
              </p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg mb-6">
            <h3 className="text-[20px] flex items-center font-semibold mb-4">
              <PersonOutlineIcon style={{ fontSize: "20px" }} />
                <div className="ml-2">
                Customer Information
                </div>
              </h3>
              <div className="flex items-center mb-4">
              </div>
              <p className="text-gray-500 flex items-center">
              <LocalPhoneIcon style={{ fontSize: "20px" }} />
              <div className="ml-2">
              {order.phone_number}
                </div>
              
              </p>
              <p className="text-gray-500 flex items-center">
              <HomeIcon style={{ fontSize: "20px" }} />
              <div className="ml-2">
              {order.address}
                </div>
              
              </p>
              <p className="text-gray-500 flex items-center">
              <HomeIcon style={{ fontSize: "20px" }} />
              <div className="ml-2">
              {order.delivery_notes}
                </div>
              
              </p>
            </div>
            {/* <div className="bg-gray-50 p-4 rounded-lg mb-6">
            <h3 className="text-[20px] flex items-center font-semibold mb-4">
              <PersonOutlineIcon style={{ fontSize: "20px" }} />
                <div className="ml-2">
                Store Information
                </div>
              </h3>
              <div className="flex items-center mb-4">
                <img
                  src={store.owner_photo}
                  alt="Customer"
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div>
                  <p className="font-semibold">{store.name}</p>
                  <p className="text-gray-500">{store.id}</p>
                </div>
              </div>
              <div className="flex items-center mb-4">
              </div>
              <p className="text-gray-500 flex items-center">
              <LocalPhoneIcon style={{ fontSize: "20px" }} />
              <div className="ml-2">
              {store.phone_number}
                </div>
              
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllOrderDetails;
