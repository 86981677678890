import {
    SPLIT_ORDER_REQUEST,
    SPLIT_ORDER_SUCCESS,
    SPLIT_ORDER_FAIL,
    GET_SHIPMENTS_BY_ORDER_ID_REQUEST,
    GET_SHIPMENTS_BY_ORDER_ID_SUCCESS,
    GET_SHIPMENTS_BY_ORDER_ID_FAIL,
    ASSIGN_DELIVERY_PERSON_REQUEST,
    ASSIGN_DELIVERY_PERSON_SUCCESS,
    ASSIGN_DELIVERY_PERSON_FAIL,
    CLEAR_ERRORS
} from '../constants/shipmentConstants';

const initialState = {
    shipments: [],
    loading: false,
    error: null
};

// Reducer for splitting orders into shipments
export const splitOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SPLIT_ORDER_REQUEST:
            return { ...state, loading: true };
        case SPLIT_ORDER_SUCCESS:
            return { ...state, loading: false, shipments: action.payload, error: null };
        case SPLIT_ORDER_FAIL:
            return { ...state, loading: false, error: action.payload };
        case CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};

// Reducer for getting shipments by order ID
export const getShipmentsByOrderIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SHIPMENTS_BY_ORDER_ID_REQUEST:
            return { ...state, loading: true };
        case GET_SHIPMENTS_BY_ORDER_ID_SUCCESS:
            return { ...state, loading: false, shipments: action.payload, error: null };
        case GET_SHIPMENTS_BY_ORDER_ID_FAIL:
            return { ...state, loading: false, error: action.payload };
        case CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};

// Reducer for shipments
export const shipmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ASSIGN_DELIVERY_PERSON_REQUEST:
            return { ...state, loading: true };
        case ASSIGN_DELIVERY_PERSON_SUCCESS:
            return { ...state, loading: false, success: action.payload };
        case ASSIGN_DELIVERY_PERSON_FAIL:
            return { ...state, loading: false, error: action.payload };
        case CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};