import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your existing Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDwFWZ_gV5MRMSgHq9xEmnrCCrEltjdl4s",
    authDomain: "vivimartuser.firebaseapp.com",
    projectId: "vivimartuser",
    storageBucket: "vivimartuser.appspot.com",
    messagingSenderId: "978230642962",
    appId: "1:978230642962:web:e8d97ff4228aa5ab740bcb",
    measurementId: "G-24PKCSSS02"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const messaging = getMessaging(app);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
  })
  .catch((error) => {
      console.error('Service Worker registration failed:', error);
  });
}

// Requesting permission and getting the token
export const requestForToken = async () => {
    try {
        const token = await getToken(messaging, { vapidKey: "BDIXBLauUEJhim13VZ0npxAvWX8ybqjwugP7Yk5KlmcbADJR6MnIapdUh1H9ft5tJr08i1i4bYTeaX3eecmXZjE" });
        console.log('Token generated:', token);
        return token;
    } catch (error) {
        console.error('Error getting token:', error);
    }
};
