import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Actions from "./Action";
import MetaData from "../../Layouts/MetaData";
import BackdropLoader from "../../Layouts/BackdropLoader";
import EditModal from "./EditModal";
import AddModal from "./AddModal";
import { getOrders } from "../../../actions/orderAction";

const AllOrders = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [updateCount, setUpdateCount] = useState(0);
  const [selectedStore, setSelectedStore] = useState(null); // State to hold selected store data
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const { subStores } = useSelector((state) => state.subStores); // Access sub-store data
  const { isDeleted, deleteError } = useSelector((state) => state.subStores);

  const { orders, loading, error } = useSelector((state) => state.orders); // Access orders data

  useEffect(() => {
    dispatch(getOrders()); // Fetch all orders when component mounts
  }, [dispatch]);

  console.log("all orders data:", orders)

  // Handle error notifications
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [error, enqueueSnackbar]);

  const getPaymentMethodColor = (method) => {
    switch (method) {
      case 'upi':
      case 'card':
        return { color: 'green', fontWeight: 'bold' }; // Green for UPI and Card
      case 'COD':
        return { color: 'blue', fontWeight: 'bold' }; // Blue for COD
      default:
        return { color: 'black' }; // Default color
    }
  };

  const getPaymentStatusColor = (status) => {
    return status === 'Paid'
      ? { color: 'green', fontWeight: 'bold' } // Green for Paid
      : { color: 'red', fontWeight: 'bold' }; // Red for Not Paid
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "New":
        return { color: 'blue', fontWeight: 'bold' };
      case "Accepted":
        return { color: 'green', fontWeight: 'bold' };
      case "Forwarded":
        return { color: 'yellow', fontWeight: 'bold' };
      case "Dispatched":
        return { color: 'orange', fontWeight: 'bold' };
      case "Delivered":
        return { color: 'green', fontWeight: 'bold' };
      case "Canceled":
        return { color: 'red', fontWeight: 'bold' };
      default:
        return { color: 'gray', fontWeight: 'bold' };
    }
  };

  const columns = [
    {
      field: "order_id",
      headerName: "Order ID",
      minWidth: 100,
      flex: 0.1,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "store_id",
      headerName: "Store",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "total_price",
      headerName: "Total Price",
      minWidth: 120,
      flex: 0.1,
      renderCell: (params) => {
        const { color, fontWeight } = getPaymentStatusColor(params.row.payment_status);
        return (
          <div>
            <div>
            ₹ {params.value}
            </div>
            <div className="" style={{ color, fontWeight }}>
            ({params.row.payment_status}) {/* Display total price and payment status */}
          </div>
          </div>          
        );
      },
    },
    {
      field: "total_items",
      headerName: "Total Items",
      minWidth: 100,
      flex: 0.1,
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const { color, fontWeight } = getPaymentMethodColor(params.value);
        return (
          <div style={{ color, fontWeight }}>
            {params.value.toUpperCase()} {/* Convert to uppercase */}
          </div>
        );
      },
    },
    {
      field: "order_date",
      headerName: "Order Date",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => {
        const { color, fontWeight } = getStatusStyle(params.value);
        return (
          <div style={{ color, fontWeight }}>
            {params.value} {/* Convert to uppercase */}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Actions id={params.row.order_id} />
      ),
    },  
  ];

  const rows = orders
  .sort((a, b) => new Date(b.order_date) - new Date(a.order_date)) // Sort orders by date descending
  .map(order => {
    const totalQuantity = order.order_items.reduce((total, item) => total + item.quantity, 0);
  
    return {
      id: order.order_id,
      order_id: order.order_id,
      phone_number: order.phone_number,
      total_price: order.total_price,
      payment_status: order.payment_status,
      payment_method: order.payment_method,
      store_id: order.store_id,
      order_date: new Date(order.order_date).toLocaleDateString(),
      status: order.status,
      total_items: `${totalQuantity} item${totalQuantity !== 1 ? 's' : ''}`,
    };
  });

  

  return (
    <>
      <MetaData title="Admin All Order | Vivimart" />

      {loading && <BackdropLoader />}

      <div className="flex justify-between mx-10 mb-6">
        <div className="text-[20px]">All Orders Table</div>
      </div>

      <div
        className="bg-white rounded-xl shadow-lg w-full"
        style={{ height: 470 }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectIconOnClick
          sx={{
            boxShadow: 0,
            border: 0,
          }}
        />
      </div>

      <EditModal
        open={isEditModalOpen}
        handleClose={() => setEditModalOpen(false)}
        storeData={selectedStore}
      />
      <AddModal
        open={isAddModalOpen}
        handleClose={() => setAddModalOpen(false)} // Close the add modal
        existingStores={subStores} // Pass existing stores for ID calculation
      />
    </>
  );
};

export default AllOrders;
