import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { getAllSubStores, updateSubStore } from '../../../actions/subStoreActions';

const EditStoreModal = ({ open, handleClose, storeData }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        id: '',
        store_id: '',
        sub_store_id: '',
        phone: '',
        sub_store_owner: '',
        is_active: 1,
    });

    useEffect(() => {
        if (storeData) {
            setFormData(storeData);
        }

        console.log("form data in editStoreModal:", storeData)
    }, [storeData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Prepare data for submission
        const dataToSubmit = {
            ...formData,
        };
    
        // Correctly dispatch the update action
        dispatch(updateSubStore({ id: formData.id, subStoreData: dataToSubmit }))
            .then(() => {
                handleClose(); // Close the modal after submitting
                // Optionally, dispatch getAllSubStores to refresh the data
                dispatch(getAllSubStores());
            });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Store</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        name="store_id"
                        label="Store ID"
                        value={formData.store_id}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        name="sub_store_id"
                        label="Sub Store ID"
                        value={formData.sub_store_id}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        name="phone"
                        label="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sub_store_owner"
                        label="Sub Store Owner"
                        value={formData.sub_store_owner}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="is_active"
                        label="Is Active"
                        type="number"
                        value={formData.is_active}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />                   
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default EditStoreModal;