import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupIcon from "@mui/icons-material/Group";
import ReviewsIcon from "@mui/icons-material/Reviews";
import AddBoxIcon from "@mui/icons-material/AddBox";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import KeyboardDoubleArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowLeftTwoTone';
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import "./Sidebar.css";
import { useSnackbar } from "notistack";
import { logoutUser } from "../../../actions/userAction";
import logo from "../../../assets/images/logo1.png";

const navMenu = [
  {
    icon: <EqualizerIcon />,
    label: "Dashboard",
    ref: "/admin/dashboard",
  },
  {
    icon: <EqualizerIcon />,
    label: "Location",
    ref: "/admin/location",
  },
  {
    icon: <ShoppingBagIcon />,
    label: "Orders",
    ref: "/admin/orders",
  },
  {
    icon: <ShoppingBagIcon />,
    label: "Stores",
    ref: "/admin/stores",
  },
  {
    icon: <GroupIcon />,
    label: "Virtual Account",
    ref: "/admin/virtual-account",
  },
  {
    icon: <ShoppingBagIcon />,
    label: "Sub Stores",
    ref: "/admin/sub-stores",
  },
  {
    icon: <ShoppingBagIcon />,
    label: "All Orders",
    ref: "/admin/all-orders",
  },
  {
    icon: <InventoryIcon />,
    label: "Products",
    ref: "/admin/products",
  },
  {
    icon: <InventoryIcon />,
    label: "Payment Gateway",
    ref: "/admin/payment-gateway",
  },
  {
    icon: <AddBoxIcon />,
    label: "Add Product",
    ref: "/admin/new_product",
  },
  {
    icon: <AddBoxIcon />,
    label: "Categories",
    ref: "/admin/Category",
  },
  {
    icon: <AddBoxIcon />,
    label: "Sub Categories",
    ref: "/admin/SubCategory",
  },
  {
    icon: <AddBoxIcon />,
    label: "Sub Sub Categories",
    ref: "/admin/SubSubCategory",
  },
  {
    icon: <GroupIcon />,
    label: "Users",
    ref: "/admin/users",
  },
  {
    icon: <ReviewsIcon />,
    label: "Reviews",
    ref: "/admin/reviews",
  },
  {
    icon: <AccountBoxIcon />,
    label: "My Profile",
    ref: "/account",
  },
  {
    icon: <LogoutIcon />,
    label: "Logout",
  },
];

const Sidebar = ({ activeTab, setToggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.user);


  const handleLogout = () => {
    dispatch(logoutUser());
    enqueueSnackbar("Successfully logged out", { variant: "success" });
    navigate("/admin/login");
  };


  return (
    <div
      className="fixed top-0 left-0 h-screen bg-[#073b74] shadow-lg w-[17rem] z-20 hidden-scrollbar"
      style={{ height: "100%", overflow: "auto" }}
    >
      <div className="flex justify-between items-center p-4 border-b border-gray-300">
        <img src={logo} alt="Logo" className="w-25 h-20" />
        {/* <button
          onClick={() => {
            console.log("Close button clicked");
            setToggleSidebar(false);
          }}
          className="text-white hover:text-gray-700"
        >
          <KeyboardDoubleArrowLeftTwoToneIcon />
        </button> */}
      </div>
      <ul className="mt-4">
  {navMenu.map((menuItem, index) => (
    <li key={index} className="px-4 py-2">
      {menuItem.label === "Logout" ? (
        <button
          className={`w-full text-white flex items-center p-2 rounded-lg text-gray-700 hover:bg-primary-opacity hover:text-green-200`}
          onClick={handleLogout}
        >
          {menuItem.icon}
          <span className="ml-3">{menuItem.label}</span>
        </button>
      ) : (
        <Link
          to={menuItem.ref} // Use menuItem.ref instead of menuItem.path
          className={`w-full flex text-white items-center p-2 rounded-lg text-gray-700 hover:bg-primary-opacity hover:text-green-200 ${
            activeTab === menuItem.label ? "bg-gray-200 " : ""
          }`}
        >
          {menuItem.icon}
          <span className="ml-3">{menuItem.label}</span>
        </Link>
      )}
    </li>
  ))}
</ul>
    </div>
  );
};

export default Sidebar;

