import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllOrders, updateOrderItemStatus } from "../../actions/orderAction";
import OrderDetails from "./OrderDetails";
import LoadingPage from "../LoadingPage";
import MetaData from "../Layouts/MetaData";
import { SignalCellularNull } from "@mui/icons-material";

const Orders = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const dispatch = useDispatch();
  const [selectedShipmentId, setSelectedShipmentId] = useState(null);
  const [filteredOrdersList, setFilteredOrdersList] = useState([]);

  const [activeTab, setActiveTab] = useState(0); // Default to "All"
  const [value, setValue] = useState(0);

  const { orders, loading, error } = useSelector((state) => state.allOrders);
  const { user } = useSelector((state) => state.user);
  const store_id = user ? user.id : null;

  const tabs = [
    { name: "New" },
    { name: "Forwarded" },
    { name: "Accepted" },    
    { name: "Dispatched" },
    { name: "Delivered" },
    { name: "Cancelled" },
    { name: "Return" },
    { name: "Failed to Deliver" },
  ];

  const currentStatus = tabs[activeTab].name;

  console.log("current status:", currentStatus);

  useEffect(() => {
    if (store_id) {
      dispatch(getAllOrders(store_id));
    }
  }, [dispatch, store_id]);

  useEffect(() => {
    setFilteredOrdersList(filteredOrders());
  }, [orders, activeTab, selectedShipmentId]);

  const handleBackToOrders = () => {
    setSelectedOrder(null); // Reset the selected order
    setSelectedShipmentId(null); // Reset the selected shipment ID
    dispatch(getAllOrders(store_id)); // Re-fetch orders
  };

  const handleOrderItemClick = (order, shipmentId) => {
    setSelectedOrder(order);
    setSelectedShipmentId(shipmentId); // Set the selected shipment ID
  };

  const filterOrders = (status) => {
    return orders.filter((order) =>
      order.order_items.some((item) => item.status === status)
    );
  };

  // Function to filter orders by shipment ID
  const filterOrdersByShipmentId = (shipmentId) => {
    return orders.filter((order) =>
      order.shipments && order.shipments.some((shipment) => shipment.shipment_id === shipmentId)
  );
  };

  // Example usage of filtering logic
  const filteredOrders = () => {
    // Check if a shipment ID is selected
    if (selectedShipmentId) {
      return filterOrdersByShipmentId(selectedShipmentId);
    }
  
    // If no shipment ID is selected, filter based on the current tab
    const currentStatus = tabs[value].name;
  
    // If the current tab is "New", filter for orders with item status "N"
    return value === 0 ? filterOrders('New') : filterOrders(currentStatus);
};

  // Call filteredOrders to get the orders to render
  const ordersToRender = filteredOrders();

  return (
    <>
  <MetaData title="Orders | Vivimart" />
  <div className="border border-gray-300 w-full min-h-[33rem] max-h-full font-sans rounded-md bg-gray-100">
    <div className="border-b border-gray-300 p-2 pl-4 flex bg-white justify-between">
      <h1 className="text-gray-700 font-sans">
        {selectedOrder ? "Order Details" : "Orders"}
      </h1>
    </div>
    {!selectedOrder ? (
      <div className="flex overflow-x-auto border-b border-gray-300 bg-white p-4 justify-center">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-4 py-2 ${
              activeTab === index
                ? "text-blue-500 border-b-2 border-blue-500 font-bold"
                : "text-gray-600"
            }`}
            onClick={() => {
              setActiveTab(index); // Set the active tab
              setValue(index); // Update the value using the selected tab's value
            }}
          >
            {tab.name}
          </button>
        ))}
      </div>
    ) : null}
    
    {!selectedOrder ? (
      <div>
        {loading ? (
          <LoadingPage />
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          ordersToRender.map((order) =>
            order.shipments && order.shipments.length > 0 ? (
              order.shipments.map((shipment) => (
                  <div key={shipment.shipment_id} className="px-10 mb-4 mt-4">
                    <div
                      className="pl-4 pt-4 py-4 flex rounded-lg relative gap-8 cursor-pointer bg-white"
                      style={{ boxShadow: "0 3px 6px #00000080" }}
                      onClick={() =>
                        handleOrderItemClick(order, shipment.shipment_id)
                      }
                    >
                      <div className="relative h-20 w-28 mt-8">
                        <img
                          src={order.order_items[0].product_image_0}
                          alt={order.order_items[0].Product_name}
                        />
                        {shipment.items.length > 1 && (
                          <div className="absolute bottom-0 right-0 bg-gray-800 text-white text-xs px-2 py-1 rounded-full">
                            +{shipment.items.length - 1}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col w-full">
                        <div className="text-gray-600 text-sm font-medium">
                          <div className="mb-2">ORDER ID: {order.order_id}</div>
                          <div className="mb-3">
                            {new Date(order.order_date).toLocaleDateString()}
                          </div>
                        </div>
                        <p className="text-gray-600 text-sm">
                          Address: {order.address}
                        </p>
                        <p className="text-gray-600 text-sm">
                          Phone: {order.phone_number}
                        </p>
                        <p className="text-gray-600 text-sm">
                          Delivery Notes: {order.delivery_notes}
                        </p>

                        <p className="text-gray-600 text-sm">
                          Shipment ID: {shipment.shipment_id}
                        </p>
    
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div key={order.order_id} className="px-10 mb-4 mt-4">
                <div
                  className="pl-4 pt-4 py-4 flex rounded-lg relative gap-8 cursor-pointer bg-white"
                  style={{ boxShadow: "0 3px 6px #00000080" }}
                  onClick={() => handleOrderItemClick(order)} // Handle click for orders without shipments
                >
                  <div className="relative h-20 w-28">
                    <img
                      src={order.order_items[0]?.product_image_0 || ""}
                      alt={order.order_items[0]?.Product_name || "Product"}
                    />
                    {order.order_items.length > 1 && (
                      <div className="absolute bottom-0 right-0 bg-gray-800 text-white text-xs px-2 py-1 rounded-full">
                        +{order.order_items.length - 1}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="text-gray-600 text-sm font-medium">
                      <div className="mb-2">ORDER ID: {order.order_id}</div>
                      <div className="mb-3">
                        {new Date(order.order_date).toLocaleDateString()}
                      </div>
                    </div>
                    <p className="text-gray-600 text-sm">Address: {order.address}</p>
                    <p className="text-gray-600 text-sm">Phone: {order.phone_number}</p>
                    <p className="text-gray-600 text-sm">
                      Delivery Notes: {order.delivery_notes}
                    </p>
                  </div>
                </div>
              </div>
            )
          )
        )}
      </div>
    ) : (
      <OrderDetails
        order={selectedOrder}
        status={currentStatus}
        shipmentId={selectedShipmentId}
        onBack={handleBackToOrders} // Pass back function to OrderDetails
      />
    )}
  </div>
</>
  );
};

export default Orders;
