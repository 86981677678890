import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryName, getSubCategories } from "../../actions/categoryAction";
import { ADMIN_SUB_CATEGORY_API } from "../../utils/constants";
import { useParams, Link } from "react-router-dom";

const AllCategories = () => {
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const [subCategory, setSubCategory] = useState([]);

  console.log("Received category id:", categoryId)

  const { categoryNames } = useSelector((state) => state.categoryNames);

  useEffect(() =>{
    if(categoryId) {
      dispatch(getCategoryName(categoryId))
    }
  }, [dispatch, categoryId])


  useEffect(() => {
    const fetchSubCategoryData = async () => {
      try {
        const response = await fetch(
          `${ADMIN_SUB_CATEGORY_API}/category/${categoryNames.categoryName}`
        );
        const data = await response.json();
        console.log("Fetched product data:", data); // Debugging line
        setSubCategory(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchSubCategoryData();
  }, [categoryNames.categoryName]);

  console.log("sub category data:", subCategory);

  return (
    <div className="mt-32 mx-20">
        <div>
          <div className="flex justify-between items-center mb-6 border-l-4 border-primary-blue">
            <h1 className="text-2xl font-semibold text-gray-900 ml-2">
              Explore By {categoryNames.categoryName}
            </h1>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-6 w-full h-full mt-4 ml-10 mr-20">
            {subCategory.map((item, index) => (
              <div key={item.sub_category_id}>
                <Link
                to={`/products?sub_category_id=${item.sub_category_id}`}
                key={item.sub_category_id}
              >
                <div className="items-center">
                  <div className="flex justify-center items-center">
                    <img
                      src={item.sub_category_img}
                      alt={item.sub_category_name}
                      className="w-full h-full object-cover rounded-lg bg-black mb-2 items-center"
                      onError={(e) => (e.target.src = "https://ik.imagekit.io/efsdltq0e/icons/No_img.png?updatedAt=1727376099723")}
                    />
                  </div>                  
                  <p className="text-black text-[18px] text-bold text-center leading-6">{item.sub_category_name}</p>
                </div>
                </Link>
                {(index + 1) % 5 === 0 && <br />} {/* add a line break every 5 items */}
              </div>
            ))}
          </div>
        </div>
    </div>
  );
};

export default AllCategories;