import { useEffect, useState } from 'react';
import MinCategory from '../Layouts/MinCategory';
import MetaData from '../Layouts/MetaData';
import { Link } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import DeliveryEdit from '../Cart/DeliveryEdit';
import homeImage from "../../assets/images/house.png"
import officeImage from "../../assets/images/office.png"
import otherImage from "../../assets/images/other.png"

const MyAddress = () => {
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [proceed, setProceed] = useState(false);
  const [addressDetails, setAddressDetails] = useState([]);

  useEffect(() => {
    const addresses = JSON.parse(localStorage.getItem("addressDetails")) || [];
    console.log("Address details:", addresses);
    setAddressDetails(addresses);
  }, []);

  useEffect(() => {
    const phoneNumber = localStorage.getItem("userPhoneNumber");
    if (phoneNumber) {
      setUserPhoneNumber(phoneNumber);
    }
  }, []);

  const handleLogout = () => {
    // dispatch(logoutUser());
    // navigate("/login");
    // enqueueSnackbar("Logout Successfully", { variant: "success" });
    localStorage.removeItem("userPhoneNumber");
    window.location.href = "/";
  };

  const addAddress = () => {
    setIsEdit(false);
    setIsOpen(true);
  };

  const handleEdit = (index) => {
    handleSelectAddress(index);
    setIsEdit(true);
    setEditingIndex(index);
    setIsOpen(true); // Open the DeliveryEdit component
  };

  //select address for delivery
  const handleSelectAddress = (index) => {
    const address = addressDetails[index]; // Get the address object
    setSelectedAddress(address); // Update the state with the address object
    setProceed(false); // Close the address modal or sidebar
  };


  const refreshAddressDetails = () => {
    const addresses = JSON.parse(localStorage.getItem("addressDetails")) || [];
    setAddressDetails(addresses);
  };

    // Function to get image URL based on selectResident value
    const getImageUrl = (selectResident) => {
      switch (selectResident) {
        case 'Home':
          return homeImage; // Replace with your home image URL
        case 'Office':
          return officeImage; // Replace with your office image URL
        case 'Other':
          return otherImage; // Replace with your other image URL
        default:
          return 'https://via.placeholder.com/150'; // Fallback image
      }
    };

  return (
    <>
      <MetaData title="My Addresses | Vivimart" />

      <main className="w-full h-full sm:mt-0">
        {/* <!-- row --> */}
        <div className="flex mb-7">
          <div className="w-full mx-6 lg:mx-10 mt-10">
          <div className="flex fixed z-40 w-full top-0 left-0 border-b p-4 bg-white lg:hidden">
              <div className="mr-4">
                <Link to="/account">
                  <ArrowBackIosNewIcon sx={{ fontSize: 25 }} />
                </Link>
              </div>
              <div>
                <div className="text-[20px] font-semibold lg:mb-4">My Addresses</div>
              </div>
            </div>
            <div className="flex items-center text-green-600 mt-4 mb-6">
              <div
                className="flex items-center gap-4 bg-white cursor-pointer"
                onClick={() => addAddress()}
              >
                <div>
                  <AddIcon />
                </div>
                <div>
                  <span className="text-sm">Add a new address</span>
                </div>
              </div>
            </div>

            <div className="bg-white lg:shadow lg:rounded-md">
              {addressDetails.length > 0 ? (
                addressDetails.map((address, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-4 border-b border-gray-200 cursor-pointer"
                    onClick={() => handleSelectAddress(index)}
                  >
                    <div className="flex items-center">
                      <div className="w-12 h-12 bg-gray-100 flex items-center justify-center rounded-md">
                      <img
                          src={getImageUrl(address.selectResident)}
                          alt={address.selectResident}
                          className="w-full h-full object-cover rounded-md"
                        />
                      </div>
                      <div className="ml-4">
                        <h2 className="text-lg font-semibold">
                          {address.name}
                        </h2>
                        <p className="text-gray-600">
                          {address.house}, {address.floor}, {address.area},{" "}
                          {address.landmark}
                        </p>
                        <p className="text-gray-600">{address.phoneNumber}</p>
                        <p className="text-gray-600">
                          {address.selectResident}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <EditIcon
                        onClick={() => handleEdit(index)}
                        className="text-green-600"
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className='p-4'>No addresses available</div>
              )}
            </div>
          </div>
        </div>
        {/* <!-- row --> */}
      </main>
      {isOpen && (
      <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-70 py-4">
        <DeliveryEdit 
      isEdit={isEdit} 
      setIsOpen={setIsOpen} 
      editingIndex={editingIndex} 
      selectedAddress={selectedAddress} 
      onAddressSaved={refreshAddressDetails} // Pass the function here
    />
      </div>
    )}
    </>
  );
};

export default MyAddress;
