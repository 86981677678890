import {
    GET_VIRTUAL_ACCOUNT_REQUEST,
    GET_VIRTUAL_ACCOUNT_SUCCESS,
    GET_VIRTUAL_ACCOUNT_FAIL,
    CLEAR_ERRORS
} from '../constants/virtualAccountConstants';
import axiosInstance from '../utils/api'; // Adjust the import path as necessary

export const getVirtualAccountIdByStoreId = (storeId) => async (dispatch) => {
    try {
        dispatch({ type: GET_VIRTUAL_ACCOUNT_REQUEST });

        const { data } = await axiosInstance.get(`/stores/virtual-account/${storeId}`); // Adjust the endpoint as necessary

        dispatch({ type: GET_VIRTUAL_ACCOUNT_SUCCESS, payload: data.virtualAccountId });
    } catch (error) {
        dispatch({
            type: GET_VIRTUAL_ACCOUNT_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};