// ComingSoon.js
import React from "react";
import { useNavigate } from "react-router-dom"; // Step 1: Import useNavigate

const ComingSoon = ({ featureName }) => {
  const navigate = useNavigate(); // Step 1: Initialize useNavigate

  const handleHomeClick = () => {
    navigate('/'); // Step 2: Navigate to the home route
  };

  return (
    <div className="flex flex-col items-center justify-center h-full p-4">
      <img
        src="https://ik.imagekit.io/efsdltq0e/Logo/Logo%202.png?updatedAt=1726132810913"
        alt="Coming Soon"
        className="mb-6 w-32 h-32"
      />
      <h1 className="text-5xl font-bold text-gray-800">Coming Soon!</h1>
      <p className="mt-4 text-lg text-gray-600">
        {featureName} is on its way. Stay tuned!
      </p>
      <button 
        className="bg-primary-blue text-sm text-white px-12 py-2 rounded-lg shadow mt-3"
        onClick={handleHomeClick} // Step 3: Attach the click handler
      >
        Home
      </button>
    </div>
  );
};

export default ComingSoon;