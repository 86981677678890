import { useEffect, useState } from "react"; 
import Sidebar from "./Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Layouts/Loader";
import MetaData from "../Layouts/MetaData";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import { Tooltip, tooltipClasses } from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddBoxIcon from '@mui/icons-material/AddBox';

const BankInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [holderName, setHolderName] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
    // Load existing bank details from localStorage
    const bankDetails = JSON.parse(localStorage.getItem("bankDetails"));
    if (bankDetails) {
      setHolderName(bankDetails.holderName);
      setBankName(bankDetails.bankName);
      setBranchName(bankDetails.branchName);
      setAccountNumber(bankDetails.accountNumber);
    }
  }, [isAuthenticated, navigate]);

  const handleSave = () => {
    const bankDetails = {
      holderName,
      bankName,
      branchName,
      accountNumber,
    };
    localStorage.setItem("bankDetails", JSON.stringify(bankDetails));
    setIsModalOpen(false);
  };

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleAddClick = () => {
    // Clear the form fields for adding new bank details
    setHolderName("");
    setBankName("");
    setBranchName("");
    setAccountNumber("");
    setIsModalOpen(true);
  };

  // Check if bank details exist
  const bankDetailsExist = !!localStorage.getItem("bankDetails");

  return (
    <>
      <MetaData title="My Profile" />

      {loading ? (
        <Loader />
      ) : (
        <>
          <main className="w-full mt-12 sm:mt-0">
            <div className="flex flex-col gap-3.5 sm:w-11/12 sm:mt-4 m-auto mb-7">
              <div className="flex-1 overflow-hidden shadow-lg rounded-lg bg-white">
                <div className="p-6">
                  <div className="flex items-center mb-4">
                    <AccountBalanceIcon />
                    <h1 className="text-xl font-semibold text-black ml-2">
                      Account details
                    </h1>
                    <Tooltip
                      title="Update your Bank details with correct information. It will be used for add funds to your Wallet"
                      arrow
                      placement="right"
                      slotProps={{
                        popper: {
                          sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
                              marginTop: "0px",
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
                              marginBottom: "0px",
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
                              marginLeft: "0px",
                            },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
                              marginRight: "0px",
                            },
                          },
                        },
                      }}
                    >
                      <span className="px-1">
                        <InfoIcon style={{ fontSize: "18px" }} />
                      </span>
                    </Tooltip>
                  </div>
                  <div className="bg-[url('https://ik.imagekit.io/efsdltq0e/icons/SL-121021-47240-13.jpg?updatedAt=1731138026764')] bg-cover bg-center shadow-md rounded-lg py-28 px-10 flex items-center justify-between">
                    <div>
                      <p className="text-white text-[24px] font-semibold mb-4">
                        <i className="fas fa-user mr-2 text-[24px]">
                          <PersonIcon />
                        </i>
                        Holder Name:{" "}
                        <span className="font-semibold text-[24px]">{holderName}</span>
                      </p>
                      <p className="text-white font-semibold text-[20px] mt-2">
                        Bank Name:{" "}
                        <span className="font-semibold text-[20px]">{bankName}</span>
                      </p>
                      <p className="text-white font-semibold mt-2 text-[20px]">
                        Branch Name:{" "}
                        <span className="font-semibold text-[20px]">{branchName}</span>
 </p>
                      <p className="text-white font-semibold mt-2 text-[20px]">
                        Account Number:{" "}
                        <span className="font-semibold text-[20px]">{accountNumber}</span>
                      </p>
                    </div>
                    <div className="relative">
                      {bankDetailsExist ? (
                        <button 
                          className="bg-green-500 text-white px-4 py-2 rounded-lg font-semibold shadow-md hover:bg-green-600"
                          onClick={handleEditClick}
                        >
                          Edit <EditNoteIcon />
                        </button>
                      ) : (
                        <button 
                          className="bg-blue-500 text-white px-4 py-2 rounded-lg font-semibold shadow-md hover:bg-blue-600"
                          onClick={handleAddClick}
                        >
                          Add <AddBoxIcon />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-11/12 sm:w-1/3">
                <h2 className="text-xl font-semibold mb-4">Edit Bank Details</h2>
                <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                  <div className="mb-4">
                    <label className="block text-gray-700">Holder Name</label>
                    <input 
                      type="text" 
                      value={holderName} 
                      onChange={(e) => setHolderName(e.target.value)} 
                      className="border rounded w-full py-2 px-3"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Bank Name</label>
                    <input 
                      type="text" 
                      value={bankName} 
                      onChange={(e) => setBankName(e.target.value)} 
                      className="border rounded w-full py-2 px-3"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Branch Name</label>
                    <input 
                      type="text" 
                      value={branchName} 
                      onChange={(e) => setBranchName(e.target.value)} 
                      className="border rounded w-full py-2 px-3"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Account Number</label>
                    <input 
                      type="text" 
                      value={accountNumber} 
                      onChange={(e) => setAccountNumber(e.target.value)} 
                      className="border rounded w-full py-2 px-3"
                      required
                    />
                  </div>
                  <div className="flex justify-end">
                    <button 
                      type="button" 
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button 
                      type="submit" 
                      className="bg-green-500 text-white px-4 py-2 rounded-lg"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BankInfo;