import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { getStores, updateStore } from '../../actions/storeAction'; // Import your action to update store

const EditStoreModal = ({ open, handleClose, storeData }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        owner_name: '',
        owner_photo: '',
        password: '',
        state: '',
        city_name: '',
        primary_location: '',
        sec_location_1: '',
        sec_location_2: '',
        sec_location_3: '',
        sec_location_4: '',
        sec_location_5: '',
        package_fee: null,
        sec_location_6: '',
        sec_location_7: '',
        sec_location_8: '',
        sec_location_9: '',
        sec_location_10: '',
        is_active: 1,
        email: '',
        phone_number: '',
    });

    useEffect(() => {
        if (storeData) {
            setFormData(storeData);
        }

        console.log("form data in editStoreModal:", storeData)
    }, [storeData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
    e.preventDefault();
    
    // Prepare data for submission
    const dataToSubmit = {
        ...formData,
        package_fee: formData.package_fee === undefined ? null : formData.package_fee,
        // Repeat for other fields if necessary
    };

    dispatch(updateStore(formData.id, dataToSubmit)) // Dispatch the update action
    .then(() => {
        handleClose(); // Close the modal after submitting
        // Optionally, dispatch getAllSubStores to refresh the data
        dispatch(getStores());
    });
};

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Store</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        name="name"
                        label="Name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        name="owner_name"
                        label="Owner Name"
                        value={formData.owner_name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        name="owner_photo"
                        label="Owner Photo URL"
                        value={formData.owner_photo}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="password"
                        label="Password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="state"
                        label="State"
                        value={formData.state}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="city_name"
                        label="City Name"
                        value={formData.city_name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="primary_location"
                        label="Primary Location"
                        value={formData.primary_location}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="email"
                        label="Email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        name="phone_number"
                        label="Phone Number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="package_fee"
                        label="Package Fee"
                        type="number"
                        value={formData.package_fee || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sec_location_1"
                        label="Secondary Location 1"
                        value={formData.sec_location_1}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sec_location_2"
                        label="Secondary Location 2"
                        value={formData.sec_location_2}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sec _location_3"
                        label="Secondary Location 3"
                        value={formData.sec_location_3}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sec_location_4"
                        label="Secondary Location 4"
                        value={formData.sec_location_4}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sec_location_5"
                        label="Secondary Location 5"
                        value={formData.sec_location_5}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sec_location_6"
                        label="Secondary Location 6"
                        value={formData.sec_location_6}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sec_location_7"
                        label="Secondary Location 7"
                        value={formData.sec_location_7}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sec_location_8"
                        label="Secondary Location 8"
                        value={formData.sec_location_8}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sec_location_9"
                        label="Secondary Location 9"
                        value={formData.sec_location_9}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="sec_location_10"
                        label="Secondary Location 10"
                        value={formData.sec_location_10}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="is_active"
                        label="Is Active"
                        type="number"
                        value={formData.is_active}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default EditStoreModal;