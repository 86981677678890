import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { searchProducts } from "../../actions/searchAction";
import Product from "../Products/Product";
import axiosInstance from "../../utils/api";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Layouts/Loader";
import CategoryProduct from "../Home/CategoryProduct";

const SearchProducts = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState(""); // Add a state for search query
  const [suggestions, setSuggestions] = useState([]); // Add a state for suggestions
  const [similarProducts, setSimilarProducts] = useState([]);
  const [productsCount, setProductsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const keyword = params.keyword ? params.keyword.toLowerCase() : "";

  const { loading, products, error } = useSelector((state) => state.search);

  useEffect(() => {
    if (keyword) {
      dispatch(searchProducts(keyword));
    }
  }, [dispatch, keyword]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery) {
      dispatch(searchProducts(searchQuery));
    }
  };

  const handleInputChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 2) {
      // Make API call to get suggestions
      const response = await fetch(`/search?q=${query}`);
      const data = await response.json();
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
  };

  const validProducts = Array.isArray(products) ? products : [];

  const subcategories = [
    ...new Set(validProducts.map((product) => product.Sub_Categories)),
  ];
  const uniqueSubcategory = subcategories.length > 0 ? subcategories[0] : "";

  console.log("sub categories:", uniqueSubcategory);

  useEffect(() => {
    if (!uniqueSubcategory) {
      return;
    }

    const fetchData = async () => {
      const res = await axiosInstance.get(
        `/products/subcategory/${uniqueSubcategory}?page=${currentPage}&limit=10`
      );
      const data = res.data;

      setProductsCount(data[0]);
      setSimilarProducts(data[1]);
      console.log("similar products data:", data);
    };

    fetchData();
  }, [uniqueSubcategory, currentPage]);

  const loadMoreProducts = () => {
    if (similarProducts.length < productsCount) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div className="flex flex-col gap-3 h-full hidden-scrollbar overflow-y-auto p-4 sm:p-6 bg-white md:p-8 lg:mt-0 mt-[14rem] lg:px-[10rem] lg:py-[7rem]">
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <span className="font-bold text-2xl">Loading...</span>
        </div>
      ) : error ? (
        <div className="flex items-center justify-center h-screen">
          <span className="font-bold text-2xl text-red-500">{error}</span>
        </div>
      ) : validProducts.length > 0 ? (
        <>
          <div>
            <span className="font-bold font-sans">
              Showing results for "{keyword}"
            </span>
          </div>
          <div className="flex flex-cols-2 h-2xl w-64 sm:flex-cols-3 md:flex-cols-4 lg:flex-cols-5 gap-3 sm:gap-4 md:gap-5">
            {validProducts.map((item) => (
              <Product key={item.Product_id} product={item} />
            ))}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <span className="font-bold text-2xl">
            No results found for "{keyword}"
          </span>
        </div>
      )}
      <div className="lg:mt-5 w-full hidden-scrollbar overflow-y-hidden">
        <div className="text-lg font-semibold">Other Similar Products</div>
        <div className="flex flex-col h-auto w-auto">
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={loadMoreProducts}
            hasMore={similarProducts.length < productsCount}
            loader={
              similarProducts.length === productsCount ? <Loader /> : null
            }
            useWindow={true}
            dataLength={similarProducts.length}
          >
            <div className="grid grid-cols-2 h-full w-full md:h-full sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 mt-2">
              {similarProducts.map((item) => (
                <CategoryProduct key={item.Product_id} product={item} />
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default SearchProducts;