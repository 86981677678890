import { useSnackbar } from "notistack";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearErrors, getProducts } from "../../actions/productAction";
import Loader from "../Layouts/Loader";
import MinCategory from "../Layouts/MinCategory";
import Product from "./Product";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  ADMIN_SUB_CATEGORY_API,
  ADMIN_SUB_SUB_CATEGORY_API,
  ADMIN_PRODUCT_API,
} from "../../utils/constants";
import MetaData from "../Layouts/MetaData";
import { useLocation } from "react-router-dom";
import {
  getCategoryName,
  getSubCategoryName,
} from "../../actions/categoryAction";
import InfiniteScroll from "react-infinite-scroll-component";
import Cart from "../Cart/Cart";

const Products = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const location = useLocation();
  const [price, setPrice] = useState([0, 200000]);
  const [category, setCategory] = useState("");
  const [ratings, setRatings] = useState(0);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subSubCategoryData, setSubSubCategoryData] = useState([]);
  const [allSubSubCategoryData, setAllSubSubCategoryData] = useState([]);
  const [data, setData] = useState([]);
  const [product, setProduct] = useState([]);
  const [infiniteScrollDataLength, setInfiniteScrollDataLength] = useState();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  // filter toggles
  const [categoryToggle, setCategoryToggle] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectItem, setSelectItem] = useState(null);
  const subCategoryId = new URLSearchParams(location.search).get(
    "sub_category_id"
  );
  console.log("Selected Category ID:", subCategoryId);
  const [expandedSubCategoryIndex, setExpandedSubCategoryIndex] = useState(-1);
  const [productsCount, setProductsCount] = useState(0);
  const [productsLength, setProductsLength] = useState(0);
  const { products, error, resultPerPage, filteredProductsCount } = useSelector(
    (state) => state.products
  );
  const keyword = params.keyword;

  const { subCategoryName } = useSelector((state) => state.subCategoryName);

  const { cartItems } = useSelector((state) => state.cart);

  console.log("category name based on selected id:", subCategoryName);

  useEffect(() => {
    if (subCategoryId) {
      dispatch(getSubCategoryName(subCategoryId));
    }
  }, [dispatch, subCategoryId]);

  console.log(
    "Fetched category name data:",
    subCategoryName.subCategoryName
  );

  useEffect(() => {
    console.log("Location search:", location.search);
    const queryParams = new URLSearchParams(location.search);
    const subCategoryId = queryParams.get("sub_category_id");
    setCategory(subCategoryId);
    console.log("Selected sub category id:", subCategoryId);
  }, [location.search]);
  
  useEffect(() => {
    setProduct([]);
    setProductsLength(0);
    setProductsCount(0);
    setCurrentPage(1);
  }, [subCategoryName.subCategoryName]);

  useEffect(() => {
    if (!subCategoryName || !subCategoryName.subCategoryName) return;
  
    const fetchProductData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${ADMIN_PRODUCT_API}/subcategory/${subCategoryName.subCategoryName}?page=${currentPage}&limit=30`
        );
    
        if (!response.ok) {
          throw new Error(`Failed to fetch products: ${response.statusText}`);
        }
    
        const productData = await response.json();
        console.log("Product data in fetch product data:", productData);
    
        const totalCount = productData[0];
        const newProducts = productData[1];
    
        // Filter products where hide = 1
        const filteredProducts = newProducts.filter(product => product.hide === 1);
    
        setProductsCount(totalCount);
        console.log("total Product count:", totalCount);
    
        if (currentPage === 1) {
          setProduct(filteredProducts); // Replace products for the first page
        } else {
          setProduct(prevProducts => [...prevProducts, ...filteredProducts]); // Append for subsequent pages
        }
    
        setProductsLength(prevLength => prevLength + filteredProducts.length);
        console.log("Total Products Length:", filteredProducts.length);
    
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    
  
    fetchProductData();
  }, [subCategoryName.subCategoryName, currentPage, subCategoryId]);

  useEffect(() => {
    if (!subCategoryName || !subCategoryName.subCategoryName) return;

    const fetchSubSubCategoryData = async () => {
      try {
        const response = await fetch(
          `${ADMIN_SUB_SUB_CATEGORY_API}/subCategory/${subCategoryName.subCategoryName}`
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch sub-sub-categories: ${response.statusText}`
          );
        }

        const subSubCategoryData = await response.json();
        setSubSubCategoryData(subSubCategoryData);
        console.log(
          "Sub sub category by sub category name:",
          subSubCategoryData
        );
      } catch (error) {
        console.error("Error fetching sub-sub categories:", error);
      }
    };

    fetchSubSubCategoryData();
  }, [subCategoryName.subCategoryName]);

  console.log("Products data:", product);

  // Infinite scrolling logic
  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled near the bottom of the page
      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 500 &&
        !loading
      ) {
        setCurrentPage((prevPage) => prevPage + 1); // Load next page
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup listener
  }, [loading]);

  const handleProductClick = async (index, name) => {
    setExpandedSubCategoryIndex(
      index === expandedSubCategoryIndex ? -1 : index
    );
    setSelectedItem(index === selectedItem ? null : index);

    try {
      setProduct([]); // Reset the product state to an empty array
      const response = await fetch(
        `${ADMIN_PRODUCT_API}/subsubcategory/${name}`
      );
      const productData = await response.json();
      setProduct(productData);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getProducts(keyword, category, price, ratings, currentPage));
  }, [
    dispatch,
    keyword,
    category,
    price,
    ratings,
    currentPage,
    error,
    enqueueSnackbar,
  ]);

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [sortOption, setSortOption] = useState("relevance");

  const normalizeBrandName = (brandName) => (brandName ? brandName.trim().toLowerCase() : "");

  const uniqueBrandNames = Array.from(
    new Set(product.map((item) => normalizeBrandName(item.Brand_name)))
  );

  const productOptions = uniqueBrandNames.map((brandName) => ({
    value: brandName,
    label: brandName.charAt(0).toUpperCase() + brandName.slice(1),
  }));

  const options = [    
    { value: "relevance", label: "Relevance" },
    { value: "priceAsc", label: "Price (Low to High)" },
    { value: "priceDesc", label: "Price (High to Low)" },
    { value: "discountHigh", label: "Discount (High to Low)" },
    { value: "nameAZ", label: "Name (A to Z)" },
  ];

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setSortOption(value);
    setIsOpen(false);
  
    let sortedProducts = [...product];
  
    switch (value) {
      case "priceAsc":
        sortedProducts.sort((a, b) => a.sell_price - b.sell_price);
        break;
      case "priceDesc":
        sortedProducts.sort((a, b) => b.sell_price - a.sell_price);
        break;
      case "discountHigh":
        sortedProducts.sort((a, b) => b.offer - a.offer);
        break;
      case "nameAZ":
        sortedProducts.sort((a, b) => a.Product_name.localeCompare(b.Product_name));
        break;
      case "relevance":
      default:
        // Default sorting logic
        break;
    }
  
    setProduct(sortedProducts);
  };

  const parentRef = useRef(null);
  const childRef = useRef(null);

  //Infinit scroll

  const loadMoreProducts = () => {
    if (hasMoreProducts()) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  
  console.log("check current page:", currentPage)

  const hasMoreProducts = () => {
    return productsLength < productsCount;
  };

  const closeCartSidebar = () => {
    setIsCartOpen(false);
    document.body.style.overflow = "auto"; // Enable scrolling
  };

  return (
    <>
      <MetaData title="All Products | Vivimart" />

      <main className="w-full mt-48 lg:mt-24">
        <div
          className="flex lg:h-auto lg:mx-28 overflow-hidden"
          ref={parentRef}
        >
          {/* <!-- sidebar column  --> */}

          {
                    subSubCategoryData.length > 0 && (

                      <div className="flex flex-col w-[16%] lg:w-[16%] md:w-46 pr-1 mt-6">
            {/* <!-- nav tiles --> */}
            <div
              className="flex flex-col rounded-lg lg:h-[29
            rem]"
            >
              <div className="flex flex-col text-sm overflow-hidden">
                {/* Desktop view */}
                <div className="flex flex-col mt-[19rem] lg:mt-2 hidden lg:block">
                  <div
                    className="flex justify-between cursor-pointer mb-6 ml-16 items-center"
                    onClick={() => {
                      setCategoryToggle(!categoryToggle);
                      // window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <p className="font-medium text-lg uppercase">Category</p>
                    {/* {categoryToggle ? (
                      <ExpandLessIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                    )} */}
                  </div>

                  

                  <div className="flex flex-col pb-1 w-2xl max-h-full hidden-scrollbar">
                    {subSubCategoryData
                    .filter((item) => item.is_active === 1)
                    .map((subsubCategory, subsubIndex) => (
                      <div
                        key={subsubIndex}
                        onClick={() => {
                          handleProductClick(
                            subsubIndex,
                            subsubCategory.sub_sub_category_name
                          );
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        className="py-[5px] flex flex-col w-full md:flex-row justify-between items-center gap-6 cursor-pointer bg-white"
                      >
                        <div className="flex flex-col p-2 hover:bg-blue-100 hover:border-l-4 border-blue-700 md:flex-row items-center">
                          <div className="h-[3.7rem] w-[3.7rem]">
                            <img
                              draggable="false"
                              className="h-full w-full object-contain rounded-full"
                              src={subsubCategory.sub_sub_category_img}
                              alt={subsubCategory.sub_sub_category_name}
                            />
                          </div>
                          <div
                            className="ml-4 w-[7rem]"
                            title={subsubCategory.sub_sub_category_name}
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "normal", // Allow text to wrap
                              maxHeight: "3rem", // Adjust based on your line height and desired number of lines
                              lineHeight: "1.5rem", // Set line height (adjust as necessary)
                            }}
                          >
                            <span className="mt-2 text-custom-gray text-[15px] font-medium">
                              {subsubCategory.sub_sub_category_name}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* mobile view */}
                <div className="flex flex-col h-auto w-full md:mt-[0rem] lg:hidden">
                  <div className="max-h-full hidden-scrollbar">
                    {subSubCategoryData
                    .filter((item) => item.is_active === 1)
                    .map((subsubCategory, subsubIndex) => (
                      <div
                        key={subsubIndex}
                        onClick={() => {
                          handleProductClick(
                            subsubIndex,
                            subsubCategory.sub_sub_category_name
                          );
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        className="py-[10px] w-full flex flex-col items-center cursor-pointer hover:bg-[#EBFFEF] bg-white"
                      >
                        <div className="flex flex-col mx-8 text-center items-center">
                          <div className="h-[3.5rem] w-[3.5rem]">
                            <img
                              draggable="false"
                              className="h-[3rem] w-[3rem] object-contain rounded-full"
                              src={subsubCategory.sub_sub_category_img}
                              alt={subsubCategory.sub_sub_category_name}
                            />
                          </div>
                          <div
                            className="w-full text-center break-words"
                            title={subsubCategory.sub_sub_category_name}
                          >
                            <span
                              className={`text-custom-gray text-[10px] font-medium max-w-[6rem]`}
                            >
                              {subsubCategory.sub_sub_category_name}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
                      
                    )
                  }
          
          <div className="flex-1 w-[120px] lg:w-full mt-6 hidden-scrollbar flex-grow">
            
                <div className="flex items-center justify-between mb-2 text-gray-900 p-2 font-semibold text-[13px] shadow-[0px 0px 10px 0px rgba(0, 0, 0, 0.07)] w-full hidden lg:flex">
                  <div className="flex items-center">
                    <span className="font-sans text-lg">
                      Buy {subCategoryName.subCategoryName} Online
                    </span>
                  </div>
                  <div className="lg:ml-auto lg:pr-3 flex flex-cols gap-2 justify-center text-center">
                    <span className="font-sans text-sm mt-2">Sort by</span>
                    <div className="">
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="p-2 border rounded-lg bg-white w-[11rem] focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-between"
                      >
                        <span className="text-center truncate">
                          {options.find((option) => option.value === sortOption)
                            ?.label || "Select an option"}
                        </span>
                        <svg
                          className="ml-2 fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M7 10l5 5 5-5H7z" />
                        </svg>
                      </button>

                      {isOpen && (
                        <ul className="absolute w-[11rem] rounded-lg border bg-white shadow-md z-10">
                          {options.map((option) => (
                            <li
                              key={option.value}
                              onClick={() => handleOptionClick(option.value)}
                              className="px-4 py-2 text-start hover:bg-blue-50 cursor-pointer"
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="w-full h-auto md:w-auto lg:w-auto overflow-y-auto hidden-scrollbar lg:mt-3 lg:mx-4"
                  ref={childRef}
                >
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={loadMoreProducts}
                    hasMore={productsLength < productsCount}
                    loader={
                      productsLength === productsCount ? <Loader /> : null
                    }
                    useWindow={true}
                    dataLength={product.length}
                  >
                    <div className="grid grid-cols-2 gap-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 lg:gap-2 m-2">
                      {product.length > 0 ? (
                        product.map((item) => (
                          <Product
                            key={item.Product_id}
                            id={item.Product_id}
                            product={item}
                          />
                        ))
                      ) : (
                        <div className="flex items-center justify-center">
                          <div>No products found</div>
                        </div>
                      )}
                    </div>
                  </InfiniteScroll>
                </div>            
          </div>
          {cartItems.length > 0 && !isCartOpen && (
            <div className="fixed left-3 right-3 flex z-[10001] bg-[#0c831f] bottom-3 text-white rounded-lg p-3.5 pl-3 pr-4 mx-auto justify-between transition-transform duration-300 transform translate-y-0 opacity-100 lg:hidden">
              <div className="flex justify-between gap-3">
                <ShoppingCartIcon />
                <div>
                  {cartItems.length} item{cartItems.length !== 1 && "s"}
                </div>
              </div>
              <div
                onClick={() => setIsCartOpen(true)}
                className="flex justify-center items-center font-sans text-[17px] leading-[22px]"
              >
                View Cart
                <ArrowRightIcon />
              </div>
            </div>
          )}
          {isCartOpen && (
            <div className="fixed inset-0 z-40 flex">
              <div
                className="fixed inset-0 opacity-50 backdrop-blur-sm"
                onClick={closeCartSidebar}
              ></div>
              <div className="ml-auto">
                <Cart
                  closeCartSidebar={closeCartSidebar}
                  setIsEdit={setIsEdit}
                  setIsOpen={setIsOpen}
                />
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default Products;