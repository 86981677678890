import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalletDetails } from "../../actions/walletAction";
import {
  clearErrors,
  getVirtualAccountIdByStoreId,
} from "../../actions/virtualAccountActions";
import { getAllOrders } from "../../actions/orderAction";
import {
  disableStore,
  enableStore,
  getStoreById,
} from "../../actions/storeAction";
import { addHoldings, debitHoldings } from "../../actions/transactionAction";
import { useSnackbar } from "notistack";
import { updateHoldings } from "../../actions/transactionAction";

const StoreOverview = ({ storeId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { store } = useSelector((state) => state.store);
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.allOrders);
  const wallet = useSelector((state) => state.wallet);
  const [isDebitPopupOpen, setIsDebitPopupOpen] = useState(false);
  const [debitAmount, setDebitAmount] = useState("");
  const [note, setNote] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const { virtualAccountId, error } = useSelector(
    (state) => state.virtualAccount
  );
  const [totalRoyalty, setTotalRoyalty] = useState(0);

  useEffect(() => {
    dispatch(getStoreById(storeId));
  }, [dispatch, storeId]);

  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setAmount(value);
  };

  const handleSubmit = () => {
    if (!amount || isNaN(amount)) {
        enqueueSnackbar("Please enter a valid amount", { variant: "error" });
        return;
    }

    const data = { id: storeId, amount: parseFloat(amount) };
    console.log(data);

    dispatch(addHoldings(data)).then(() => {
        // Refetch store data after adding funds
        dispatch(getStoreById(storeId));
    });

    enqueueSnackbar("Funds added successfully", { variant: "success" });
    setAmount("");
    handleClosePopup();
};

  const toggleActiveHandler = (id, isActive) => {
    if (isActive) {
      dispatch(enableStore(id));
      console.log("Store enabled");
    } else {
      dispatch(disableStore(id));
      console.log("Store disabled");
    }

    // Refetch the store data after enabling/disabling
    dispatch(getStoreById(storeId));
  };

  // Ensure store is defined before accessing its properties
  const isActive = store ? store.is_active : false; // Assuming is_active is a boolean
  const id = store ? store.id : null; // Assuming id is a string

  useEffect(() => {
    if (storeId) {
      dispatch(getAllOrders(storeId));
    }
  }, [dispatch, storeId]);

  useEffect(() => {
    if (storeId) {
      dispatch(getVirtualAccountIdByStoreId(storeId));
    }

    // Clear errors when the component unmounts
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch, storeId]);

  // Log the virtual account ID and any errors
  useEffect(() => {
    if (error) {
      console.error("Error fetching virtual account ID:", error);
    }
    if (virtualAccountId) {
      dispatch(getWalletDetails(virtualAccountId)); // Fetch wallet details using the virtual account ID
    }
  }, [dispatch, virtualAccountId, error]);

  const getOrderCounts = (status) => {
    return orders.filter((order) => order.status === status).length;
  };

  useEffect(() => {
    dispatch(getWalletDetails(virtualAccountId)); // Pass the virtual account ID to the action
  }, [dispatch, virtualAccountId]);

  const getOrderItemCountsByShipmentStatus = (status) => {
    let count = 0;

    // Iterate through each order
    orders.forEach((order) => {
      // Iterate through each shipment in the order
      order.shipments.forEach((shipment) => {
        // Check the order items in the shipment
        shipment.items.forEach((item) => {
          // Find the corresponding order item based on product_id
          const orderItem = order.order_items.find(
            (orderItem) => orderItem.product_id === item.product_id
          );

          // Log the found order item
          if (orderItem) {
            // If the order item exists and its status matches the specified status, increment the count
            if (orderItem.status === status) {
              count++;
            }
          } else {
            console.log(
              `No matching order item found for product ID: ${item.product_id}`
            );
          }
        });
      });
    });
    return count;
  };

  const calculateTotalAcceptedOrderItemsAmount = () => {
    return orders.reduce((total, order) => {
      // Filter order_items with status "Accepted"
      const acceptedItems = order.order_items.filter(
        (item) => item.status === "Accepted"
      );

      // Sum the total value of accepted items (sell_price * quantity)
      const acceptedItemsTotal = acceptedItems.reduce((itemTotal, item) => {
        const itemValue =
          (parseFloat(item.sell_price) || 0) * (item.quantity || 0); // Multiply sell_price by quantity
        return itemTotal + itemValue; // Add to the total for this order
      }, 0);

      // Add to the overall total
      return total + acceptedItemsTotal;
    }, 0);
  };
  // Function to aggregate products
  const aggregateProducts = (orders) => {
    const productMap = {};

    orders.forEach((order) => {
      order.order_items.forEach((item) => {
        const productId = item.product_id;
        const productName = item.Product_name;
        const productImage = item.product_image_0; // Assuming this is the image URL
        const quantity = item.quantity;

        if (productMap[productId]) {
          productMap[productId].quantity += quantity; // Sum the quantities
        } else {
          productMap[productId] = {
            name: productName,
            image: productImage,
            quantity: quantity,
          };
        }
      });
    });

    return Object.values(productMap); // Return an array of aggregated products
  };

  const aggregatedProducts = aggregateProducts(orders);

  // Sort the aggregated products by quantity in descending order and get the top 4
  const topProducts = aggregatedProducts
    .sort((a, b) => b.quantity - a.quantity)
    .slice(0, 4);

  const calculateTotalAcceptedOrderItemsAmountForCOD = () => {
    return orders.reduce((total, order) => {
      // Check if the payment method is "COD"
      if (order.payment_method === "COD") {
        // Filter order_items with status "Accepted"
        const acceptedItems = order.order_items.filter(
          (item) => item.status === "Accepted"
        );

        // Sum the total value of accepted items (sell_price * quantity)
        const acceptedItemsTotal = acceptedItems.reduce((itemTotal, item) => {
          const itemValue =
            (parseFloat(item.sell_price) || 0) * (item.quantity || 0); // Multiply sell_price by quantity
          return itemTotal + itemValue; // Add to the total for this order
        }, 0);

        // Add to the overall total
        return total + acceptedItemsTotal;
      }
      return total; // If payment method is not "COD", return the total as is
    }, 0);
  };

  const handleDebit = () => {
    setIsDebitPopupOpen(true); // Open the debit popup
  };

  const handleConfirmDebit = async () => {
    if (!debitAmount || !note) {
        enqueueSnackbar("Please fill in all fields", { variant: "warning" });
        return;
    }

    const amountToDebit = parseFloat(debitAmount);
    if (isNaN(amountToDebit) || amountToDebit <= 0) {
        enqueueSnackbar("Please enter a valid amount", { variant: "warning" });
        return;
    }

    console.log("Debiting amount:", amountToDebit, "with transaction type:", note);

    try {
        await dispatch(debitHoldings({
            id: store.id,
            amount: amountToDebit,
            note: note,
        })).then(() => {
            // Refetch store data after debiting
            dispatch(getStoreById(storeId));
        });

        // Close the popup and reset the input fields
        setIsDebitPopupOpen(false);
        setDebitAmount('');
        setNote('');
        enqueueSnackbar("Holdings updated successfully", { variant: "success" });
    } catch (error) {
        console.error("Error updating holdings:", error);
        enqueueSnackbar("Failed to update holdings", { variant: "error" });
    }
};

// Ensure store is defined before accessing its properties
const holdings = store?.holdings || 0; // Fallback to 0 if store is null
const royalty = store?.royalty || 0; // Fallback to 0 if store is null
const withdrawLimit = store?.withdraw_limit || 0; // Fallback to 0 if store is null


  return (
    <>
      <div className="m-10 border p-5 rounded-lg shadow-md">
        <div className="flex justify-between items-start">
          <div className="flex items-center gap-2">
            {store ? (
              <div className="flex flex-row gap-2">
                <div className="w-28 h-28">
                  <img
                    src={store.owner_photo}
                    className="w-full h-full rounded-full"
                    alt="Owner"
                  />
                </div>
                <div className="flex flex-col gap-1 ml-10">
                  <h1 className="text-[20px] font-semibold">{store.name}</h1>
                  <p className="text-[18px] text-gray-500">{store.address}</p>
                  <p className="text-[15px] text-gray-500">
                    +{store.phone_number}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex items-center">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={isActive}
                onChange={() => toggleActiveHandler(id, !isActive)} // Call the toggle handler
              />
              <div
                className={`relative w-11 h-6 rounded-full transition-colors duration-200 ${
                  isActive ? "bg-green-500" : "bg-gray-200"
                }`}
              >
                <div
                  className={`absolute top-0.5 left-0.5 w-5 h-5 rounded-full transition-transform duration-200 ${
                    isActive ? "translate-x-full bg-white" : "bg-gray-300"
                  }`}
                ></div>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4 mt-8 m-10">
        <div className="p-2 mb-4 flex justify-between">
          <h1 className="text-lg font-bold">Store Wallet</h1>
          <button className="text-white font-semibold px-2 py-1 rounded-md bg-primary-blue" onClick={handleOpenPopup}>Add Fund</button>
          {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Add Funds</h2>
            <div className="flex items-center border rounded-md p-2 mb-4">
              <span className="mr-2 text-gray-500">₹</span>
              <input
                type="text"
                value={amount}
                onChange={handleChange}
                className="w-full outline-none"
                placeholder="Enter amount"
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-md"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* First Column */}
          <div className="lg:col-span-1 flex flex-col justify-center items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-6 h-full cursor-pointer transition-all duration-200 ease-in-out">
            <div className="flex flex-col items-center mb-6">
              <img
                src="/images/withdraw.png"
                alt="Withdraw"
                className="w-16 h-16 mb-3"
              />
              <h4 className="text-black text-lg sm:text-xl font-bold mb-1">
                Rs. {holdings}{" "}
                {/* Display the calculated withdrawable amount */}
              </h4>
              <h4 className="text-gray-500 text-sm sm:text-base font-medium">
                Store Holdings
              </h4>
            </div>
            <button
              className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-lg"
              onClick={handleDebit}
            >
              Debit
            </button>
          </div>
          

          {/* Second Column */}
          <div className="lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out">
                <div className="flex flex-col mr-4 flex-grow">
                  {" "}
                  {/* Add flex-grow to take up remaining space */}
                  <h2 className="text-black text-xl font-bold mb-1">
                    Rs. {calculateTotalAcceptedOrderItemsAmount()}
                  </h2>
                  <h4 className="text-gray-500 font-medium text-sm">
                    Order Value
                  </h4>
                </div>
                <img
                  src="/images/pw.png"
                  alt="Packaging"
                  className="w-12 h-12 ml-auto" // Move image to the right end
                />
              </div>
              <div className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out">
                <div className="flex flex-col mr-4 flex-grow">
                  {" "}
                  {/* Add flex-grow to take up remaining space */}
                  <h2 className="text-black text-xl font-bold mb-1">
                    Rs. {royalty}
                  </h2>
                  <h4 className="text-gray-500 font-medium text-sm">Royalty</h4>
                </div>
                <img
                  src="/images/tcg.png"
                  alt="Packaging"
                  className="w-12 h-12 ml-auto" // Move image to the right end
                />
              </div>
            </div>
            <div className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out">
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                  Rs. {withdrawLimit}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">
                  Withdraw Limit
                </h4>
              </div>
              <img
                src="/images/aw.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out">
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                  Rs. {wallet.totalDeliveryCharge}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">
                  Total Delivery Charge Earned
                </h4>
              </div>
              <img
                src="/images/tdce.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out">
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                  Rs. {wallet.totalTaxGiven}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">
                  Total Tax Given
                </h4>
              </div>
              <img
                src="/images/ttg.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out">
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                  Rs. {calculateTotalAcceptedOrderItemsAmountForCOD()}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">
                  Collected Cash
                </h4>
              </div>
              <img
                src="/images/cc.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
          </div>
        </div>
      </div>
      {isDebitPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
          <div className="bg-white shadow-lg rounded-lg p-6 w-11/12 max-w-md">
            <h2 className="text-xl font-bold mb-4">Debit Amount</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Amount:</label>
              <input
                type="number"
                value={debitAmount}
                onChange={(e) => setDebitAmount(e.target.value)}
                className="border border-gray-300 rounded p-2 w-full"
                placeholder="Enter amount to debit"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Transaction Type:</label>
              <input
                type="text"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className="border border-gray-300 rounded p-2 w-full"
                placeholder="Enter transaction type"
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                onClick={() => setIsDebitPopupOpen(false)} // Close the popup
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 ml-4"
                onClick={handleConfirmDebit} // Handle the debit confirmation
              >
                Confirm Debit
              </button>
            </div>
          </div>

          
        </div>
      )}
    </>
  );
};

export default StoreOverview;
