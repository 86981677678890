import { useEffect, useState, useRef } from "react";
import Categories from "../Layouts/Categories";
import Banner from "./Banner/Banner";
import DealSlider from "./DealSlider/DealSlider";
import ProductSlider from "./ProductSlider/ProductSlider";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getSliderProducts } from "../../actions/productAction";
import { useSnackbar } from "notistack";
import Cart from "../Cart/Cart";
import DeliveryEdit from "../Cart/DeliveryEdit";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MetaData from "../Layouts/MetaData";
import Product from "./Product";
import { ADMIN_CATEGORY_API } from "../../utils/constants";
import CategorySection from "./Categories";
import axios from "axios";

const Home = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const categorySectionRefs = useRef({});
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const { error, loading } = useSelector((state) => state.products);
  const { cartItems } = useSelector((state) => state.cart);

  const [titles, setTitles] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getSliderProducts());
  }, [dispatch, error, enqueueSnackbar]);

  
  const closeCartSidebar = () => {
    setIsCartOpen(false);
    document.body.style.overflow = "auto"; // Enable scrolling
  };

  console.log("category data in home screen:", category)

  useEffect(() => {
    fetch(ADMIN_CATEGORY_API)
      .then((response) => response.json())
      .then((categoryData) => {
        const uniqueCategories = Array.from(
          new Map(
            categoryData.map((item) => [item.category_name, item])
          ).values()
        );
        setTitles(uniqueCategories.map((category) => category.category_name));
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${ADMIN_CATEGORY_API}`);
        const data = await response.data;
        setCategory(data); // Set category to the array of objects
      } catch (error) {
        console.error(error);
      }
    };
    fetchCategory();
  }, []);

  return (
    <>
      <MetaData title="Vivimart" />      
      <main className="flex flex-col px-2 lg:mt-8 sm:mt-2 lg:mx-20">
      <Categories categorySectionRefs={categorySectionRefs} />
      <div className="mb-4">
      <Banner />
      </div>        
      {category.length > 0 &&
  category
    .filter((category) => category.is_active === 1) // Filter only active categories
    .map((category, index) => (
      <div
        key={index}
        ref={(ref) =>
          (categorySectionRefs.current[category.category_name] = ref)
        }
        className="lg:mx-6 bg-white"
      >
        <CategorySection id={category.id} category={category.category_name} />
      </div>
    ))}


        {cartItems.length > 0 && !isCartOpen && (
          <div className="fixed left-3 right-3 flex z-20 bg-[#0c831f] bottom-3 text-white rounded-lg p-3.5 pl-3 pr-4 mx-auto justify-between transition-transform duration-300 transform translate-y-0 opacity-100 lg:hidden">
            <div className="flex justify-between gap-3">
              <ShoppingCartIcon />
              <div>
                {cartItems.length} item{cartItems.length !== 1 && "s"}
              </div>
            </div>
            <div
              onClick={() => setIsCartOpen(true)}
              className="flex justify-center items-center font-sans text-[17px] leading-[22px]"
            >
              View Cart
              <ArrowRightIcon />
            </div>
          </div>
        )}
        {isCartOpen && (
          <div className="fixed inset-0 z-40 flex">
            <div
              className="fixed inset-0 bg-black opacity-50 backdrop-blur-sm"
              onClick={closeCartSidebar}
            ></div>
            <div className="ml-auto">
              <Cart closeCartSidebar={closeCartSidebar} setIsEdit={setIsEdit} setIsOpen={setIsOpen} />
            </div>
          </div>
        )}

        {isEdit && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 py-4">
            <DeliveryEdit setIsEdit={setIsEdit} setIsOpen={setIsOpen} />
          </div>
        )}

        
      </main>
    </>
  );
};

export default Home;
