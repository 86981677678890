import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearError,
  getSubStoreById,
  getSubStoreByStoreId,
} from "../../actions/subStoreActions";
import { useSnackbar } from "notistack";
import "./OrderDetails.css";
import {
  updateOrderItemStatus,
  getAllOrders,
  forwardOrder,
} from "../../actions/orderAction";
import { useNavigate } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import {
  assignDeliveryPerson,
  getShipmentsByOrderId,
  splitOrderIntoShipments,
} from "../../actions/shipmentActions";
import { v4 as uuidv4 } from "uuid";
import ShareableLink from "../SharableLink/ShareableLink";
import {
  createDeliveryOrder,
  getDeliveryPersonsByStoreId,
} from "../../actions/deliveryPersonActions";
import { getStoreById } from "../../actions/storeAction";

const OrderDetails = ({ order, status, shipmentId, onBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [checkedItems, setCheckedItems] = useState({});
  const [tab, setTab] = useState("New");
  const [allSelected, setAllSelected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeliveryOpen, setIsDeliveryOpen] = useState(false);
  const [isForwardOpen, setIsForwardOpen] = useState(false);
  const [selectedSubStoreOwner, setSelectedSubStoreOwner] = useState("");
  const [forwardedItems, setForwardedItems] = useState([]);
  const [currentTab, setCurrentTab] = useState("New Order");
  const [refreshOrderDetails, setRefreshOrderDetails] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [showMyStore, setShowMyStore] = useState(false);
  const [selectedSubStore, setSelectedSubStore] = useState(null);
  const [selectedDeliveryMan, setSelectedDeliveryMan] = useState(null);
  const [isCancelConfirmationVisible, setIsCancelConfirmationVisible] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [uniqueForwardId, setUniqueForwardId] = useState("");
  const [subStoreData, setSubStoreData] = useState(null);
  const [pickupAddress, setPickupAddress] = useState("");

  const orderId = order.order_id;
  const storeId = order.store_id;

  console.log("passing status:", status);

  const {
    shipments,
    loading: loadingShipments,
    error: shipmentError,
  } = useSelector((state) => state.shipmentsByOrderId);

  const { store } = useSelector((state) => state.store);
  const { id, city_name } = store || {};

  useEffect(() => {
    // Fetch shipment details by order ID
    if (orderId) {
      dispatch(getShipmentsByOrderId(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (storeId) {
      dispatch(getStoreById(storeId));
    }
  }, [dispatch, storeId]);

  console.log("store data:", store);

  const { subStores, loading, error } = useSelector((state) => state.subStores);
  const { subStore } = useSelector((state) => state.subStoreById);

  const { deliveryPersons } = useSelector((state) => state.deliveryPersons);
  const { success, error: statusError } = useSelector(
    (state) => state.orderItemStatus
  );
  const [orderDetails, setOrderDetails] = useState(order);


  console.log("delivery persons:", deliveryPersons)

  useEffect(() => {
    if (selectedSubStore) {
      dispatch(getSubStoreById(selectedSubStore));
    }
  }, [dispatch, selectedSubStore]);

  console.log("get sub store data based on id:", subStore);

  useEffect(() => {
    if (id) {
      dispatch(getSubStoreByStoreId(id));
    }

    return () => {
      dispatch(clearError());
    };
  }, [dispatch, order.store_id]);

  useEffect(() => {
    if (id) {
      dispatch(getDeliveryPersonsByStoreId(id));
    }

    return () => {
      dispatch(clearError());
    };
  }, [dispatch, order.store_id]);

  const handleMyStoreChange = () => {
    const isChecked = !showMyStore; // Determine if the checkbox is being checked or unchecked
    setShowMyStore(isChecked);

    if (isChecked) {
      // If My Store is being checked, set pickup address to city_name
      setPickupAddress(city_name);
    } else {
      // If My Store is being unchecked, check if a sub store is selected
      if (!selectedSubStore) {
        setPickupAddress(""); // Clear if no sub store is selected
      }
    }
  };

  const handleSubStoreChange = (e) => {
    const selectedId = e.target.value;
    setSelectedSubStore(selectedId);

    // Find the selected sub store from subStores array
    const selectedSubStoreData = subStores.find(
      (store) => store.sub_store_id === selectedId
    );
    if (selectedSubStoreData) {
      // Set pickup address to the location of the selected sub store
      setPickupAddress(selectedSubStoreData.location);
    } else {
      // If no sub store is selected, clear the pickup address
      setPickupAddress("");
    }
  };

  // Define the callback function using useCallback
  const fetchOrderDetails = useCallback(() => {
    dispatch(getAllOrders(order.order_id));
  }, [dispatch, order.order_id]); // Add dependencies

  // Use useLayoutEffect to call fetchOrderDetails when order.order_id changes
  useLayoutEffect(() => {
    if (order.order_id) {
      // Check if order_id is defined
      fetchOrderDetails(); // Call the fetch function
    }
  }, [fetchOrderDetails]); // Dependencies

  useEffect(() => {
    const allItemsSelected = order.order_items.every(
      (item) => checkedItems[item.product_id]
    );
    setAllSelected(allItemsSelected);
  }, [checkedItems, order.order_items]);

  const handleCheckboxChange = (productId) => {
    setCheckedItems((prevState) => {
      const newCheckedItems = {
        ...prevState,
        [productId]: !prevState[productId],
      };

      // Update forwardedItems based on the new checked state
      const updatedForwardedItems = order.order_items
        .filter((item) => newCheckedItems[item.product_id]) // Get only selected items
        .map((item) => ({
          product_id: item.product_id,
          Product_name: item.Product_name,
          weight: item.weight,
          sell_price: item.sell_price,
          quantity: item.quantity,
          product_image_0: item.product_image_0,
        })); // Store the entire item object

      setForwardedItems(updatedForwardedItems); // Update forwardedItems state
      return newCheckedItems;
    });
  };

  const handleForward = () => {
    const selectedItems = Object.keys(checkedItems)
      .filter((productId) => checkedItems[productId])
      .map((productId) => ({
        product_id: parseInt(productId, 10),
        quantity:
          order.order_items.find(
            (item) => item.product_id === parseInt(productId, 10)
          )?.quantity || 0,
      }));

    // Add logic to handle the forward action with selectedItems and selectedSubStoreOwner
    console.log("Selected Items: ", selectedItems);
    console.log("Selected Sub Store Owner: ", selectedSubStoreOwner);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setIsDeliveryOpen(false);
  };

  const handleAccept = async () => {
    const itemsToDispatch = order.order_items.filter(
      (item) => checkedItems[item.product_id]
    );

    console.log("Items to Dispatch:", itemsToDispatch);
    if (itemsToDispatch.length === 0) {
      enqueueSnackbar("Please select items to Dispatch", {
        variant: "warning",
      });
      return;
    }

    // Prepare the data for updating the order item status
    const validItems = itemsToDispatch.map((item) => ({
      product_id: item.product_id,
      status: "Accepted",
      item_weight: item.weight,
      item_quantity: item.quantity,
      item_sell_price: item.sell_price,
    }));

    // Dispatch updateOrderItemStatus action
    try {
      await dispatch(updateOrderItemStatus(order.order_id, validItems));

      // Now pass the updated order details to split into shipments
      const shipmentData = {
        order_id: order.order_id,
        order_items: validItems, // This should be a flat array of items
        pickup_address: pickupAddress,
      };

      console.log("Shipment Data:", shipmentData);

      // Dispatch splitOrderIntoShipments action
      await dispatch(splitOrderIntoShipments(shipmentData));

      // Trigger reload and reset selection
      setRefreshOrderDetails(true);
      setCheckedItems({});
      setAllSelected(false);

      enqueueSnackbar("Order accepted and split into shipments successfully", {
        variant: "success",
      });
      setIsPopupOpen(false);
      fetchOrderDetails();
    } catch (error) {
      console.error("Error dispatching order:", error);
      enqueueSnackbar("Failed to dispatch order", { variant: "error" });
    }
  };

  // Update the useEffect that fetches order details
  useEffect(() => {
    if (refreshOrderDetails) {
      dispatch(getAllOrders(order.order_id))
        .then((response) => {
          if (response && response.data) {
            setOrderDetails(response.data);
            console.log("Updated order details:", response.data);
          } else {
            console.error("No order details found in response:", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching updated order details:", error);
        })
        .finally(() => {
          setRefreshOrderDetails(false);
        });
    }
  }, [dispatch, order.order_id, refreshOrderDetails]);

  const handleSelectAll = () => {
    const newCheckedItems = {};

    // Filter items based on the passed status
    const itemsToSelect = order.order_items.filter(
      (item) =>
        item.status === status &&
        !forwardedItems.some(
          (forwardedItem) => forwardedItem.product_id === item.product_id
        )
    );

    // Determine if we are selecting or deselecting all
    const shouldSelectAll = !allSelected;

    itemsToSelect.forEach((item) => {
      newCheckedItems[item.product_id] = shouldSelectAll; // Set to true or false based on shouldSelectAll
    });

    // Update forwardedItems based on the new checked state
    const updatedForwardedItems = shouldSelectAll
      ? itemsToSelect.map((item) => ({
          product_id: item.product_id,
          Product_name: item.Product_name,
          weight: item.weight,
          sell_price: item.sell_price,
          quantity: item.quantity,
          product_image_0: item.product_image_0,
        })) // Select all items
      : []; // Deselect all

    setCheckedItems(newCheckedItems);
    setAllSelected(shouldSelectAll); // Update allSelected state
    setForwardedItems(updatedForwardedItems); // Update forwardedItems state
  };

  // Update the allSelected state based on the checked items
  useEffect(() => {
    const allItemsSelected = order.order_items
      .filter((item) => item.status === status) // Only consider items with the passed status
      .every((item) => checkedItems[item.product_id]);

    setAllSelected(allItemsSelected);
  }, [checkedItems, order.order_items, status]);

  const selectedItems = order.order_items.filter(
    (item) => checkedItems[item.product_id]
  );
  const selectedTotalItems = selectedItems.length;
  const selectedTotalQuantity = selectedItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const selectedTotalAmount = selectedItems
    .reduce((total, item) => total + item.sell_price * item.quantity, 0)
    .toFixed(2);

  const handleSuccessForward = async () => {
    const selectedItems = Object.keys(checkedItems)
      .filter((productId) => checkedItems[productId])
      .map((productId) => {
        const item = order.order_items.find(
          (item) => item.product_id === parseInt(productId, 10)
        );
        return {
          product_id: item.product_id,
          quantity: item.quantity,
          category: item.category,
          weight: item.weight,
          sell_price: item.sell_price,
          product_image_0: item.product_image_0,
        };
      });

    if (selectedItems.length === 0) {
      enqueueSnackbar("Please select items to forward", { variant: "warning" });
      return;
    }

    const uniqueId = uuidv4(); // Generate a unique ID

    // Prepare payload for forwarding the order
    const payload = {
      order_id: order.order_id,
      store_id: order.store_id,
      sub_store_id: selectedSubStore, // Use selectedSubStore here
      items_to_forward: selectedItems,
      unique_id: uniqueId, // Include the unique ID in the payload
      pickup_address: pickupAddress,
    };

    // Log the payload for debugging
    console.log("Payload for forwarding order:", payload);

    try {
      await dispatch(forwardOrder(payload));
      // Navigate to accept invoice page with the unique ID
      navigate(`/sub-store/accept-invoice/${uniqueId}`);
      setIsPopupOpen(false);
    } catch (error) {
      console.error("Error forwarding order:", error);
      enqueueSnackbar("Failed to forward order", { variant: "error" });
    }
};

  const handleDeliveryManChange = (e) => {
    setSelectedDeliveryMan(e.target.value);

    console.log("selected sub store:", e.target.value);
  };

  console.log("shipment id passed to orderDetails:", shipmentId);

  // Find the shipment that matches the shipmentId (if provided)
  const selectedShipment = shipments.find(
    (shipment) => shipment.shipment_id === shipmentId
  ) || { items: [] };

  // Filter order_items based on the selected shipment or status
  const filteredOrderItems = () => {
    // If the status is "New", return all order items with the status "New"
    if (status === "New") {
      return order.order_items.filter((item) => item.status === "New");
    }

    // Otherwise, filter by shipment ID or the current status
    if (shipmentId) {
      const selectedShipment = shipments.find(
        (shipment) => shipment.shipment_id === shipmentId
      );

      // Check if selectedShipment is defined
      if (selectedShipment && selectedShipment.items) {
        return order.order_items.filter((item) =>
          selectedShipment.items.some(
            (selectedItem) => selectedItem.product_id === item.product_id
          )
        );
      } else {
        // Handle the case where selectedShipment is not found
        console.warn(`No shipment found for shipmentId: ${shipmentId}`);
        return []; // or return order.order_items to show all items
      }
    }

    // Default filtering by current status
    return order.order_items.filter((item) => item.status === status);
  };

  // Filter for forwarded items
  const forwardedOrderItems = order.order_items.filter(
    (item) => item.status === "Forwarded"
  );

  //function to implement status
  const getStatusStyle = (status) => {
    switch (status) {
      case "New":
        return "text-blue-500 font-bold";
      case "Accepted":
        return "text-green-500 font-bold";
      case "Forwarded":
        return "text-yellow-500 font-bold";
      case "Dispatched":
        return "text-orange-500 font-bold";
      case "Delivered":
        return "text-green-500 font-bold";
      case "Canceled":
        return "text-red-500 font-bold";
      default:
        return "text-gray-500 font-bold";
    }
  };

  const handleCancel = () => {
    if (!orderId) {
      alert("Invalid order ID");
      return;
    }
    const itemsToDispatch = order.order_items.filter(
      (item) => checkedItems[item.product_id]
    );
    if (itemsToDispatch.length === 0) {
      alert("No items selected to cancel.");
      return;
    }
    setSelectedItem(itemsToDispatch.map((item) => item.product_id)); // Store selected item IDs
    setIsCancelConfirmationVisible(true); // Show confirmation modal
  };

  const handleConfirmCancel = async () => {
    const itemsToDispatch = order.order_items.filter(
      (item) => checkedItems[item.product_id]
    );

    console.log("Items to Cancel:", itemsToDispatch);
    if (itemsToDispatch.length === 0) {
      enqueueSnackbar("Please select items to Cancel", {
        variant: "warning",
      });
      return;
    }

    // Prepare the data for updating the order item status
    const validItems = itemsToDispatch.map((item) => ({
      product_id: item.product_id,
      status: "Cancelled",
      item_weight: item.weight,
      item_quantity: item.quantity,
      item_sell_price: item.sell_price,
    }));

    // Dispatch updateOrderItemStatus action
    try {
      await dispatch(updateOrderItemStatus(order.order_id, validItems));

      // Now pass the updated order details to split into shipments
      const shipmentData = {
        order_id: order.order_id,
        order_items: validItems, // This should be a flat array of items
      };

      console.log("Shipment Data:", shipmentData);

      // Dispatch splitOrderIntoShipments action
      await dispatch(splitOrderIntoShipments(shipmentData));

      // Trigger reload and reset selection
      setRefreshOrderDetails(true);
      setCheckedItems({});
      setAllSelected(false);

      enqueueSnackbar("Order cancelled successfully", {
        variant: "success",
      });
      setIsPopupOpen(false);
    } catch (error) {
      console.error("Error Canceling order:", error);
      enqueueSnackbar("Failed to Canceling order", { variant: "error" });
    }
  };

  const handleAssignDeliveryMan = () => {
    const itemsToDispatch = order.order_items.filter(
      (item) => checkedItems[item.product_id]
    );
    if (itemsToDispatch.length === 0) {
      alert("No items selected to cancel.");
      return;
    }
    setIsDeliveryOpen(true);
  };

  const handleConformDeliveryMan = async () => {
    // Assuming you have a selected delivery person ID from your state or a selection
    const deliveryPersonId = selectedDeliveryMan; // Use the selected delivery person ID

    // Check if a delivery person is selected
    if (!deliveryPersonId) {
        alert("Please select a delivery person.");
        return;
    }

    // Step 1: Assign delivery person to shipment
    try {
        await dispatch(assignDeliveryPerson(shipmentId, deliveryPersonId)); // Ensure shipmentId is available
        enqueueSnackbar("Delivery person assigned successfully.", {
            variant: "success",
        });

        // Step 2: Create a delivery order
        const deliveryOrderData = {
            delivery_person_id: deliveryPersonId,
            order_id: order.order_id, // No pickup_address or delivery_address
        };

        await dispatch(createDeliveryOrder(deliveryOrderData));
        enqueueSnackbar("Delivery order created successfully.", {
            variant: "success",
        });

        setIsDeliveryOpen(false);
    } catch (error) {
        console.error(
            "Error assigning delivery person or creating delivery order:",
            error
        );
        enqueueSnackbar(
            "Failed to assign delivery person or create delivery order.",
            { variant: "error" }
        );
    }
};

  const renderActionButton = () => {
    switch (status) {
      case "New":
        return (
          <>
            <button
              className="bg-red-500 text-white py-2 px-4 rounded"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded"
              onClick={handleForward}
            >
              Forward
            </button>
          </>
        );
      case "Accepted":
        return (
          <>
            <button
              className="bg-yellow-500 text-white py-2 px-4 rounded"
              onClick={handleAssignDeliveryMan}
            >
              Assign Delivery Man
            </button>
          </>
        );
      case "Forwarded":
        return (
          <>
            <button
              className="bg-yellow-500 text-white py-2 px-4 rounded"
              onClick={handleAssignDeliveryMan}
            >
              Assign Delivery Man
            </button>
          </>
        );
      case "Dispatched":
      case "Delivered":
      case "Canceled":
        return null;
      default:
        return null;
    }
  };

  const handlePrintInvoice = () => {
    const selectedItems = order.order_items.filter(
      (item) => checkedItems[item.product_id]
    );
    setInvoiceItems(selectedItems);

    navigate("/store/orders/printStoreInvoice", {
      state: { order: { ...order, order_items: selectedItems } },
    });
  };

  const columns = [
    {
      field: "selectAll",
      headerAlign: "center",
      align: "center",
      headerName: (
        <input
          type="checkbox"
          checked={allSelected}
          onChange={handleSelectAll}
          className="mr-2 custom-checkbox"
        />
      ),
      minWidth: 50,
      type: "number",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="checkbox"
          className="mr-2 custom-checkbox"
          checked={checkedItems[params.row.product_id] || false}
          onChange={() => handleCheckboxChange(params.row.product_id)}
        />
      ),
    },
    {
      field: "category",
      headerName: "Category",
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "product",
      headerName: "Product",
      minWidth: 200,
      flex: 0.2,
      renderCell: (params) => (
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 rounded-full">
            <img
              draggable="false"
              src={params.row.image}
              alt={params.row.name}
              className="w-full h-full rounded-full object-cover"
            />
          </div>
          {params.row.name}
        </div>
      ),
    },
    {
      field: "weight",
      headerName: "Unit",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "sell_price",
      headerName: "Sell Price",
      type: "number",
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      renderCell: (params) => (
        <span>₹{params.row.sell_price.toLocaleString()}</span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
      renderCell: (params) => (
        <span className={`${getStatusStyle(params.value)}`}>
          {params.value}
        </span>
      ),
    },
    {
      field: "total_price",
      headerName: "Total Price",
      type: "number",
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      renderCell: (params) => (
        <span>₹{(params.row.sell_price * params.row.quantity).toFixed(2)}</span>
      ),
    },
  ];

  console.log("filtered orders:", filteredOrderItems());

  const rows = filteredOrderItems().map((item) => ({
    id: item.product_id,
    product_id: item.product_id,
    name: item.Product_name,
    category: item.category,
    image: item.product_image_0,
    weight: item.weight,
    quantity: item.quantity,
    sell_price: item.sell_price,
    status: item.status,
    total_price: item.sell_price * item.quantity,
  }));

  // Add a function to handle back navigation
  const handleBack = () => {
    navigate("/store/orders"); // Go back to the previous page
  };

  return (
    <div className="px-8 py-4">
      <button onClick={onBack} className="mb-4 text-blue-500">
        &lt; Back to Orders
      </button>
      <div>
        <div className="p-6 flex border shadow-md rounded-lg relative gap-8 cursor-pointer">
          <div className="flex gap-2 flex-col w-full">
            <h1>
              <span className="font-bold">Order Date: </span>
              {order.order_date}
            </h1>
            <h2>
              <span className="font-bold">Delivery Address: </span>
              {order.address}
            </h2>
          </div>
        </div>

        <div className="mt-4 mb-4 border shadow-md rounded-lg p-6">
          <div className="flex justify-between items-center mb-2 pb-2 border-b">
            <div className="text-md font-bold mb-2">Order Items</div>
            <button
              onClick={handlePrintInvoice}
              className="text-white bg-blue-500 p-2 mb-2"
            >
              Print Invoice
            </button>
          </div>
          {/* Order Items Table */}

          <div className="bg-white rounded-xl w-full" style={{ height: 300 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              sx={{
                boxShadow: 0,
                border: 0,
              }}
            />
          </div>
        </div>
        <div className="mt-6 border shadow-md rounded-md p-6">
          <div className="text-md font-bold mb-4 pb-2">
            Selected Items Summary
          </div>
          <div className="flex flex-wrap gap-4">
            <div className="border p-2 rounded-lg bg-purple-50 flex-1 text-center">
              Selected Items: {selectedTotalItems}
            </div>
            <div className="border p-2 rounded-lg bg-green-50 flex-1 text-center">
              Selected Items Quantity: {selectedTotalQuantity}
            </div>
            <div className="border p-2 rounded-lg bg-amber-100 flex-1 text-center">
              Selected Items Total Price: {selectedTotalAmount}
            </div>
          </div>
        </div>
        {/* Actions */}
        <div className="mt-4 flex gap-4">{renderActionButton()}</div>

        {isCancelConfirmationVisible && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
        )}

        {isCancelConfirmationVisible && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white shadow-lg rounded-lg p-6 w-11/12 max-w-md">
              <h2 className="text-xl font-bold mb-4">Cancel Confirmation</h2>
              <p className="text-lg text-gray-400">
                Are you sure you want to cancel the selected items?
              </p>
              <div className="flex justify-end mt-4">
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                  onClick={() => setIsCancelConfirmationVisible(false)} // Close modal
                >
                  No, cancel
                </button>
                <button
                  className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-800 ml-4"
                  onClick={handleConfirmCancel} // Confirm cancellation
                >
                  Yes, I'm sure
                </button>
              </div>
            </div>
          </div>
        )}
        {isForwardOpen && (
          <ShareableLink
            items={forwardedItems}
            subStoreId={selectedSubStore}
            uniqueId={uniqueForwardId}
            onAccept={handleAccept}
          />
        )}

        {isDeliveryOpen && (
          <div className="fixed inset-0 flex items-center justify-center h-auto z-50 bg-black bg-opacity-70">
            <div className="bg-white shadow-lg rounded-lg p-6 w-11/12 max-w-md">
              <h2 className="text-xl font-bold mb-4">Select Delivery Person</h2>

              <div className="form-group">
                <label htmlFor="subStore" className="font-bold text-gray-500">
                  Select a Delivery person:
                </label>
                <select
                  id="subStore"
                  value={selectedDeliveryMan}
                  onChange={handleDeliveryManChange}
                  className="w-full p-2 border border-gray-300 rounded mt-2"
                >
                  <option value="">Select a Delivery person</option>
                  {deliveryPersons.map((item) => (
                    <option
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </option>
                  ))}
                </select>
              </div>

              <div className="flex justify-end mt-4">
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                  onClick={handleClosePopup}
                >
                  Close
                </button>

                <button
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 ml-4"
                  onClick={handleConformDeliveryMan}
                >
                  Assign Dellivery Man
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Popup for Forward Order */}
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
            <div className="bg-white shadow-lg rounded-lg p-6 w-11/12 max-w-md">
              <h2 className="text-xl font-bold mb-4">Forward Details</h2>
              <p className="text-lg text-gray-400">Items to Forward:</p>
              <div className="list-disc pl-5 mb-4 ">
                {forwardedItems.map((item) => (
                  <div
                    key={item.product_id} // Use product_id as the key
                    className="flex items-center border rounded-lg gap-2 mt-3 p-2 bg-white"
                  >
                    <img
                      src={item.product_image_0}
                      alt={item.Product_name}
                      className="w-12 h-12 object-cover rounded"
                    />
                    <div className="flex flex-col ml-3 flex-grow">
                      <div className="text-left text-sm">
                        {item.Product_name}
                      </div>
                      <div className="text-left text-sm text-gray-600">
                        {item.weight}
                      </div>
                      <div className="text-left text-sm font-semibold">
                        {item.sell_price}
                      </div>
                    </div>
                    <div className="ml-auto mr-12 text-right">
                      {item.quantity}
                    </div>
                  </div>
                ))}
              </div>

              {/* My Store Checkbox */}
              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  checked={showMyStore}
                  onChange={handleMyStoreChange}
                  className="mr-2"
                />
                <label className="font-bold text-gray-500">
                  My Store: {id || "N/A"}
                </label>
              </div>

              <div className="form-group">
                <label htmlFor="subStore" className="font-bold text-gray-500">
                  Select Sub Store:
                </label>
                <select
                  id="subStore"
                  value={selectedSubStore}
                  onChange={handleSubStoreChange}
                  className="w-full p-2 border border-gray-300 rounded mt-2"
                >
                  <option value="">Select Sub Store</option>
                  {subStores.map((subStore) => (
                    <option
                      key={subStore.sub_store_id}
                      value={subStore.sub_store_id}
                    >
                      {subStore.sub_store_owner}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex justify-end mt-4">
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                  onClick={handleClosePopup}
                >
                  Close
                </button>
                {showMyStore ? (
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 ml-4"
                    onClick={handleAccept}
                  >
                    Accept
                  </button>
                ) : selectedSubStore ? (
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 ml-4"
                    onClick={handleSuccessForward}
                  >
                    Forward
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
