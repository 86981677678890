import { useEffect, useState } from "react"; // Import useState
import Sidebar from "./Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Layouts/Loader";
import MinCategory from "../Layouts/MinCategory";
import MetaData from "../Layouts/MetaData";
import { auth } from "../../firebase";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import { updatePassword } from "../../actions/userAction";
import { useSnackbar } from "notistack";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);

  // State for password visibility
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  // State for passwords
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const getLastName = () => {
    const nameArray = user.name.split(" ");
    return nameArray[nameArray.length - 1];
  };

  const handlePasswordUpdate = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
        await dispatch(updatePassword(currentPassword, newPassword));
        enqueueSnackbar('Password updated successfully!', { variant: 'success' });
    } catch (error) {
        enqueueSnackbar(error.response.data.message || 'Failed to update password', { variant: 'error' }); // Show error message
    }
};


  return (
    <>
      <MetaData title="My Profile" />

      {loading ? (
        <Loader />
      ) : (
        <>
          <main className="w-full mt-12 sm:mt-0">
            <div className="flex flex-col gap-3.5 sm:w-11/12 sm:mt-4 m-auto mb-7">
              <div className="flex-1 overflow-hidden shadow-lg rounded-lg bg-white">
                <div className="flex flex-col gap-12 m-4 sm:mx-8 sm:my-6">
                  <div className="flex flex-col gap-5 items-start">
                    <div className="flex text-center">
                      <span className="mr-4">
                        <PersonRoundedIcon />
                      </span>
                      <span className="font-medium text-lg">
                        Personal Information
                      </span>
                    </div>
                    <div className="relative flex justify-center">
                      <img
                        src={user.owner_photo}
                        alt="Profile"
                        className="rounded-full border-4 w-28 h-28 border-white shadow-md"
                      />
                    </div>
                    <div
                      className="flex flex-col sm:flex-row items-center gap-3"
                      id="personalInputs"
                    >
                      <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border inputs cursor-not-allowed hover:border-primary-blue">
                        <label className="text-xs text-gray-500">Name</label>
                        <input
                          type="text"
                          value={user.name}
                          className="text-sm outline-none border-none cursor-not-allowed bg-white"
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-5 items-start">
                    <span className="font-medium text-lg">Store Details</span>
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-3">
                        <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-white hover:border-primary-blue">
                          <label className="text-xs text-gray-500">
                            Store ID
                          </label>
                          <input
                            type="text"
                            value={user.id}
                            className="text-sm outline-none border-none bg-white"
                            disabled
                          />
                        </div>
                        <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-white hover:border-primary-blue">
                          <label className="text-xs text-gray-500">
                            Owner Name
                          </label>
                          <input
                            type="text"
                            value={user.owner_name}
                            className="text-sm outline-none border-none bg-white"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-white hover:border-primary-blue">
                          <label className="text-xs text-gray-500">State</label>
                          <input
                            type="text"
                            value={user.state}
                            className="text-sm outline-none border-none bg-white"
                            disabled
                          />
                        </div>
                        <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-white hover:border-primary-blue">
                          <label className="text-xs text-gray-500">City</label>
                          <input
                            type="text"
                            value={user.city_name}
                            className="text-sm outline-none border-none bg-white"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-white hover:border-primary-blue">
                          <label className="text-xs text-gray-500">
                            Primary Location
                          </label>
                          <input
                            type="text"
                            value={user.primary_location}
                            className="text-sm outline-none border-none bg-white"
                            disabled
                          />
                        </div>
                        <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border bg-white hover:border-primary-blue">
                          <label className="text-xs text-gray-500">
                            Package Fee
                          </label>
                          <input
                            type="text"
                            value={user.package_fee}
                            className="text-sm outline-none border-none bg-white"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 overflow-hidden shadow-lg rounded-lg bg-white">
                <div className="flex flex-col gap-12 m-4 sm:mx-8 sm:my-6">
                  <div className="flex flex-col gap-5 items-start">
                    <div className="flex text-center">
                      <span className="mr-4">
                        <HttpsRoundedIcon />
                      </span>
                      <span className="font-medium text-lg">
                        Change Password
                      </span>
                    </div>
                  </div>

                  <form onSubmit={handlePasswordUpdate} className="flex flex-col gap-5 items-start">
                    <div className="max-w-sm">
                      <label
                        htmlFor="new-password"
                        className="block text-sm mb-2 dark:text-white"
                      >
                        New password
                      </label>
                      <div className="relative">
                        <input
                          id="new-password"
                          type={showNewPassword ? "text" : "password"}
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)} // Update new password state
                          className="py-3 ps-4 pe-10 block w-full border border-primary-blue rounded-lg text-sm hover:border-primary-blue"
                          placeholder="Enter new password"
                        />
                        <button
                          type="button"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400"
                        >
                          {showNewPassword ? (
                            <svg
                              className="shrink-0 size-3.5"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                              <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c 7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                              <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                              <line x1="2" x2="22" y1="2" y2="22"></line>
                            </svg>
                          ) : (
                            <svg
                              className="shrink-0 size-3.5"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                              <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="max-w-sm mb-5">
                      <label
                        htmlFor="current-password"
                        className="block text-sm mb-2 dark:text-white"
                      >
                        Current password
                      </label>
                      <div className="flex">
                        <div className="relative">
                          <input
                            id="current-password"
                            type={showCurrentPassword ? "text" : "password"}
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)} // Update current password state
                            className="py-3 ps-4 pe-10 block w-full border border-primary-blue rounded-lg text-sm hover:border-primary-blue"
                            placeholder="Enter current password"
                          />
                          <button
                            type="button"
                            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                            className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400"
                          >
                            {showCurrentPassword ? (
                              <svg
                                className="shrink-0 size-3.5"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                <line x1="2" x2="22" y1="2" y2="22"></line>
                              </svg>
                            ) : (
                              <svg
                                className="shrink-0 size-3.5"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                              </svg>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="mt-4 bg-primary-blue text-white py-2 px-4 rounded-lg"
                    >
                      Update Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default Profile;
