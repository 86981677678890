// ExampleComponent.js
import React, { useState } from 'react';
import ComingSoon from '../Layouts/ComingSoon';

const MyWallet = () => {
  const [isFeatureAvailable, setIsFeatureAvailable] = useState(false); // Change this to true when the feature is ready

  return (
    <div className=''>
      {isFeatureAvailable ? (
        <div className='w-full h-full bg-black'>
          {/* Render your feature here */}
          <h1 className="text-2xl">This is the feature!</h1>
        </div>
      ) : (
        <div className='w-full h-full'>
          <ComingSoon featureName="Wallet feature is coming soon..." />
        </div>
        
      )}
    </div>
  );
};

export default MyWallet;