import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ADMIN_CATEGORY_API } from "../../utils/constants";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick"; // Import Slider from react-slick
import "slick-carousel/slick/slick.css"; // Import slick CSS
import "slick-carousel/slick/slick-theme.css"; // Import slick theme CSS


export const PreviousBtn = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIosIcon />
    </div>
  );
};

export const NextBtn = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIosIcon />
    </div>
  );
};

const Categories = ({ categorySectionRefs }) => {
  const [categoryData, setCategoryData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(4); // Default to mobile view

  const step = 2; // The number of items to shift by each click

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 500) {
        setVisibleCount(5); // Tablet view
      } else {
        setVisibleCount(3); // Mobile view
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetch(ADMIN_CATEGORY_API)
      .then((response) => response.json())
      .then((categoryData) => {
        const uniqueCategories = Array.from(
          new Map(
            categoryData.map((item) => [item.category_name, item])
          ).values()
        );
        // Filter to only include active categories
        const activeCategories = uniqueCategories.filter(
          (item) => item.is_active === 1
        );
        setCategoryData(activeCategories);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const handlePrevClick = () => {
    setStartIndex((prevStartIndex) => Math.max(prevStartIndex - step, 0));
  };

  const handleNextClick = () => {
    setStartIndex((prevStartIndex) =>
      Math.min(prevStartIndex + step, categoryData.length - visibleCount)
    );
  };

  const touchStartXRef = useRef(0);
  const touchEndXRef = useRef(0);

  const handleTouchStart = (e) => {
    touchStartXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const touchStartX = touchStartXRef.current;
    const touchEndX = touchEndXRef.current;
    const touchDiff = touchStartX - touchEndX;

    if (touchDiff > 50) {
      handleNextClick();
    } else if (touchDiff < -50) {
      handlePrevClick();
    }
  };

  const visibleCategories = categoryData.slice(
    startIndex,
    startIndex + visibleCount
  );

  const handleCategoryClick = (category) => {
    const categorySection = categorySectionRefs.current[category];
    if (categorySection) {
      const headerOffset = 100; // Adjust this value based on your header height
      const elementPosition = categorySection.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  // Slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  // Slider settings
  const mobileSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <>
      <style>
        {`
          @media (max-width: 900px) {
            .desktop-view {
              display: none;
            }
          }

          @media (min-width: 901px) {
            .mobile-view {
              display: none;
            }
          }
        `}
      </style>
      <section className="relative mt-[14rem] sm:mt-[14rem] mb-[4rem] lg:mt-[5rem] lg:mb-4 w-full md:w-full overflow-hidden">
        {/* Slider for larger screens */}
        <div className="desktop-view w-full">
  <Slider {...sliderSettings}>
    {categoryData.map((item, i) => (
      <div key={i} className="flex items-center justify-center">
        <Link
          to="#"
          onClick={() => handleCategoryClick(item.category_name)}
          className="flex items-center text-center border border-gray-400 rounded-lg bg-gray-100 hover:bg-gray-300"
          style={{
            minWidth: "9rem", // Minimum width to prevent it from getting too small
            maxWidth: "none", // Allow to expand based on content
            height: "65px",
            display: 'flex', // Ensure flex behavior
            alignItems: 'center', // Center items vertically
            margin: '0 10px', // Add margin for gap
          }}
        >
          <div className="h-full flex-shrink-0">
            <img
              draggable="false"
              className="h-full w-full rounded-l-lg object-cover"
              src={item.category_img}
              alt={item.category_name}
            />
          </div>
          <span
            className="text-[16px] text-gray-800 w-full font-medium text-left m-2 md:mx-3"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "wrap",
              maxWidth: "calc(100% - 40px)", // Adjust the width based on your needs
            }}
          >
            {item.category_name}
          </span>
        </Link>
      </div>
    ))}
  </Slider>
</div>

<div className="mobile-view">
  <Slider {...mobileSliderSettings}>
    {categoryData.map((item, i) => (
      <div key={i} className="flex items-center justify-center">
        <Link
          to="#"
          onClick={() => handleCategoryClick(item.category_name)}
          className="flex flex-row items-center group text-center border h-[50px] md:h-[65px] rounded-lg bg-gray-100 hover:bg-gray-300"
          style={{
            minWidth: "9rem", // Minimum width
            maxWidth: "none", // Allow to expand
            margin: '0 10px', // Add margin for gap
          }}
        >
          <div className="h-full flex-shrink-0">
            <img
              draggable="false"
              className="h-full w-full rounded-l-lg object-cover"
              src={item.category_img}
              alt={item.category_name}
            />
          </div>
          <span
            className="text-sm text-gray-800 w-full font-medium text-left m-2 md:mx-3"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "wrap",
              maxWidth: "calc(100% - 40px)", // Adjust the width based on your needs
            }}
          >
            {item.category_name}
          </span>
        </Link>
      </div>
    ))}
  </Slider>
</div>
      </section>
    </>
  );
};

export default Categories;