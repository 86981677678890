import { useSnackbar } from "notistack";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearErrors, getProducts } from "../../actions/productAction";
import Loader from "../Layouts/Loader";
import MinCategory from "../Layouts/MinCategory";
import Product from "./Product";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  ADMIN_SUB_CATEGORY_API,
  ADMIN_SUB_SUB_CATEGORY_API,
  ADMIN_PRODUCT_API,
} from "../../utils/constants";
import MetaData from "../Layouts/MetaData";
import { useLocation } from "react-router-dom";
import {
  getCategoryName,
  getSubCategoryName,
} from "../../actions/categoryAction";
import InfiniteScroll from "react-infinite-scroll-component";
import Cart from "../Cart/Cart";

const BrandProducts = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const location = useLocation();
  const [price, setPrice] = useState([0, 200000]);
  const [category, setCategory] = useState("");
  const [ratings, setRatings] = useState(0);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subSubCategoryData, setSubSubCategoryData] = useState([]);
  const [allSubSubCategoryData, setAllSubSubCategoryData] = useState([]);
  const [data, setData] = useState([]);
  const [product, setProduct] = useState([]);
  const [infiniteScrollDataLength, setInfiniteScrollDataLength] = useState();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  // filter toggles
  const [categoryToggle, setCategoryToggle] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectItem, setSelectItem] = useState(null);
  const subCategoryId = new URLSearchParams(location.search).get(
    "sub_category_id"
  );
  console.log("Selected Category ID:", subCategoryId);
  const [expandedSubCategoryIndex, setExpandedSubCategoryIndex] = useState(-1);
  const [productsCount, setProductsCount] = useState(0);
  const [productsLength, setProductsLength] = useState(0);
  const { products, error, resultPerPage, filteredProductsCount } = useSelector(
    (state) => state.products
  );
  const keyword = params.keyword;

  const { subCategoryName } = useSelector((state) => state.subCategoryName);

  const { cartItems } = useSelector((state) => state.cart);

  console.log("category name based on selected id:", subCategoryName);


  const brandName = params.keyword;

  useEffect(() => {
    if (subCategoryId) {
      dispatch(getSubCategoryName(subCategoryId));
    }
  }, [dispatch, subCategoryId]);

  console.log(
    "Fetched category name data:",
    subCategoryName.subCategoryName
  );

  useEffect(() => {
    console.log("Location search:", location.search);
    const queryParams = new URLSearchParams(location.search);
    const subCategoryId = queryParams.get("sub_category_id");
    setCategory(subCategoryId);
    console.log("Selected sub category id:", subCategoryId);
  }, [location.search]);
  
  useEffect(() => {
    setProduct([]);
    setProductsLength(0);
    setProductsCount(0);
    setCurrentPage(1);
  }, [subCategoryName.subCategoryName]);

   // Fetch products by brand name
   useEffect(() => {
    const fetchProductsByBrand = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://backend.vivimart.in/api/products/brand/${brandName}?page=${currentPage}&limit=30`);
            if (!response.ok) {
                throw new Error(`Failed to fetch products: ${response.statusText}`);
            }
            const productData = await response.json();
            console.log("Fetched product data:", productData);

            // Directly set the products since productData is an array
            setProduct(prevProducts => [...prevProducts, ...productData]); // Assuming productData is an array of products
            setProductsCount(productData.length); // Set products count based on the length of the array
        } catch (error) {
            console.error("Error fetching products:", error);
            enqueueSnackbar(error.message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    fetchProductsByBrand();
}, [brandName, currentPage, enqueueSnackbar]);

 
  // Infinite scrolling logic
  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled near the bottom of the page
      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 500 &&
        !loading
      ) {
        setCurrentPage((prevPage) => prevPage + 1); // Load next page
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup listener
  }, [loading]);

  const handleProductClick = async (index, name) => {
    setExpandedSubCategoryIndex(
      index === expandedSubCategoryIndex ? -1 : index
    );
    setSelectedItem(index === selectedItem ? null : index);

    try {
      setProduct([]); // Reset the product state to an empty array
      const response = await fetch(
        `${ADMIN_PRODUCT_API}/subsubcategory/${name}`
      );
      const productData = await response.json();
      setProduct(productData);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getProducts(keyword, category, price, ratings, currentPage));
  }, [
    dispatch,
    keyword,
    category,
    price,
    ratings,
    currentPage,
    error,
    enqueueSnackbar,
  ]);

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [sortOption, setSortOption] = useState("relevance");

  const normalizeBrandName = (brandName) => brandName.trim().toLowerCase();

  const uniqueBrandNames = Array.from(
    new Set(product.map((item) => normalizeBrandName(item.Brand_name)))
  );

  const productOptions = uniqueBrandNames.map((brandName) => ({
    value: brandName,
    label: brandName.charAt(0).toUpperCase() + brandName.slice(1),
  }));

  const options = [    
    { value: "relevance", label: "Relevance" },
    { value: "priceAsc", label: "Price (Low to High)" },
    { value: "priceDesc", label: "Price (High to Low)" },
    { value: "discountHigh", label: "Discount (High to Low)" },
    { value: "nameAZ", label: "Name (A to Z)" },
  ];


  console.log("products for filter:", product)

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setSortOption(value);
    setIsOpen(false);
  
    let sortedProducts = [...product];


  
    switch (value) {
      case "priceAsc":
        sortedProducts.sort((a, b) => a.sell_price - b.sell_price);
        break;
      case "priceDesc":
        sortedProducts.sort((a, b) => b.sell_price - a.sell_price);
        break;
      case "discountHigh":
        sortedProducts.sort((a, b) => b.offer - a.offer);
        break;
      case "nameAZ":
        sortedProducts.sort((a, b) => a.Product_name.localeCompare(b.Product_name));
        break;
      case "relevance":
      default:
        // Default sorting logic
        break;
    }
  
    setProduct(sortedProducts);
  };

  const parentRef = useRef(null);
  const childRef = useRef(null);

  //Infinit scroll

  const loadMoreProducts = () => {
    if (hasMoreProducts()) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  
  console.log("check current page:", currentPage)

  const hasMoreProducts = () => {
    return productsLength < productsCount;
  };

  const closeCartSidebar = () => {
    setIsCartOpen(false);
    document.body.style.overflow = "auto"; // Enable scrolling
  };

  return (
    <>
      <MetaData title="All Products | Vivimart" />

      <main className="w-full mt-48 lg:mt-24">
        <div
          className="flex lg:h-[118rem] lg:mx-28 overflow-hidden"
          ref={parentRef}
        >
          

          <div className="flex-1 w-[120px] lg:w-full mt-6 hidden-scrollbar flex-grow">
            
                <div className="flex items-center justify-between mb-2 text-gray-900 p-2 font-semibold text-[13px] shadow-[0px 0px 10px 0px rgba(0, 0, 0, 0.07)] w-full hidden lg:flex">
                  <div className="flex items-center">
                    <span className="font-sans text-lg">
                      Buy {brandName} Online
                    </span>
                  </div>
                  <div className="lg:ml-auto lg:pr-3 flex flex-cols gap-2 justify-center text-center">
                    <span className="font-sans text-sm mt-2">Sort by</span>
                    <div className="">
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="p-2 border rounded-lg bg-white w-[11rem] focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-between"
                      >
                        <span className="text-center truncate">
                          {options.find((option) => option.value === sortOption)
                            ?.label || "Select an option"}
                        </span>
                        <svg
                          className="ml-2 fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M7 10l5 5 5-5H7z" />
                        </svg>
                      </button>

                      {isOpen && (
                        <ul className="absolute w-[11rem] rounded-lg border bg-white shadow-md z-10">
                          {options.map((option) => (
                            <li
                              key={option.value}
                              onClick={() => handleOptionClick(option.value)}
                              className="px-4 py-2 text-start hover:bg-blue-50 cursor-pointer"
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="w-full md:h-[100rem] lg:h-[110rem] h-[70rem] md:w-auto lg:w-auto overflow-y-auto hidden-scrollbar lg:mt-3 lg:mx-4"
                  ref={childRef}
                >
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={loadMoreProducts}
                    hasMore={productsLength < productsCount}
                    loader={
                      productsLength === productsCount ? <Loader /> : null
                    }
                    useWindow={true}
                    dataLength={product.length}
                  >
                    <div className="grid grid-cols-2 gap-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 lg:gap-2 m-2">
                      {product.length > 0 ? (
                        product.map((item) => (
                          <Product
                            key={item.Product_id}
                            id={item.Product_id}
                            product={item}
                          />
                        ))
                      ) : (
                        <div className="flex items-center justify-center">
                          <div>No products found</div>
                        </div>
                      )}
                    </div>
                  </InfiniteScroll>
                </div>            
          </div>
          {cartItems.length > 0 && !isCartOpen && (
            <div className="fixed left-3 right-3 flex z-[10001] bg-[#0c831f] bottom-3 text-white rounded-lg p-3.5 pl-3 pr-4 mx-auto justify-between transition-transform duration-300 transform translate-y-0 opacity-100 lg:hidden">
              <div className="flex justify-between gap-3">
                <ShoppingCartIcon />
                <div>
                  {cartItems.length} item{cartItems.length !== 1 && "s"}
                </div>
              </div>
              <div
                onClick={() => setIsCartOpen(true)}
                className="flex justify-center items-center font-sans text-[17px] leading-[22px]"
              >
                View Cart
                <ArrowRightIcon />
              </div>
            </div>
          )}
          {isCartOpen && (
            <div className="fixed inset-0 z-40 flex">
              <div
                className="fixed inset-0 opacity-50 backdrop-blur-sm"
                onClick={closeCartSidebar}
              ></div>
              <div className="ml-auto">
                <Cart
                  closeCartSidebar={closeCartSidebar}
                  setIsEdit={setIsEdit}
                  setIsOpen={setIsOpen}
                />
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default BrandProducts;