import {
    GET_PAYMENT_GATEWAYS_REQUEST,
    GET_PAYMENT_GATEWAYS_SUCCESS,
    GET_PAYMENT_GATEWAYS_FAIL,
    GET_PAYMENT_GATEWAY_BY_NAME_REQUEST,
    GET_PAYMENT_GATEWAY_BY_NAME_SUCCESS,
    GET_PAYMENT_GATEWAY_BY_NAME_FAIL,
    UPDATE_PAYMENT_GATEWAY_REQUEST,
    UPDATE_PAYMENT_GATEWAY_SUCCESS,
    UPDATE_PAYMENT_GATEWAY_FAIL,
    TOGGLE_PAYMENT_GATEWAY_STATUS_REQUEST,
    TOGGLE_PAYMENT_GATEWAY_STATUS_SUCCESS,
    TOGGLE_PAYMENT_GATEWAY_STATUS_FAIL,
    CLEAR_ERRORS,
} from "../constants/paymentGatewayConstants";

const initialState = {
    gateways: [],
    gatewayByName: null, // New state for a single gateway
    loading: false,
    error: null,
};

export const paymentGatewayReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYMENT_GATEWAYS_REQUEST:
        case GET_PAYMENT_GATEWAY_BY_NAME_REQUEST:
        case UPDATE_PAYMENT_GATEWAY_REQUEST:
        case TOGGLE_PAYMENT_GATEWAY_STATUS_REQUEST:
            return { ...state, loading: true };

        case GET_PAYMENT_GATEWAYS_SUCCESS:
            return { ...state, loading: false, gateways: action.payload };

        case GET_PAYMENT_GATEWAY_BY_NAME_SUCCESS:
            return { ...state, loading: false, gatewayByName: action.payload };

        case UPDATE_PAYMENT_GATEWAY_SUCCESS:
        case TOGGLE_PAYMENT_GATEWAY_STATUS_SUCCESS:
            return { ...state, loading: false, success: true };

        case GET_PAYMENT_GATEWAYS_FAIL:
        case GET_PAYMENT_GATEWAY_BY_NAME_FAIL:
        case UPDATE_PAYMENT_GATEWAY_FAIL:
        case TOGGLE_PAYMENT_GATEWAY_STATUS_FAIL:
            return { ...state, loading: false, error: action.payload };

        case CLEAR_ERRORS:
            return { ...state, error: null };

        default:
            return state;
    }
};
