import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalletDetails } from "../../actions/walletAction";
import { clearErrors, getVirtualAccountIdByStoreId } from "../../actions/virtualAccountActions";
import { getAllOrders } from "../../actions/orderAction";
import SingleAreaChart from "../Charts/SingleAreaChart";
import WithdrawModal from "./WithdrawModal";
import { getSocket } from "../../utils/socket"; // Import socket utility
import { getStoreById } from "../../actions/storeAction";

const MainData = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { store } = useSelector((state) => state.store);
  const { orders } = useSelector((state) => state.allOrders)
  const wallet = useSelector((state) => state.wallet)
  const { virtualAccountId, error } = useSelector((state) => state.virtualAccount);
  const [totalRoyalty, setTotalRoyalty] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (user.id) {
      dispatch(getAllOrders(user.id));
      dispatch(getStoreById(user.id));
    }
  }, [dispatch, user.id]);

  const storeId = user?.id;

    useEffect(() => {
        if (storeId) {
            dispatch(getVirtualAccountIdByStoreId(storeId));
        }

        // Clear errors when the component unmounts
        return () => {
            dispatch(clearErrors());
        };
    }, [dispatch, storeId]);

    useEffect(() => {
      const socket = getSocket();

      socket.on('holdingsUpdated', (data) => {
          if (data.storeId === storeId) {
              console.log('Holdings updated:', data);
              dispatch(getStoreById(user.id)); // Refetch user data to get updated holdings
          }
      });

      socket.on('withdrawLimitUpdated', (data) => {
          if (data.storeId === storeId) {
              console.log('Withdraw limit updated:', data);
              dispatch(getStoreById(user.id)); // Refetch user data to get updated withdraw limit
          }
      });

      return () => {
          socket.off('holdingsUpdated');
          socket.off('withdrawLimitUpdated');
      };
  }, [dispatch, storeId]);


     // Log the virtual account ID and any errors
  useEffect(() => {
    if (error) {
      console.error("Error fetching virtual account ID:", error);
    }
    if (virtualAccountId) {
      dispatch(getWalletDetails(virtualAccountId)); // Fetch wallet details using the virtual account ID
    }
  }, [dispatch, virtualAccountId, error]);

  const getOrderCounts = (status) => {
    return orders.filter(order => order.status === status).length;
  }

  useEffect(() => {
    dispatch(getWalletDetails(virtualAccountId)); // Pass the virtual account ID to the action
}, [dispatch, virtualAccountId]);

const getOrderItemCountsByShipmentStatus = (status) => {
  let count = 0;

  // Iterate through each order
  orders.forEach((order) => {

    // Iterate through each shipment in the order
    order.shipments.forEach((shipment) => {

      // Check the order items in the shipment
      shipment.items.forEach((item) => {
        // Find the corresponding order item based on product_id
        const orderItem = order.order_items.find(
          (orderItem) => orderItem.product_id === item.product_id
        );

        // Log the found order item
        if (orderItem) {
          
          // If the order item exists and its status matches the specified status, increment the count
          if (orderItem.status === status) {
            count++;
          }
        } else {
          console.log(`No matching order item found for product ID: ${item.product_id}`);
        }
      });
    });
  });
  return count;
};

const calculateTotalAcceptedOrderItemsAmount = () => {
  return orders.reduce((total, order) => {
      // Filter order_items with status "Accepted"
      const acceptedItems = order.order_items.filter(item => item.status === "Accepted");
      
      // Sum the total value of accepted items (sell_price * quantity)
      const acceptedItemsTotal = acceptedItems.reduce((itemTotal, item) => {
          const itemValue = (parseFloat(item.sell_price) || 0) * (item.quantity || 0); // Multiply sell_price by quantity
          return itemTotal + itemValue; // Add to the total for this order
      }, 0);
      
      // Add to the overall total
      return total + acceptedItemsTotal;
  }, 0);
};
    // Function to aggregate products
    const aggregateProducts = orders => {
      const productMap = {};
  
      orders.forEach(order => {
        order.order_items.forEach(item => {
          const productId = item.product_id;
          const productName = item.Product_name;
          const productImage = item.product_image_0; // Assuming this is the image URL
          const quantity = item.quantity;
  
          if (productMap[productId]) {
            productMap[productId].quantity += quantity; // Sum the quantities
          } else {
            productMap[productId] = {
              name: productName,
              image: productImage,
              quantity: quantity,
            };
          }
        });
      });
  
      return Object.values(productMap); // Return an array of aggregated products
    };
  
    const aggregatedProducts = aggregateProducts(orders);
  
    // Sort the aggregated products by quantity in descending order and get the top 4
    const topProducts = aggregatedProducts
      .sort((a, b) => b.quantity - a.quantity)
      .slice(0, 4);


      const calculateTotalAcceptedOrderItemsAmountForCOD = () => {
        return orders.reduce((total, order) => {
            // Check if the payment method is "COD"
            if (order.payment_method === "COD") {
                // Filter order_items with status "Accepted"
                const acceptedItems = order.order_items.filter(item => item.status === "Accepted");
                
                // Sum the total value of accepted items (sell_price * quantity)
                const acceptedItemsTotal = acceptedItems.reduce((itemTotal, item) => {
                    const itemValue = (parseFloat(item.sell_price) || 0) * (item.quantity || 0); // Multiply sell_price by quantity
                    return itemTotal + itemValue; // Add to the total for this order
                }, 0);
                
                // Add to the overall total
                return total + acceptedItemsTotal;
            }
            return total; // If payment method is not "COD", return the total as is
        }, 0);
    };


    const holdings = store?.holdings || 0; // Fallback to 0 if store is null
const royalty = store?.royalty || 0; // Fallback to 0 if store is null
const withdrawLimit = store?.withdraw_limit || 0; // Fallback to 0 if store is null

  

  return (
    <>
      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
        <div className="p-2 mb-4">
          <h1 className="text-lg font-bold">Order Analytics</h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/Pending.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                All Orders
              </h4>
            </div>
            <h2 className="text-blue-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("New")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/Confirmed.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Confirmed
              </h4>
            </div>
            <h2 className="text-green-500 text-xs sm:text-xl font-bold">
              {getOrderItemCountsByShipmentStatus("Accepted")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/Packaging.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Forwarded
              </h4>
            </div>
            <h2 className="text-red-500 text-xs sm:text-xl font-bold">
              {getOrderItemCountsByShipmentStatus("Forwarded")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/out-of-delivery.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Out for Delivery
              </h4>
            </div>
            <h2 className="text-green-500 text-xs sm:text-xl font-bold">
              {getOrderItemCountsByShipmentStatus("Dispatched")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/delivered.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Delivered
              </h4>
            </div>
            <h2 className="text-green-500 text-xs sm:text-xl font-bold">
              {getOrderItemCountsByShipmentStatus("Delivered")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/canceled.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Cancelled
              </h4>
            </div>
            <h2 className="text-red-500 text-xs sm:text-xl font-bold">
              {getOrderItemCountsByShipmentStatus("Cancelled")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/returned.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Returned
              </h4>
            </div>
            <h2 className="text-red-500 text-xs sm:text-xl font-bold">
              {getOrderItemCountsByShipmentStatus("Returned")}
            </h2>
          </div>
        </div>
      </div>

      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4 mt-8">
        <div className="w-full">
          <SingleAreaChart orders={orders} />
        </div>
      </div>

      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4 mt-8">
        <div className="p-2 mb-4 flex justify-between">
          <h1 className="text-lg font-bold">Store Wallet</h1>
          {/* <button className="text-white font-semibold px-2 py-1 rounded-md bg-primary-blue" onClick={handleCreatePaymentLink}>Add Fund</button> */}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* First Column */}
          <div
            className="lg:col-span-1 flex flex-col justify-center items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-6 h-full cursor-pointer transition-all duration-200 ease-in-out"            
          >
            <div className="flex flex-col items-center mb-6">
              <img
                src="/images/withdraw.png"
                alt="Withdraw"
                className="w-16 h-16 mb-3"
              />
              <h4 className="text-black text-lg sm:text-xl font-bold mb-1">
                Rs. {withdrawLimit}{" "}
                {/* Display the calculated withdrawable amount */}
              </h4>
              <h4 className="text-gray-500 text-sm sm:text-base font-medium">
                Withdraw Limit
              </h4>
            </div>
            <button className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-lg" onClick={() => setIsModalOpen(true)} >
              Withdraw
            </button>
          </div>

          {/* Withdraw Modal */}
      <WithdrawModal 
        isOpen={isModalOpen}
        withdrawLimit={withdrawLimit}
        onClose={() => setIsModalOpen(false)} 
        storeId={storeId} 
      />

          {/* Second Column */}
          <div className="lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div
                className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
                onClick={() => navigate("/store/orders")}
              >
                <div className="flex flex-col mr-4 flex-grow">
                  {" "}
                  {/* Add flex-grow to take up remaining space */}
                  <h2 className="text-black text-xl font-bold mb-1">
                    Rs. {calculateTotalAcceptedOrderItemsAmount()}
                  </h2>
                  <h4 className="text-gray-500 font-medium text-sm">
                    Order Value
                  </h4>
                </div>
                <img
                  src="/images/pw.png"
                  alt="Packaging"
                  className="w-12 h-12 ml-auto" // Move image to the right end
                />
              </div>
              <div
                className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
                onClick={() => navigate("/store/orders")}
              >
                <div className="flex flex-col mr-4 flex-grow">
                  {" "}
                  {/* Add flex-grow to take up remaining space */}
                  <h2 className="text-black text-xl font-bold mb-1">
                    Rs. {royalty}
                  </h2>
                  <h4 className="text-gray-500 font-medium text-sm">Royalty</h4>
                </div>
                <img
                  src="/images/tcg.png"
                  alt="Packaging"
                  className="w-12 h-12 ml-auto" // Move image to the right end
                />
              </div>
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                  Rs. {holdings}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">
                  Holding Amount
                </h4>
              </div>
              <img
                src="/images/aw.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                  Rs. {wallet.totalDeliveryCharge}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">
                  Total Delivery Charge Earned
                </h4>
              </div>
              <img
                src="/images/tdce.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                  Rs. {wallet.totalTaxGiven}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">
                  Total Tax Given
                </h4>
              </div>
              <img
                src="/images/ttg.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                  Rs. {calculateTotalAcceptedOrderItemsAmountForCOD()}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">
                  Collected Cash
                </h4>
              </div>
              <img
                src="/images/cc.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
          </div>
        </div>
      </div>
      {/* New Section for Popular and Top Selling Products */}
      <div className="mt-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-bold mb-4">Most Popular Products</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {topProducts.map((product, index) => (
                <div
                  key={index}
                  className="bg-gray-50 flex flex-col items-center rounded-md border border-slate-100 p-2 w-50 hover:shadow-md md:p-1"
                >
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-14 h-14 mb-2 border border-blue-400 rounded-md sm:w-20 h-20"
                  />
                  <h3 className="text-sm font-medium mb-1">{product.name}</h3>
                  <span className="text-xs">Sales: {product.quantity}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-4">
            <h2 className="text-xl font-bold mb-4">Top Selling Products</h2>
            <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
              {topProducts.map((product, index) => (
                <div
                  className="flex justify-between items-center bg-gray-50 text-white rounded-md hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
                  onClick={() => navigate("/store/orders")}
                >
                  <div className="flex items-center">
                    <img
                      src={product.image} // Path to your image
                      alt="Confirmed"
                      className="w-12 h-12 border border-blue-400" // Adjust the size as needed
                    />
                    <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                      {product.name}
                    </h4>
                  </div>
                  <h2 className="text-blue-400 text-xs sm:text-xs border border-slate-100 p-1">
                    Sold: {product.quantity}
                  </h2>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainData;
