import {
    GET_VIRTUAL_ACCOUNT_REQUEST,
    GET_VIRTUAL_ACCOUNT_SUCCESS,
    GET_VIRTUAL_ACCOUNT_FAIL,
    CLEAR_ERRORS
} from '../constants/virtualAccountConstants';

const initialState = {
    loading: false,
    virtualAccountId: null,
    error: null
};

const virtualAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VIRTUAL_ACCOUNT_REQUEST:
            return { ...state, loading: true };
        case GET_VIRTUAL_ACCOUNT_SUCCESS:
            return { loading: false, virtualAccountId: action.payload, error: null };
        case GET_VIRTUAL_ACCOUNT_FAIL:
            return { loading: false, virtualAccountId: null, error: action.payload };
        case CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};

export default virtualAccountReducer;