import MetaData from "../MetaData";
import React, { useState } from 'react';
import background from "../../../assets/images/bg_1.jpg";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";

const GoogleForm = () => {
  const [formData, setFormData] = useState({
    help: "",
    email: "",
    phone: "",
    details: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
  };

  const backgroundImageStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const formStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    maxWidth: "500px",
    width: "100%",
  };

  return (
    <div>
      <header class="bg-primary-blue p-4 flex w-auto justify-between items-center">
        <div className="flex col-span-1">
          <Link className="h-8 mr-4 sm:mr-4 pr-14 md:h-12 pr-3" to="/">
            <img
              draggable="false"
              className="h-full w-full object-contain"
              src={logo}
              alt="Vivimart Logo"
            />
          </Link>
        </div>
        <div class="text-black">
          <i class="fas fa-bars"></i>
        </div>
      </header>

      <div style={backgroundImageStyle}>
        <div className="lg:mr-20 mx-10 flex flex-col lg:flex-row mt-6 items-center lg:justify-between">
          <div className="text-[18px] lg:text-[30px] text-black font-semibold mb-4 lg:mb-0 lg:mr-4">
            Got questions? We're here to help—reach out today!
          </div>
          <form
            onSubmit={handleSubmit}
            style={formStyle}
            className="w-full lg:w-1/2"
          >
            <h2 className="text-[20px] font-semibold">Contact Us</h2>
            <div className="mt-6">
              <label>
                How can we help you?
                <input
                  type="text"
                  name="help"
                  value={formData.help}
                  onChange={handleChange}
                  className="bg-gray-50"
                  style={{
                    width: "100%",
                    padding: "8px",
                    margin: "10px 0",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </label>

              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  className="bg-gray-50"
                  value={formData.email}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    margin: "10px 0",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </label>

              <label>
                Phone Number:
                <input
                  type="tel"
                  name="phone"
                  className="bg-gray-50"
                  value={formData.phone}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    margin: "10px 0",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </label>

              <label>
                Enter the details:
                <textarea
                  name="details"
                  className="bg-gray-50"
                  value={formData.details}
                  onChange={handleChange}
                  rows="4"
                  style={{
                    width: "100%",
                    padding: "8px",
                    margin: "10px 0",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </label>
            </div>

            <button
              type="submit"
              className="bg-primary-blue"
              style={{
                padding: "10px 20px",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GoogleForm;
