import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HelpIcon from '@mui/icons-material/Help';
import paymentMethods from '../../../assets/images/payment-methods.svg';
import instagram from "../../../assets/images/instagram.png";
import facebook from "../../../assets/images/facebook.png";
import twitter from "../../../assets/images/twitter2.png";
import youtube from "../../../assets/images/youtube.png";
import whatsapp from "../../../assets/images/whatsapp.png"

const footerLinks = [
  {
    title: "about",
    links: [
      { name: "Contact Us", redirect: "/contact-us" },
      { name: "About Us", redirect: "/about" },
    ],
  },
  {
    title: "help",
    links: [
      { name: "Shipping", redirect: "/shipping-policy" },
      { name: "Cancellation & Returns", redirect: "/return-replacement" },
    ],
  },
  {
    title: "policy",
    links: [
      { name: "Return Policy", redirect: "/return-replacement" },
      { name: "Terms Of Use", redirect: "/terms" },
      { name: "Privacy", redirect: "/privacy" },
    ],
  },
  {
    title: "social",
    links: [
      {
        name: "Facebook",
        redirect: "https://www.facebook.com/profile.php?id=61551726244471",
      },
      { name: "X", redirect: "https://x.com/vivimart_in" },
      {
        name: "Instagram",
        redirect:
          "https://www.instagram.com/vivimart.in/?fbclid=IwAR0dgR9TA8qXFaE5tKkUe8GbmzaRkNs10GVgesEwSZTiqg96KFJgYe-CXgw",
      },
      { name: "YouTube", redirect: "https://www.youtube.com/@vivimart" },
    ],
  },
];

const socialMediaLinks = [
  {
    image: twitter, // Use the imported variable
    alt: "X",
    link: "https://twitter.com/vivimart_in",
  },
  {
    image: youtube, // Use the imported variable
    alt: "YouTube",
    link: "https://www.youtube.com/@vivimart",
  },
  {
    image: facebook, // Use the imported variable
    alt: "Facebook",
    link: "https://www.facebook.com/profile.php?id=61551726244471",
  },
  {
    image: instagram, // Use the imported variable
    alt: "Instagram",
    link: "https://www.instagram.com/vivimart.in/",
  },
  {
    image: whatsapp, // Use the imported variable
    alt: "WhatsApp",
    link: "https://wa.me/your-whatsapp-number",
  },
];

const Footer = () => {
  const location = useLocation();
  const [adminRoute, setAdminRoute] = useState(false);
  const [storeRoute, setstoreRoute] = useState(false);

  useEffect(() => {
    setAdminRoute(location.pathname.split("/", 2).includes("admin"));
    setstoreRoute(location.pathname.split("/", 2).includes("store"));
  }, [location]);

  return (
    <>
      {!adminRoute && !storeRoute && (
        <>
          <footer className="w-full py-1 mt-3 sm:py-4 px-4 sm:px-0 bg-primary-darkBlue text-white text-xs border-b border-gray-600 flex flex-col sm:flex-row overflow-hidden">
            <div className="w-full grid grid-cols-2 sm:w-7/12 lg:flex sm:flex">
              {footerLinks.map((el, i) => (
                <div
                  className="w-full sm:w-1/5 flex flex-col gap-2 my-3 sm:my-6 ml-5"
                  key={i}
                >
                  <h2 className="text-primary-grey mb-2 uppercase">
                    {el.title}
                  </h2>
                  {el.links.map((item, i) =>
                    item.redirect.startsWith("/") ? (
                      <Link
                        to={item.redirect}
                        className="hover:underline"
                        key={i}
                      >
                        {item.name}
                      </Link>
                    ) : (
                      <a
                        href={item.redirect}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline"
                        key={i}
                      >
                        {item.name}
                      </a>
                    )
                  )}
                </div>
              ))}
            </div>
            <div className="ml-5 mt-4 sm:hidden lg:hidden">
              <h2 className="text-primary-grey uppercase">FOLLOW US</h2>
              <div className="flex mt-5 flex-1">
                {socialMediaLinks.map((item, index) => (
                  <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-8"
                  >
                    <img src={item.image} alt={item.alt} className="w-7 h-7" />
                  </a>
                ))}
              </div>
            </div>

            <div className="border-gray-600 h-36 w-1 border-l mr-5 mt-6 hidden sm:block"></div>
            <div className="w-full lg:w-4/12 my-6 mx-5 sm:mx-0 flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between">
              <div className="w-full">
                <h2 className="text-primary-grey">Contact Us:</h2>
                <p className="mt-2 leading-5">
                  Vivimart,
                  <br />
                  Address: 3rd Floor, Door (O)No.736/6 (N)No.1045,
                  <br />
                  Avinashi Road, Coimbatore-641018, Tamilnadu, India.
                  <br />
                  Phone: +918686865851.
                  <br />
                  Email: <a href="mailto:cs@vivimart.in">cs@vivimart.in</a>
                </p>
              </div>
            </div>
            <div className="border-gray-600 h-36 w-1 border-l mr-5 mt-3 hidden sm:block"></div>
            <div className="w-full sm:w-5/12 my-6 mx-5 sm:mx-0 flex flex-col sm:flex-row gap-2 sm:gap-0">
              <div className="w-full sm:w-1/2">
                <h2 className="text-primary-grey">Download App:</h2>
                <p className="mt-2 flex flex-col items-start">
                  <div className="flex">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.vivmart.customer&pcampaignid=web_share"
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center gap-2"
                    >
                      <span className="flex w-32">
                        <img
                          src="https://ik.imagekit.io/efsdltq0e/icons/playStore.png?updatedAt=1729839550988"
                          alt="Play Store"
                          className="w-full h-full object-contain"
                        />
                      </span>
                    </a>
                    <a
                      href="https://apps.apple.com/in/app"
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center gap-2"
                    >
                    <span className="flex justify-center w-32">
                      <img
                        src="https://ik.imagekit.io/efsdltq0e/icons/appStore.png?updatedAt=1729839484218"
                        alt="App Store"
                        className="w-full h-full object-contain"
                      />
                    </span>
                    </a>
                  </div>
                </p>
              </div>
            </div>
          </footer>

          <div className="px-16 sm:px-0 lg:px-16 py-6 w-full bg-primary-darkBlue hidden sm:flex justify-between items-center text-sm text-white">
            <a
              href=""
              rel="noreferrer"
              target="_blank"
              className="flex items-center gap-2"
            >
              <span className="text-yellow-400">
                <CardGiftcardIcon sx={{ fontSize: "20px" }} />
              </span>{" "}
              Offer Zone
            </a>
            <a
              href=""
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2"
            >
              <span className="text-yellow-400">
                <HelpIcon sx={{ fontSize: "20px" }} />
              </span>{" "}
              Help Center
            </a>
            <span>Copyright © 2024 All rights reserved by ViviMart</span>
            <img draggable="false" src={paymentMethods} alt="Card Payment" />
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
