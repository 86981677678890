import { useEffect, useState } from "react";
import { ADMIN_CATEGORY_API } from "../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";

const POS = () => {
  const [cart, setCart] = useState([]);
  const [isPlaceOrder, setIsPlaceOrder] = useState(false); // State for modal

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("storeCart")) || [];
    setCart(storedCart);
  }, []);

  const handleAddToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);
      let updatedCart;
      if (existingProduct) {
        updatedCart = prevCart.map((item) =>
          item.id === product.id
            ? { ...item, qty: item.qty + product.quantity }
            : item
        );
      } else {
        updatedCart = [...prevCart, { ...product, qty: product.quantity }];
      }

      localStorage.setItem("storeCart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const handleOpenModal = () => {
    setIsPlaceOrder(true);
  };

  const handleCloseModal = () => {
    setIsPlaceOrder(false);
  };

  return (
    <div className="flex flex-col md:flex-row gap-6 p-4">
      <ProductSection onAddToCart={handleAddToCart} setCart={setCart} />

      <BillingSection
        cart={cart}
        setCart={setCart}
        onOpenModal={handleOpenModal}
      />

      {isPlaceOrder && (
        <div
          id="hs-vertically-centered-modal"
          className="fixed top-0 left-0 z-[80] w-full h-full flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white rounded-xl shadow-lg w-1/3 p-4">
            <div className="flex justify-center items-center p-4">
              <SmsFailedOutlinedIcon
                className="text-red-500"
                style={{ fontSize: "48px" }}
              />
            </div>
            <div className="p-4">
              <div className="flex justify-center items-center tracking-wide">
                <p className="text-[25px]">Are you sure?</p>
              </div>
              <div className="flex justify-center items-center tracking-wide">
                <p className="text-gray-500">Want to place this Order?</p>
              </div>
            </div>
            <div className="flex justify-center items-center p-4">
              <button
                onClick={handleCloseModal}
                className="w-32 h-12 bg-red-500 text-white font-semibold rounded-lg"
              >
                No
              </button>
              <button className="w-32 h-12 bg-blue-600 text-white rounded-lg ml-2 font-semibold">
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ProductSection = ({ onAddToCart, setCart }) => {
  const [categoryData, setCategoryData] = useState([]);
  const [products, setProducts] = useState([]); // State for products
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Fixed typo from isModelOpen to isModalOpen

  useEffect(() => {
    fetch(ADMIN_CATEGORY_API)
      .then((response) => response.json())
      .then((categoryData) => {
        const uniqueCategories = Array.from(
          new Map(
            categoryData.map((item) => [item.category_name, item])
          ).values()
        );
        // Filter to only include active categories
        const activeCategories = uniqueCategories.filter(
          (item) => item.is_active === 1
        );
        setCategoryData(activeCategories);

        // Set the first category as the selected category and fetch products
        if (activeCategories.length > 0) {
          const firstCategory = activeCategories[0].category_name;
          setSelectedCategory(firstCategory);
          fetchProducts(firstCategory); // Fetch products for the first category
        }
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  // Function to fetch products based on selected category
  const fetchProducts = (categoryName) => {
    fetch(`https://backend.vivimart.in/api/products/category/${categoryName}`)
      .then((response) => response.json())
      .then((data) => {
        // Assuming data is structured as [categoryId, productsArray]
        const productsArray = data[1]; // Get the array of products
        setProducts(productsArray); // Set the fetched products
        console.log("Product data:", productsArray);
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  // Handle category change
  const handleCategoryChange = (event) => {
    const categoryName = event.target.value;
    setSelectedCategory(categoryName);
    fetchProducts(categoryName); // Fetch products based on selected category
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleAddToCart = (product) => {
    setCart((prevCart) => {
      // Check if the product already exists in the cart
      const existingProduct = prevCart.find(
        (item) => item.Product_id === product.Product_id
      );
      let updatedCart;
      if (existingProduct) {
        // If it exists, update the quantity
        updatedCart = prevCart.map((item) =>
          item.Product_id === product.Product_id
            ? { ...item, qty: item.qty + product.quantity } // Update quantity
            : item
        );
      } else {
        // If it doesn't exist, add it to the cart with only the required properties
        updatedCart = [
          ...prevCart,
          {
            Product_id: product.Product_id,
            Product_name: product.Product_name,
            Weight: product.Weight, // Assuming product has a Weight property
            purchase_price: product.purchase_price,
            qty: product.quantity,
          },
        ];
      }

      // Save updated cart to localStorage
      localStorage.setItem("storeCart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  return (
    <div className="flex-1 bg-white p-4 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4">Product Section</h2>
      <div className="flex items-center gap-4 mb-6">
        <select
          className="w-1/2 border rounded-lg p-2"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          {categoryData.map((item) => (
            <option key={item.id} value={item.category_name}>
              {item.category_name}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Search by name"
          className="w-1/2 border rounded-lg p-2"
        />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {products.map((product) => (
          <div
            key={product.id}
            className="p-4 border rounded-lg flex flex-col items-center cursor-pointer"
            onClick={() => handleProductClick(product)}
          >
            <div className="bg-gray-100 h-32 w-24">
              <img
                className="zoom-image bg-white w-full h-full object-cover"
                src={product.Prodouct_img_0}
                alt={product.Product_name}
                onError={(e) => (e.target.src = "/images/Noimage.jpg")}
              />
            </div>
            <h3 className="text-sm text-center mt-4 line-clamp-2">
              {product.Product_name}
            </h3>
            <p className="text-blue-500 font-semibold mt-2">
              ₹ {product.purchase_price}
            </p>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <ProductModal
          product={selectedProduct}
          onClose={handleCloseModal}
          onAdd={handleAddToCart} // Pass the updated function
        />
      )}
    </div>
  );
};

const ProductModal = ({ product, onClose, onAdd }) => {
  const [quantity, setQuantity] = useState(1); // State for quantity

  if (!product) return null;

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1)); // Prevent quantity from going below 1
  };

  const handleAddToCart = () => {
    onAdd({
      Product_id: product.Product_id, // Pass only the required properties
      Product_name: product.Product_name,
      Weight: product.Weight, // Assuming product has a Weight property
      purchase_price: product.purchase_price,
      quantity, // Pass the quantity
    });
    onClose(); // Close the modal after adding
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 max-w-2xl">
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="flex">
          <div className="w-1/3">
            <img
              className="w-full"
              src={product.Prodouct_img_0}
              alt={product.Product_name}
              onError={(e) => (e.target.src = "/images/Noimage.jpg")}
            />
          </div>
          <div className="w-2/3 pl-6">
            {/* <div className="flex items-center mb-4">
              <span className="bg-green-100 text-green-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">In stock</span>
            </div> */}
            <h1 className="text-2xl font-bold mb-2">{product.Product_name}</h1>
            <p className="text-2xl text-blue-600 mb-4">
              ₹ {product.purchase_price}
            </p>
            <div className="flex items-center mb-4">
              <span className="mr-2">Qty:</span>
              <div className="flex items-center border rounded">
                <button onClick={handleDecrease} className="px-2 py-1 border-r">
                  -
                </button>
                <div className="w-12 text-center">{quantity}</div>
                <button onClick={handleIncrease} className="px-2 py-1 border-l">
                  +
                </button>
              </div>
            </div>
            <div className="flex items-center mb-4">
              <span className="mr-2">Total Price:</span>
              <span className="text-xl text-blue-600">
                ₹ {product.purchase_price}
              </span>
              <span className="text-sm text-gray-500 ml-2">(Tax included)</span>
            </div>
            <button
              onClick={handleAddToCart}
              className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
            >
              Add to cart
            </button>
          </div>
        </div>
        <div className="mt-6">
          <p className="text-sm">
            <strong>Category:</strong> {product.Categories}
          </p>
          <p className="text-sm">
            <strong>Brand:</strong> {product.Brand_name}
          </p>
        </div>
      </div>
    </div>
  );
};

const BillingSection = ({ cart, setCart, onOpenModal }) => {
  const [isPlaceOrder, setIsPlaceOrder] = useState(false);

  const handleOpenModal = () => {
    isPlaceOrder(true);
  };

  const handleCloseModal = () => {
    isPlaceOrder(false);
  };

  const handleAddCustomer = () => {
    // Logic to add new customer
  };

  const handleClearCart = () => {
    // Logic to clear the cart
    setCart([]); // Clear the cart state
    localStorage.removeItem("storeCart"); // Remove from localStorage
  };

  const handleRemoveCart = (Product_id) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter(
        (item) => item.Product_id !== Product_id
      );
      // Save updated cart to localStorage
      localStorage.setItem("storeCart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const handleQuantityChange = (Product_id, newQuantity) => {
    const quantity = parseInt(newQuantity, 10); // Convert input to integer
    if (isNaN(quantity) || quantity < 1) return; // Validate quantity

    setCart((prevCart) => {
      const updatedCart = prevCart.map((item) =>
        item.Product_id === Product_id
          ? { ...item, qty: quantity } // Update the quantity
          : item
      );

      // Save updated cart to localStorage
      localStorage.setItem("storeCart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  return (
    <div className="flex-1 bg-white p-4 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4">Billing Section</h2>

      <div className="border-t pt-4">
        <table className="w-full text-left">
          <thead>
            <tr>
              <th>Item</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {cart.map((item) => (
              <tr key={item.id}>
                <td>{item.Product_name}</td>
                <td>
                  <input
                    type="number"
                    value={item.qty}
                    min="1" // Prevents entering less than 1
                    onChange={(e) =>
                      handleQuantityChange(item.Product_id, e.target.value)
                    }
                    className="border rounded p-1 w-16 text-center"
                  />
                </td>
                <td>₹{(item.purchase_price * item.qty).toFixed(2)}</td>
                <td>
                  <button
                    className="text-red-500"
                    onClick={() => handleRemoveCart(item.Product_id)}
                  >
                    <DeleteForeverOutlinedIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between mt-4">
          <div>
            <p>
              Sub Total: ₹
              {cart
                .reduce((acc, item) => acc + item.purchase_price * item.qty, 0)
                .toFixed(2)}
            </p>
            <p>Product Discount: ₹0.00</p>
          </div>
        </div>
        <div className="flex gap-4 mt-6">
          <button className="flex-1 bg-pink-500 text-white p-2 rounded-lg">
            Cancel Order
          </button>
          <button
            className="flex-1 bg-blue-500 text-white p-2 rounded-lg"
            onClick={onOpenModal}
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default POS;
