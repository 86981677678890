import {
    STORE_CREATE_REQUEST,
    STORE_CREATE_SUCCESS,
    STORE_CREATE_FAIL,
    STORE_UPDATE_REQUEST,
    STORE_UPDATE_SUCCESS,
    STORE_UPDATE_FAIL,
    STORE_DELETE_REQUEST,
    STORE_DELETE_SUCCESS,
    STORE_DELETE_FAIL,
    STORE_GET_REQUEST,
    STORE_GET_SUCCESS,
    STORE_GET_FAIL,
    STORE_ENABLE_REQUEST,
    STORE_ENABLE_SUCCESS,
    STORE_ENABLE_FAIL,
    STORE_DISABLE_REQUEST,
    STORE_DISABLE_SUCCESS,
    STORE_DISABLE_FAIL,
    CLEAR_ERRORS,
    GET_STORE_BY_ID_REQUEST,
    GET_STORE_BY_ID_SUCCESS,
    GET_STORE_BY_ID_FAIL,
    VIRTUAL_ACCOUNT_CREATE_REQUEST,
    VIRTUAL_ACCOUNT_CREATE_SUCCESS,
    VIRTUAL_ACCOUNT_CREATE_FAIL,
    ADD_ALLOWED_PAYER_REQUEST,
    ADD_ALLOWED_PAYER_SUCCESS,
    ADD_ALLOWED_PAYER_FAIL,
    GET_ALL_VIRTUAL_ACCOUNTS_REQUEST,
    GET_ALL_VIRTUAL_ACCOUNTS_SUCCESS,
    GET_ALL_VIRTUAL_ACCOUNTS_FAIL,
    CUSTOMER_UPDATE_REQUEST,
    CUSTOMER_UPDATE_SUCCESS,
    CUSTOMER_UPDATE_FAIL,
} from '../constants/storeConstants';
import axiosInstance from '../utils/api'; // Adjust the import path as necessary

// Create Store
export const createStore = (storeData) => async (dispatch) => {
    try {
        dispatch({ type: STORE_CREATE_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const { data } = await axiosInstance.post(`/stores/create`, storeData, config);

        dispatch({
            type: STORE_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: STORE_CREATE_FAIL,
            payload: error.response.data.error,
        });
    }
};

export const getAllVirtualAccounts = () => async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_VIRTUAL_ACCOUNTS_REQUEST });
  
      const { data } = await axiosInstance.get("/virtual-account/virtual-accounts");
  
      dispatch({
        type: GET_ALL_VIRTUAL_ACCOUNTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_VIRTUAL_ACCOUNTS_FAIL,
        payload: error.response?.data?.message || error.message,
      });
    }
  };

// Create Virtual Account
export const createVirtualAccount = (storeData) => async (dispatch) => {
    try {
        dispatch({ type: VIRTUAL_ACCOUNT_CREATE_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axiosInstance.post('/stores/virtual-account-create', storeData, config);

        dispatch({
            type: VIRTUAL_ACCOUNT_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: VIRTUAL_ACCOUNT_CREATE_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Add Allowed Payer
export const addAllowedPayer = (virtualId, payerData) => async (dispatch) => {
    try {
        dispatch({ type: ADD_ALLOWED_PAYER_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axiosInstance.post(`/stores/${virtualId}/add-allowed-payer`, payerData, config);

        dispatch({
            type: ADD_ALLOWED_PAYER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ADD_ALLOWED_PAYER_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Update Store
export const updateStore = (id, storeData) => async (dispatch) => {
    try {
        dispatch({ type: STORE_UPDATE_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const { data } = await axiosInstance.put(`/stores/${id}`, storeData, config);

        dispatch({
            type: STORE_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: STORE_UPDATE_FAIL,
            payload: error.response.data.error,
        });
    }
};

// Delete Store
export const deleteStore = (id) => async (dispatch) => {
    try {
        dispatch({ type: STORE_DELETE_REQUEST });

        await axiosInstance.delete(`/stores/${id}`);

        dispatch({
            type: STORE_DELETE_SUCCESS,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: STORE_DELETE_FAIL,
            payload: error.response.data.error,
        });
    }
};

// Get All Stores
export const getStores = () => async (dispatch) => {
    try {
        dispatch({ type: STORE_GET_REQUEST });

        const { data } = await axiosInstance.get("/stores");


        console.log("get store action:", data)

        dispatch({
            type: STORE_GET_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: STORE_GET_FAIL,
            payload: error.response.data.error,
        });
    }
};

// Get Store by ID
export const getStoreById = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_STORE_BY_ID_REQUEST });

        const { data } = await axiosInstance.get(`/stores/${id}`);

        dispatch({
            type: GET_STORE_BY_ID_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_STORE_BY_ID_FAIL,
            payload: error.response.data.error,
        });
    }
};

// Enable Store
export const enableStore = (id) => async (dispatch) => {
    try {
        dispatch({ type: STORE_ENABLE_REQUEST });

        const { data } = await axiosInstance.put(`/stores/${id}/enable`);

        dispatch({
            type: STORE_ENABLE_SUCCESS,
            payload: { id, is_active: true },
        });
    } catch (error) {
        dispatch({
            type : STORE_ENABLE_FAIL,
            payload: error.response.data.error,
        });
    }
};

// Disable Store
export const disableStore = (id) => async (dispatch) => {
    try {
        dispatch({ type: STORE_DISABLE_REQUEST });

        const { data } = await axiosInstance.put(`/stores/${id}/disable`);

        dispatch({
            type: STORE_DISABLE_SUCCESS,
            payload: { id, is_active: false },
        });
    } catch (error) {
        dispatch({
            type: STORE_DISABLE_FAIL,
            payload: error.response.data.error,
        });
    }
};

// Update Customer Details
export const updateCustomer = (customerId, customerData) => async (dispatch) => {
    try {
        dispatch({ type: CUSTOMER_UPDATE_REQUEST });

        console.log("Sending update request for customerId:", customerId);
        console.log("Customer Data:", customerData);

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axiosInstance.put(`/virtual-account/edit-customer/${customerId}`, customerData, config);

        console.log("API Response:", data);

        dispatch({
            type: CUSTOMER_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error("Error updating customer:", error.response?.data || error.message);
        dispatch({
            type: CUSTOMER_UPDATE_FAIL,
            payload: error.response?.data?.message || error.message,
        });
    }
};

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};