import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { BASE_URL } from "../../config";
import notFound from "../../assets/images/NotFound.png";
import { getStoreIdByPostalCode } from "../../actions/locationAction";

const DetectLoc = ({ setLocation, searchLoc, setIsMobileOpen }) => {
  const dispatch = useDispatch();
  const [detectLocation, setDetectLocation] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [searchLocation, setSearchLocation] = useState("");
  const [addresses, setAddresses] = useState([]);

  // Access the store location and store ID from Redux state
  const { storeLocation, loading, error } = useSelector(
    (state) => state.storeLocation
  );

  console.log("selected location from DetectLoc:", searchLoc);


  // Define fetchCurrentLocation as a separate function
  const fetchCurrentLocation = async (latitude, longitude) => {
    console.log(
      "Fetching current location with lat:",
      latitude,
      "lng:",
      longitude
    );
    try {
      // Change 'latlng' to 'lat' and 'lng'
      const response = await fetch(
        `${BASE_URL}api/geocode?lat=${latitude}&lng=${longitude}`
      );
      const data = await response.json();
      if (response.ok) {
        console.log("Current location data:", data);
        setCurrentLocation(data);
      } else {
        console.error("Location not found");
        setCurrentLocation("Location not found");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      setDetectLocation(true);
    }
  };

  const handleDetectLocation = () => {
    console.log("Detect my location button clicked");
    setDetectLocation(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        console.log("Current position on button click:", position);
        const { latitude, longitude } = position.coords; // Extract latitude and longitude
        await fetchCurrentLocation(latitude, longitude); // Pass coordinates to the function
        setSearchLocation(""); // Reset searchLocation when using current location
      },
      (error) => {
        console.error("Geolocation error:", error);
        setDetectLocation(false);
      }
    );
  };

  useEffect(() => {
    if (searchLocation) {
      handleSearch();
    }
  }, [searchLocation]);

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}api/autocomplete?input=${searchLocation}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const predictions = data.predictions;
      const promises = predictions.map(async (prediction) => {
        const placeId = prediction.place_id;
        const placeResponse = await fetch(
          `${BASE_URL}api/place-details?place_id=${placeId}`
        );
        if (!placeResponse.ok) {
          throw new Error(`HTTP error! Status: ${placeResponse.status}`);
        }
        const placeData = await placeResponse.json();
        const addressComponents = placeData.result.address_components;
        const postalCodeComponent = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );
        const postalCode = postalCodeComponent
          ? postalCodeComponent.long_name
          : null;
        return { ...placeData.result, postal_code: postalCode };
      });
      const addressResults = await Promise.all(promises);
      setAddresses(
        addressResults.filter((address) => address.postal_code !== null)
      );
    } catch (error) {
      console.error("Error searching location:", error);
      // Handle error state here, e.g., show error message to the user
    }
  };

  const handleSelectLocation = (location) => {
    setLocation(location);
    setSearchLocation(location.formatted_address); // Set the selected address
    localStorage.setItem("selectedAddress", location.formatted_address);
    localStorage.setItem("SelectedPostalCode", location.postal_code);

    // Dispatch action to get store ID by postal code
    dispatch(getStoreIdByPostalCode());

    localStorage.removeItem("cartItems");

    localStorage.removeItem("totalQuantity");

    setIsMobileOpen(false);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="detectLoc-slide-up bg-blue-500 p-4 rounded-t-lg w-full h-[40rem] rounded-t-[1rem]"
        style={{ backgroundColor: "rgb(244, 246, 252)" }}
      >
        {searchLoc === "" ? (
          <div className="flex items-center justify-between">
            <div className="font-sans font-bold" style={{ fontSize: "17px" }}>
              Select Your Location
            </div>
          </div>
        ) : (
          <div className="flex justify-between z-20 mb-20">
            <h2 className="font-sans text-base font-semibold leading-normal tracking-normal text-black font-medium">
              Change Location
            </h2>
            <button
              className="text-xs text-gray-600"
              onClick={() => setIsMobileOpen(false)}
            >
              <CloseIcon />
            </button>
          </div>
        )}

        <div className="absolute top-[3rem] pl-2 left-[49%] transform -translate-x-1/2 w-[calc(100%-24px)] h-[54px] z-[9999]">
          <div className="h-full rounded-[12px] bg-white border border-[rgb(207,207,207)] shadow-[2px_2px_12px_0_rgba(0,0,0,0.06)]">
            <div className="flex items-center h-full ">
              <div className="p-2">
                <SearchIcon className="text-green-800" />
              </div>
              <div className="text-sm items-center font-sans w-[16rem] md:w-[21rem] relative">
                <input
                  className="flex-grow flex-shrink w-full focus:outline-none"
                  type="text"
                  placeholder="Search delivery location"
                  value={searchLocation}
                  onChange={(e) => setSearchLocation(e.target.value)}
                />
                {searchLocation && (
                  <button
                    className="absolute text-gray-500 text-[6px]"
                    onClick={() => {
                      setSearchLocation("");
                    }}
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={handleDetectLocation}
          class="absolute top-[7rem] px-2 flex items-center p-[8px_10px] gap-2 rounded-lg border-[0.654px] bg-white text-[12px] font-medium text-[#0c831f] cursor-pointer bottom-[246px] w-[calc(100%-32px)] h-[40px] box-border"
        >
          <span>
            <MyLocationIcon style={{ fontSize: "1rem" }} />
          </span>
          <span style={{ fontSize: "14px" }} className="font-sans">
            Use current location
          </span>
        </div>
        {detectLocation && currentLocation && addresses.length === 0 && (
          <div
            key="current-location"
            className="p-2 relative text-left bg-white border rounded-b-lg cursor-pointer flex mt-36"
            onClick={() => handleSelectLocation(currentLocation)}
          >
            <div className="flex ml-2 items-center justify-center text-center">
              <PlaceOutlinedIcon />
            </div>
            <div className="mt-1 ml-4">
              <div className="font-semibold text-gray-800">
                Current Location
              </div>
              <div className="text-[12px] mt-1">
                {currentLocation.formatted_address || "No Address"}
              </div>
            </div>
          </div>
        )}
        {/* Scrollable Container for Addresses */}
        <div className="mt-28 z-40 overflow-y-auto scroll-smooth scrollbar-hide" style={{height: "22rem"}}>
          {addresses.map((address, index) => {
            // Extracting the city name from address components
            const cityComponent = address.address_components.find((component) =>
              component.types.includes("locality")
            );
            const cityName = cityComponent
              ? cityComponent.long_name
              : "City not specified";

            return (
              <div
                key={index}
                className="mt-4 p-2 relative text-left bg-white rounded-lg cursor-pointer flex"
                onClick={() => handleSelectLocation(address)}
              >
                <div className="flex ml-2 items-center justify-center text-center">
                  <PlaceOutlinedIcon />
                </div>
                <div className="mt-1 ml-4">
                  {/* Display the city name above the address */}
                  <div className="font-semibold text-gray-800">{cityName}</div>
                  <div className="text-[12px] mt-1">
                    {typeof address.formatted_address === "string"
                      ? address.formatted_address
                      : "No Address"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DetectLoc;
