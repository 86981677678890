import {
    SET_WALLET_DETAILS,
    GET_WALLET_REQUEST,
    GET_WALLET_SUCCESS,
    GET_WALLET_FAIL,
    CLEAR_ERRORS
} from "../constants/walletConstants";

const initialState = {
    withdrawableAmount: 0,
    pendingWithdrawal: 0,
    totalCommission: 0,
    alreadyWithdrawn: 0,
    totalDeliveryCharge: 0,
    totalTaxGiven: 0,
    collectedCash: 0,
    loading: false,
    error: null,
};

export const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_WALLET_REQUEST:
            return { ...state, loading: true };
        case GET_WALLET_SUCCESS:
            return { loading: false, ...action.payload };
        case GET_WALLET_FAIL:
            return { ...state, loading: false, error: action.payload };
        case SET_WALLET_DETAILS:
            return { ...state, ...action.payload };
        case CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};