import {
    SET_WALLET_DETAILS,
    GET_WALLET_REQUEST,
    GET_WALLET_SUCCESS,
    GET_WALLET_FAIL,
    CLEAR_ERRORS
} from "../constants/walletConstants";
import axiosInstance from "../utils/api"; // Adjust the import path as necessary

export const getWalletDetails = (virtual_account_id) => async (dispatch) => {
    try {
        dispatch({ type: GET_WALLET_REQUEST });
        const { data } = await axiosInstance.get(`/stores/virtual-account-details/${virtual_account_id}`); // Use the virtual account ID in the endpoint
        dispatch({ type: GET_WALLET_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: GET_WALLET_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

export const setWalletDetails = (walletDetails) => ({
    type: SET_WALLET_DETAILS,
    payload: walletDetails,
});

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};