import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FcAcceptDatabase } from 'react-icons/fc';
import { MdOutlineCancel } from 'react-icons/md';

const MobileOrderItem = ({ order }) => {
  const navigate = useNavigate();

  if (!order) {
    console.error('Order object is missing');
    return <p className="text-red-500">Order object is missing.</p>;
  }

  const orderId = order.order_id;
  if (!orderId) {
    console.error('Order ID is undefined:', order);
    return <p className="text-red-500">Order ID is missing.</p>;
  }

  const getStatusDetails = (status) => {
    switch (status) {
      case 'New':
        return {
          message: 'Processing',
          color: 'text-blue-500',
          icon: <FcAcceptDatabase className="text-blue-500" />,
        };

      case 'Canceled':
        return {
          message: 'Order Canceled',
          color: 'text-red-300',
          icon: <MdOutlineCancel className="text-red-300" />,
        };

      default:
        return {
          message: status,
          color: 'text-gray-500',
          icon: null,
        };
    }
  };

  const { message, color, icon } = getStatusDetails(order.status);

  const handleClick = () => {
    if (orderId) {
      navigate(`/account/order_details/${orderId}`);
    } else {
      console.error('Order ID is still undefined');
    }
  };

  return (
    <div
      className="order-item border rounded-xl p-2 lg:p-4  mt-4 mb-2 lg:mb-4 bg-white flex justify-between items-center cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex flex-col space-y-2">
        <div className="flex space-x-2">
          {order.order_items.slice(0, 3).map((item, index) => (
            <img
              key={index}
              src={item.product_image_0}
              alt={item.Product_name}
              className="w-14 h-14 object-cover rounded-lg border"
            />
          ))}
          {order.order_items.length > 4 && (
            <div className="flex items-center justify-center w-14 h-14 rounded-lg border">
              <span className="text-lg">+{order.order_items.length - 4}</span>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <h2 className="flex flex-row items-center text-sm font-semibold text-gray-800 mb-1">
            <div className="mr-2">
              Order <span className={color}>{message}</span>
            </div>
            <div className="text-lg">{icon}</div>
          </h2>
          <p className="text-xs text-gray-500">
            Placed at{" "}
            {new Date(order.order_date).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}
            ,{" "}
            {new Date(order.order_date).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </div>
      </div>

      <div className="text-sm font-semibold text-gray-800">
        ₹ {parseFloat(order.total_price).toFixed(2)}
      </div>
    </div>
  );
};

export default MobileOrderItem;
