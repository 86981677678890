import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPaymentGateways,
  updatePaymentGateway,
  togglePaymentGatewayStatus,
  clearErrors,
} from "../../actions/paymentGatewayActions";
import {
  Box,
  Typography,
  Switch,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
} from "@mui/material";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";

const PaymentGateway = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { gateways, loading, error } = useSelector(
    (state) => state.paymentGateways
  );

  const [gatewayData, setGatewayData] = useState([]);

  // Fetch gateways on mount
  useEffect(() => {
    dispatch(getPaymentGateways());
  }, [dispatch]);

  // Populate gatewayData when gateways are fetched
  useEffect(() => {
    if (gateways.length > 0) {
      const initialData = gateways.reduce((acc, gateway) => {
        acc[gateway.id] = {
          api_key: gateway.api_key || "",
          api_secret: gateway.api_secret || "",
          environment: gateway.status || "Live",
          title: gateway.title || "",
        };
        return acc;
      }, {});
      setGatewayData(initialData);
    }
  }, [gateways]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  const handleSave = (id, data) => {
    dispatch(updatePaymentGateway(id, data));
  };

  const handleToggleStatus = async (id, isActive) => {
    try {
      // Call the API to toggle the status
      await dispatch(togglePaymentGatewayStatus(id, isActive));
      
      // Update only the specific gateway in local state
      setGatewayData((prevGateways) =>
        Array.isArray(prevGateways)
          ? prevGateways.map((gateway) =>
              gateway.id === id ? { ...gateway, is_active: isActive } : gateway
            )
          : prevGateways
      );      
    } catch (error) {
      console.error("Failed to update status:", error);
      alert("An error occurred while updating the status.");
    }
  };
  

  return (
    <Box className="container" sx={{ padding: 2 }}>
      <Box sx={{ paddingX: 2 }}>
        <Box className="flex items-center gap-3 font-semibold text-xl">
          <AssuredWorkloadIcon />
          <Typography variant="h5">Payment Gateway</Typography>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <Grid container spacing={2}>
            {loading ? (
              <Typography>Loading...</Typography>
            ) : (
              gateways.map((gateway) => (
                <Grid item xs={12} sm={6} key={gateway.id}>
                  <Card variant="outlined" sx={{ width: "100%" }}>
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {gateway.name.toUpperCase()}
                      </Typography>
                      <Box className="flex justify-between items-center mb-4">
                        <Typography variant="body2" sx={{ marginRight: 1 }}>
                          {gateway.is_active ? "On" : "Off"}
                        </Typography>
                        <Switch
                          checked={gateway.is_active}
                          onChange={(e) =>
                            handleToggleStatus(gateway.id, e.target.checked)
                          }
                        />
                      </Box>
                      <CardMedia
                        component="img"
                        image={
                          gateway.logo_url || "https://placehold.co/100x100"
                        }
                        alt={gateway.name}
                        sx={{
                          width: 100,
                          height: 100,
                          margin: "0 auto",
                          objectFit: "cover",
                        }}
                      />
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={{ marginY: 2 }}
                      >
                        <Select
                          value={gatewayData[gateway.id]?.environment || "Live"}
                          onChange={(e) =>
                            setGatewayData({
                              ...gatewayData,
                              [gateway.id]: {
                                ...gatewayData[gateway.id],
                                environment: e.target.value,
                              },
                            })
                          }
                        >
                          <MenuItem value="Live">Live</MenuItem>
                          <MenuItem value="Test">Test</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="API Key"
                        margin="normal"
                        value={gatewayData[gateway.id]?.api_key || ""}
                        onChange={(e) =>
                          setGatewayData({
                            ...gatewayData,
                            [gateway.id]: {
                              ...gatewayData[gateway.id],
                              api_key: e.target.value,
                            },
                          })
                        }
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="API Secret"
                        margin="normal"
                        value={gatewayData[gateway.id]?.api_secret || ""}
                        onChange={(e) =>
                          setGatewayData({
                            ...gatewayData,
                            [gateway.id]: {
                              ...gatewayData[gateway.id],
                              api_secret: e.target.value,
                            },
                          })
                        }
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Payment Gateway Title"
                        margin="normal"
                        value={gatewayData[gateway.id]?.title || ""}
                        onChange={(e) =>
                          setGatewayData({
                            ...gatewayData,
                            [gateway.id]: {
                              ...gatewayData[gateway.id],
                              title: e.target.value,
                            },
                          })
                        }
                      />
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleSave(gateway.id, gatewayData[gateway.id])
                        }
                        sx={{ marginTop: 2 }}
                      >
                        Save {gateway.name} Data
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentGateway;
