import React from 'react';
import { useNavigate } from 'react-router-dom';

const ShareableLink = ({ items, subStoreId, uniqueId, onAccept }) => {
    const navigate = useNavigate();
  
    // Create a URL with the items and subStoreId encoded in the state
    const handleShareLink = () => {
      const link = `/sub-store/accept-invoice/${uniqueId}`; // Include unique ID in the path
      navigate(link, { state: { items, subStoreId } });
    };

    console.log("items in sharable link:", items)
  
    return (
      <div>
        <h2>Shareable Link</h2>
        <button onClick={handleShareLink} className="bg-blue-500 text-white py-2 px-4 rounded">
          Share Link
        </button>
        <button onClick={onAccept} className="bg-green-500 text-white py-2 px-4 rounded">
          Accept
        </button>
      </div>
    );
  };

export default ShareableLink;