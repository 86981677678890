import React from 'react'

const Message = ({ notification }) => {
  return (
    <div>
        <div id="flex justify-between items-center text-[20px] font-bold">
        {/* image is optional */}
        {notification.image && (
          <div id="flex items-center h-[100px] object-contain">
            <img src={notification.image} width={100} />
          </div>
        )}
        <span>{notification.title}</span>
      </div>
      <div id="mt-10 text-center">{notification.body}</div>
    </div>
  )
}

export default Message