import {
    GET_PAYMENT_GATEWAYS_REQUEST,
    GET_PAYMENT_GATEWAYS_SUCCESS,
    GET_PAYMENT_GATEWAYS_FAIL,
    UPDATE_PAYMENT_GATEWAY_REQUEST,
    UPDATE_PAYMENT_GATEWAY_SUCCESS,
    UPDATE_PAYMENT_GATEWAY_FAIL,
    TOGGLE_PAYMENT_GATEWAY_STATUS_REQUEST,
    TOGGLE_PAYMENT_GATEWAY_STATUS_SUCCESS,
    TOGGLE_PAYMENT_GATEWAY_STATUS_FAIL,
    CLEAR_ERRORS,
    GET_PAYMENT_GATEWAY_BY_NAME_REQUEST,
    GET_PAYMENT_GATEWAY_BY_NAME_SUCCESS,
    GET_PAYMENT_GATEWAY_BY_NAME_FAIL,
} from "../constants/paymentGatewayConstants";
import axiosInstance from "../utils/api";

// Get all payment gateways
export const getPaymentGateways = () => async (dispatch) => {
    try {
        dispatch({ type: GET_PAYMENT_GATEWAYS_REQUEST });
        const { data } = await axiosInstance.get("/payment-gateways");
        dispatch({ type: GET_PAYMENT_GATEWAYS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: GET_PAYMENT_GATEWAYS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Update payment gateway details
export const updatePaymentGateway = (id, paymentGatewayData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PAYMENT_GATEWAY_REQUEST });
        const { data } = await axiosInstance.put(`/payment-gateways/update?id=${id}`, paymentGatewayData);
        dispatch({ type: UPDATE_PAYMENT_GATEWAY_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: UPDATE_PAYMENT_GATEWAY_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Toggle payment gateway status
export const togglePaymentGatewayStatus = (id, isActive) => async (dispatch) => {
    try {
        dispatch({ type: TOGGLE_PAYMENT_GATEWAY_STATUS_REQUEST });
        const { data } = await axiosInstance.put("/payment-gateways/toggle-status", { id, is_active: isActive });
        dispatch({ type: TOGGLE_PAYMENT_GATEWAY_STATUS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: TOGGLE_PAYMENT_GATEWAY_STATUS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Get payment gateway by name
export const getPaymentGatewayByName = (name) => async (dispatch) => {
    try {
        dispatch({ type: GET_PAYMENT_GATEWAY_BY_NAME_REQUEST });

        const { data } = await axiosInstance.get(`/payment-gateways/name?name=${name}`);

        dispatch({ type: GET_PAYMENT_GATEWAY_BY_NAME_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: GET_PAYMENT_GATEWAY_BY_NAME_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};


// Clear errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};