import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalletDetails } from "../../actions/walletAction";
import { clearErrors, getVirtualAccountIdByStoreId } from "../../actions/virtualAccountActions";
import axios from "axios";
import axiosInstance from "../../utils/api";
import AreaChart from "./AreaChart";

const MainData = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, loading, isAuthenticated } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.allOrders)
  const wallet = useSelector((state) => state.wallet)
  const { virtualAccountId, error } = useSelector((state) => state.virtualAccount);
  
  const storeId = user?.id;

  console.log("store id for getting virtual account:", storeId);
  

    useEffect(() => {
        if (storeId) {
            dispatch(getVirtualAccountIdByStoreId(storeId));
        }

        // Clear errors when the component unmounts
        return () => {
            dispatch(clearErrors());
        };
    }, [dispatch, storeId]);


     // Log the virtual account ID and any errors
  useEffect(() => {
    if (error) {
      console.error("Error fetching virtual account ID:", error);
    }
    if (virtualAccountId) {
      console.log("Fetched virtual account ID:", virtualAccountId);
      dispatch(getWalletDetails(virtualAccountId)); // Fetch wallet details using the virtual account ID
    }
  }, [dispatch, virtualAccountId, error]);


  console.log("Main data:", orders);
  console.log("Wallet data:", wallet)
  console.log("Virtual account:", virtualAccountId)


  const getOrderCounts = (status) => {
    return orders.filter(order => order.status === status).length;
  }

  useEffect(() => {
    dispatch(getWalletDetails(virtualAccountId)); // Pass the virtual account ID to the action
}, [dispatch, virtualAccountId]);


const handleAddFund = async () => {
  const bankDetails = JSON.parse(localStorage.getItem("bankDetails"));

  if (!bankDetails) {
    alert("Bank details not found in localStorage.");
    return;
  }

  const amountToAdd = 1000; // Amount to add in paise (1000 paise = 10 INR)

  try {
    // Create an order with Razorpay, including the virtual account ID in metadata
    const orderResponse = await axiosInstance.post(`/create-order`, {
      amount: amountToAdd, // Amount in paise
      currency: "INR",
      receipt: "receipt#1", // Optional, can be any string
      virtualAccountId: virtualAccountId, // Include the virtual account ID
    });

    const { id: orderId } = orderResponse.data;

    // Open Razorpay payment dialog
    const options = {
      key: "rzp_test_Cd1cVSHpocrBwT", // Replace with your Razorpay key
      amount: amountToAdd, // Amount in paise
      currency: "INR",
      name: "Your Company Name",
      description: "Adding funds to wallet",
      order_id: orderId,
      handler: async function (response) {
        // Handle successful payment here
        console.log("Payment successful:", response);
        alert("Funds added successfully!");

        // Call your backend to update the wallet balance
        await axiosInstance.post('/update-fund', {
          virtualAccountId: virtualAccountId,
          paymentResponse: response, // Send payment response for verification
        });
      },
      prefill: {
        name: user.name, // Replace with user's name
        email: user.email, // Replace with user's email
        contact: user.contact, // Replace with user's contact number
      },
      theme: {
        color: "#F37254", // Customize color
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  } catch (error) {
    console.error("Error creating order:", error);
    alert("Failed to create order. Please try again.");
  }
};


const handleCreatePaymentLink = async () => {
  try {
    // Ensure the virtual account ID is a string and defined properly
    const virtualAccountId = "va_PKZk3e4Wh7LwOY"; // Replace with the correct virtual account ID if necessary

    const response = await axiosInstance.post('/create-payment-link', {
      amount: 1000, // Amount in paise (e.g., 1000 paise = 10 INR)
      currency: "INR",
      virtualAccountId: virtualAccountId,
      vendorName: user.name,
      vendorContact: user.contact,
      vendorEmail: user.email,
    });

    // Redirect vendor to the payment link
    window.location.href = response.data.paymentLink.short_url;
  } catch (error) {
    console.error("Error generating payment link:", error);
    alert("Failed to generate payment link. Please try again.");
  }
};

const handleVerifyPayment = async (paymentId) => {
  try {
    const response = await axiosInstance.post('/verify-payment', {
      paymentId: paymentId,
      virtualAccountId: "va_PKZk3e4Wh7LwOY", // Replace with your specific virtual account ID if needed
    });

    if (response.data.success) {
      alert('Funds successfully transferred to the virtual account!');
    } else {
      alert('Payment verification failed: ' + response.data.message);
    }
  } catch (error) {
    console.error('Error verifying payment:', error);
    alert('Failed to verify payment. Please try again.');
  }
};


  const popularProducts = [
    { name: "Product 1", sales: 20, ratings: 4.5, img: "https://ik.imagekit.io/efsdltq0e/product_images/product_1720781237454_Prodouct_img_0_hFg_Fe-qAV" },
    { name: "Product 2", sales: 15, ratings: 4.5, img: "https://ik.imagekit.io/efsdltq0e/product_images/product_1720781249748_Prodouct_img_0_rqXrGrKFy" },
    { name: "Product 2", sales: 15, ratings: 4.5, img: "https://ik.imagekit.io/efsdltq0e/product_images/product_1720781249748_Prodouct_img_0_rqXrGrKFy" },
  ]

  const topSellingProducts = [
    { name: "Product 3", sales: 30, img: "https://ik.imagekit.io/efsdltq0e/product_images/product_1720781237454_Prodouct_img_0_hFg_Fe-qAV" },
    { name: "Product 4", sales: 25, img: "https://ik.imagekit.io/efsdltq0e/product_images/product_1720781237454_Prodouct_img_0_hFg_Fe-qAV" },
  ]

  return (
    <>
      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4">
        <div className="p-2 mb-4">
          <h1 className="text-lg font-bold">Order Analytics</h1>
        </div>
        <div>
          <AreaChart />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/Pending.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Pending
              </h4>
            </div>
            <h2 className="text-blue-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("New")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/Confirmed.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Confirmed
              </h4>
            </div>
            <h2 className="text-green-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Accepted")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/Packaging.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Packaging
              </h4>
            </div>
            <h2 className="text-red-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Packaging")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/out-of-delivery.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Out for Delivery
              </h4>
            </div>
            <h2 className="text-green-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Dispatched")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/delivered.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Delivered
              </h4>
            </div>
            <h2 className="text-green-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Delivered")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/canceled.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Canceled
              </h4>
            </div>
            <h2 className="text-red-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Canceled")}
            </h2>
          </div>
          <div
            className="flex justify-between items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex items-center">
              <img
                src="/images/returned.png" // Path to your image
                alt="Confirmed"
                className="w-5 h-5" // Adjust the size as needed
              />
              <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                Returned
              </h4>
            </div>
            <h2 className="text-red-500 text-xs sm:text-xl font-bold">
              {getOrderCounts("Returned")}
            </h2>
          </div>
        </div>
      </div>

      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4 mt-8">
        <div className="p-2 mb-4 flex justify-between">
          <h1 className="text-lg font-bold">Store Wallet</h1>
          {/* <button className="text-white font-semibold px-2 py-1 rounded-md bg-primary-blue" onClick={handleCreatePaymentLink}>Add Fund</button> */}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* First Column */}
          <div
            className="lg:col-span-1 flex flex-col justify-center items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-6 h-full cursor-pointer transition-all duration-200 ease-in-out" // Centered content vertically
            onClick={() => navigate("/store/orders")}
          >
            <div className="flex flex-col items-center mb-6">
              <img
                src="/images/withdraw.png"
                alt="Withdraw"
                className="w-16 h-16 mb-3" // Adjusted image size
              />
              <h4 className="text-black text-lg sm:text-xl font-bold mb-1">
                Rs. {wallet.withdrawableAmount}
              </h4>
              <h4 className="text-gray-500 text-sm sm:text-base font-medium">
                Withdrawable Amount
              </h4>
            </div>
            <button className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-lg">
              Withdraw
            </button>
          </div>
          

          {/* Second Column */}
          <div className="lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div
                className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
                onClick={() => navigate("/store/orders")}
              >
                <div className="flex flex-col mr-4 flex-grow">
                  {" "}
                  {/* Add flex-grow to take up remaining space */}
                  <h2 className="text-black text-xl font-bold mb-1">
                  Rs. {wallet.pendingWithdrawal}
                  </h2>
                  <h4 className="text-gray-500 font-medium text-sm">Pending Withdrawel</h4>
                </div>
                <img
                  src="/images/pw.png"
                  alt="Packaging"
                  className="w-12 h-12 ml-auto" // Move image to the right end
                />
              </div>
              <div
                className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
                onClick={() => navigate("/store/orders")}
              >
                <div className="flex flex-col mr-4 flex-grow">
                  {" "}
                  {/* Add flex-grow to take up remaining space */}
                  <h2 className="text-black text-xl font-bold mb-1">
                  Rs. {wallet.totalCommission}
                  </h2>
                  <h4 className="text-gray-500 font-medium text-sm">Total Commission Given</h4>
                </div>
                <img
                  src="/images/tcg.png"
                  alt="Packaging"
                  className="w-12 h-12 ml-auto" // Move image to the right end
                />
              </div>
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                Rs. {wallet.alreadyWithdrawn}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">Already Withdrawn</h4>
              </div>
              <img
                src="/images/aw.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                Rs. {wallet.totalDeliveryCharge}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">Total Delivery Charge Earned</h4>
              </div>
              <img
                src="/images/tdce.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                Rs. {wallet.totalTaxGiven}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">Total Tax Given</h4>
              </div>
              <img
                src="/images/ttg.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
            <div
              className="flex items-center bg-gray-50 text-white rounded-md shadow-lg hover:shadow-xl p-3 h-[6rem] cursor-pointer transition-all duration-200 ease-in-out" // Adjust height and transition
              onClick={() => navigate("/store/orders")}
            >
              <div className="flex flex-col mr-4 flex-grow">
                {" "}
                {/* Add flex-grow to take up remaining space */}
                <h2 className="text-black text-xl font-bold mb-1">
                Rs. {wallet.collectedCash}
                </h2>
                <h4 className="text-gray-500 font-medium text-sm">Collected Cash</h4>
              </div>
              <img
                src="/images/cc.png"
                alt="Packaging"
                className="w-12 h-12 ml-auto" // Move image to the right end
              />
            </div>
          </div>
        </div>
      </div>
      {/* New Section for Popular and Top Selling Products */}
      <div className="mt-8">
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-bold mb-4">Most Popular Products</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {popularProducts.map((product, index) => (
                 <div key={index} className="bg-gray-50 flex flex-col items-center rounded-md border border-slate-100 p-2 w-50 hover:shadow-md md:p-1">
                 <img src={product.img} alt={product.name} className="w-14 h-14 mb-2 border border-blue-400 rounded-md sm:w-20 h-20" />
                 <h3 className="text-sm font-medium mb-1">{product.name}</h3>
                 <span className="text-xs">Ratings: {product.ratings}</span>
               </div>
              ))}
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-4">
            <h2 className="text-xl font-bold mb-4">Top Selling Products</h2>
            <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
              {topSellingProducts.map((product, index) => (
                <div
                className="flex justify-between items-center bg-gray-50 text-white rounded-md hover:shadow-xl p-3 h-[4rem] cursor-pointer transition-all duration-200 ease-in-out"
                onClick={() => navigate("/store/orders")}
              >
                <div className="flex items-center">
                  <img
                    src={product.img} // Path to your image
                    alt="Confirmed"
                    className="w-12 h-12 border border-blue-400" // Adjust the size as needed
                  />
                  <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                    {product.name}
                  </h4>
                </div>
                <h2 className="text-blue-400 text-xs sm:text-xs border border-slate-100 p-1">
                  Sold: {product.sales}
                </h2>
              </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainData;
