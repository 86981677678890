import io from 'socket.io-client';

let socket;

const initSocket = (storeIds) => {
    if (!socket) {
        socket = io('https://backend.vivimart.in', { // Replace with your backend URL
            withCredentials: true, // Enable cookies for session auth
        });

        socket.on('connect', () => {
            console.log('Connected to Socket.io server:', socket.id);
            storeIds.forEach(storeId => {
                socket.emit('registerVendor', { storeId });
                console.log(`Joined room for store ID: ${storeId}`);
            });
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from Socket.io server');
        });
    }
    return socket;
};

const getSocket = () => {
    if (!socket) {
        throw new Error('Socket not initialized');
    }
    return socket;
};

const disconnectSocket = () => {
    if (socket) {
        socket.disconnect();
        socket = null;
    }
};

export { initSocket, getSocket, disconnectSocket };