import { useEffect, useState, useRef } from "react";
import Sidebar from "./Sidebar/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import socket from "../../socket"; // Import your socket connection
import { messaging } from "../../firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useSnackbar } from "notistack";

const Dashboard = ({ activeTab, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [onMobile, setOnMobile] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isOpenNotfi, setIsOpenNotfi] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dashboardRef = useRef(null);
  const [notifications, setNotifications] = useState([]);

  const { user, isAuthenticated } = useSelector((state) => state.user);

  console.log("user data:", user)

  //real time notification using socket.io and firebase cloud messaging
  
  useEffect(() => {
    const requestPermission = async () => {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: "BDIXBLauUEJhim13VZ0npxAvWX8ybqjwugP7Yk5KlmcbADJR6MnIapdUh1H9ft5tJr08i1i4bYTeaX3eecmXZjE", // Replace with your VAPID key
            });
            console.log("FCM Token:", token);
            // Send the token to your backend to register it
            socket.emit('registerVendor', { storeId: user.id }); // Assuming user.id is your store ID
        } else {
            console.error("Notification permission denied");
        }
    };

    requestPermission();

    // Listen for incoming messages
    onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
        setNotifications((prev) => [...prev, payload.notification]);
        displayNotification(payload.notification);
    });

    // Listen for notifications from Socket.IO
    socket.on('newOrder', (data) => {
        console.log('New order notification:', data);
        setNotifications((prev) => [...prev, data]);
        displayNotification(data);
    });

    return () => {
        socket.off('newOrder'); // Clean up the listener on unmount
    };
}, []);


useEffect(() => {
  // Listen for order item status update notifications
  socket.on('orderItemStatusUpdated', (data) => {
      console.log('Order item status updated notification:', data);
      enqueueSnackbar(`Order ID: ${data.orderId} item statuses updated.`, {
          variant: 'info',
          autoHideDuration: 3000,
      });
  });

  return () => {
      socket.off('orderItemStatusUpdated'); // Clean up the listener on unmount
  };
}, []);


const displayNotification = (notification) => {
  // Use enqueueSnackbar to show the notification
  enqueueSnackbar(notification.message || 'New notification', {
    variant: 'info', // You can change this to 'success', 'error', etc.
    autoHideDuration: 10000, // Duration to show the notification
    action: (key) => (
      <CloseIcon
        onClick={() => { /* Close notification logic */ }}
        style={{ cursor: 'pointer', color: 'white' }}
      />
    ),
  });

  // Play notification sound
  const audio = new Audio('/notification.wav'); // Adjust path as necessary
  audio.play();

  // Update notifications state
  setNotifications((prev) => [...prev, notification]);
};

  useEffect(() => {
    const handleResize = () => {
      setOnMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("Dashboard page");
  }, []);

  const setTogglebar = () => {
    setToggle(!toggle);
  };
  useEffect(() => {
    if (onMobile) {
      setToggleSidebar(false);
    }
  }, [onMobile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dashboardRef.current &&
        !dashboardRef.current.contains(event.target)
      ) {
        setToggleSidebar(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    console.log("Logging out...");
  };

  const handleProfile = () => {
    navigate("/store/profile");
  };

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div ref={dashboardRef} className="flex bg-[#f9f9fb] min-h-screen sm:min-w-full">
      {!onMobile && !toggle && <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar} />}
      {toggleSidebar && (
        <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar} />
      )}
      <div
        className={`w-full min-h-screen ${
          toggle ? "" : "lg:ml-[18rem] sm:ml-48 md:ml-[10rem] sm:w-4/5"
        } `}
      >
        {!onMobile && (
          <div className="w-full bg-white shadow-md lg:w-full">
            <div className="flex justify-between items-center p-2 border-b border-gray-300">
              <div className="flex items-center gap-4 text-gray-500">
                <button className="pl-4" onClick={setTogglebar}>
                  <MenuIcon />
                </button>
                <span className="mt-1 text-lg">Overview</span>
              </div>
              <div className="pr-4 cursor-pointer">
                {user && (
                  <div className="relative">
                    <button
                      className="flex items-center space-x-2 bg-white rounded-full p-2 focus:outline-none hover:bg-gray-200"
                      onClick={handleDropdownToggle}
                    >
                      <div className="relative">
                        <img
                          src={user.owner_photo}
                          className="w-10 h-10 rounded-full object-cover shadow-md"
                          alt="User Avatar"
                        />
                        <span className="absolute right-0 bottom-0 w-3 h-3 rounded-full bg-green-500 border-2 border-white"></span>
                      </div>
                      <span className="text-gray-700">{user.name}</span>
                      <span className="ml-2">&#9662;</span>
                    </button>
                    {isDropdownOpen && (
                      <div className="absolute right-0 mt-3 bg-white border-b border-gray-300 rounded-md shadow-lg z-10 w-60">
                        <div className="flex items-center px-4 py-2 border-b border-gray-300">
                          <img
                            src={user.owner_photo}
                            className="w-8 h-8 rounded-full object-cover shadow-md"
                            alt="User Avatar"
                          />
                          <span className="ml-2 text-gray-700">{user.name}</span>
                        </div>
                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={handleProfile}
                        >
                          Profile
                        </button>
                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={handleLogout}
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col gap-6 p-4 pb-6 overflow-hidden">
          <div className="flex justify-between">
            <div>
              <button
                onClick={() => setToggleSidebar(true)}
                className="sm:hidden bg-gray-700 w-10 h-10 rounded-full shadow text-white flex items-center justify-center"
              >
                <MenuIcon />
              </button>
            </div>
            <div className="flex gap-2 sm:hidden">
              {/* Your existing header and content */}
              <div className="flex flex-col gap-6 p-4 pb-6 overflow-hidden">
                    <div className="flex justify-end">
                        <NotificationsIcon
                            className="cursor-pointer"
                            onClick={() => setIsOpenNotfi(!isOpenNotfi)} // Toggle notification dropdown
                        />
                    </div>
                    {/* Notification dropdown */}
                    {isOpenNotfi && (
                        <div className="absolute right-0 mt-3 bg-white border border-gray-300 rounded-md shadow-lg z-10 w-60">
                            {notifications.length > 0 ? (
                                notifications.map((notification, index) => (
                                    <div key={index} className="px-4 py-2 border-b border-gray-300">
                                        <span className="text-gray-700">{notification.title}</span>
                                        <span className="text-sm text-gray-500">{notification.body}</span>
                                    </div>
                                ))
                            ) : (
                                <div className="px-4 py-2">No notifications</div>
                            )}
                        </div>
                    )}
                </div>
              <div className="relative">
                <img
                  src={user.owner_photo}
                  className="w-10 h-10 rounded-full object-cover shadow-md cursor-pointer"
                  alt="User Avatar"
                  onClick={handleDropdownToggle}
                />
                <span className="absolute right-0 bottom-0 w-3 h-3 rounded-full bg-green-500 border-2 border-white"></span>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-3 bg-white border-b border-gray-300 rounded-md shadow-lg z-10 w-60">
                    <div className="flex items-center px-4 py-2 border-b border-gray-300">
                      <img
                        src={user.owner_photo}
                        className="w-8 h-8 rounded-full object-cover shadow-md"
                        alt="User Avatar"
                      />
                      <span className="ml-2 text-gray-700">{user.name}</span>
                    </div>
                    <button
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={handleProfile}
                    >
                      Profile
                    </button>
                    <button
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!isOpenNotfi ? (
            <>
              <div className="flex flex-col rounded-lg">
                <span className="text-xl font-semibold text-gray-700">
                  Welcome, {user.name}
                </span>
                <span className="text-sm text-gray-500 mt-1">
                  Monitor your business analytics and statistics.
                </span>
              </div>              
            </>
          ) : (
            <div>Notifications will be here</div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Dashboard;
